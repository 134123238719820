import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GridSizeSelectBox from "../../../components/grid/gridSizeSelectBox";
import { RootState } from "../../../store/store";
import {
  useAddPositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
  useGetPositionListMutation,
} from "../../../store/slices/dashboard/dashboardApiSlice";
import GridSearchBox from "../../../components/grid/gridSearchBox";
import GridColumnHeader from "../../../components/grid/gridColumnHeader";
import PopupEdit from "./popup-edit";
import MessageBox, { MessageBoxType } from "../../../components/dialog/messagebox/messageBox";
import PopupCreate from "./popup-create";
import PopupDelete from "./popup-delete";
import Pagination from "../../../components/dashboard/pagination";
import { t } from "i18next";
import { userInfo } from "os";

const DashBoardPosition = () => {
  const loginUser: any = useSelector(
    (state: RootState) => state.loginUser.userInfo
  );
  //const user:any = useSelector((state:RootState)=>state.loginUser.userInfo);

  const editInfoDefault = {
    isOpen: false,
    name: "",
    idx: 0,
  };

  const dialogInfoDefault = {
    isOpen: false,
    message: <></>,
    idx: 0,
  };

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
    search: "",
    companyId: loginUser.companyId,
    // companyId:user.companyId,
    lang: "KR",
    sortColumn: "idx",
    sortOrder: true,
  });

  const [gridData, setGridData] = useState({
    list: [],
    totalCount: 0,
  });

  const [createInfo, setCreateInfo] = useState(editInfoDefault);
  const [editInfo, setEditInfo] = useState(editInfoDefault); // 수정창에 대한 정보
  const [deleteInfo, setDeleteInfo] = useState(dialogInfoDefault);

  const [getPositionList] = useGetPositionListMutation();
  const [addPosition] = useAddPositionMutation();
  const [deletePosition] = useDeletePositionMutation();
  const [updatePosition] = useUpdatePositionMutation();

  //#region 공통 컴포넌트
  //------------------------------------------------------------------------------------
  const handlePageChange = (e: any) => {
    setPageInfo({
      ...pageInfo,
      page: e.selected,
    });
  };

  const clickColumnHeader = (columnId: string) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder,
    });
  };

  const clickSizeSelectBox = (size: number) => {
    // console.log(size);
    setPageInfo({
      ...pageInfo,
      size: size,
    });
  };

  const handleQueryChange = (keyword: string) => {
    setPageInfo({
      ...pageInfo,
      search: keyword,
    });
  };
  //------------------------------------------------------------------------------------
  //#endregion

  const getGrid = async () => {
    try {
      const response: any = await getPositionList(pageInfo);
      if (response.data.result === true) {
        // console.log(response);
        setGridData({
          list: response.data.list,
          totalCount: response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  useEffect(() => {
    // 처음 1회 조회
    getGrid();
  }, []);

  useEffect(() => {
    // pageInfo가 바뀔때마다 조회
    getGrid();
  }, [pageInfo]);

  //------------------------------------------------------------------------------------
  //#region  생성
  const clickCreatePosition = () => {
    setCreateInfo({
      isOpen: true,
      name: "",
      idx: 0,
    });
  };

  const clickCreateOk = async (name: string) => {
    try {
      await addPosition({
        up_company_id: loginUser.companyId,
        up_name: name,
        up_lang: "KR",
      });
      setCreateInfo(editInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickCreateCancel = () => {
    setCreateInfo(editInfoDefault);
  };
  //#endregion 생성끝

  const handleEditPosition = (e: any, data: any) => {
    setEditInfo({
      isOpen: true,
      name: data.up_name,
      idx: data.idx,
    });
  };

  const clickEditOk = async (name: string) => {
    try {
      await updatePosition({
        idx: editInfo.idx,
        up_name: name,
        up_lang: "KR",
      });
      setEditInfo(editInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickEditCancel = () => {
    setEditInfo(editInfoDefault);
  };

  const handleDeletePosition = (e: any, data: any) => {
    console.log(data);

    const message = (
      <>
        {`[`}
        <b>{data.up_name}</b>
        {`]`}직급을 삭제하시겠습니까?
      </>
    );
    setDeleteInfo({
      isOpen: true,
      message: message,
      idx: data.idx,
    });
  };

  const clickDeleteYes = async () => {
    try {
      await deletePosition({
        adminId: loginUser.userId,
        idx: deleteInfo.idx,
      });
      setDeleteInfo(dialogInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickDeleteNo = () => {
    setDeleteInfo(dialogInfoDefault);
  };

  const handlePrev = () => {
    if (pageInfo.page === 0) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page - 1,
    });
  };
  const handleNext = () => {
    if (pageInfo.page + 1 === Math.ceil(gridData.totalCount / pageInfo.size)) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };

  useEffect(()=>{
    if(loginUser.companyId){
      setPageInfo({...pageInfo, companyId : loginUser.companyId})
    }
  }, [loginUser.companyId])

  return (
    <>
      <div className="rank_management_container">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3 style={{cursor:'pointer'}}>
                {t("position-main")}<span onClick={()=>clickCreatePosition()}>{t("position-regist")}</span>
              </h3>
            </div>
            <div className="table_area">
              <div>
                <GridSizeSelectBox
                  pageInfo={pageInfo}
                  onClick={clickSizeSelectBox}
                />
                <GridSearchBox onChangeKeyword={handleQueryChange} />
              </div>
              <div>
                <table>
                  <caption>{t("position-caption")}</caption>
                  <thead>
                    <tr>
                      {/* <GridColumnHeader
                        id="idx"
                        caption="아이디"
                        widthStyle="w150"
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      /> */}
                      <GridColumnHeader
                        id="up_name"
                        caption={t("position-name")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.list.map((data: any) => (
                      <tr key={data.idx}>
                        <td>{data.up_name}</td>
                        <td></td>
                        {/* <td>{data.idx}</td> */}
                        <td></td>
                        <td>
                          <button
                            onClick={(e) => handleEditPosition(e, data)}
                            type="button"
                            className="button-icon wh35 skyblue"
                          ></button>
                          <button
                            onClick={(e) => handleDeletePosition(e, data)}
                            type="button"
                            className="button-icon wh35 pink"
                          ></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
             
              <Pagination prevClick={handlePrev} nextClick={handleNext} totalCount={gridData.totalCount} page={pageInfo.page} size={pageInfo.size} />
             
            </div>
          </div>
        </div>
      </div>
      {createInfo.isOpen && (
        <PopupCreate
          name=""
          onSave={clickCreateOk}
          onCancel={clickCreateCancel}
        />
      )}
      {editInfo.isOpen && (
        <PopupCreate
          name={editInfo.name}
          onSave={clickEditOk}
          onCancel={clickEditCancel}
        />
      )}
      {deleteInfo.isOpen && (
        <PopupDelete
        //   boxType={MessageBoxType.YesNo}
          message={deleteInfo.message}
          onClick1={clickDeleteNo}
          onClick2={clickDeleteYes}
        />
      )}
      {/* {deleteInfo.isOpen && (
        <MessageBox
          boxType={MessageBoxType.YesNo}
          message={deleteInfo.message}
          onClick1={clickDeleteNo}
          onClick2={clickDeleteYes}
        />
      )} */}
    </>
  );
};

export default DashBoardPosition;
