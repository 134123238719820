import React from "react";
import moduleStyles from './project.module.css'
import { ProjectHeader } from "./project-header";
import { ProjectFilter } from "./project-filter";
import { ProjectList } from "./project-list";
export interface Props {sideBar:boolean}
export const DashboardProject:React.FC<Props> = ({sideBar}) => {

    return(
        <>
            <div className={`${moduleStyles.topBox} ${moduleStyles.active}`} style={{paddingLeft : sideBar ? '320px' : '60px'}}>
                <ProjectHeader/>
                <ProjectFilter/>
            </div>
            {/* active */}
            <div className={`${moduleStyles.contentBox} ${moduleStyles.active}`}>
                <div className={moduleStyles.listContainer}>
                    <div>
                        <ProjectList/>
                    </div>
                </div>
            </div>
        
        </>
    )
}