import React, { useEffect, useRef } from "react";
import { useUserNameModifyMutation } from "../../../store/slices/member/memberApiSlice";
import { useAppSelector } from "../../../store/storeHooks";

export interface Props {
    lastName : string
    firstName : string
    onSave : Function
    onClose : Function
}

const EditName:React.FC<Props> = ({lastName, firstName, onSave, onClose}) => {
    
    const lastNameRef = useRef<any>()
    const firstNameRef = useRef<any>()

    const [editName] = useUserNameModifyMutation();

    const handleClose = () => {
        onClose()
    }

    const handleSaveName = async() => {
        const lastName = lastNameRef.current.value;
        const firstName = firstNameRef.current.value;
       onSave(lastName, firstName)
    }
    
    useEffect(()=>{
        lastNameRef.current.value = lastName;
        firstNameRef.current.value = firstName;
    },[])

    return(
        <>
        <div className="profile_name_popUp active">
            <div className="wrap">
                <div>
                    <div className="con on">
                        <div className="body">
                            <div className="content">
                                <div>
                                    <h4>성</h4>
                                    <input ref={lastNameRef} type="text" name="" id="" className="input_text" placeholder="성을 입력해 주세요."/>
                                </div>
                                <div>
                                    <h4>이름</h4>
                                    <input ref={firstNameRef} type="text" name="" id="" className="input_text" placeholder="이름을 입력해 주세요."/>
                                </div>
                                <div className="buttonBox">
                                    <button onClick={handleSaveName}>저장</button>
                                    <button onClick={handleClose}>취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg"></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default EditName;