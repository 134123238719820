import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://alink3d.com/api/controller/user";
// const baseUrl = "http://akuser.alink3d.com";

export const modelingPartsApiSlice = createApi({
  reducerPath: "modelingPartsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 부품을 선택하고, 부품정보를 누르면 해당 데이터 조회
    getUserParts : builder.mutation({
      query : (param : any) => {
        const {modelingId, partsId} = param;
        return{
          url : `/getUserParts.php?AssetModelingId=${modelingId}&part_unique_value=${partsId}`,
        }
      }
    }),
    // 부품 스펙 정보 저장
    setUserPartsSpec : builder.mutation({
        query : (body : any) => {
            return{
                url : `/setUserPartsSpec.php`, method : 'post', body
            }
        }
    }),
    // 부품 스펙 정보 수정
    uptUserPartsSpec : builder.mutation({
      query : (body : any) => {
        return{
          url : `/uptUserPartsSpec.php`, method: 'post', body
        }
      }
    }),

    // 부품 도면 조회
    getPartsDrawing : builder.mutation({
      query : (param : any) => {
        const {modelingId, id} = param;
        return{
          url : `/getPartsDrawing.php?AssetModelingId=${modelingId}&idx=${id}`
        }
      }
    }),

    // 부품 도면 저장
    setPartsDrawing : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setPartsDrawing.php`, method : 'post', body
        }
      }
    }),

    // 부품 도면 수정
    uptPartsDrawing : builder.mutation({
      query : (param : any) => {
        const { openYn, idx} = param;
        return{
          url : `/uptPartsDrawing.php?open_yn=${openYn}&idx=${idx}`
        }
      }
    }),
    // 부품 품질검사 조회
    getPartsInspection : builder.mutation({
      query : (param : any) => {
        const {modelingId, id} = param;
        return{
          url : `/getPartsInspection.php?AssetModelingId=${modelingId}&idx=${id}`
        }
      }
    }),
    // 부품 품질검사 등록
    setPartsInspection : builder.mutation({
      query : (body : any) => {
        return {
          url : `/setPartsInspection.php`, method : 'post' , body
        }
      }
    }),
    // 부품 품질검사 삭제
    delPartsInspection : builder.mutation({
      query : (param : any) => {
        const {insp_idx, image_idx} = param;
        return{
          url : `/delPartsInspection.php?insp_idx=${insp_idx}&image_idx=${image_idx}`
        }
      }
    }),
    // 부품 메뉴얼 조회
    getPartsManuel : builder.mutation({
      query : (param : any) => {
        const {id, modelingId} = param;
        return{
          url : `/getPartsManuel.php?idx=${id}&AssetModelingId=${modelingId}`
        }
      }
    }),
    // 부품 메뉴얼 등록
    setPartsManuel : builder.mutation({
      query : (body : any) => {
        return {
          url : '/setPartsManuel.php', method : 'post' , body
        }
      }
    }),
    // 부품 메뉴얼 삭제
    delPartsManuel : builder.mutation({
      query : (param : any) => {
        const {manuel_idx} = param;
        return{
          url : `/delPartsManuel.php?manuel_idx=${manuel_idx}`
        }
      }
    }),
    // 부품 홍보영상 조회
    getPartsVideo : builder.mutation({
      query : (param : any) => {
        const {idx, modelingId} = param;
        return{
          url : `/getPartsVideo.php?idx=${idx}&AssetModelingId=${modelingId}`
        }
      }
    }),
    // 부품 홍보영상 등록
    setPartsVideo : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setPartsVideo.php`, method :'post', body
        }
      }
    }),
    // 부품 홍보영상 삭제
    delPartsVideo : builder.mutation({
      query : (param : any) => {
        const {video_idx} = param; 
        return{
          url : `/delPartsVideo.php?video_idx=${video_idx}`
        }
      }
    }),
    // 부품 트러블 조회
    getPartsTrouble : builder.mutation({
      query : (param : any) => {
        const {modelingId, idx} = param;
        return{
          url : `/getPartsTrouble.php?AssetModelingId=${modelingId}&idx=${idx}`
        }
      }
    }),
    // 부품 트러블 등록
    setPartsTrouble : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setPartsTrouble.php', method: 'post' , body
        }
      }
    }),
    // 부품 트러블 삭제
    delPartsTrouble : builder.mutation({
      query : (param: any) => {
        const {idx} = param;
        return{
          url : `/delPartsTrouble.php?trouble_idx=${idx}`
        }
      }
    }),
    // 부품 메모 조회
    getPartsMemo : builder.mutation({
      query : (param : any) => {
        const {modelingId , idx} = param;
        return{
          url : `/getPartsMemo.php?AssetModelingId=${modelingId}&idx=${idx}`,
        }
      }
    }),
    // 부품 메모 등록 
    setPartsMemo : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setPartsMemo.php', 
          method: 'post', body
        }
      }
    }),
    // 부품 메모 삭제
    delPartsMemo : builder.mutation({
      query : (param : any) => {
        const {idx} = param;
        return{
          url : `/delPartsMemo.php?memo_idx=${idx}`
        }
      }
    }),
    uptPartsMemo : builder.mutation({
      query : (param : any) => {
        const {idx, yn} = param;
        return{
          url : `/uptPartsMemo.php?memo_idx=${idx}&open_yn=${yn}`
        }
      }
    })
  }),
});

export const {
  useGetUserPartsMutation, useSetUserPartsSpecMutation, useSetPartsDrawingMutation, useGetPartsDrawingMutation, useSetPartsInspectionMutation, useGetPartsInspectionMutation, useUptUserPartsSpecMutation,
  useUptPartsDrawingMutation, useDelPartsInspectionMutation, useSetPartsManuelMutation, useGetPartsManuelMutation, useDelPartsManuelMutation, useSetPartsVideoMutation, useGetPartsVideoMutation,
  useDelPartsVideoMutation, useSetPartsTroubleMutation, useGetPartsTroubleMutation, useDelPartsTroubleMutation, useSetPartsMemoMutation, useGetPartsMemoMutation, useDelPartsMemoMutation,
  useUptPartsMemoMutation
} = modelingPartsApiSlice;