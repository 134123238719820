import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetAssembleTroubleMutation } from "../../../../store/slices/modeling/modelingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";

export interface Props {getTrouble : Function; onClose : Function}
const ProductTroubleRegist:React.FC<Props> = ({getTrouble,onClose}) => {
    const {modelingId} = useParams();
    const userData : any = useAppSelector(state => state.loginUser.userInfo)
    const [setAssembleTroubleApi] = useSetAssembleTroubleMutation()
    const [troubleInfo, setTroubleInfo] = useState<any>({modelingId : modelingId, lang : "KR", troubleFiles : []})
    const handeLangChage = (type : string) => {
        if(type === 'KR'){setTroubleInfo({...troubleInfo, lang : "KR"})}
        else {setTroubleInfo({...troubleInfo, lang : "EN"})}
    }
    const handleFilesUpload = (files : any) => {
        if(files.length === 1) {
            setTroubleInfo((prev : any) => ({
                ...prev,
                troubleFiles: files,
            }));
        } else {
            alert("한 개의 파일만 업로드 가능합니다.")
        }
    }
    const handleDelFiles = (index : number) => {
        const updatedFiles = [...troubleInfo.troubleFiles];
        updatedFiles.splice(index, 1);
        setTroubleInfo({...troubleInfo, troubleFiles : updatedFiles})
    }
    const handleSave = async() => {
        if(troubleInfo.troubleFiles.length < 1){alert("최소 하나의 파일은 업로드하셔야 합니다.")}
        const formData = new FormData();
        formData.append("AssetModelingId", troubleInfo.modelingId)
        formData.append("UserId", userData.userId)
        formData.append("trouble_lang" , troubleInfo.lang)
        formData.append("trouble_files" , troubleInfo.troubleFiles[0])
        const result : any = await setAssembleTroubleApi(formData);
        if(result.data.result === true ) {alert("등록이 완료되었습니다.");getTrouble(); onClose();}
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
                <div className={PageStyles.miniPopUp_titleBox}>
                    <span className={PageStyles.active}>트러블슈팅 업로드</span>
                </div>
                <div className={PageStyles.scrollBox}>
                    <div className={PageStyles.paddingBox}>
                        <div className={PageStyles.langBoxWrap}>
                            <div className={PageStyles.langBox}>
                                <span className={[PageStyles.lang, PageStyles.lang_KR, troubleInfo.lang==="KR"?PageStyles.active:''].join(" ")} onClick={()=>handeLangChage("KR")}>KR</span>
                                <span className={[PageStyles.lang, PageStyles.lang_EN, troubleInfo.lang==="EN"?PageStyles.active:''].join(" ")} onClick={()=>handeLangChage("EN")}>EN</span>
                            </div>
                            <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 영상 업로드가 가능합니다.</p>
                        </div>

                        <Dropzone type={'PDF'} onFileUpload={handleFilesUpload}/>
                        {troubleInfo?.troubleFiles?.map((files:any,index:number)=>(
                            // <p>{files.name}</p>
                            <ul className={PageStyles.dropZone_attachmentBox} key={index}>
                                <li>
                                    <div className={PageStyles.dropZone_attachmentList}>
                                        <p key={index}>{files.name}</p>
                                        <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/redCloseBtn.png" alt="deleteBtn"/></button>
                                    </div>
                                </li>
                            </ul>
                        ))}
                    </div>
                    <div className={PageStyles.dots_troubleUpload_btnBox}>
                        <div className={[PageStyles.dots_trouble_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                        <button type="button" className={PageStyles.dots_trouble_submit} id="dots_trouble_submit" onClick={handleSave}>등록</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductTroubleRegist;