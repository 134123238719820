import { combineReducers, configureStore, getDefaultMiddleware  } from '@reduxjs/toolkit'
import { counterApiSlice } from './slices/counter/counterApiSlice'
import {courseReducer} from './slices/practice/practiceSlice';
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { xvlSlice } from './slices/xvlStatus/xvlSlice';
import menuSlice from './slices/menu/menuSlice';
import { dashBoardApiSlice } from './slices/dashboard/dashboardApiSlice';
import { memberApi } from './slices/member/memberApiSlice';
import sampleReducer from './slices/counter/counterSlice'
import userReducer  from './slices/member/userLocalSlice';
import loginUserReducer from './slices/member/loginUserSlice';
import { todoListApi } from './slices/todoList/todoListApiSlice';
import { taskListReducer } from './slices/todoList/todoListSlice';
import { taskPopupApi } from './slices/todoList/taskpopupApiSlice';
import { dashBoardModelingOrderApiSlice } from './slices/dashboard/dashboardModelingOrderApiSlice';
import { modelingApiSlice } from './slices/modeling/modelingApiSlice';
import { modelingPartsApiSlice } from './slices/modeling/modelingPartsApiSlice';
import { marineConnect } from './slices/modeling/marineConnectApiSlice';
import { modelingIotApiSlice } from './slices/modeling/modelingIotApiSlice';

const reducer = combineReducers({sampleReducer,courseReducer:courseReducer.reducer, xvlSlice:xvlSlice.reducer})

export const store = configureStore({
  reducer:  {
    reducer,
    loginUser : loginUserReducer,
    sample : sampleReducer,
    taskInfo : taskListReducer.reducer,
    menu : menuSlice.reducer,
    [dashBoardApiSlice.reducerPath]:dashBoardApiSlice.reducer,
    [dashBoardModelingOrderApiSlice.reducerPath]: dashBoardModelingOrderApiSlice.reducer,
    user : userReducer,
    [counterApiSlice.reducerPath] : counterApiSlice.reducer,
    [memberApi.reducerPath] : memberApi.reducer,
    [todoListApi.reducerPath] : todoListApi.reducer,
    [taskPopupApi.reducerPath] : taskPopupApi.reducer,
    [modelingApiSlice.reducerPath] : modelingApiSlice.reducer,
    [modelingPartsApiSlice.reducerPath] : modelingPartsApiSlice.reducer,
    [marineConnect.reducerPath] : marineConnect.reducer,
    [modelingIotApiSlice.reducerPath] : modelingIotApiSlice.reducer
  },
  
  middleware: getMiddleware => getMiddleware().concat(memberApi.middleware,dashBoardApiSlice.middleware,dashBoardModelingOrderApiSlice.middleware,
    modelingApiSlice.middleware, modelingPartsApiSlice.middleware, marineConnect.middleware, modelingIotApiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development'
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
