import React, { FC, useState, useEffect, useRef } from 'react';
import * as Icon from 'react-feather';
import { toLocaleToDate } from '../../utils/toLocaleDate';
// import AssigneePopup from '../dashboard/popup/assigneeMenu/assigneePopup';
import GroupProfile from './groupProfile';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarPopup from './dayPickerMenu/dayPicker';
import { toLocaleString } from '../../utils/toLocaleString';
import { ModelingOrderDetailType, ModelingOrderType } from '../../types/modelingOrderType';
import { useEditOrderModelMutation, useGetOrderModelDetailMutation } from '../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import styled from 'styled-components';

interface GroupListItemProps {
  groupColor: string;
  data: ModelingOrderType;
  openPopup: (data: any) => void;
}

const GroupListItem: FC<GroupListItemProps> = (props) => {
  console.log(props.data)
  const [detailInfo, setDetailInfo] = useState<ModelingOrderDetailType>();
  const { groupColor, data, openPopup } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [getOrderModelDetail] = useGetOrderModelDetailMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { firstName, lastName } = data?.requestUser;
  const fullName = lastName?.concat(firstName);

  const [editOrderModel] = useEditOrderModelMutation();

  const [writeActive, setWriteActive] = useState(false);

  const [name, setName] = useState(data.subject);

  const showPopup = () => {
    if (writeActive) {
      return;
    }
    openPopup(data);
  }

  const handleWriteActive = () => {
    setWriteActive(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }

    if (name === data.subject) {
      return;
    }
  }

  const editList = async () => {
    try {
      const result = await editOrderModel({
        modelingId: data.modelingId,
        subject: name
      })

      console.log(result);
    } catch (err) {
      console.error(err);
    }
  }

  const handleSave = (e: any) => {
    if (e.key === 'Enter') {
      editList();
      setWriteActive(false);
    }
  }

  useEffect(() => {
    const handleOutSide = (e: any) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setWriteActive(false);
      }
    }
    document.addEventListener('mousedown', handleOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOutSide);
    }
  }, [inputRef])

  return (
    <>
      <div className='rece-row' onClick={showPopup}>
        <div className='rece-title'>
          <div className="triangle-topleft" style={{ borderTop: `15px solid ${groupColor}` }}>

          </div>
          <div className="rece-name">
            <span>{data.modelingNo}</span>
          </div>
          <div className="rece-name2" style={{ }}>
            <span onClick={showPopup}>{data.subject}</span>
            <textarea></textarea>
          </div>
        </div>

        <div className='rece-list-field'>

          {/* 의뢰 접수자 영역 */}
          <div className="rece-col company">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>{fullName}</span>
                </div>
              </div>
            </div>
          </div>

          {/* 접수일자 영역 */}
          <div className="rece-col date-2">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>
                    <p style={{ cursor: 'pointer' }}>{toLocaleToDate(data.requestDate)}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* 제작 완료예상 일자 영역 */}
          <div className="rece-col date-2">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>
                    {
                      !data.expectedDate ? <Icon.Calendar style={{ width: '19', height: '19', color: '#888888' }} /> : toLocaleToDate(data.expectedDate)
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* 견적 비용 예상 일자 영역 */}
          <div className="rece-col pay">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  {
                    !data.amount ? "-" : toLocaleString(String(data.amount))
                  }
                </div>
              </div>
            </div>
          </div>
          <GroupProfile managers={data.managers} />
        </div>
      </div>

    </>
  )
}

export default GroupListItem;

const StyledInput = styled.input<{ active: boolean }>`
    border: none;
    outline: none;
    cursor: pointer;
    background: ${(props) => props.active ? '#ffffff' : 'none'};
    &:focus {
        border-radius: 4px;
        outline: none;
        border: none;
    }

`