import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { idText } from "typescript";
import PartsMemoRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_memoRegist";
import { useDelPartsMemoMutation, useGetPartsMemoMutation, useUptPartsMemoMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props {partsName : string; partsId : string}
const PartsMemo:React.FC<Props> = ({partsName, partsId}) => {
    const {modelingId} : any = useParams()
    const [getPartsMemoApi] = useGetPartsMemoMutation()
    const [delPartsMemoApi] = useDelPartsMemoMutation()
    const [uptPartsMemoApi] = useUptPartsMemoMutation()
    const [memoList, setMemoList] = useState<any>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setIsOpen(prev=>!prev)
    }
    const getPartsMemo = async() => {
        const param = {modelingId : modelingId, idx : partsId}
        const result : any = await getPartsMemoApi(param)
        if(result.data.result === true){
            setMemoList(result.data.list)
        }
    }

    const handleDelMemo = async(idx : string) => {
        const param = {idx : idx};
        const confirm = window.confirm("해당 메모를 삭제하시겠습니까?");
        if(confirm){
            const result : any = await delPartsMemoApi(param)
            if(result.data.result === true){
                getPartsMemo()
            }
        }
    }

    const handleOpenYnChange = async(idx: string, yn : string) => {
        const param = {idx: idx, yn : yn === 'Y'?'N':'Y'}
        const confirm = window.confirm(yn === 'Y'?'비공개로 전환하시겠습니까?':'공개로 전환하시겠습니까?')
        if(confirm){
            const result : any = await uptPartsMemoApi(param)
            if(result.data.result === true){getPartsMemo()}
        }
    }

    useEffect(()=>{
        getPartsMemo()
    }, [partsId])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_7, PageStyles.active].join(" ")}>
            <div className={PageStyles.memoBox} id="parts_memo_list">
                <ul>
                    {memoList?.map((data : any) => (
                    <li key={data.idx}>
                        <div className={PageStyles.top}>
                            <div className={PageStyles.textArea}>{data.parts_memo_contents}</div>
                        </div>
                        <div className={PageStyles.bottom}>
                            <div>
                                <span className={PageStyles.name}>{data.last_kr}{data.first_kr}</span>
                                <span className={PageStyles.date}>{data.parts_memo_mdate}</span>
                            </div>
                            <div>
                                <div className={PageStyles.radioBox}>
                                    <div>
                                        <input type="radio" name={`sample${data.idx}`} id={`sample${data.idx}`} onClick={()=>handleOpenYnChange(data.idx,data.parts_memo_publish)} checked={data.parts_memo_publish==='Y'?true:false}/>
                                        <label htmlFor={`sample${data.idx}`}>공개</label>
                                    </div>
                                    <div>
                                        <input type="radio" name={`sam${data.idx}`} id={`sam${data.idx}`} onClick={()=>handleOpenYnChange(data.idx,data.parts_memo_publish)} checked={data.parts_memo_publish==='N'?true:false}/>
                                        <label htmlFor={`sam${data.idx}`}>비공개</label>
                                    </div>
                                </div>
                                <div className={PageStyles.btnBox}>
                                    <button className={PageStyles.thumb_closeBtn} onClick={()=>handleDelMemo(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="close"/></button>
                                </div>
                            </div>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
            {isOpen && <PartsMemoRegist getMemo={getPartsMemo} partsName={partsName} partsId={partsId} onClose={handleOpen}/>}
        <button className={[PageStyles.memoAddBtn, PageStyles.blueAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
    </div>
        </>
    )
}

export default PartsMemo;