import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsInspectionMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import Dropzone from "../../../dropzone/dropzone";

export interface Props {getData:Function; partsName:string; partsId : string; inspectType: any; onClose : any}
const PartsInspectionRegist:React.FC<Props> = ({getData, partsName, partsId, inspectType, onClose}) => {
    const {modelingId} = useParams()
    const [setPartsInspectionApi] = useSetPartsInspectionMutation()
    const [pdfFileName, setPdfFileName] = useState<string>('')
    const [inspectInfo, setInspectInfo] = useState<any>({modelingId : modelingId, partsName : partsName, partsId : partsId, inspectType : 0, passYn : 'Y', inspectComment: '', inspectPdfFile : '', inspectImageFiles : []})
    const handleChange = (e:any) => {
        const {name, type, value} = e.target;
        if(type==='file'){setInspectInfo({...inspectInfo, inspectPdfFile : e.target.files[0]}); setPdfFileName(e.target.files[0].name)}
        // if(type==='textarea'){setInspectInfo({...inspectInfo, [name] : value})}
        else {setInspectInfo({...inspectInfo, [name] : value})}
    }
    const handleDelFiles = (index : number) => {
        const updatedFiles = [...inspectInfo.inspectImageFiles];
        updatedFiles.splice(index, 1)
        setInspectInfo({...inspectInfo, inspectImageFiles : updatedFiles})
    }
    const handleMultiUpload = (files:any) =>{
        setInspectInfo((prev : any) => ({
            ...prev,
            inspectImageFiles: files,
        }));
    }
    const handlePass = (yn : string) => {
        setInspectInfo({...inspectInfo, passYn : yn})
    }
    const handleSave = async() => {
        const formData = new FormData()
        formData.append('idx' , inspectInfo.partsId)
        formData.append('AssetModelingId' , inspectInfo.modelingId)
        formData.append('part_unique_value', inspectInfo.partsName)
        formData.append('parts_insp_result', inspectInfo.passYn)
        formData.append('parts_insp_type', inspectInfo.inspectType)
        formData.append('parts_insp_comments', inspectInfo.inspectComment)
        formData.append('pdf_files', inspectInfo.inspectPdfFile)
        inspectInfo.inspectImageFiles.forEach((file : any, index : number) => {
            formData.append(`inspection_files_${index+1}`, file)
        })
        formData.append('files_length' , inspectInfo.inspectImageFiles.length)
        const result : any = await setPartsInspectionApi(formData)
        if(result.data.result === true) {alert("등록이 완료되었습니다."); onClose(); getData();}
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>품질검사 등록</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.dataWrap}>
                        <div className={PageStyles.selectBox}>
                            <label htmlFor="">성적서</label>
                            <select name="inspectType" id="parts_insp_type" onChange={handleChange}>
                                <option value="none" disabled selected className="disabled">성적서 유형을 선택해 주세요.</option>
                                {inspectType.map((data:any) => (
                                    <option value={data.code_idx} className="test">{data.code_name}</option>
                                ))}
                                
                            </select>
                        </div>

                        <div className={PageStyles.reportUploadBox}>
                            <label htmlFor="">성적서 및 이미지 업로드</label>
                            <div className={PageStyles.filebox}>
                                <input className={PageStyles.reportFile_uploadName} value={pdfFileName} placeholder="Files supported : PDF" readOnly/>
                                <label htmlFor="parts_insp_pdsFile">파일첨부</label> 
                                <input type="file" id="parts_insp_pdsFile" accept=".pdf" onChange={handleChange}/>
                            </div>
                        </div>
                                    
                        <Dropzone onFileUpload={handleMultiUpload} type={'image'}/>
                        <ul className={PageStyles.dropZone_imgBox}>
                        {inspectInfo?.inspectImageFiles?.map((files:any, index:number) => (
                            // <img src={URL.createObjectURL(files)} alt="pic"/>
                            <li key={index}>
                                <div>
                                    <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/whiteCloseBtn.png" alt="" /></button>
                                    <img src={URL.createObjectURL(files)} alt="pic"/>
                                </div>
                            </li>
                        ))}
                        </ul>                    
                        <div className={PageStyles.commentBox}>
                            <div className={PageStyles.radioBox}>
                                <div>
                                    <input type="radio" name="parts_insp_result" id="parts_insp_result_y" onChange={()=>handlePass('Y')} checked={inspectInfo.passYn==='Y'?true:false} />
                                    <label htmlFor="parts_insp_result_y">통과</label>
                                </div>
                                <div>
                                    <input type="radio" name="parts_insp_result" id="parts_insp_result_n" onChange={()=>handlePass('N')} checked={inspectInfo.passYn==='N'?true:false} />
                                    <label htmlFor="parts_insp_result_n">이상 발견</label>
                                </div>
                            </div>
                            <textarea name="inspectComment" id="parts_insp_comments" placeholder="코멘트를 입력해 주세요." onChange={handleChange}></textarea>
                        </div>
                    </div>
                </div>
                <div className={PageStyles.data_btnBox}>
                    <div className={[PageStyles.data_cancleBtn, PageStyles.cancle].join(" ")} onClick={onClose}>취소</div>
                    <button type="button" id="goPartsInspectionSubmit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsInspectionRegist;