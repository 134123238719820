import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  useGetJoinCompanyMutation,
  useAcceptJoinCompanyMutation,
  useRejectJoinCompanyMutation,
} from "../../../store/slices/dashboard/dashboardApiSlice";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import GridSizeSelectBox from "../../../components/grid/gridSizeSelectBox";
import GridSearchBox from "../../../components/grid/gridSearchBox";
import GridColumnHeader from "../../../components/grid/gridColumnHeader";
import Pagination from "../../../components/dashboard/pagination";
import MessageBox, { MessageBoxType } from "../../../components/dialog/messagebox/messageBox";

//김건우
import { useTranslation } from "react-i18next";

const DashboardApprove = () => {

  //김건우
  const { t } = useTranslation();

  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);
  const dialogInfoDefault = {
    isOpen: false,
    message: <></>,
    idx: 0,
  };

  const [getJoinCompany] = useGetJoinCompanyMutation();
  const [acceptJoinCompany] = useAcceptJoinCompanyMutation();
  const [rejectJoinCompany] = useRejectJoinCompanyMutation();

  //#region useState...
  const [acceptInfo, setAcceptInfo] = useState(dialogInfoDefault); // 승인창에 대한 정보
  const [rejectInfo, setRejectInfo] = useState(dialogInfoDefault); // 거절창에 대한 정보

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
    sortColumn: "assetId",
    companyId: user.companyId,
    sortOrder: false,
    lang: "KR",
    search: "",
  });

  const [gridData, setGridData] = useState({
    list: [],
    totalCount: 0,
  });
  //#endregion

  //#region 그리드 관련 영역...
  const handlePageChange = (e: any) => {
    setPageInfo({
      ...pageInfo,
      page: e.selected,
    });
  };

  const clickColumnHeader = (columnId: string) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder,
    });
  };

  const clickSizeSelectBox = (size: number) => {
    setPageInfo({
      ...pageInfo,
      size: size,
    });
  };

  const handleQueryChange = (keyword: string) => {
    setPageInfo({
      ...pageInfo, // 기존값
      search: keyword, // search는 덮어써줘
    });
  };

  //#endregion

  //#region 승인창 관련 코드 영역...

  const clickAcceptYes = async () => {
    try {
      await acceptJoinCompany({
        adminId: user.userId,
        idx: acceptInfo.idx,
      });
      setAcceptInfo(dialogInfoDefault);
      getGrid();
    } catch (err) { }
  };

  const clickAcceptNo = () => {
    setAcceptInfo(dialogInfoDefault);
  };

  const handleApprove = async (data: any) => {
    // 문구를 만들어야...
    const message = (
      <>
        <b>{data.userName}</b> {t("approve-accept")}
      </>
    );
    setAcceptInfo({
      isOpen: true,
      message: message,
      idx: data.idx,
    });
  };
  //#endregion

  //#region 거절창 관련 코드 영역...
  const handleReject = async (data: any) => {
    const message = (
      <>
        <b>{data.userName}</b> {t("approve-reject")}
      </>
    );
    setRejectInfo({
      isOpen: true,
      message: message,
      idx: data.idx,
    });
  };

  const clickRejectYes = async () => {
    try {
      await rejectJoinCompany({
        adminId: user.userId,
        idx: rejectInfo.idx,
      });
      setRejectInfo(dialogInfoDefault);
      getGrid();
    } catch (err) { }
  };

  const clickRejectNo = () => {
    setRejectInfo(dialogInfoDefault);
  };

  const getGrid = async () => {
    try {
      const response: any = await getJoinCompany(pageInfo);
      if (response.data.result === true) {
        setGridData({
          list: response.data.list,
          totalCount: response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  const handlePrev = () => {
    if (pageInfo.page === 0) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page - 1,
    });
  };
  const handleNext = () => {
    if (pageInfo.page + 1 === Math.ceil(gridData.totalCount / pageInfo.size)) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };

  useEffect(() => {
    getGrid();
  }, [pageInfo]);
  //#endregion

  useEffect(()=>{
    if(user.companyId){
      setPageInfo({...pageInfo, companyId : user.companyId})
    }
  }, [user.companyId])

  return (
    <>
      <div className="company_registration_container">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3>
                {t("approve-main")}<span></span>
              </h3>
            </div>
            <div className="table_area">
              <div>
                <GridSizeSelectBox
                  pageInfo={pageInfo}
                  onClick={clickSizeSelectBox}
                />
                <GridSearchBox onChangeKeyword={handleQueryChange} />
              </div>
              <div>
                <table>
                  <caption>{t("approve-employeeList")}</caption>
                  <thead>
                    <tr>
                      <GridColumnHeader
                        id="userName"
                        caption={t("approve-name")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                        // widthStyle="w150"
                        width={10}
                      />
                      <GridColumnHeader
                        id="email"
                        caption={t("approve-loginId")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                        width={20}
                      />
                      <GridColumnHeader
                        id="mobile"
                        caption={t("approve-mobile")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                        width={20}
                      />
                      <GridColumnHeader
                        id="requestDate"
                        caption={t("approve-requestDate")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                        width={35}
                      />
                      <GridColumnHeader
                        id=""
                        caption={t("approve-whether")}
                        pageInfo={pageInfo}
                        width={15}
                        onClick={() => { }}
                      />
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.list.length === 0 ? (
                      <>
                        <tr>
                          <td colSpan={5} style={{ padding: '0' }}>
                            <div className="no_applicant">
                              <div>
                                <img src="/assets/images/no_applicant.png" alt="no applicant" />
                                <p>{t("approve-no_applicant")}</p>  
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      gridData.list.map((data: any) => (
                        <tr key={data.idx}>
                          <td style={{ whiteSpace: 'nowrap' }}>{data.userName}</td>
                          <td>{data.email}</td>
                          <td>{data.mobile}</td>
                          <td>{data.requestDate}</td>
                          <td>
                            <div className="approval-box">
                              <button
                                onClick={() => handleApprove(data)}
                                type="button"
                                className="blue"
                              >
                                <i className="fa-light fa-circle-check"></i>{" "}
                                승인
                              </button>
                              <button
                                onClick={() => handleReject(data)}
                                type="button"
                                className="red"
                              >
                                <i className="fa-light fa-circle-xmark"></i>{" "}
                                거절
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                prevClick={handlePrev}
                nextClick={handleNext}
                totalCount={gridData.totalCount}
                page={pageInfo.page}
                size={pageInfo.size}
              />
              {acceptInfo.isOpen && (
                <MessageBox
                  id="accept"
                  boxType={MessageBoxType.YesNo}
                  message={acceptInfo.message}
                  onClick1={clickAcceptNo}
                  onClick2={clickAcceptYes}
                />
              )}
              {/* 거절 팝업 */}
              {rejectInfo.isOpen && (
                <MessageBox
                  id="reject"
                  boxType={MessageBoxType.YesNo}
                  message={rejectInfo.message}
                  onClick1={clickRejectNo}
                  onClick2={clickRejectYes}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardApprove;
