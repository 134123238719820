export const IMAGE_SRC:any = {
    zip : '/assets/images/zip-file.png',
    pdf : '/assets/images/pdf-file.png',
    xlsx: '/assets/images/xlsx-file.png',
    txt: '/assets/images/txt-file.png',
    hwp: '/assets/images/hwp-file.png',
    ppt: '/assets/images/ppt-file.png',
    jpg: '/assets/images/txt-file.png',
    png: '/assets/images/txt-file.png',
}

export const groupHeaderList:any = {
    defaultHeaderList: [
        {
            key: 'modelingNo',
            title: '접수 번호'
        },
        {
            key: 'subject',
            title: '의뢰 제목'
        },
        {
            key: '',
            title: '의뢰접수자'
        },
        {
            key: 'requestDate',
            title: '접수일자'
        },
        {
            key: 'expectDate',
            title: '제작완료 예상일자'
        },
        {
            key: '',
            title: '견적비용'
        },
        {
            key: '',
            title: '담당자'
        },
    ],
    useHeaderList: [
        {
            key:'modelingNo',
            title:'접수 번호'
        },
        {
            key:'modelingName',
            title:'모델링 명'
        },
        {
            key:'manufactDate',
            title:'제작 완료일'
        },
        {
            key:'freeDate',
            title:'무료 사용 만기일'
        },
        {
            key:'expiredDate',
            title:'만기일'
        },
        {
            key:'',
            title:'링크'
        },
        {
            key:'publicYn',
            title:'공개여부'
        },
        {
            key:'enableYn',
            title:'사용여부'
        },
    ],
    expireHeaderList: [
        {
            key:'modelingNo',
            title:'접수 번호'
        },
        {
            key:'modelingName',
            title:'모델링 명'
        },
        {
            key:'manufactDate',
            title:'제작 완료일'
        },
        {
            key:'freeDate',
            title:'무료 사용 만기일'
        },
        {
            key:'expiredDate',
            title:'만기일'
        },
        {
            key:'',
            title:'링크'
        },
        {
            key:'publicYn',
            title:'공개여부'
        },
        {
            key:'enableYn',
            title:'사용여부'
        },
    ],
}