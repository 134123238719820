import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './test.module.css';
const TestList = () => {

    return(
        <>
            <div>
                <p className={styles.testText}>test</p>
            </div>
        </>
    )
}

export default TestList;