import React, { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import {toast, Toaster} from 'react-hot-toast';
import Paginate from 'react-paginate'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import classnames from 'classnames'
import GridColumnHeader from '../../../components/grid/gridColumnHeader';
import GridSizeSelectBox from '../../../components/grid/gridSizeSelectBox';
import GridSearchBox from '../../../components/grid/gridSearchBox';
import { useGetModelingManagementQuery, useGetModelingUseYnMutation,useGetModelingPublicYnMutation, useGetModelingManagementListMutation } from '../../../store/slices/dashboard/dashboardApiSlice';


const DashBoardModelingManagement = () => {

  const user:any = useSelector((state:RootState)=>state.loginUser.userInfo);

  const [pageInfo, setPageInfo] = useState({
      page: 0,
      size: 10,
      search: '',
      companyId:user.companyId,
      lang:'KR',
      sortColumn:'assetId',
      sortOrder:true
    });

  const [ gridData, setGridData ] = useState({
    list : [],
    totalCount : 0,
  });

  const [ getManagementList ] = useGetModelingManagementListMutation();
  const [ setEnableUseYn ] = useGetModelingUseYnMutation();
  const [ setPublicUseYn ] = useGetModelingPublicYnMutation();


  const handlePageChange = (e: any) => {
    setPageInfo({
        ...pageInfo,
        page: e.selected
    })
  }

  const clickColumnHeader = ( columnId : string ) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder
    });
  }

  const clickSizeSelectBox = ( size : number ) => {
    setPageInfo({
        ...pageInfo,
        size: size
    });
  }

  const handleQueryChange = (keyword :string) => {
    setPageInfo({
        ...pageInfo,        // 기존값
        search: keyword     // search는 덮어써줘
    })
  }

  const handleEnableChange = async(data:any) => {
    // console.log(data);
    const body = {
      idx: data.idx,
      enable: data.enable==="Y"? "N" : "Y"
    }
    try {
      await setEnableUseYn(body);
      getGrid();
    } catch (err) {
    }
  }

  const handlePublicChange = async(data:any) => {
    const body = {
      idx: data.idx,
      public: data.flag==="Y"? "N": "Y"
    }
    try {
      await setPublicUseYn(body);
      getGrid();
    } catch (err) {
    }
  }

  // 목록 데이터를 가져오는 함수
  const getGrid = async () => {
    try {
      const response: any = await getManagementList(pageInfo);
      if( response.data.result === true ) {
        setGridData({
          list : response.data.list,
          totalCount : response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  }

  console.log(gridData);

  useEffect(()=> {// 처음 1회 조회
    getGrid();
  },[]);

  useEffect(()=> { // pageInfo가 바뀔때마다 조회
    getGrid();
  },[pageInfo]);

  useEffect(()=>{
    if(user.companyId){
      alert("!")
    } else {
      alert("1")
    }
  }, [user.companyId])

  return (
    <>
    <div className="contents">
      <div className="box-row">
        <div className="box-col w100">
          <section className="">
            <div className="section-tit">
              <h1 onClick={getGrid}>모델링 관리 (Modeling Management)</h1>
            </div>
            <div className="dash-table st-1">
              <div className="table-toolbar">
                <div className="left">
                  <GridSizeSelectBox pageInfo={pageInfo} onClick={clickSizeSelectBox} />
                </div>
                <div className="right">
                  <GridSearchBox onChangeKeyword={handleQueryChange} />
                </div>
              </div>
                  <table>
                    <thead>
                      <tr>
                        <GridColumnHeader id="assetId" caption="Asset#" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="name" caption="Name" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="filename" caption="파일명" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="flag" caption="공개여부" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="freeExpireDate" caption="무료 사용 만기일자" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="expireDate" caption="만기일자" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="enable" caption="사용 여부" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        {/* <td className="t-c"><span className="sort">공개여부</span></td> */}
                        {/* <td className="t-c"><span className="sort">사용 여부</span></td> */}
                      </tr>
                    </thead>
                    <tbody>
                    {
                      gridData.list.map((data:any)=> (
                          <tr key={data.idx}>
                              <td>{data.assetId}</td>
                              <td>{data.name}</td>
                              <td>{data.filename}</td>
                              <td className="t-c">
                                <input type="checkbox" onChange={()=>handlePublicChange(data)} id={`toggle-${data.idx}`} checked={data.flag==="Y"} className="toggleIpt" hidden /> 
                                <label htmlFor={`toggle-${data.idx}`} className="toggleSwitch">
                                  <span className="toggleButton"></span>
                                </label>
                              </td>
                              <td>{data.freeExpiredDate}</td>
                              <td>{data.expiredDate}</td>
                               
                              {/* <td>{data.flag}</td> */}
                              <td className="t-c">
                                <input type="checkbox" onChange={()=>handleEnableChange(data)} id={`${data.idx}`} checked={data.enable==="Y"} className="toggleIpt" hidden /> 
                                <label htmlFor={`${data.idx}`} className="toggleSwitch">
                                  <span className="toggleButton"></span>
                                </label>
                              </td>

                          </tr>
                      ))
                    }

                    </tbody>
                  </table>
                  <div className={classnames('pageing', 'issuesPagination', /*styles.pagination*/)}>
                      <Paginate
                          forcePage={pageInfo.page}
                          pageCount={gridData.totalCount/pageInfo.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pageInfo.size}
                          onPageChange={handlePageChange}
                          nextLabel=""
                          previousLabel=""
                      />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
    </>
  )
}

export default DashBoardModelingManagement;