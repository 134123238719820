import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "http://210.114.19.235/api";
// const baseUrl = "http://akuser.alink3d.com";

export const dashBoardApiSlice = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 일반정보 (o)
    createDashboardCompanyInfo: builder.mutation({
      query: (body: any) => {
        console.log(body);
        return {
          // url: "/company/createCompanyInfo",
          url : 'https://alink3d.com/api/controller/company/createCompanyP.php',
          method: "post",
          body,
        };
      },
    }),

    updateDashboardCompanyInfo: builder.mutation({
      query: (body: any) => {
        return {
          url: "https://alink3d.com/api/controller/company/uptCompanyP.php",
          method: "post",
          body,
        };
      },
    }),

    //소속 기업 일반정보 조회
    getCompanySearchInfo: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/getCompanyInfo.php?mp_company_id=${params}`,
          method : 'get',
        };
      },
    }),

    getCompanyDetails : builder.mutation({
      query : (param : any) => {
        const {companyId} = param;
        return{
          url: `https://alink3d.com/api/controller/company/getCompanyDetail.php?companyId=${companyId}`,
        }
      }
    }),
    // 소속기업 상세정보 조회
    // getCompanyDetails: builder.query<{
    //   companyDetail?:any;
    //   result: boolean;
    //   certificationList:any;
    //   divisionList:any;
    //   categoryEmployees:any;
    // }, string >({
    //   query: (param: any) => {
    //     return {
    //       url: `https://alink3d.com/api/controller/company/getCompanyDetail.php?companyId=${param}`,
    //     };
    //   },
    // }),

    // 소속기업 입력시, 조회결과 받아오는 api
    getDashBoardCompanyInfo: builder.mutation({
      query: (params: any) => {
        const { userId, size, page, inputValue } = params;
        const body = {userId : userId, page : page + 1, size : size, keyword : inputValue}
        return {
          // url : 'https://alink3d.com/api/controller/company/searchCompanyP.php',
          url : `https://alink3d.com/api/controller/company/searchCompanyP.php?userId=${userId}&page=${page + 1}&size=${size}&keyword=${inputValue}`,
          // url: `/company/findCompany?userId=${userId}&page=${page + 1}&size=${size}&keyword=${inputValue}`,
          method: "get"
        };
      },
    }),

    // adminName: "이시모"
    // companyId: 408
    // companyLicense: "8838801284"
    // companyName: "주식회사 비엠씨"
    // isRequested: "N"
    // registeredDate: "2023-03-23"
    // representativeName: "test"

    //회사 수정
    createDashboardDetailInfo: builder.mutation({
      query: (body: any) => {
        const {
          userId,
          companyId,
          representativeName,
          businessCommencementYear,
          businessType,
          businessItem,
          annualRevenue,
          employeeNumber,
          telNo,
          faxNo,
          address1,
          address2,
          email,
          businessDivision,
          certifications,
          mainProducts,
          snsInstagram,
          snsFacebook,
          snsYoutube,
          snsSkype,
          snsLinkedIn,
          introduction,
        } = body;
        return {
          url: 'https://alink3d.com/api/controller/company/uptCompanyDetail.php',
          method: "post",
          body
        };
      },
    }),

    // 직급 불러오는 api
    getPositionList: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/getPosition.php?page=${params.page + 1}&size=${params.size}&up_company_id=${
            params.companyId
          }&sortOrder=${params.sortOrder}&sortColumn=${
            params.sortColumn
          }&keyword=${params.search}&up_lang=${params.lang}`,
          method: "get",
        };
      },
    }),

    // 직급 등록
    addPosition: builder.mutation({
      query: (body: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/setPosition.php?up_company_id=${body.up_company_id}&up_lang=${body.up_lang}
          &up_name=${body.up_name}`,
          method: "get",
        };
      },
    }),
    // 직급 삭제
    deletePosition: builder.mutation({
      query: (body: any) => {
        console.log(body);
        return {
          url: `https://alink3d.com/api/controller/company/delPosition.php`,
          method: "post",
          body
        };
      },
    }),

    // 직급 수정
    updatePosition: builder.mutation({
      query: (body: any) => ({
        url: `https://alink3d.com/api/controller/company/uptPosition.php`,
        method: "post",
        body
      }),
    }),

    //부서정보 불러오는 api
    getDepartment: builder.mutation({
      query: (params: any) => {
        console.log(params);
        return {
          // /company/selectDepartment
          url: `https://alink3d.com/api/controller/company/getDepartment.php?page=${params.page + 1}&size=${
            params.size
          }&sortOrder=${params.sortOrder}&ud_company_id=${
            params.companyId
          }&keyword=${params.search}&type=${params.type}`,
          method: "get",
        };
      },
    }),

    // 부서 등록 api
    addDepartment: builder.mutation({
      query: (body: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/setDepartment.php?ud_company_id=${body.ud_company_id}&ud_email=${body.ud_email}&ud_lang=${body.ud_lang}&ud_name=${body.ud_name}`,
          method: "get",
        };
      },
    }),

    // 부서 수정 api
    updateDepartment: builder.mutation({
      query: (body: any) => {
        console.log(body);
        return {
          url: `https://alink3d.com/api/controller/company/uptDepartment.php`,
          method: "post",
          body
        };
      },
    }),

    // 부서 삭제 api
    deleteDepartment: builder.mutation({
      query: (body: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/delDepartment.php`,
          method: "post",
          body
        };
      },
    }),

    // 소속 기업 신청관련 api
    // 소속 기업 신청
    addRequestJoinCompany: builder.mutation({
      query: (body: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/setCompanyJoin.php?companyId=${body.companyId}&userId=${body.userId}`,
          method: "get",
        };
      },
    }),
    // 소속 기업 신청 취소
    deleteCancelJoinCompany: builder.mutation({
      query: (body: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/delCompanyJoin.php`,
          method: "post",
          body
        };
      },
    }),

    // 가입 신청자 목록 불러오는 api

    getJoinCompany: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/getCompanyJoinList.php?page=${
            Number(params.page) + 1
          }&size=${params.size}&companyId=${params.companyId}&sortOrder=${
            params.sortOrder
          }&sortColumn=${params.sortColumn}&keyword=${params.search}`,
          method: "get",
        };
      },
    }),


    // 가입 신청 승인 api
    acceptJoinCompany: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/company/uptCompanyJoinApproval.php?adminId=${params.adminId}&idx=${params.idx}`,
          method: "get",
        };
      },
    }),

    // 가입 신청 거절 api
    rejectJoinCompany: builder.mutation({
      query: (params: any) => {
        console.log(params)
        return {
          url: `https://alink3d.com/api/controller/company/uptCompanyJoinReject.php?adminId=${params.adminId}&idx=${params.idx}`,
          method: "get",
        };
      },
    }),
    
    //  직원 리스트 api

    // 직원 리스트 목록 불러오는 api
    getEmployeeList: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/employee/getEmployeeList.php?companyId=${params.companyId}&keyword=${
            params.search
          }&lang=${params.lang}&page=${params.page + 1}&size=${
            params.size
          }&sortColumn=${params.sortColumn}&sortOrder=${params.sortOrder}`,
          method: "get",
        };
      },
    }),
    // 권한 수정 api
    editRole: builder.mutation({
      query: (params: any) => {
        console.log(params);
        return {
          url: `https://alink3d.com/api/controller/employee/uptPermission.php?idx=${params.idx}&permission=${params.permission}`,
          method: "get",
        };
      },
    }),
    // 직원 여부 수정 api
    editEmployee: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/employee/uptEmployeeStatus.php?idx=${params.idx}&status=${params.status}`,
          method: "get",
        };
      },
    }),
    // 직원 초대 api
    inviteEmployee: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/mail/invite_send.php?inviteEmails=${params.inviteEmails}&userId=${params.userId}&companyId=${params.companyId}`,
          method: "get",
        };
      },
    }),

    //모델링 관리 목록 불러오는 api
    getModelingManagement: builder.query({
      query: (params: any) => {
        return {
          url: `/Modeling/list?companyId=${params.ud_company_id}&keyword=${params.search}&page=${params.page+1}&size=${params.size}&sortColumn=${params.sortColumn}&sortOrder=${params.sortOrder}`,
          method: "get",
        };
      },
      keepUnusedDataFor: 1,
    }),

    getModelingManagementList : builder.mutation({
      query: (params: any) => {
        return {
          url: `/Modeling/list?companyId=${params.companyId}&keyword=${params.search}&page=${params.page+1}&size=${params.size}&sortColumn=${params.sortColumn}&sortOrder=${params.sortOrder}`,
          method: "get",
        };
      },
      transformResponse: (response: any) => response,
    }),

    // 보유한 모델의 사용여부 설정
    getModelingUseYn : builder.mutation({
      query: (params:any) => {
        return {
          url:`/Modeling/setEnable?idx=${params.idx}&enable=${params.enable}`,
          method:'get'
        }
      }
    }),

    //보유한 모델의 공개여부 설정
    getModelingPublicYn: builder.mutation({
      query: (params:any) => {
        return {
          url:`/Modeling/setPublic?idx=${params.idx}&public=${params.public}`,
          method:'get'
        }
      }
    }),

    //제작 상태 및 결제
    getPurChaseList: builder.mutation({
      query: (params:any) => {
        return {
          url: `/Purchase/list?page=${params.page+1}&size=${params.size}&keyword=${params.search}&companyId=${params.companyId}&sortColumn=${params.sortColumn}&sortOrder=${params.sortOrder}`,
          method:'get'
        }
      }
    }),

    setPurchare: builder.mutation({
      query: (body:any) => {
        return {
          url:`/Purchase/pay?idx=${body.idx}&Y/N=${body.Y}`,
          method:'get'
        }
      }
    }),

    //사진으로 의뢰하기
    setModelingPhoto: builder.mutation({
      query: (body:any) => {
        return {
          url:`https://alink3d.com/api/controller/request/setRequestPhoto.php`,
          method:'post',
          body
        }
      }
    }),
    // 2d 도면으로 의뢰하기
    setModeling2D: builder.mutation({
      query: (body:any)=> {
        return {
          url:`https://alink3d.com/api/controller/request/setRequest2D.php`,
          method:'post',
          body
        }
      }
    }),

    // 3d 도면으로 의뢰하기
    setModeling3D:builder.mutation({
      query: (body:any) => {
        return {
          url:`https://alink3d.com/api/controller/request/setRequest3D.php`,
          method:'post',
          body
        }
      }
    })

  }),
});

export const {
  useGetCompanyDetailsMutation,
  useGetCompanySearchInfoMutation,
  useCreateDashboardDetailInfoMutation,
  useGetDashBoardCompanyInfoMutation,
  useCreateDashboardCompanyInfoMutation,
  useUpdateDashboardCompanyInfoMutation,
  useGetPositionListMutation,
  useAddPositionMutation,
  useDeletePositionMutation,
  useUpdatePositionMutation,
  useGetDepartmentMutation,
  useDeleteDepartmentMutation,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useAddRequestJoinCompanyMutation,
  useDeleteCancelJoinCompanyMutation,
  useGetJoinCompanyMutation,
  useAcceptJoinCompanyMutation,
  useRejectJoinCompanyMutation,
  useGetEmployeeListMutation,
  useEditRoleMutation,
  useEditEmployeeMutation,
  useInviteEmployeeMutation,
  useGetModelingManagementQuery,
  useGetModelingManagementListMutation,
  useGetModelingUseYnMutation,
  useGetModelingPublicYnMutation,
  useGetPurChaseListMutation,
  useSetModelingPhotoMutation,
  useSetModeling2DMutation,
  useSetModeling3DMutation,
  useSetPurchareMutation
} = dashBoardApiSlice;