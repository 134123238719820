import React, { FC, forwardRef } from 'react';

interface InputLabelProps {
    name: string;
    starYn?: boolean;
    btnYn?: string;
    liClassname?: string;
    title?: string;
    placeholder: string;
    onChange?: any;
    type?: string;
    value?: any;
    inputType?: string;
    removeInput?: any;
    maxLength?:number;
    // ref?:any;
}

const InputLabels = forwardRef<any, InputLabelProps>((props,ref) => {

    const { onChange, starYn, btnYn, liClassname, title, placeholder, name, type, value, inputType, removeInput ,maxLength} = props;

    return (

        !inputType ?
            <li className={liClassname}>
                <h4>{title}<span className="red">{starYn && '*'}</span></h4>
                <input maxLength={maxLength ?? 400} ref={ref} value={value} onChange={onChange} type={type ?? "text"} name={name} id="" className="input_text" placeholder={placeholder} />
                {
                    btnYn &&
                    <button className="input_searchBtn"></button>
                }
            </li>
            :

            <li className={value && "active"}>
                <span></span>
                <input value={value} onChange={onChange} name={name} type="text"  id="" className="input_text" placeholder={placeholder} />
                {/* <button onClick={removeInput} className="input_closeBtn"></button> */}
            </li>
    )
})

export default InputLabels;