import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PartsMenualRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_menualRegist";
import { useDelPartsManuelMutation, useGetPartsManuelMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props {partsName:string; partsId: string}
const PartsMenual:React.FC<Props> = ({partsName, partsId}) => {
    const {modelingId} = useParams()
    const [getPartsManuelApi] = useGetPartsManuelMutation()
    const [delPartsManuelApi] = useDelPartsManuelMutation()
    const [manuelList, setManuelList] = useState<any>({kr :[], en :[]})
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    const getPartsManuel = async() => {
        const param = {modelingId : modelingId, id : partsId}
        const result : any = await getPartsManuelApi(param)
        if(result.data.result === true){
            setManuelList({...manuelList,
                kr : result?.data?.list?.filter((item:any)=>item.parts_manuel_lang==="KR"),
                en : result?.data?.list?.filter((item:any)=>item.parts_manuel_lang==="EN")
            })
        }
    }
    const handleFileDown = (file : string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    const handleDelManuel = async(manuel_idx : string) => {
        const param = {manuel_idx : manuel_idx}
        const confirm = window.confirm("해당 메뉴얼을 삭제하시겠습니까?");
        if(confirm){
            const result : any = await delPartsManuelApi(param);
            if(result.data.result === true){
                getPartsManuel()
            }
        }
    }
    useEffect(()=>{
        getPartsManuel()
    }, [partsId])
    // 모델링이 변경될 때마다 팝업창이 닫혀야 한다.
    useEffect(()=>{
        setIsOpen(false)
    }, [partsName])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_4, PageStyles.active].join(" ")}>
            <div className={PageStyles.file_uploadBox}>

                <div className={PageStyles.catalog_uploadBox}  id="catalog_kr_box">
                    <span className={PageStyles.lang}>KR</span>
                    <div className={PageStyles.parts_manuel_kr_box}>
                        {manuelList?.kr?.map((data:any) => (
                        <div className={PageStyles.catalogDown} key={data.idx}>
                            <div>
                                <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                                <a target="_black" onClick={()=>handleFileDown(data.parts_manuel_pdsFilename)}><input id="partManuelKr_after" name="parts_manuel_title" value={data.parts_manuel_pdsFile} placeholder="등록할 부품 메뉴얼 파일을 업로드하시기 바랍니다." readOnly/></a>
                            </div>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button onClick={()=>handleDelManuel(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

                <div className={PageStyles.catalog_uploadBox}  id="catalog_en_box">
                    <span className={PageStyles.lang}>EN</span>
                    <div className={PageStyles.parts_manuel_en_box}>
                        {manuelList?.en?.map((data:any) => (
                        <div className={PageStyles.catalogDown} key={data.idx}>
                            <div>
                                <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                                <a target="_black" onClick={()=>handleFileDown(data.parts_manuel_pdsFilename)}><input id="partManuelKr_after" name="parts_manuel_title" value={data.parts_manuel_pdsFile} placeholder="등록할 부품 메뉴얼 파일을 업로드하시기 바랍니다." readOnly/></a>
                            </div>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button onClick={()=>handleDelManuel(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                        {/* <div className={PageStyles.none_content_admin}>
                            <p>
                                <span>아직 등록된 콘텐츠가 없습니다.</span>
                                하단의 '+' 버튼을 눌러 콘텐츠를 등록해 주세요.
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            {isOpen && <PartsMenualRegist getManuel={getPartsManuel} partsName={partsName} partsId={partsId} onClose={handleOpen}/>}
                
            <button className={[PageStyles.blueAddBtn, PageStyles.frmPartManuelAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            <div className={PageStyles.downloadBox} id="partManuels">
            </div>
        </div>
        </>
    )
}

export default PartsMenual;