import { FC, useEffect, useState } from 'react';
import { FileGroup } from '../../../../../components/dashboard/popup/firstPopup/fileGroup';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../../types/modelingOrderType';
import { useGetOrderModelFileListMutation } from '../../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import ProfileCircle from '../../../../../components/grouplist/groupProfile/profile-circle';

interface IFirstPopupProps {
  selectedPopupInfo: ModelingOrderType;
  detailInfo: ModelingOrderDetailType;
}

const FirstPopup: FC<IFirstPopupProps> = (props) => {

  const [getOrderModelFileList] = useGetOrderModelFileListMutation();
  const { selectedPopupInfo, detailInfo } = props;
  const { requestUser, modelingId } = selectedPopupInfo;
  const fullName = requestUser.lastName + requestUser.firstName;
  const { subject, content } = detailInfo;

  const userProfile = requestUser.profile2Url != "" ? requestUser.profile2Url : '../../../assets/images/profile_none.png';

  const [fileList, setFileList] = useState<any>();

  const getFileList = async () => {
    try {
      const res: any = await getOrderModelFileList({
        modelingId: modelingId,
        keyword: ''
      });

      const { result, filelist } = res.data;

      if (result) {
        setFileList(filelist);
      }

    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getFileList();
  }, [])

  return (
    <>
      <div className="modal_popUp_1">
        <div>
          <h3 style={{ fontFamily: '700' }}>{subject}</h3>
          {
            requestUser.profile2Url != "" ?
              <span className="user_profile" style={{ background: `url(${requestUser.profile2Url}) center / cover no-repeat` }}></span>
              :
              <span className='user_profile' style={{ background: 'rgb(52, 152, 219)', color: '#fff' , textAlign: 'center', lineHeight: '28px', fontSize: '14px'}}>{requestUser.lastName}</span>
          }
          <span className="user_name">{fullName}</span>
        </div>
        <div className="textarea">
          <textarea value={content} readOnly></textarea>
        </div>

        <FileGroup getFileList={getFileList} fileList={fileList} />
      </div>
    </>
  )
}

export default FirstPopup;