import React, { FC } from 'react';

type listType = {
    codeId: string;
    codeName: string;
}

interface CheckListProps {
    open : boolean;
    onOpen : ()=> void;
    list : listType[];
    checkList: Array<string>;
    onChange: (value: string) => void;
    showData: string;
    title: string;
}

const CheckList:FC<CheckListProps> = (props) => {

    const { open, onOpen, list, checkList, onChange, showData, title } = props;

    // console.log(list);

    return (
        <>
            <div>
                <p className={open ? "input_text active" : "input_text"} onClick={onOpen} style={{height : '50px'}}>{showData}</p>
                <div className="input_selectBox">
                    <ul>
                        <li className="active">{title}</li>
                    </ul>
                    <ul>
                        {
                            list.map((x) => (
                                <li>
                                    <input checked={checkList.includes(x.codeId)} onChange={() => onChange(x.codeId)} type="checkbox" name="shipbuilding" id={`shipbuilding_${x.codeId}`} />
                                    <label htmlFor={`shipbuilding_${x.codeId}`}>{x.codeName}</label>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default CheckList;