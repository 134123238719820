import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
export interface Props {selectStyle : any, selValue : any, selName :string, name : string, list : any, onChange : any}
export const SelectBox:React.FC<Props> = ({selectStyle, selValue, selName, name, list, onChange}) => {

    return(
        <>
        
        </>
    )
}