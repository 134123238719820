import React, { useState, useRef, useEffect } from 'react';
import type { FC } from 'react';
import { current } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { menuOpen } from '../../../store/slices/menu/menuSlice';
import { Transition, CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import AnimateHeight from 'react-animate-height';
import { Tooltip } from '@mui/material';

//김건우
import { useTranslation } from "react-i18next";

export interface Props {
  paddingRef : any
  topbarRef : any
  isOpen : Function
}

const DashBoardSideBar:React.FC<Props> = ({paddingRef, topbarRef, isOpen}) => {

  //김건우
  const { t } = useTranslation();

  const user = useSelector((state: any) => state.loginUser.userInfo);
  const userImage = useSelector((state:any)=>state.loginUser.userImage2)
  const hasCompanyId = user.companyId === 0 ? false : true;
  const isAdmin = user.isAdmin === "Y" ? true : false;
  const userName = `${user.lastName} ${user.firstName}`;
  const profileUrl = userImage != undefined && userImage != '' ? userImage : "/assets/images/dsbd/no-profile.png";
  const sidebarRef = useRef<any>(null)

  const navigate = useNavigate();
  const location = useLocation();
  const homeBtn = location.pathname === '/dashboard/memberModify' ? "sideBar_home active" : "sideBar_home";

  const [sideBtn, setSideBtn] = useState<any>({
    request : false, project : false, account : false, setting : false
  })

  //companyId가 0일때 팝업 띄우고 소속기업페이지로 이동
  const [isShow, setShow] = useState(false);
  const handleClick = (pagePath: string) => {
    if (user.companyId === '0') {
      setShow(true);
      return;
    } else {
      navigate(pagePath);
    }
  }

  const handleClickClose = () => {
    setShow(false)
  }

  const handleClickMyCompany = () => {
    navigate('/dashboard/company')
    setShow(false)
  }

  const handleSideOpen = (btn : any) => {
    if(btn === 'request'){ setSideBtn((prev:any)=>({...sideBtn, request : !prev.request, project : false, account : false, setting : false}))}
    if(btn === 'project'){ setSideBtn((prev:any)=>({...sideBtn, request : false, project : !prev.project, account : false, setting : false}))}
    if(btn === 'account'){ setSideBtn((prev:any)=>({...sideBtn, request : false, project : false, account : !prev.account, setting : false}))}
    if(btn === 'setting'){ setSideBtn((prev:any)=>({...sideBtn, request : false, project : false, account : false, setting : !prev.setting}))}
  }

  useEffect(()=>{
    if(paddingRef.current && topbarRef.current){
      if(!sideBtn.request && !sideBtn.setting && !sideBtn.project && !sideBtn.account){
        paddingRef.current.style.paddingLeft = '60px';
        topbarRef.current.style.paddingLeft = '80px';
        isOpen(false)
      } else {
        paddingRef.current.style.paddingLeft = '320px';
        topbarRef.current.style.paddingLeft = '340px';
        isOpen(true)
      }
    }
  }, [sideBtn])

  return (
    <>
      {isShow && (
        <div className="modeling_request_popUp active">
          <div className="wrap">
            <div>
              <div className="con on">
                <div className="body">
                  <div className="content">
                    <div>
                      <p><span>{t("sidebar-explan")}</span>
                        <strong>{t("sidebar-ask")}</strong></p>
                    </div>
                    <div className="buttonBox">
                      <button onClick={handleClickClose}>{t("sidebar-cancel")}</button>
                      <button onClick={handleClickMyCompany}>{t("sidebar-regist")}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg"></div>
            </div>
          </div>
        </div>
      )}
      {
        <div className="sideBar">
        <div className="iconBar">
            <div className="symbolLogo underline" onClick={()=>navigate('/')}>
                <img src="/assets/images/sideBar/symbol-logo.png" alt="symbol-logo"/>
            </div>
            <div className={homeBtn} onClick={()=>navigate('/dashboard/memberModify')}>
                <span></span>
            </div>
            <div className="sideBar_notification underline">
                <span className="red_alarm"></span>
            </div>
            <div className={sideBtn.request ? "sideBar_request active" :"sideBar_request" } onClick={()=>handleSideOpen('request')}>
                <span></span>
            </div>
            <div className={sideBtn.project ? "sideBar_project active" :"sideBar_project" } onClick={()=>handleSideOpen('project')}>
                <span></span>
            </div>
            <div className={sideBtn.account ? "sideBar_customers active" : "sideBar_customers"} onClick={()=>handleSideOpen('account')}> 
                <span></span>
            </div>
            <div className={sideBtn.setting ? "sideBar_setting active" :"sideBar_setting" } onClick={()=>handleSideOpen('setting')}>
                <span></span>
            </div>
        </div>
        
        <div className={sideBtn.request ? "sideMenu sideMenu1 active" : "sideMenu sideMenu1"}>
            <button className="sideBar_slideBtn" onClick={()=>setSideBtn({...sideBtn,request : false})}>
                <img src="/assets/images/sideBar/sideBar_slideBtn.png" alt=""/>
            </button>
            {/* <!-- 사이드바 열고닫는 버튼 --> */}
            <button className="sideMenu_introduction_btn">
                <span></span>
            </button>
            {/* <!-- 사이드바 내용 소개하는 버튼 --> */}
            <div className="sideMenu_introduction">
                <ul className="active sideMenu_introduction_txt sideMenu_introduction_txt1">
                    <li>
                        <p>
                            <strong>모델링 제작 의뢰</strong>
                            : 3D 모델링 제작 의뢰 서비스
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>의뢰 및 결제 관리</strong>
                            : 3D 모델링 제작 의뢰 관리가
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>자사 모델링 접근</strong>
                            : 제작 완료된 모델링의 마스터 도면 관리
                        </p>
                    </li>
                </ul>
                
            </div>
            {/* <!-- 사이드바 내용 텍스트 : 위에 아이콘의 active에 따라 ul 활성화 --> */}
            <div className="sideMenu_title">
                <p>{t("sidebar-requestModeling")}</p>
            </div>
            {/* <!-- 사이드바 제목 --> */}
            <div className="sideMenu_box">
                <div>
                    <p className={location.pathname==='/dashboard/modelingRequest'?'active':''} onClick={()=>navigate('/dashboard/modelingRequest')}>
                        <span className="sideBar_plus_square" ></span>
                        {t("sidebar-requestMaking")}
                    </p>
                    <span className={location.pathname==='/dashboard/modelingPhoto'?'active':''} onClick={()=>handleClick('/dashboard/modelingPhoto')}>{t("sidebar-modelingPhoto")}</span>
                    <span className={location.pathname==='/dashboard/modeling2d'?'active':''} onClick={()=>handleClick('/dashboard/modeling2d')}>{t("sidebar-modeling2d")}</span>
                    <span className={location.pathname==='/dashboard/modeling3d'?'active':''} onClick={()=>handleClick('/dashboard/modeling3d')}>{t("sidebar-modeling3d")}</span>
                </div>
                <div>
                    <p onClick={()=>handleClick('/dashboard/modelingOrders')}>
                        <span className="sideBar_check_square"></span>
                        {t("sidebar-requestAndPayment")}
                    </p>
                    <span className={location.pathname==='/dashboard/modelingOrders'?'active':''} onClick={()=>handleClick('/dashboard/modelingOrders')}>{t("sidebar-requestManage")}</span>
                    <span className={location.pathname==='/dashboard/modelingPayment'?'active':''} onClick={()=>handleClick('/dashboard/modelingPayment')}>{t("sidebar-paymentManage")}</span>
                </div>
                <div>
                    <p className={location.pathname==='/dashboard/modelingSupple'?'active':''} onClick={()=>handleClick('/dashboard/modelingSupple')}>
                        <span className="sideBar_cube"></span>
                        {t("sidebar-modelingManage")}
                    </p>
                </div>
            </div>
            
        </div>
        {/* <!-- 3D 모델링 요청 --> */}

        <div className={sideBtn.project ? "sideMenu sideMenu2 active" : "sideMenu sideMenu2"}>
            <button className="sideBar_slideBtn" onClick={()=>setSideBtn({...sideBtn,project : false})}>
                <img src="/assets/images/sideBar/sideBar_slideBtn.png" alt=""/>
            </button>
            {/* <!-- 사이드바 열고닫는 버튼 --> */}
            <button className="sideMenu_introduction_btn">
                <span></span>
            </button>
            {/* <!-- 사이드바 내용 소개하는 버튼 --> */}
            <div className="sideMenu_introduction">
                <ul className="active sideMenu_introduction_txt sideMenu_introduction_txt1">
                    <li>
                        <p>
                            <strong>주요 프로젝트</strong>
                            : 프로젝트 즐겨찾기 기능
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>내부 프로젝트</strong>
                            : 자사 모델링 활용 프로젝트
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>외부 프로젝트</strong>
                            : 외부업체에게 공유받은 프로젝트
                        </p>
                    </li>
                </ul>
                
            </div>
            {/* <!-- 사이드바 내용 텍스트 : 위에 아이콘의 active에 따라 ul 활성화 --> */}
            <div className="sideMenu_title">
                <p>{t("sidebar-projectManage")}</p>
            </div>
            {/* <!-- 사이드바 제목 --> */}
            <div className="sideMenu_box">
                <div>
                    <p className={location.pathname==='/dashboard/project/major'?'active':''} onClick={()=>handleClick('/dashboard/project/major')}>
                        <span className="sideBar_plus_square"></span>
                        {t("sidebar-mainProject")}
                    </p>
                </div>
                <div>
                    <p className={location.pathname==='/dashboard/project/internal'?'active':''} onClick={()=>handleClick('/dashboard/project/internal')}>
                        <span className="sideBar_check_square"></span>
                        {t("sidebar-insideProject")}
                    </p>
                    <span className="blue">{t("sidebar-proceedingProject")}</span>
                    <span className="red">{t("sidebar-pendingProject")}</span>
                    <span className="green">{t("sidebar-completedProject")}</span>
                    <button className="new_btn"><img src="/assets/images/sideBar/sideBar_newProject.png" alt="new project"/> {t("sidebar-newProject")}</button>
                </div>
                <div>
                    <p className={location.pathname==='/dashboard/project/external'?'active':''} onClick={()=>handleClick('/dashboard/project/external')}>
                        <span className="sideBar_cube"></span>
                        {t("sidebar-outsideProject")}
                    </p>
                    <span className="blue">{t("sidebar-proceedingProject")}</span>
                    <span className="red">{t("sidebar-pendingProject")}</span>
                    <span className="green">{t("sidebar-completedProject")}</span>
                </div>
            </div>
            
        </div>
        {/* <!-- 프로젝트 관리 --> */}

        <div className={sideBtn.account ? "sideMenu sideMenu3 active" : "sideMenu sideMenu3"}>
            <button className="sideBar_slideBtn" onClick={()=>setSideBtn({...sideBtn,account : false})}>
                <img src="/assets/images/sideBar/sideBar_slideBtn.png" alt=""/>
            </button>
            {/* <!-- 사이드바 열고닫는 버튼 --> */}
            <button className="sideMenu_introduction_btn">
                <span></span>
            </button>
            {/* <!-- 사이드바 내용 소개하는 버튼 --> */}
            <div className="sideMenu_introduction">
                <ul className="active sideMenu_introduction_txt sideMenu_introduction_txt1">
                    <li>
                        <p>
                            <strong>주요 거래처</strong>
                            : 거래처 즐겨찾기 가능
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>거래처 리스트</strong>
                            : 거래처 등록 및 관리 기능
                        </p>
                    </li>
                </ul>
                
            </div>
            <div className="sideMenu_title">
                <p>{t("sidebar-accountManage")}</p>
            </div>
            <div className="sideMenu_box">
                <div>
                    <p className="active">
                        <span className="sideBar_plus_square"></span>
                        {t("sidebar-mainAccount")}
                    </p>
                </div>
                <div>
                    <p>
                        <span className="sideBar_check_square"></span>
                        {t("sidebar-accountList")}
                    </p>
                    <span>{t("sidebar-allList")}</span>
                    <span>{t("sidebar-customerList")}</span>
                    <span>{t("sidebar-supplierList")}</span>
                    <button className="new_btn"><img src="/assets/images/sideBar/sideBar_newProject.png" alt="new project"/> {t("sidebar-newCustomer")}</button>
                </div>
            </div>
            
        </div>
        {/* <!-- 거래처 관리 --> */}

        <div className={sideBtn.setting ? "sideMenu sideMenu4 active" : "sideMenu sideMenu4"}>
            <button className="sideBar_slideBtn" onClick={()=>setSideBtn({...sideBtn,setting:false})}>
                <img src="/assets/images/sideBar/sideBar_slideBtn.png" alt=""/>
            </button>
            {/* <!-- 사이드바 열고닫는 버튼 --> */}
            <button className="sideMenu_introduction_btn">
                <span></span>
            </button>
            {/* <!-- 사이드바 내용 소개하는 버튼 --> */}
            <div className="sideMenu_introduction">
                <ul className="active sideMenu_introduction_txt sideMenu_introduction_txt1">
                    <li>
                        <p>
                            <strong>주요 거래처</strong>
                            : 거래처 즐겨찾기 가능
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>거래처 리스트</strong>
                            : 거래처 등록 및 관리 기능
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>거래처 리스트</strong>
                            : 거래처 등록 및 관리 기능
                        </p>
                    </li>
                </ul>
                
            </div>
            {/* <!-- 사이드바 내용 텍스트 : 위에 아이콘의 active에 따라 ul 활성화 --> */}
            <div className="sideMenu_title">
                <p>{t("sidebar-settings")}</p>
            </div>
            {/* <!-- 사이드바 제목 --> */}
            <div className="sideMenu_box">
                <div>
                    <p className={location.pathname==='/dashboard/company'?'active':''} onClick={()=>navigate('/dashboard/company')}>
                        <span className="sideBar_plus_square"></span>
                        {t("sidebar-settingEnterprise")}
                    </p>
                    <span className={location.pathname==='/dashboard/company'?'active':''} onClick={()=>navigate('/dashboard/company')}>{t("sidebar-logoAndName")}</span>
                    {hasCompanyId&&isAdmin ?
                    <>
                    <span className={location.pathname==='/dashboard/companyDetail'?'active':''} onClick={()=>navigate('/dashboard/companyDetail')}>{t("sidebar-detail")}</span>
                    <span className={location.pathname==='/dashboard/department'?'active':''} onClick={()=>navigate('/dashboard/department')}>{t("sidebar-departmentManage")}</span>
                    <span className={location.pathname==='/dashboard/position'?'active':''} onClick={()=>navigate('/dashboard/position')}>{t("sidebar-rankManage")}</span>
                    </>
                    :''
                    }
                </div>
                {hasCompanyId && isAdmin ?
                <div>
                    <p className={location.pathname==='/dashboard/employee'?'active':''} onClick={()=>navigate('/dashboard/employee')}>
                        <span className="sideBar_check_square"></span>
                        {t("sidebar-employeeManage")}
                    </p>
                    <span className={location.pathname==='/dashboard/employee'?'active':''} onClick={()=>navigate('/dashboard/employee')}>{t("sidebar-employeeList")}</span>
                    <span className={location.pathname==='/dashboard/approve'?'active':''} onClick={()=>navigate('/dashboard/approve')}>{t("sidebar-approval")}</span>
                </div> : ''
                }
            </div>
            
        </div>
        {/* <!-- Settings --> */}

    </div>
      }
    </>
  )
}

export default DashBoardSideBar;