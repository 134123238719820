import React, { useEffect, useState } from "react";
import moduleStyle from './payment.module.css'
import { PaymentPopup } from "./popup/paymentPopup";
export const DashboardModelingPayment = () => {
    const [popUp, setPopup] = useState<any>({isOpen : false})
    const handleOpen = (id:any) => {
        setPopup((prev:any)=>({...prev, isOpen : true}))
    }
    const handleClose = () => {
        setPopup((prev:any)=>({...prev, isOpen : false}))
    }
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                handleClose();
            }
        };
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [])
    return(
        <>
        <div className={`${moduleStyle.contentBox} ${moduleStyle.active}`}>
            <div className={moduleStyle.payment_management}>
                <div className={moduleStyle.company_details}>
                    <div className={moduleStyle.wrap}>
                        <div className={moduleStyle.content}>
                            <div className={moduleStyle.fixed}>
                                <h3>결제관리</h3>
                            </div>
                            
                            <div className={`${moduleStyle.table_area} ${moduleStyle.blue_table}`}>
                                <div>
                                    <div className={moduleStyle.select_box}>
                                        <select name="" id="">
                                            <option value="">10</option>
                                            <option value="">30</option>
                                        </select>
                                    </div>
                                    <div className={moduleStyle.search_box}>
                                        <input type="search" name="" id="" placeholder="search..."/>
                                        <button></button>
                                    </div>
                                </div>
                                <div>
                                    <table>
                                        <caption>직급관리</caption>
                                        <thead>
                                            <tr>
                                                <th>결제내용<span className={moduleStyle.sortWrap}><span className={moduleStyle.sort}></span></span></th>
                                                <th>결제금액<span className={moduleStyle.sortWrap}><span className={moduleStyle.sort}></span></span></th>
                                                <th>결제완료일자<span className={moduleStyle.sortWrap}><span className={moduleStyle.sort}></span></span></th>
                                                <th>결제상태<span className={moduleStyle.sortWrap}><span className={moduleStyle.sort}></span></span></th>
                                                <th>세금계산서<span className={moduleStyle.sortWrap}><span className={moduleStyle.sort}></span></span></th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr onClick={()=>handleOpen('')}>
                                                <td>3D 모델링 의뢰 ( #Bunkering Vessel Assemble )</td>
                                                <td>2,400,000</td>
                                                <td>2022.07.14</td>
                                                <td><span className={moduleStyle.red}>미결재</span></td>
                                                <td>
                                                    <button className={moduleStyle.billBtn}></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3D 모델링 의뢰 ( #Bunkering Vessel Assemble )</td>
                                                <td>2,400,000</td>
                                                <td>2022.07.14</td>
                                                <td><span className={moduleStyle.blue}>결제완료</span></td>
                                                <td>
                                                    <button className={`${moduleStyle.billBtn} ${moduleStyle.active}`}></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3D 모델링 의뢰 ( #Bunkering Vessel Assemble )</td>
                                                <td>2,400,000</td>
                                                <td>2022.07.14</td>
                                                <td><span className={moduleStyle.red}>미결재</span></td>
                                                <td>
                                                    <button className={moduleStyle.billBtn}></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                                <div>
                                    <div className={moduleStyle.paging_box}>
                                        <span>01</span>
                                        <span>/</span>
                                        <span>12</span>
                                    </div>
                                    <div className={moduleStyle.arrow_box}>
                                        <button></button>
                                        <button></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        {popUp.isOpen && <PaymentPopup onClose={handleClose}/>}
        </>
    )
}