import axios from 'axios';
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';

const initialState:any = {
    data:[]
}

export const getCourseList = createAsyncThunk("GET_COURSE",async(limit:number)=> {
    const response = await axios.get(`http://210.114.19.235:8080/testapi/pages?limit=${limit}`);
    console.log(response);
    return response.data.customers;
})

export const courseReducer = createSlice({
    name:"courseReducer",
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder.addCase(getCourseList.fulfilled, (state,action)=> {
            console.log(action.payload);
            state.data = action.payload;
        })
    }
})

