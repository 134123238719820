import React from "react";
import moduleStyles from '../payment.module.css'
export interface Props {onClose:Function}
export const PaymentPopup:React.FC<Props> = ({onClose}) => {

    return(
        <>
        <div className={`${moduleStyles.billPopUp} ${moduleStyles.popUp_active} ${moduleStyles.active}`}>
            <div>
                <div>
                    <span>결제내역</span>
                    <button><i className="fa-regular fa-xmark" onClick={()=>onClose()}></i></button>
                </div>
                <div>
                    <div>
                        <span>주문자 정보</span>
                        <table>
                            <tbody>
                                <tr>
                                    <th>이름</th>
                                    <td>백지희</td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td>aa9810@bmcnet.kr</td>
                                </tr>
                                <tr>
                                    <th>연락처</th>
                                    <td>032-837-5388</td>
                                </tr>
                                <tr>
                                    <th>소속기업</th>
                                    <td>주식회사 비엠씨</td>
                                </tr>
                                <tr>
                                    <th>주소</th>
                                    <td>인천광역시 연수구 송도미래로 30, 스마트밸리 E-1906</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <span>결제정보</span>
                        <table>
                            <tbody>
                                <tr>
                                    <th>결제정보</th>
                                    <td>신용카드 (국민카드)</td>
                                    <th>카드정보</th>
                                    <td>1234-5678-9012-3456</td>
                                </tr>
                                <tr>
                                    <th>공급가액</th>
                                    <td>2,400,000원</td>
                                    <th>세액</th>
                                    <td>240,000원</td>
                                </tr>
                                <tr>
                                    <th>총 결제금액</th>
                                    <td>2,640,000원 (2,400,000 + 240,000)</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>결제상태</th>
                                    <td>결제완료</td>
                                    <th></th>
                                    <td></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <span>Tax invoices</span>
                        <div>
                            <div>
                                <span>견적서</span>
                            </div>
                            <div>
                                <span>세금계산서</span>
                            </div>
                            <div>
                                <span>거래명세서</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}