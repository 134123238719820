import React from "react";
import moduleStyles from './project.module.css'
import { useNavigate } from "react-router-dom";
export const ProjectFilter = () => {
    const navigate = useNavigate()
    return(
        <>
        <div className={moduleStyles.sub_header}>
            <div className={moduleStyles.filterBox}>
                <ul>
                    <li className={moduleStyles.filterIcon}><span></span></li>
                    <li className={moduleStyles.all}><span>All</span></li>
                    <li className={''}><span>진행중(999)</span></li>
                    <li className={''}><span>진행보류(999)</span></li>
                    <li className={''}><span>완료(999)</span></li>
                </ul>
            </div>
            <div className={moduleStyles.right}>
                <div className={moduleStyles.search_box}>
                    <input type="text" placeholder="Search"/>
                    <button type="button" className="ic-button"><img src="/assets/images/search_gray.png" alt="검색아이콘"/></button>
                </div>
                <button className={moduleStyles.newProjectBtn} onClick={()=>navigate('/dashboard/new-internal')}>
                    <span>NEW PROJECT</span>
                </button>
            </div>
        </div>
        </>
    )
}