import React, {useState, useEffect}  from 'react';
import type {FC} from 'react';
import Modal from 'react-modal';
import DaumPostcode from 'react-daum-postcode';
import { idText } from 'typescript';

interface PostCodeModal {
    show : boolean,
    handleClose: ()=> void ;
    handleComplete :any;
}

const customStyles:any = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width:'50%',
      height:'50%'
    },
};

const PostCodeModal :FC<PostCodeModal> = (props) => {
    
    const {show, handleClose, handleComplete} = props;

    return (
        <>
          <Modal ariaHideApp={false} isOpen={show} style={customStyles}>
                <button style={{float:'right'}} onClick={handleClose}>X</button>
                <DaumPostcode onComplete={handleComplete} { ...props } />
          </Modal>    
        </>
    )
}

export default PostCodeModal;