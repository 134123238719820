import React, { FC, useEffect, useState, useRef } from 'react';
import FileGroup from '../../../../../components/dashboard/popup/fourthPopup/fileGroup';
import { useGetOrderModelFileListMutation, useSetOrderFielsMutation } from '../../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../../types/modelingOrderType';

//김건우
import { useTranslation } from "react-i18next";
import { useAppSelector } from '../../../../../store/storeHooks';

interface IFourthPopupProps {
  selectedPopupInfo: ModelingOrderType;
  detailInfo: ModelingOrderDetailType;
}
const FourthPopup: FC<IFourthPopupProps> = (props) => {

  //김건우
  const { t } = useTranslation();
  const userData : any = useAppSelector(state=>state.loginUser.userInfo)
  const { selectedPopupInfo: { modelingId } } : any = props;
  const [fileList, setFileList] = useState<any>();
  const [getOrderModelFileList] = useGetOrderModelFileListMutation();
  const [setOrderFilesApi] = useSetOrderFielsMutation()
  const searchRef = useRef<any>(null);

  const [filter, setFilter] = useState({
    search: ''
  })

  const handleChangeFilter = () => {
    setFilter({
      ...filter,
      search: searchRef.current.value
    })
  }

  const getFileList = async () => {
    try {
      const res: any = await getOrderModelFileList({
        modelingId: modelingId,
        keyword: filter.search
      });
      const { result } = res.data;

      if (result) {
        setFileList(res.data.filelist);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleUpload = async(e:any) => {
    const formData = new FormData()
    formData.append('UserId', userData.userId)
    formData.append('modelingId', modelingId)
    formData.append('contentType', 'CTYPE_500')
    formData.append('file', e.target.files[0])
    const result : any = await setOrderFilesApi(formData)
    if(result.data.result === true){getFileList()}
  }

  useEffect(() => {
    getFileList();
  }, [filter])

  if (!fileList) {
    return <></>
  }


  return (
    <>
      <div className="modal_popUp_4">
        <div className="zipFile">
          <h3>{t("modelingPopup-zipFile")}<span>{fileList.length}</span></h3>
          <label className="uploadBtn" htmlFor='uploadBtn'><img src="../../../../assets/images/cloud-arrow-up-solid.png" alt=""/>{t("modelingPopup-upload")}</label>
          <input type={'file'} onChange={handleUpload} id="uploadBtn" style={{display : 'none'}}/>
          <div>
            <input ref={searchRef} onKeyPress={handleChangeFilter} type="search" placeholder="Search..." />
            <span></span>
          </div>

          <FileGroup getFileList={getFileList} fileList={fileList} />
        </div>
      </div>
    </>
  )
}

export default FourthPopup;