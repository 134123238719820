import React, { FC } from 'react';

interface ArrowLeftProps {
    color : string;
}

const ArrowLeft:FC<ArrowLeftProps> = (props) => {

    const { color } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left">
            <polyline color={color} points="15 18 9 12 15 6">
            </polyline>
            </svg>
    )
}

export default ArrowLeft;