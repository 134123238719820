import React, { FC, useState, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import ChatWrapper, { eChatType } from '../../../../components/chat-wrapper';
import MessageBox from '../../../../components/message-box';
import MessageInputBox from '../../../../components/message-input-box';
import { useGetAssetMessageMutation, useRecvMessageMutation, useSendAssetMessageMutation, useSendMessageMutation } from '../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { RootState } from '../../../../store/store';
import { MessageType } from '../../../../types/message';
import { ModelingOrderType } from '../../../../types/modelingOrderType';
import { useInView } from 'react-intersection-observer';
interface IModelingPopupSideContentProps {
  selectedPopupInfo: ModelingOrderType | any;
  type: string;
}

const ModelingPopupSideContent: FC<IModelingPopupSideContentProps> = (props) => {

  const { selectedPopupInfo: { modelingId }, type } = props;
  const [message, setMessage] = useState<Array<MessageType>>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const user: any = useSelector((state: RootState) => state.loginUser);
  const innerRef = useRef<any>();

  // 이후에 정리할 필요가 있겠네
  const chatType = props.type !== 'defaultHeaderList' ? eChatType.eAssetModeling : eChatType.eOrderModeling;

  const [recvMessage] = useRecvMessageMutation();
  const [getAssetMessage] = useGetAssetMessageMutation();

  const [reciveFilter, setReciveFilter] = useState({
    modelingId: modelingId,
    page: 1,
    size: 10
  })

  const [sendFilter, setSendFilter] = useState({
    modelingId: modelingId,
    msg: '',
    msgType: 'MSG01',
    userId: user.userId
  })

  const getMessage = async () => {
    let result: any;
    try {
      if (chatType === eChatType.eAssetModeling)
        result = await getAssetMessage(reciveFilter);
      else
        result = await recvMessage(reciveFilter);

      if (result.data.result) {
        setMessage(result.data.msg)
      }
    } catch (err) {
      console.error(err);
    }
  }

  const changePage = () => {
    // 총 total 값 필요
    // if( reciveFilter.size > total) {
    //     return ;
    // }
    setReciveFilter({
      ...reciveFilter,
      size: reciveFilter.size + 10
    })
  }

  const scrollToBottom = () => {
    innerRef.current.scrollTo(0, innerRef.current.scrollHeight);
  }

  useEffect(() => {
    getMessage();
    let time = setInterval(() => {
      getMessage();
    }, 1000)

    return () => {
      clearInterval(time);
    }
  }, [reciveFilter.size])

  return (
    <>
      <div className="chat-container">
        <ChatWrapper
          eChatType={chatType}
          modelingId={modelingId}
          inputRef={inputRef}
          innerRef={innerRef}
          getItems={changePage}
          message={message}
          page={reciveFilter.page}
          size={reciveFilter.size} />
      </div>
    </>
  )
}

export default ModelingPopupSideContent;