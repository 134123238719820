import React, { FC, useState } from 'react';
import BigCalendar from '../../../../../components/dashboard-requsetManagement/bigCalendar/bigCalendar';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../../types/modelingOrderType';
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import './calendar.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from 'styled-components';
import { t } from 'i18next';

interface ISecondPopupProps {
  selectedPopupInfo: ModelingOrderType | any;
  detailInfo: ModelingOrderDetailType | any;
}

const SecondPopup: FC<ISecondPopupProps> = (props) => {

  const { detailInfo: { registeredDate, paidDate, expectedDate, completedDate } } = props;

  const [dateInfo, setDateInfo] = useState({
    registeredDate: registeredDate ? new Date(registeredDate) : null,
    paidDate: paidDate ? new Date(paidDate) : null, // 결제완료일자
    // expectedDate: expectedDate ? new Date(expectedDate) : new Date(),
    expectedDate: expectedDate ? new Date(expectedDate) : null,
    completedDate: completedDate ? new Date(completedDate) : null, // 제작완료일
  })

  const handleChange = (e: any, type: string) => {
    // switch (type) {
    //   case 'registeredDate': {
    //     setDateInfo({
    //       ...dateInfo,
    //       registeredDate: e
    //     })
    //     break;
    //   }

    //   case 'paidDate': {
    //     setDateInfo({
    //       ...dateInfo,
    //       paidDate: e
    //     })
    //     break;
    //   }
    //   case 'expectedDate': {
    //     setDateInfo({
    //       ...dateInfo,
    //       expectedDate: e
    //     })
    //     break;
    //   }
    //   case 'completedDate': {
    //     setDateInfo({
    //       ...dateInfo,
    //       completedDate: e
    //     })
    //     break;
    //   }
    //   default: {
    //     return;
    //   }
    // }
  }

  if (!props.detailInfo) {
    return <></>;
  }

  return (
    <>
      <div className="modal_popUp_3">
        <BigCalendar dateInfo={dateInfo} />
        <div className="calendar_area" style={{ marginTop: '40px' }}>
          <ul style={{ display: 'flex', justifyContent: 'space-between' }}>
            <li className="off">
              <StyledTitle>{t("modelingPopup-registeredDate")}</StyledTitle>
              <StyledBox><DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.registeredDate} onChange={(e) => handleChange(e, 'registeredDate')} disabled/><StyledIcon></StyledIcon></StyledBox>
            </li>
            <li className="off">
              <StyledTitle>{t("modelingPopup-paidDate")}</StyledTitle>
              <StyledBox><DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.paidDate} onChange={(e) => handleChange(e, 'paidDate')} disabled/><StyledIcon></StyledIcon></StyledBox>
            </li>
            <li className="on">
              <StyledTitle>{t("modelingPopup-expectedDate")}</StyledTitle>
              <StyledBox>
                <DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.expectedDate} onChange={(e) => handleChange(e, 'expectedDate')} disabled/>
                <StyledIcon></StyledIcon>
              </StyledBox>
            </li>
            <li className="on">
              <StyledTitle>{t("modelingPopup-completedDate")}</StyledTitle>
              <StyledBox><DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.completedDate} onChange={(e) => handleChange(e, 'completedDate')} disabled/><StyledIcon></StyledIcon></StyledBox>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const StyledIcon = styled.span`
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAABHNCSVQICAgIfAhkiAAAAJlJREFUKFNjZICC8+fPK/z9+/e8iYmJIEwMRJ85c+YDMzOzgaGh4QMQnxFEnD592oGRkVEfyJzAxMTkgKzh379/B4D8AqD4BSMjo4OMQMUHgIrtkRXhYR9gBFr5n0jFYGXkafj//7+jqakpyK04AdSf+8E2jGrAEU7IoXQBqIYfiMFpBQ9QAMp9ZAQmOoE/f/4UEBPbLCwsEwDpCXxegc3HCQAAAABJRU5ErkJggg==) center / contain no-repeat;
  position: absolute;
  right: 20px;
  top: 13px;
  display: block;
  width: 12px;
  height: 13.5px;
`

const StyledBox = styled.p`
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  font-size: 14px;
  color: #999999;
  position: relative;
`

const StyledTitle = styled.h3`
    font-size:12px;
    font-weight:600;
    line-height: 25px;
    color: #444444;
`


export default SecondPopup;