import React, { FC, useRef, useEffect } from "react";
interface IPopupCreateProps {
  name: string;
  onSave: Function;
  onCancel: Function;
}

const PopupCreate: FC<IPopupCreateProps> = (props) => {
  const { onSave, onCancel, name } = props;

  const positionNameRef = useRef<any>();

  const clickCancel = () => {
    onCancel();
  };

  const clickSave = () => {
    const name = positionNameRef.current.value;
    onSave(name);
  };

  
  useEffect(()=> {
    positionNameRef.current.value = name;
  },[name])

  return (
    <>
      <div className="rank_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body">
                <div className="content">
                  <div>
                    <h4>
                      직급명<span className="red"> *</span>
                    </h4>
                    <input
                      ref={positionNameRef}
                      type="text"
                      className="input_text"
                    />
                  </div>
                  <div className="buttonBox">
                    <button onClick={clickSave}>저장</button>
                    <button onClick={clickCancel}>취소</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupCreate;
