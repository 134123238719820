import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import QuillEditor from "../../../../../../../components/reactQuillEditor/quillEditor";
import QuillEditorModeling from "../../../../../../../components/reactQuillEditor/quillEditor_modeling";
import { useEditAssembleSpecMutation, useGetAssembleSpecMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductSpec = () => {
    const {modelingId} = useParams()
    const [getProductSpecApi] = useGetAssembleSpecMutation();
    const [editSpecApi] = useEditAssembleSpecMutation()
    const registInfo = {modelingId : modelingId, specContentsKr : '' , specContentsEn : ''}
    const [specInfo, setSpecInfo] = useState<{modelingId:any,specContentsKr:string, specContentsEn:string}>(registInfo)

    const getProductSpec = async()=> {
        const param = {modelingId : modelingId}
        const result : any = await getProductSpecApi(param);
        const data = result.data;
        if(data.result===true){setSpecInfo((prev:any) => ({...prev, 
            specContentsKr : data.list.modeling_specification_kr, specContentsEn : data.list.modeling_specification_en
        }))}
    }
    const handleChangeKr = (e:string) => {setSpecInfo({...specInfo, specContentsKr : e})}
    const handleChangeEn = (e:string) => {setSpecInfo({...specInfo, specContentsEn : e})}
    const handleSave = async(e:React.MouseEvent<HTMLButtonElement>) => {
        const formData = new FormData();
        formData.append('AssetModelingId', specInfo.modelingId);
        formData.append('Specification_kr', specInfo.specContentsKr);
        formData.append('Specification_en', specInfo.specContentsEn);
        const result : any = await editSpecApi(formData)
        if(result.data.result===true){
            alert("등록 및 수정이 완료되었습니다.");
        }
    }
    useEffect(()=>{getProductSpec()},[])
    console.log(specInfo.specContentsEn)
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_1, PageStyles.active].join(" ")}>
            <div className={PageStyles.titleBox}>
                <span></span>
                <p>LNG Tanker in ALINK3D</p>
            </div>
            <div className={PageStyles.summerNote_box}>
                <div>
                    <span className={PageStyles.lang}>KR</span>
                    <div className={PageStyles.txtBox}>
                        <QuillEditorModeling value={specInfo.specContentsKr} onChange={handleChangeKr}/>
                    </div>
                </div>
                <div>
                    <span className={PageStyles.lang}>EN</span>
                    <div className={PageStyles.txtBox}>
                        <QuillEditorModeling value={specInfo.specContentsEn} onChange={handleChangeEn}/>
                    </div>
                </div>
            </div>

            <div className={PageStyles.saveBox}>
                <button type="button" className="dots-btn dots-btn-5" id="goAssembleSpecSubmit" onClick={handleSave}>저장</button>
            </div>
        </div>
        </>
    )
}

export default ProductSpec;