import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import Document from '../../../../../components/dashboard/popup/thirdPopup/document';
import { useGetOrderModelFileListMutation } from '../../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../../types/modelingOrderType';
import { toLocaleString } from '../../../../../utils/toLocaleString';

//김건우
import { useTranslation } from "react-i18next";

interface IThirdPopupProps {
  selectedPopupInfo: ModelingOrderType;
  detailInfo: ModelingOrderDetailType;
}

const ThirdPopup: FC<IThirdPopupProps> = (props) => {

    //김건우
    const { t } = useTranslation();

  // const { selectedPopupInfo, detailInfo } = props;
  const { modelingId, paid, amount } = props.detailInfo;

  const [file400, setFile400] = useState<any>(undefined);
  const [file401, setFile401] = useState<any>(undefined);
  const [file403, setFile403] = useState<any>(undefined);

  // 견적서, 세금계산서, 거래명세서 가져오는 기능 구현
  // 설명이 필요했었던 듯 
  const [getOrderModelFileList] = useGetOrderModelFileListMutation();
  const getFileList = async () => {
    try {
      const res: any = await getOrderModelFileList({
        modelingId: modelingId,
        keyword: ''
      });

      const { result, filelist } = res.data;

      if (result) {
        for( const file of filelist ) {
          switch( file.contentType )
          {
            case "CTYPE_400": // 견적서
              setFile400(file);
              break; 
            case "CTYPE_401": // 세금계산서
              setFile401(file);
              break; 
            case "CTYPE_403": // 거래명세서
              setFile403(file);
              break; 
          }
        }
      }

    } catch (err) {
      console.error(err);
    }
  }

  const handlePayMent = () => {
    alert("견적서에 있는 은행 정보를 참조하여 대금 송금 바랍니다.")
  }

  useEffect(() => {
    getFileList();
  }, [])
  // ---------------------------------------------------------------------------------------

  return (
    <>
      <div className="modal_popUp_3">
        <div className="amount_area">
          <h3>{t("modelingPopup-amountArea")}</h3>
          <div>
            <div className="amount">
              <span>￦</span>
              <input value={toLocaleString(String(amount))} type="text" name="" id="" placeholder="000,000,000,000" readOnly />
            </div>
            <div>
              <button onClick={handlePayMent}>{t("modelingPopup-payment")}</button>
            </div>
          </div>
        </div>
        <div className="document">
          <h3>{t("modelingPopup-document")}</h3>
          <ul>
            <Document keyType="document_estimate" name={'견적서'} fileInfo={file400}/>
            <Document keyType="document_tax" name={'거래명세서'} fileInfo={file401}/>
            <Document keyType="document_transaction" name={'세금명세서'} fileInfo={file403}/>
          </ul>
        </div>
        <div className="payment_status">
          <div>
            <h3>{t("modelingPopup-paymentStatus")}</h3>
            <div>
              <button className={classNames('status_red', { 'active': paid === "N" })}>{t("modelingPopup-fail")}</button>
              <button className={classNames('status_blue', { 'active': paid === "Y" })}>{t("modelingPopup-complete")}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThirdPopup;