import React from "react";
interface Props {fileUrl : string,fileName:string}
export const FileDownLoad:React.FC<Props> = ({fileUrl, fileName}) => {
    const handleFileDown = (file : string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    return(
        <>
        <div>
            <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
            <a target="_blank" onClick={()=>handleFileDown(fileUrl)}>
                <input id="" name="" value={fileName} readOnly/>
            </a>
        </div>
        </>
    )
}