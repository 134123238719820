import React, { FC, useRef } from 'react';
import ManagerProfile from '../../../../components/dashboard/popup/manager-profile';
import { ModelingOrderType } from '../../../../types/modelingOrderType';
import ProfileGroupList from '../../../../components/grouplist/groupProfile/profile-group-list';

//김건우
import { useTranslation } from "react-i18next";

interface IModelingPopupHeaderProps {
    closePopup: any;
    selectedPopupInfo: ModelingOrderType;
}

const ModelingPopupHeader: FC<IModelingPopupHeaderProps> = (props) => {

    //김건우
    const { t } = useTranslation();

    const { closePopup, selectedPopupInfo } = props;
    const searchRef = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const searchFocus = () => {
        if(searchRef.current){
            searchRef.current.classList.add('active');
        }
    }

    // enter 누를시 api 호출
    const getSearch = async() => {
        try {

        } catch (err) {

        }
    }

    return (
        <>
            <div className="head">
                <div className="tit">
                    <h2>
                        {t("modelingPopup-header")}<span>: {selectedPopupInfo.modelingNo}</span>
                    </h2>
                    <div className="progress">
                        <span>제작중</span>
                    </div>
                    {/* <!-- <div className="assign">
                                        아이콘
                                    </div> --> */}
                </div>
                <div className="closeBox">
                    <div className="todo-content">
                        <div className="content-row">
                            <div className="todo-list-field">
                                <div className="todo-col assignee">
                                  {
                                    !selectedPopupInfo.managers ?
                                    <></>
                                    :
                                    <ProfileGroupList managers={selectedPopupInfo.managers} />
                                  }
                                </div>
                                <div className="todo-col priority">
                                    <span className="normal">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button ref={searchRef} onClick={searchFocus}>search</button> */}
                    {/* <div className="searchBox">
                        <input ref={inputRef} onKeyPress={getSearch} type="search" className="popUp_search" name="popUp_search" id="popUp_search" />
                    </div> */}

                    <div className="searchBox">
                        <input type="text" name="" id="" placeholder="search" />
                        <button ref={searchRef} onClick={searchFocus}><img src="/assets/images/popUp_right_search.png" className='before' /><img src="/assets/images/popUp_right_search_active.png" className='after' /></button>
                    </div>
                    {/* 인풋이 비활성화 되어있을때 버튼을 누르면 searchBox에 active 클래스 부여 */}


                    <a href="#none" className="btn_close" onClick={closePopup}>닫기</a>
                </div>
            </div>
        </>
    )
}

export default ModelingPopupHeader;