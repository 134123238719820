import React, { forwardRef, useEffect, useRef, useState } from "react";

export interface EventPopupRole { }

export interface PropsPopupRole {
  name: string;
  email: string;
  onSave: Function;
  onCancel: Function;
}

const PopupEdit = forwardRef<EventPopupRole, PropsPopupRole>((props, ref) => {

  const popupRef = useRef<HTMLDivElement>(null);
  const departmentNameRef = useRef<any>();
  const departmentEmailRef = useRef<any>();

  const clickCancel = () => {
    props.onCancel();
  };

  const clickSave = () => {
    const name = departmentNameRef.current.value;
    const email = departmentEmailRef.current.value;
    props.onSave(name, email);
  };

  useEffect(() => {
    departmentNameRef.current.value = props.name
    departmentEmailRef.current.value = props.email;
  }, [])

  useEffect(() => {
    const handleOutSide = (e: any) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        props.onCancel();
      }
    }
    document.addEventListener('mousedown', handleOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOutSide);
    }
  }, [popupRef])

  return (
    <>
      <div className="department_popUp active" >
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body" ref={popupRef}>
                <div className="content">
                  <div>
                    <h4>
                      부서명<span className="red"> *</span>
                    </h4>
                    <input ref={departmentNameRef} type="text" name="" id="" className="input_text" />
                  </div>
                  <div>
                    <h4>부서 이메일</h4>
                    <input ref={departmentEmailRef} type="text" name="" id="" className="input_text" />
                  </div>
                  <div className="buttonBox">
                    <button onClick={clickCancel}>취소</button>
                    <button onClick={clickSave} >저장</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PopupEdit;
