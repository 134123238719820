import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// 로그인 요청 후, 성공 시 - 응답받은 아이디 값 localStorage
interface userState {
    name : string,
}

const initialState : userState = {
    name : '',
}

// createSlice :: 리듀서 함수의 객체, 슬라이스 이름, 초기 상태 값을 받아들이고 해당 액션 생성자와 액션 유형으로 리듀서 생성
export const userReducer = createSlice ({
    name : 'user',
    initialState,
    reducers : {
        // PayloadAction :: action 의 payload 필드의 타입을 지정할 수 있게 해주는 제네릭
        // 로그인 성공 시 :: 로그인 된 아이디 (이메일) 저장
        setUser: (state, action: PayloadAction<{ name: string }>) => {
            localStorage.setItem("user", JSON.stringify({
                // name : action.payload.name,
            }));
            // state.name = action.payload.name;
        },
    },
});

export const {setUser} = userReducer.actions;
export default userReducer.reducer;