import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsTroubleMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";
export interface Props {getTrouble:any;partsName:string;partsId:string;onClose:any}
const PartsTroubleRegist:React.FC<Props> = ({getTrouble,partsName, partsId,onClose}) => {
    const userData : any = useAppSelector(state=>state.loginUser.userInfo)
    const {modelingId}:any = useParams();
    const [setPartsTroubleApi] = useSetPartsTroubleMutation()
    const [troubleInfo, setTroubleInfo] = useState<any>({lang : 'KR',troubleFiles : []})
    const handleUpload = (files:any) => {
        setTroubleInfo((prev:any)=>({...prev , troubleFiles : files}))
    }
    const handleLangChange = (lang : string) => {
        setTroubleInfo((prev:any) => ({
            ...prev , lang : lang
        }))
    }
    const handleDelFiles = (index : number) => {
        const updatedFiles = [...troubleInfo.troubleFiles];
        updatedFiles.splice(index, 1);
        setTroubleInfo({...troubleInfo, troubleFiles : updatedFiles})
    }
    const handleSave = async() => {
        const formData = new FormData()
        formData.append('UserId', userData.userId)
        formData.append('AssetModelingId', modelingId)
        formData.append('idx', partsId)
        formData.append('part_unique_id', partsName)
        formData.append('trouble_lang', troubleInfo.lang)
        formData.append('trouble_files', troubleInfo.troubleFiles[0])
        const result : any = await setPartsTroubleApi(formData)
        if(result.data.result===true){
            getTrouble(); onClose();
        }
    }

    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>트러블슈팅 업로드</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.langBoxWrap}>
                        <div className={PageStyles.langBox}>
                            <span className={[PageStyles.lang, PageStyles.lang_KR, troubleInfo.lang==='KR'? PageStyles.active:''].join(" ")} onClick={()=>handleLangChange('KR')}>KR</span>
                            <span className={[PageStyles.lang, PageStyles.lang_EN, troubleInfo.lang==='EN'? PageStyles.active:''].join(" ")} onClick={()=>handleLangChange('EN')}>EN</span>
                        </div>
                        <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 파일 업로드가 가능합니다.</p>
                    </div>

                    <div className={[PageStyles.kr, PageStyles.frmPartsTrouble_upload, PageStyles.active].join(" ")}>
                        <Dropzone onFileUpload={handleUpload} type={'PDF'}/>
                        {troubleInfo?.troubleFiles?.map((files:any,index:number)=>(
                            // <p>{files.name}</p>
                            <ul className={PageStyles.dropZone_attachmentBox} key={index}>
                                <li>
                                    <div className={PageStyles.dropZone_attachmentList}>
                                        <p key={index}>{files.name}</p>
                                        <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/redCloseBtn.png" alt="deleteBtn"/></button>
                                    </div>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>

                <div className={PageStyles.frmPartsTrouble_btnBox}>
                    <div className={[PageStyles.frmPartsTrouble_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                    <button type="button" className={PageStyles.parts_trouble_submit} id="parts_trouble_submit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsTroubleRegist;