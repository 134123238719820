import { createSlice, PayloadAction } from "@reduxjs/toolkit"
// 상태값을 담을 변수 타입 지정
interface loginUserState {
    userId : string,
    account : string,
    companyId : string,
    companyName : string,
    userName  : string,
    userImage : string,
    userImage2 : string,
    mp_user_biography_kr : string,
    userInfo:loginUserInfo,
}

export interface loginUserInfo {
  userId: number,
  account: string,
  lastName: string,
  firstName: string,
  profile1Url : string,
  profile2Url : string,
  isAdmin: string,
  companyId: number,
  companyName: string,
}

const initUserInfo : loginUserInfo = {
  userId: 0,
  account: "",
  lastName: "",
  firstName: "",
  profile1Url: "",
  profile2Url: "",
  isAdmin: "N",
  companyId: 0,
  companyName: "",
};

// 상태값을 담을 변수 초기화
const initialState : loginUserState = {
    userId : '',
    account : '',
    companyId : '',
    companyName : '',
    userName : '',
    userImage : '',
    userImage2 : '',
    mp_user_biography_kr : '',
    userInfo: initUserInfo,
}

export const loginUserReducer = createSlice ({
    name : 'loginUser',
    initialState,
    reducers : {
        // 유저 고유 아이디
        setUserId : (state, action : PayloadAction<{userId : string}>) => {
            state.userId = action.payload.userId;
        },
        // 유저 계정 이메일 저장
        setLoginId : (state, action: PayloadAction<{ account : string}>) => {
            state.account = action.payload.account;
        },
        // 유저 소속 회사 아이디 저장
        setCompanyId : (state, action : PayloadAction<{companyId : string}>) => {
            state.companyId = action.payload.companyId;
        },
        // 유저 소속 회사 이름 저장
        setCompanyName : (state, action : PayloadAction<{companyName : string}>) => {
            state.companyName = action.payload.companyName;
        },
        // 유저 이름 저장
        setUserName : (state, action : PayloadAction<{userName : string}>) => {
            state.userName = action.payload.userName;
        },
        // 유저 프로필 사진 저장
        setUserPic : (state, action : PayloadAction<{userImage : string}>) => {
            state.userImage = action.payload.userImage;
        },
        // 유저 프로필 사진 저장 2 ( 프로필 사진 값이 없을 경우 테스트용 )
        setUserPic2 : (state, action : PayloadAction<{userImage2 : string}>) => {
            state.userImage2 = action.payload.userImage2;
        },
        // 유저 마케팅 활용 체크 저장
        setUserMarketingCheck : (state, action: PayloadAction<{mp_user_biography_kr : string}>) => {
            state.mp_user_biography_kr = action.payload.mp_user_biography_kr;
        },
        // 로그아웃 : 저장소에 저장되어 있는 값 초기화
        setLogOut : (state) => {
            state.userInfo = initUserInfo;
        },
        setUserInfo : (state,action) => {
            state.userInfo = action.payload;
        }
    },
});

export const {setUserId, setLoginId, setUserMarketingCheck, setCompanyId, setCompanyName, setUserName, setLogOut, setUserPic, setUserInfo, setUserPic2} = loginUserReducer.actions;
export default loginUserReducer.reducer;