import React, { FC, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAddRequestJoinCompanyMutation, useDeleteCancelJoinCompanyMutation, useGetDashBoardCompanyInfoMutation } from '../../../store/slices/dashboard/dashboardApiSlice';
import { setUserInfo } from '../../../store/slices/member/loginUserSlice';
import { RootState } from '../../../store/store';
import { useAppDispatch } from '../../../store/storeHooks';

interface IDashboardCompanyListProps {
  close: Function; setShow:Function
}

const DashboardCompanyList: FC<IDashboardCompanyListProps> = (props) => {
  const location = useLocation()
  const dispatch = useAppDispatch();
  const refLicense = useRef<HTMLInputElement>(null);
  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);

  const [getCompanyList] = useGetDashBoardCompanyInfoMutation();
  const [addRequestJoinCompany] = useAddRequestJoinCompanyMutation();
  const [deleteCancelJoinCompany] = useDeleteCancelJoinCompanyMutation();

  const [gridData, setGridData] = useState<any>([]);
  const [isMore, setMore] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    init: false,
    userId: user.userId,
    size: 5,
    page: 0,
    inputValue: "",
  });

  useEffect(() => {
    if (pageInfo.init == false)
      return;

    getGrid();
  }, [pageInfo]);

  const getGrid = async () => {
    try {
      const response: any = await getCompanyList(pageInfo);
      if (response.data.result === true) {
        setMore(response.data.list.length < pageInfo.size ? false : true);
        setGridData([...gridData, ...response.data.list]);
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  const btnSearch = () => {

    const inputValue = refLicense.current?.value ?? "";
    setGridData([]);
    setPageInfo({
      ...pageInfo,
      init: true,
      page: 0,
      inputValue: inputValue,
    });

  }

  const btnMore = () => {
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  }

  const pressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      btnSearch();
    }
  };

  const handleSaveRequest = async (e: any, data: any) => {
    try {

      const result: any = await addRequestJoinCompany({
        companyId: data.companyId,
        userId: user.userId,
      });

      if (result.data.result === true) {
        // toast.success("요청 완료");
        setGridData(updateRequested(data.companyId, "Y"));
      }

      if(result.data.result2 === true){
        const confirm = window.confirm("해당 기업에는 등록된 관리자가 없습니다. 해당 계정을 최초 관리자로 지정하시겠습니까?")
        if(confirm){
          dispatch(setUserInfo({...user, isAdmin : 'Y', companyId : data.companyId}));
          window.location.reload();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancleRequest = async (e: any, data: any) => {
    try {
      const result: any = await deleteCancelJoinCompany({
        companyId: data.companyId,
        userId: user.userId,
      });

      if (result.data.result) {
        // toast.success("요청 취소 완료");
        setGridData(updateRequested(data.companyId, "N"));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateRequested = (companyId: number, request: string) => {
    var list = [];
    for (let row of gridData) {
      if (row.companyId == companyId) {
        list.push({
          ...row,
          isRequested: request,
        });
      }
      else {
        list.push(row);
      }
    }
    return list;
  }

  console.log(gridData)

  const foundCompanyList = () => {
    return (
      <>
        <div>
          <table>
            <thead>
              <tr>
                <th>기업명</th>
                <th>사업자등록번호</th>
                <th>대표자 명</th>
                <th>소속기업 관리자</th>
                <th>등록일</th>
                <th>소속여부</th>
              </tr>
            </thead>
          </table>
          <div>
            <table>
              <tbody>
                {gridData.map((data: any, index: number) => (
                  <tr key={index}>
                    <td>{data.companyName}</td>
                    <td>{data.companyLicense}</td>
                    <td>{data.representativeName}</td>
                    <td>{data.adminName}</td>
                    <td>{data.registeredDate}</td>
                    <td className='result_box'>
                      {data.isRequested === "Y" ? (
                        <button
                          onClick={(e) => handleCancleRequest(e, data)}
                          type="button"
                          className="btn-2"
                        >
                          취소하기
                        </button>
                      ) : (
                        <button
                          onClick={(e) =>handleSaveRequest(e, data)}
                          type="button"
                          className="btn-1"
                        >
                          요청하기
                        </button>
                      )}
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
        {
          isMore &&
          <button onClick={btnMore} className="input_searchBtn">더보기</button>
        }
      </>
    );
  }

  const notFoundCompanyList = () => {
    if (pageInfo.init) {
      // 검색을 한 번 진행하였다면 표시
      return (
        <>
          <div className="none">
            <p><span>'{refLicense.current?.value}'에 대한 검색 결과가 존재하지 않습니다.</span>
              사업자등록번호를 입력하여 소속기업 신규 등록을 진행해 주세요.</p>
            <button onClick={closeWithLicenseString}>소속기업 신규 등록하기 →</button>
          </div>
        </>
      );
    }
  }

  const closeWithLicenseString = () => {
    props.close(refLicense.current?.value);
  }

  return (
    <>
      <div className="company_information_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body">
                <div className="content">
                  <div className="closeBtn">
                    <button onClick={() => props.close('')}></button>
                  </div>
                  <div className="title">
                    <h4>소속기업 검색</h4>
                    <p>신규 기업 등록을 원하시나요? <span onClick={closeWithLicenseString}>소속기업 정보 입력하기</span></p>
                  </div>
                  <div className="registratinoNumber_area">
                    <input ref={refLicense} type="text" onKeyPress={pressSearch} className="input_text" placeholder="사업자등록번호 또는 기업명을 입력해 주세요." />
                    <button onClick={btnSearch} className="input_searchBtn"></button>
                  </div>
                  <div className="table_area">
                    <div className='caption'>소속기업 조회 결과</div>
                    {
                      gridData.length > 0 ? foundCompanyList() : notFoundCompanyList()
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardCompanyList;
