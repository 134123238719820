import React, { FC } from 'react';

interface ArrowRightProps {
    color : string;
}

const ArrowRight:FC<ArrowRightProps> = (props) => {

    const { color } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right">
            <polyline color={color} points="9 18 15 12 9 6">
            </polyline>
        </svg>
    )
}

export default ArrowRight;