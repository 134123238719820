import React, { useRef, useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import {toast, Toaster} from 'react-hot-toast';
import { Link } from 'react-router-dom';
import GridSizeSelectBox from '../../../components/grid/gridSizeSelectBox';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import GridColumnHeader from '../../../components/grid/gridColumnHeader';
import { useGetPurChaseListMutation, useSetPurchareMutation } from '../../../store/slices/dashboard/dashboardApiSlice';
import classnames from 'classnames';
import Paginate from 'react-paginate'
import GridSearchBox from '../../../components/grid/gridSearchBox';

interface DashBoardModelingPurchaseProps {
}

const DashBoardModelingPurchase: FC<DashBoardModelingPurchaseProps> = (props) => {

  const user:any = useSelector((state:RootState)=>state.loginUser.userInfo);

  const [ getPurChaseList ] = useGetPurChaseListMutation();
  const [ setApproval ] = useSetPurchareMutation();

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
    search: '',
    companyId:user.companyId,
    lang:'KR',
    sortColumn:'idx',
    sortOrder:true
  })

  const [ gridData, setGridData ] = useState({
    list : [],
    totalCount : 0,
  });

  const handlePageChange = (e: any) => {
    setPageInfo({
        ...pageInfo,
        page: e.selected
    })
  }

  const clickColumnHeader = ( columnId: string) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder
    });
  }

  const clickSizeSelectBox = ( size : number ) => {
    setPageInfo({
      ...pageInfo,
      size: size
  });
  }

  const handleChangeKeyword = (data:string) => { 
    setPageInfo({
      ...pageInfo,
      search: data
    })
  }

  const handleChangeApproval = async(data:any) => {//결제상태 버튼

    const body = {
      idx: data.idx,
      Y:data.approval==="Y"? "N" : "Y"
    }
    try {
      await setApproval(body);
      getGrid();
    } catch{
    }
  }

// 목록 데이터를 가져오는 함수
  const getGrid = async () => {
    try {
      const response: any = await getPurChaseList(pageInfo);
      if( response.data.result === true ) {

        setGridData({
          list : response.data.list,
          totalCount : response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  }

  useEffect(()=> {// 처음 1회 조회
    getGrid();
  },[]);

  useEffect(()=> { // pageInfo가 바뀔때마다 조회
    getGrid();
  },[pageInfo]);


  return (
    <>
     <div className="contents">
        <div className="box-row">
          <div className="box-col w100">
            <section className="">
              <div className="section-tit">
                <h1>제작 상태 및 결제 내역 관리</h1>
              </div>
              <div className="dash-table st-1">
                <div className="table-toolbar">
                  <div className="left">
                    <GridSizeSelectBox pageInfo={pageInfo} onClick={clickSizeSelectBox} />
                  </div>
                  <div className="right">
                    <GridSearchBox onChangeKeyword={handleChangeKeyword} />
                  </div>
                </div>
                  <table>
                    <thead>
                      <tr>
                        <GridColumnHeader id="idx" caption="접수번호" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="subject" caption="의뢰 제목" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="requestDate" caption="의뢰 접수 일자" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="status" caption="진행상태" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="expectedDate" caption="제작 완료 예상 일자" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="completedDate" caption="제작 완료일자" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="amount" caption="제작비용" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                        <GridColumnHeader id="approval" caption="결제상태" pageInfo={pageInfo} onClick={clickColumnHeader}/>
                      </tr>
                    </thead>
                  <tbody>
                    {
                      gridData.list.map((data:any)=> (
                        <tr key={data.idx}>
                          <td>{data.idx}</td>
                          <td>{data.subject}</td>
                          <td>{data.requestDate}</td>
                          <td>{data.status}</td>

                          <td>{data.expectedDate}</td>
                          <td>{data.completedDate}</td>
                          <td>{data.amount}</td>
                          <td>
                            <div className="approval-box">
                              <button onClick={()=>handleChangeApproval(data)} type="button" className={data.approval==="N" ? "red":"blue"}>{data.approval==="N" ? "미결제" : "결제완료"}</button>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div className={classnames('pageing', 'issuesPagination')}>
                      <Paginate
                          forcePage={pageInfo.page}
                          pageCount={gridData.totalCount/pageInfo.size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pageInfo.size}
                          onPageChange={handlePageChange}
                          nextLabel=""
                          previousLabel=""
                      />
                  </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoardModelingPurchase;