import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileDownLoad } from "../../../../../../../components/modelingCtrl/bottomPopup/product/fileDownload";
import ProductTroubleRegist from "../../../../../../../components/modelingCtrl/bottomPopup/product/product_troubleRegist";
import { useDelAssembleTroubleMutation, useGetAssembleTroubleMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductTrouble = () => {
    const {modelingId} = useParams();
    const [getAssembleTroubleApi] = useGetAssembleTroubleMutation();
    const [delAssembleTroubleApi] = useDelAssembleTroubleMutation();
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [troubleList, setTroubleList] = useState<any>({
        kr: [], en : []
    })
    const getAssembleTrouble = async() => {
        const param = {modelingId : modelingId}
        const result : any = await getAssembleTroubleApi(param)
        if(result.data.result === true) {
            setTroubleList({...troubleList,
            kr : result?.data?.list?.filter((item:any)=>item.lang==="KR"),
            en : result?.data?.list?.filter((item:any)=>item.lang==="EN")
            })
        }
    }
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    const handleDelTrouble = async(id:number) => {
        const confirm = window.confirm("삭제하시겠습니까?")
        if(confirm){
            const param = {id : id}
            const result : any = await delAssembleTroubleApi(param)
            if(result.data.result === true) {getAssembleTrouble()}
        }
    }
    useEffect(()=>{getAssembleTrouble()},[])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_5, PageStyles.active].join(" ")}>
            <div className={PageStyles.troubleBox}>
                <div className={PageStyles.trouble_kr_box}>
                    <span className={PageStyles.lang}>KR</span>
                    <div className={PageStyles.trouble_fileBox}>
                    {troubleList?.kr?.length === 0 ?
                        <div className={PageStyles.none_content_admin}>
                            <p>
                                <span>아직 등록된 콘텐츠가 없습니다.</span>
                                하단의 '+' 버튼을 눌러 콘텐츠를 등록해 주세요.
                            </p>
                        </div> :
                        <>
                        {troubleList?.kr?.map((data:any)=>(
                        <div className={PageStyles.catalogDown}>
                            <FileDownLoad fileUrl={data.url} fileName={data.filename}/>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button type="button" onClick={()=>handleDelTrouble(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                        </>
                    }
                    </div>
                </div>
                
                <div className={PageStyles.trouble_en_box}>
                    <span className={PageStyles.lang}>EN</span>
                    <div className={PageStyles.trouble_fileBox}>
                        {troubleList?.en?.length === 0 ? 
                        <div className={PageStyles.none_content_admin}>
                            <p>
                                <span>아직 등록된 콘텐츠가 없습니다.</span>
                                하단의 '+' 버튼을 눌러 콘텐츠를 등록해 주세요.
                            </p>
                        </div> :
                        <>
                        {troubleList?.en?.map((data:any)=>(
                        <div className={PageStyles.catalogDown}>
                            <FileDownLoad fileUrl={data.url} fileName={data.filename}/>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button type="button" onClick={()=>handleDelTrouble(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                        </>
                    }
                    </div>
                </div>
            </div>
            <button className={[PageStyles.troubleAddBtn, PageStyles.blueAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            {isOpen && <ProductTroubleRegist getTrouble={getAssembleTrouble} onClose={handleOpen}/>}
        </div>
        </>
    )
}

export default ProductTrouble;
