import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSendAssetMessageMutation, useSendMessageMutation } from '../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { RootState } from '../../store/store';

export interface IMessageBoxProps {
  modelingId: number,
}

const MessageSendBoxAssetModeling = (props: IMessageBoxProps) => {

  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);
  const userId = user.userId;

  const txtRef = useRef<HTMLTextAreaElement>(null);
  const [sendMessage] = useSendAssetMessageMutation();

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      // enter가 남아 있음
      handleSubmit();
    }
  }

  const handleSubmit = async () => {

    const msg = txtRef.current?.value;

    try {
      let result : any = await sendMessage({
        modelingId: props.modelingId,
        msgType: "MSG01",
        msg: msg,
        userId: userId
      });

      if (result.data.result) {
        // console.log('보유모델채팅 발송 완료');
        if (txtRef.current != undefined) {
          txtRef.current.value = "";
        }
      }
      // scrollToBottom();
    } catch (err) {
      console.error(err);
    }
  }


  return (
    <>
      <div className="chat_area">
        <div className="textarea">
          <textarea ref={txtRef} onKeyPress={onKeyPress}></textarea>
        </div>
        <div className="chat_iconBox">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <button onClick={handleSubmit}>COMMENT</button>
        </div>
      </div>
    </>
  )
}

export default MessageSendBoxAssetModeling;