import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetInviteInfoQuery, useInviteJoinMutation } from "../../../store/slices/member/memberApiSlice";
import Footer from "../../../layout/footer/Footer_main";
// http://alink3d.com/memberInviteJoin?companyId=1&inviteUser=test00@test.com
const MemberInviteJoin = (location : any) => {
	const lot = useLocation()
	const searchParam = new URLSearchParams(lot.search)

	const inviteUser = searchParam.get('inviteUser');
	const companyId = searchParam.get("companyId");
	const senderId:any = searchParam.get('sender')
	console.log(inviteUser)
	const [inviteUserName, setInviteUserName] = useState<string>('');
	const [inviteUserEmail, setInviteUserEmail] = useState<string>('');
	const [inviteUserCompanyName, setInviteUserCompanyName] = useState<string>('');
	const [inviteUserCompanyLogo, setInviteUserCompanyLogo] = useState<string>('');

	const inviteInfo = {
		inviteUser : inviteUser,
		companyId : companyId,
	}

	const {data, isSuccess} : any = useGetInviteInfoQuery({inviteInfo});
	
	useEffect(()=>{

		if(isSuccess){
			setInviteUserName(data.inviteInfo.userName)
			setInviteUserCompanyName(data.inviteInfo.companyName)
			setInviteUserCompanyLogo(data.inviteInfo.companyLogoUrl)
		}
	})

    const emailRef = useRef<any>();
	const passwordRef = useRef<any>();
	const passwordConfirmRef = useRef<any>();
	const lastnameRef = useRef<any>();
	const firstnameRef = useRef<any>();
	const mobileRef = useRef<any>();

	const initialState = {
		mp_login_id : '',			// 아이디 (이메일)
		mp_dumie_password : '',     // 비밀번호
		mp_dumie_password_confirm : '', // 비밀번호 확인
		mp_firstname_kr : '',		// 성 (이름)
		mp_lastname_kr : '',		// 이름
		mp_user_mobile : '',		// 전화번호
		companyId
	};
	
	const navigator = useNavigate();

	const [allChecking, setAllChecking] = useState<boolean>(false);		// 전체 동의
	const [mp_user_term_of_use, setMp_user_term_of_use] = useState<boolean>(false); 	// 이용 약관 동의
	const [mp_user_privacy_agreement, setMp_user_privacy_agreement] = useState<boolean>(false); 		// 개인 정보 수집 및 이용동의
	const [mp_user_consent_to_use_of_personal_info, setMp_user_consent_to_use_of_personal_info] = useState<boolean>(false); // 마케팅활용 동의
	
	const [passwordType, setPasswordType] = useState<boolean>(true)					// 비밀번호 입력창 마스킹 처리
	const [passwordConfirmType, setPasswordConfirmType] = useState<boolean>(true)	// 비밀번호 확인창 마스킹 처리

	//const navigator = useNavigate();

	const [joinData, setJoinData] = useState(initialState);
	const {mp_login_id, mp_dumie_password, mp_dumie_password_confirm, mp_firstname_kr, mp_lastname_kr, mp_user_mobile} = joinData;
	
	const [joinUSer, {data: userData, isSuccess: isJoinSuccess, isError: isJoinError, error: joinError} ] = useInviteJoinMutation();

	// 전체 동의 항목
	const handleAllChecking = () => {
		{ allChecking === false ? setAllChecking(true) : setAllChecking(false)}
		{ allChecking === false ? setMp_user_term_of_use(true) : setMp_user_term_of_use(false)}
		{ allChecking === false ? setMp_user_privacy_agreement(true) : setMp_user_privacy_agreement(false)}
		{ allChecking === false ? setMp_user_consent_to_use_of_personal_info(true) : setMp_user_consent_to_use_of_personal_info(false)}
	}
	// 이용 약관 동의 항목
	const handleChecking1 = (e:any) => {
		{ mp_user_term_of_use === false ? setMp_user_term_of_use(true) : setMp_user_term_of_use(false)}
	}	
	// 개인정보 수집 및 이용 동의 항목
	const handleChecking2 = (e:any) => {
		{ mp_user_privacy_agreement === false ? setMp_user_privacy_agreement(true) : setMp_user_privacy_agreement(false)}
	}
	//마케팅 활용 동의 항목
	const handleChecking3 = (e:any) => {
		{ mp_user_consent_to_use_of_personal_info === false ? setMp_user_consent_to_use_of_personal_info(true) : setMp_user_consent_to_use_of_personal_info(false)}
	}
	// 동의 항목 체크 조건
	useEffect(() => {
		if(mp_user_term_of_use === true && mp_user_privacy_agreement === true && mp_user_consent_to_use_of_personal_info === true){
			setAllChecking(true)
		} else {
			setAllChecking(false)
		}
	})

	// 회원 입력 항목
	const handleTextChange = (e:any) => {
		setJoinData({...joinData, [e.target.name] : e.target.value})
	}

	// 비밀번호 입력창 마스킹 처리
	const handlePasswordType = () => {
		setPasswordType(!passwordType)
	}

	// 비밀번호 확인 입력창 마스킹 처리
	const handlePasswordConfirmType = () => {
		setPasswordConfirmType(!passwordConfirmType)
	}

	// 이메일 형식 유효성 검사
	const checkEmail = (e:any) => {
		const checkEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
		if(!checkEmail.test(e.target.value) && mp_login_id !== ''){
			alert("올바른 형식의 이메일이 아닙니다. 다시 입력해주세요.")
			setJoinData({...joinData, mp_login_id : ''})
			emailRef.current.focus()
		}
	}

	// 비밀번호 유효성 검사 ( 영문 + 특수문자 + 숫자 8~25자 필수 )
	const checkPassword = (e:any) => {
		const checkPassword = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
		if(!checkPassword.test(e.target.value) && mp_dumie_password !== ''){
			alert("영문+숫자+특수문자 8~25자의 비밀번호를 입력해주세요.")
			setJoinData({...joinData, mp_dumie_password : ''})
			passwordRef.current.focus()
		}
	}

	// 비밀번호 확인 유효성 검사 (위 입력한 비밀번호와 일치하는지)
	const checkPasswordConfirm = (e:any) => {
		if(mp_dumie_password !== mp_dumie_password_confirm && mp_dumie_password_confirm !== ''){
			alert("비밀번호가 일치하지 않습니다. 다시 입력해주세요.")
			setJoinData({...joinData, mp_dumie_password_confirm : ''})
			passwordConfirmRef.current.focus();
		}
	}

	// 전화번호 유효성 검사
	const checkMobile = (e:any) => {
		const checkMobile = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/
		if(!checkMobile.test(e.target.value) && mp_user_mobile !== ''){
			alert("'-'을 제외한 6~9자리 전화번호를 입력해주세요.")
			setJoinData({...joinData, mp_user_mobile : ''})
			mobileRef.current.focus();
		}
	}

	// 회원 가입 버튼
	const handleJoin = async (e:any) => {
		e.preventDefault();
		
		try{
			// 필수 동의 체크 안할 시, 회원가입 방지 . 기입항목 중 비어있는 곳이 있다면 회원가입 방지 . 비밀번호 & 비밀번호 확인 입력 시 일치하지 않는다면 회원가입 방지
			//if(mp_login_id && mp_dumie_password && mp_firstname_kr && mp_lastname_kr && mp_user_mobile){		
			if(!senderId){	
				emailRef.current.focus();
				alert("이메일을 입력해주세요.")
			} else if (!mp_dumie_password) {
				passwordRef.current.focus();
				alert("비밀번호를 입력해주세요.")
			} else if (!mp_dumie_password_confirm) {
				passwordConfirmRef.current.focus();
				alert("비밀번호를 확인해주세요.")
			} else if (!mp_lastname_kr) {
				lastnameRef.current.focus();
				alert("성을 입력해주세요.")
			} else if (!mp_firstname_kr) {
				firstnameRef.current.focus();
				alert("이름을 입력해주세요.")
			} else if (!mp_user_mobile) {
				mobileRef.current.focus();
				alert("전화번호를 입력해주세요.")
			} else {
				if((mp_user_term_of_use === false || mp_user_privacy_agreement === false)){
					alert("필수 항목 체크해 주세요.")
				} else {
				await joinUSer(
					{senderId, companyId,
					mp_dumie_password, 
					mp_firstname_kr, mp_lastname_kr, 
					mp_user_mobile, 
					mp_user_consent_to_use_of_personal_info});
				}
			}
        } catch(err){
            
        }
	}


	// 비밀번호 확인창 스타일 & 속성 변경
	useEffect(()=>{
		if(mp_dumie_password === ''){
			passwordConfirmRef.current.disabled = true;
			passwordConfirmRef.current.style = "background-color : whitesmoke"
		} else {
			passwordConfirmRef.current.disabled = false;
			passwordConfirmRef.current.style = "background-color : #fff"
		}
	})

	useEffect(() => {
		if(isJoinSuccess){
			if(userData.result == false){
				alert("이미 가입된 이메일입니다.")
				
			} else {
				alert("가입이 성공적으로 처리되었습니다.")
				navigator('/')
			}
		} else{

		}
	}, [isJoinSuccess])
    
    return(
        <>
        <div id="wrap" className="signup-wrap">
			<div className="container">
				<div className="con-w1400">
					<div className="login-logo"><img src="/assets/images/logo-w180.png" alt=""/></div>
					<div className="signup-box">
						<div className="invite-top-row">
                            <table>
                                <tr>
                                    <th style={{width : '70px'}}>초대자</th>
                                    {/* <td>BMC 이창근 이사 [adrian@bmcnet.kr]</td> */}
                                    <td>{inviteUserName}  [{inviteUser}]</td>
                                    <td className="td-company-logo">
                                        <div className="company-logo">
                                            <img src={inviteUserCompanyLogo} alt=""/>
                                            <div className="company-name">{inviteUserCompanyName}</div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="input-row">
								<div className="col">
									<fieldset>
										<label htmlFor="email" ><span>이메일</span></label>
										<input type="email" id="email" name="mp_login_id" value={senderId} onChange={handleTextChange} onBlur={checkEmail} ref={emailRef} placeholder="이메일을 입력해 주세요." disabled={true}/>
									</fieldset>
								</div>
                            </div>
							<div className="input-row">
                                <div className="col">
                                	<fieldset className="pw">
										<label htmlFor="password"><span>비밀번호</span></label>
	                                    <input type={passwordType ? "password":"text"} id="password" name="mp_dumie_password" value={mp_dumie_password} onChange={handleTextChange} onBlur={checkPassword} ref={passwordRef} placeholder="비밀번호를 입력해 주세요."/>
										<span onClick={handlePasswordType}>
											{passwordType ? <i className="fa-sharp fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
										</span>
	                                </fieldset>
									<fieldset className="pw">
	                                    <input type={passwordConfirmType ? "password":"text"} name="mp_dumie_password_confirm" value={mp_dumie_password_confirm} onChange={handleTextChange} onBlur={checkPasswordConfirm} ref={passwordConfirmRef} placeholder="비밀번호를 확인해 주세요."/>
										<span onClick={handlePasswordConfirmType}>{passwordConfirmType ? <i className="fa-sharp fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}</span>
	                                </fieldset>
                                </div>
                            </div>
							<div className="input-row col-2">
                                <div className="col w260">
                                	<fieldset>
										<label htmlFor="name"><span>이름</span></label>
										<input type="text" id="name" name="mp_lastname_kr" value={mp_lastname_kr} onChange={handleTextChange} ref={lastnameRef} placeholder="성"/>
									</fieldset>
                                </div>
								<div className="col w530">
									<fieldset>
										<label htmlFor="" className="hidden"><span>이름</span></label>
										<input type="text" name="mp_firstname_kr" value={mp_firstname_kr} onChange={handleTextChange} ref={firstnameRef} placeholder="이름"/>
									</fieldset>
								</div>
                            </div>
							<div className="input-row">
								<div className="col">
									<fieldset>
										<label htmlFor="tel"><span>휴대전화</span></label>
										<input type="text" id="tel" name="mp_user_mobile" value={mp_user_mobile} onChange={handleTextChange} onBlur={checkMobile} ref={mobileRef} placeholder="'-'을 제외한 전화번호를 입력해 주세요."/>
									</fieldset>
								</div>
                            </div>
							<div className="agree-row all-chk">
								<input type="checkbox" className="htmlForm-check-input" checked={allChecking} onChange={handleAllChecking} name="idSaveCheck" id="allAgree"/> <label htmlFor="allAgree"> 전체 동의</label>
							</div>
							<div className="agree-row-line"></div>
							<div className="agree-row">
								<input type="checkbox" className="htmlForm-check-input" checked={mp_user_term_of_use} onChange={handleChecking1} name="idSaveCheck" id="a"/> <label htmlFor="a"> 이용 약과 동의  (필수)</label>
								<a href="/terms1" target="_blank">[약관보기]</a>
							</div>
							<div className="agree-row">
								<input type="checkbox" className="htmlForm-check-input" checked={mp_user_privacy_agreement} onChange={handleChecking2} name="idSaveCheck" id="b"/> <label htmlFor="b"> 개인정보 수집 및 이용 동의  (필수)</label>
								<a href="/terms2" target="_blank">[약관보기]</a>
							</div>
							<div className="agree-row">
								<input type="checkbox" className="htmlForm-check-input" name="mp_user_consent_to_use_of_personal_info" checked={mp_user_consent_to_use_of_personal_info} onChange={handleChecking3} id="c"/> <label htmlFor="c"> 마케팅 활용 동의 <span className="option">(선택) - 서비스안내 수신동의 내용 포함</span></label>
								<a href="/terms3" target="_blank">[약관보기]</a>
							</div>
							{/* <div className="ad-row">
								<div className="swiper-container ad-swiper">
									<div className="swiper-wrapper">
										<div className="swiper-slide swiper-slide1 ad">
											<div className="img"><img src="https://placeimg.com/800/400/people"/></div>
										</div>
										<div className="swiper-slide swiper-slide2">
											<div className="img"><img src="https://placeimg.com/640/480/any"/></div>
										</div>
										<div className="swiper-slide swiper-slide3 ad">
											<div className="img"><img src="https://placeimg.com/800/275/tech"/></div>
										</div>
									</div>
									<div className="swiper-pagination"></div>
								</div>
							</div> */}
							<div className="submit-row">
								<button onClick={handleJoin} className="blue">가입하기</button>
							</div>
						
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
        </>
    )
}

export default MemberInviteJoin;