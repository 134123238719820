import React from 'react';
import type { FC } from 'react';
import ProfileCircle from './profile-circle';

interface ProfileGroupListProps {
  managers: any;
}

const ProfileGroupList: FC<ProfileGroupListProps> = (props) => {

  const { managers } = props;

  return (
    <>
      <div className="pic-box">
        {/* <!-- 프로필 3개 초과시 아래 pic-group 태그 생성 --> */}
        {/* <ProfileCircle  url="/assets/images/dotsAdmin/test2.png" /> */}
        {
          managers.length > 3 &&
          <div className="pic-align pic-group">
            <div className="pic" style={{ background: 'chocolate' }}>
              +{managers.length - 3}
            </div>
          </div>
        }
        {
          managers.slice(0, 4).map((item: any) => (
            <ProfileCircle item={item} />
          ))
        }
        {/* 최초 담당자 등록 아이콘 :: 담당자 등록 시, 사라져야함 */}
        {
          managers.length === 0 &&
          <div className="pic-align">
            <div className="pic has-profile" style={{ background: `url("/assets/images/profile_none.png") center / cover no-repeat` }}>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default ProfileGroupList;