import React, { FC, useState, useRef, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useEditAssetModelMutation } from '../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { toLocaleToDate } from '../../utils/toLocaleDate';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/storeHooks';
import { useSetUserAssembleMutation } from '../../store/slices/modeling/modelingApiSlice';
import { ModelingRegist } from '../dashboard/popup/modelingRegist/modelingRegistPopup';
import { useSetAssetToMp2024Mutation } from '../../store/slices/modeling/marineConnectApiSlice';

interface IGroupAvaliableItem {
  data: any;
  openPopup: (data: any) => void;
  type: string;
  groupColor: string;
  getGrid?: () => void;
}

const UseItem = (props: any) => {

  const { data, getGrid, callModelingCtrl } = props;
  const { modelingId, modelingNo, modelingName, manufacturedDate, freeExpiredDate, expiredDate, modelingIframePath, publicYn, enableYn } = data;
  const userData = useAppSelector(state=>state.loginUser.userInfo)
  // console.log(`modelingId[${modelingId}] modelingId[${modelingNo}] modelingId[${modelingName}] modelingIframePath[${modelingIframePath}]`);
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const navi = useNavigate();
  const pickerRef = useRef<HTMLInputElement>(null);
  const txtareaRef = useRef<HTMLTextAreaElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [writeActive, setWriteActive] = useState(false);
  const [setAssemble] = useSetUserAssembleMutation()
  const [editAssetModel] = useEditAssetModelMutation();
  const [setAssetToMp2024Api] = useSetAssetToMp2024Mutation()

  // api 호출용 인자 : editAssetModel을 호출하면 getGrid로 갱신하기 때문에 문제는 없음
  const filter = {
    modelingId: modelingId,
    modelingName: modelingName,
    enableYn: enableYn,
    publicYn: publicYn
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    try {
      const result: any = await editAssetModel({
        modelingId,
        modelingName,
        enableYn: name !== "enable" ? enableYn : enableYn === "Y" ? "N" : "Y",
        publicYn: name !== "public" ? publicYn : publicYn === "Y" ? "N" : "Y"
      })
      if (result.data.result) {
        getGrid();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const editList = async () => {
    try {
      const modelingName = txtareaRef.current?.value;
      const result: any = await editAssetModel({
        ...filter,
        modelingName: modelingName
      })

      if (result.data.result) {
        getGrid();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleSave = (e: any) => {
    if (e.key === 'Enter') {
      editList();
      setWriteActive(false);
    }
  }

  const copyClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(modelingIframePath);
      toast.success('복사 완료')
      setOpen(false);
    } catch (err) {
      console.error('실패')
    }
  }

  const handleWriteActive = () => {
    setWriteActive(true);
    txtareaRef.current?.focus();
  }

  useEffect(() => {

    const handleOutSide = (e: any) => {
      if (txtareaRef.current && !txtareaRef.current.contains(e.target)) {
        setWriteActive(false);
      }
    }
    document.addEventListener('mousedown', handleOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOutSide);
    }

  }, [txtareaRef]);

  useEffect(() => {
    const handleOutSide = (e: any) => {
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutSide);
    return () => {
      document.removeEventListener("mousedown", handleOutSide);
    };
  }, [pickerRef]);

  // 모델링 컨트롤 페이지로 이동
  const handleCall = async(data : any) => {
    console.log(data)
    // 접수 번호 클릭 시, Modeling Name에 따른 json 파일이 load 되어 모델링 출력된다.
    // 모델링 아이디 , 모델링 이름, 유저 아이디 서버에 전달
    const param = {modelingId : data.modelingId, userId : userData.userId, modelingName : data.modelingName};
    const result : any = await setAssemble(param);
    if(result.data.result ===true){
      navi(`/dashboard/modelingCtrl/${data.modelingId}#` , {state : {path : data.modelingIframePath}})
    }
  }

  // 모델링 공개여부 클릭 시 , 썸네일 및 카테고리 등록해야 함.
  const handleRegistModelingInfo = async(e:any) => {
    if(publicYn==='N'){
      const confirm = window.confirm("마린플라자에 공개하시겠습니까?")
      if(confirm){
        const result : any = await setAssetToMp2024Api({
          modelingId : modelingId, modelingName : modelingName, companyId : userData.companyId, modelingPath : modelingIframePath
        })
        if(result.data.result === true){
          handleChange(e)
        }
      }
    } else {
      const confirm = window.confirm("비공개로 전환하시겠습니까?")
      if(confirm){
        handleChange(e)
      }
    }
  }

  return (
    <>
      <Toaster />
      <div className="rece-row">
        <div className="rece-title">

          <div className="triangle-topleft blue">
          </div>
          <div className="rece-name" onClick={()=>handleCall(data)}>
            <span style={{ whiteSpace: 'nowrap' }}>{modelingNo}</span>
          </div>
          <div className={`rece-name2 ${writeActive ? 'active' : ''}`}>
            <span onClick={() => props.openPopup(data)}>{modelingName}</span>
            <textarea ref={txtareaRef} onKeyDown={handleSave}>{modelingName}</textarea>
          </div>
          {
            modelingName &&
            <div className="buttonBox">
              <button onClick={handleWriteActive}><img src="/assets/images/pen-to-square-light.png" alt="button" /></button>
            </div>
          }

        </div>

        <div className="rece-list-field">
          <div className="rece-col">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>{toLocaleToDate(manufacturedDate)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="rece-col company">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>{toLocaleToDate(freeExpiredDate)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="rece-col date-2">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>
                    <p>{toLocaleToDate(expiredDate)}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="rece-col date-2">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <div style={{ position: 'relative' }} className="scrap_area">
                    <button onClick={() => setOpen(true)} className={isOpen ? "active" : ""}></button>
                    <div style={{ top: '10px' }} ref={pickerRef}>
                      <input value={modelingIframePath} type="text" name="" id="" readOnly />
                      <button onClick={copyClipBoard} className="active"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rece-col pay">
            <div className="pic-box">
              <div className="pic-align">
                <div className="pic pic-register">
                  <span>
                    <input name="public" onClick={handleRegistModelingInfo} checked={publicYn === "Y"} type="checkbox" id={`toggle-1-${modelingId}`} className="toggleIpt" hidden />
                    <label htmlFor={`toggle-1-${modelingId}`} className="toggleSwitch">
                      <span className="toggleButton"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="rece-col person">
            <div className="manager_list">
              <div className="content-row">
                <div className="pic pic-register">
                  <span>
                    <input name="enable" onChange={handleChange} checked={enableYn === "Y"} type="checkbox" id={`toggle-2-${modelingId}`} className="toggleIpt" hidden />
                    <label htmlFor={`toggle-2-${modelingId}`} className="toggleSwitch">
                      <span className="toggleButton"></span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const GroupAvaliableItem: FC<IGroupAvaliableItem> = (props) => {

  const { data, openPopup, getGrid } = props;
  return (
    <UseItem getGrid={getGrid} openPopup={openPopup} data={data} />
  )
}

export default GroupAvaliableItem;

const StyledInput = styled.input<{ active: boolean }>`
    border: none;
    outline: none;
    cursor: pointer;
    background: ${(props) => props.active ? '#ffffff' : 'none'};
    &:focus {
        border-radius: 4px;
        outline: none;
        border: none;
    }

`

function handleClickModelingCtrl() {
  throw new Error('Function not implemented.');
}
