import { useState, useEffect } from "react";
import "./pagination.css";
import {
  useDeleteDepartmentMutation,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetDepartmentMutation,
} from "../../../store/slices/dashboard/dashboardApiSlice";
import { toast, Toaster } from "react-hot-toast";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import GridColumnHeader from "../../../components/grid/gridColumnHeader";
import GridSizeSelectBox from "../../../components/grid/gridSizeSelectBox";
import GridSearchBox from "../../../components/grid/gridSearchBox";
import MessageBox, {
  MessageBoxType,
} from "../../../components/dialog/messagebox/messageBox";
import PopupEdit from "./popup-edit";
import Pagination from "../../../components/dashboard/pagination";
import PopupRole from "../dashboard-employee-list/popup-role";
import { useTranslation } from "react-i18next";

const DashBoardDepartManager = () => {
  const { t } = useTranslation();
  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);

  const editInfoDefault = {
    isOpen: false,
    name: "",
    email: "",
    idx: 0,
  };

  const dialogInfoDefault = {
    isOpen: false,
    message: <></>,
    idx: 0,
  };

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
    search: "",
    companyId: user.companyId,
    lang: "KR",
    sortColumn: "idx",
    sortOrder: true,
    type : 'ud_name',
  });

  const [gridData, setGridData] = useState({
    list: [],
    totalCount: 0,
  });

  const [createInfo, setCreateInfo] = useState(editInfoDefault); //생성창에 대한 정보
  const [editInfo, setEditInfo] = useState(editInfoDefault); // 수정창에 대한 정보
  const [deleteInfo, setDeleteInfo] = useState(dialogInfoDefault); // 삭제창에 대한 정보

  const [getDepartmentList] = useGetDepartmentMutation();
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const [addDepartment] = useAddDepartmentMutation();
  const [updateDepartment] = useUpdateDepartmentMutation();

  const clickColumnHeader = (columnId: string) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder,
      type : columnId
    });
  };

  const clickSizeSelectBox = (size: number) => {
    setPageInfo({
      ...pageInfo,
      size: size,
    });
  };

  const handleQueryChange = (keyword: string) => {
    setPageInfo({
      ...pageInfo,
      search: keyword,
    });
  };
  //#endregion 공통 끝

  const getGrid = async () => {
    try {
      const response: any = await getDepartmentList(pageInfo);
      if (response.data.result === true) {
        setGridData({
          list: response.data.departmentList,
          totalCount: response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  //#region 삭제 관련 코드 영역...
  const handleDeleteDepart = (e: any, data: any) => {
    console.log(data);

    const message = (
      <>
        {t("depart-department")}{`[`}
        <b>{data.ud_name}</b>
        {`]`}{t("depart-askDelete")}
      </>
    );
    setDeleteInfo({
      isOpen: true,
      message: message,
      idx: data.idx,
    });
  };

  const clickDeleteYes = async () => {
    try {
      await deleteDepartment({
        adminId: user.userId,
        idx: deleteInfo.idx,
      });
      setDeleteInfo(dialogInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickDeleteNo = () => {
    setDeleteInfo(dialogInfoDefault);
  };
  //#endregion

  const handleUpdateDepart = (e: any, data: any) => {
    setEditInfo({
      isOpen: true,
      name: data.ud_name,
      email: data.ud_email,
      idx: data.idx,
    });
  };

  const clickCreateDepart = () => {
    setCreateInfo({
      isOpen: true,
      name: "",
      email: "",
      idx: 0,
    });
  };

  //수정창 버튼 관련
  
  const clickEditOk = async (name: string, email: string) => {
    // popup-edit에서 저장누르면
    // 서버에 저장
    try {
      await updateDepartment({
        idx: editInfo.idx,
        ud_name: name,
        ud_email: email,
        ud_lang: "KR",
      });
      setEditInfo(editInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickEditCancel = () => {
    setEditInfo(editInfoDefault);
  };

  //생성창 버튼 관련
  const clickCreateOk = async (name: string, email: string) => {
    try {
      await addDepartment({
        ud_company_id: user.companyId,
        ud_name: name,
        ud_email: email,
        ud_lang: "KR",
      });
      setCreateInfo(editInfoDefault);
      getGrid();
    } catch (err) {}
  };

  const clickCreateCancel = () => {
    setCreateInfo(editInfoDefault);
  };

  const handlePrev = () => {
    if (pageInfo.page === 0) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page - 1,
    });
  };
  const handleNext = () => {
    if (pageInfo.page + 1 === Math.ceil(gridData.totalCount / pageInfo.size)) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };

  useEffect(()=>{
    if(user.companyId){
      setPageInfo({...pageInfo, companyId : user.companyId})
    }
  }, [user])

  useEffect(() => {
    // pageInfo가 바뀔때마다 조회
    getGrid();
  }, [pageInfo]);

  return (
    <>
      <div className="department_management_container">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3>
                {t('depart-title')}<span style={{cursor:'pointer'}} onClick={()=>clickCreateDepart()}>{t('depart-add')}</span>
              </h3>
            </div>
            <div className="table_area">
              <div>
                <GridSizeSelectBox
                  pageInfo={pageInfo}
                  onClick={clickSizeSelectBox}
                />
                <GridSearchBox onChangeKeyword={handleQueryChange} />
              </div>
              <div>
                <table>
                  <caption>{t('depart-title')}</caption>
                  <thead>
                    <tr>
                      
                      {/* <GridColumnHeader
                        id="idx"
                        caption="아이디"
                        widthStyle="w150"
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      /> */}
                      <GridColumnHeader
                        id="ud_name"
                        caption={t('depart-col-name')}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="ud_email"
                        caption={t('depart-col-email')}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <th></th>
                      
                      <th></th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.list.map((data: any) => (
                      <tr>
                        {/* <td>{data.idx}</td> */}
                        <td>{data.ud_name}</td>
                        <td>{data.ud_email}</td>
                        <td></td>
                        <td>
                          <button
                            onClick={(e) => handleUpdateDepart(e, data)} 
                          >
                          </button>
                          <button
                            onClick={(e) => handleDeleteDepart(e, data)}
                          >
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                prevClick={handlePrev}
                nextClick={handleNext}
                totalCount={gridData.totalCount}
                page={pageInfo.page}
                size={pageInfo.size}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 생성 팝업 */}
      {createInfo.isOpen && (
        <PopupEdit
          name=""
          email=""
          onSave={clickCreateOk}
          onCancel={clickCreateCancel}
        />
      )}

      {/* 수정 팝업 */}
      {editInfo.isOpen && (
        <PopupEdit
          name={editInfo.name}
          email={editInfo.email}
          onSave={clickEditOk}
          onCancel={clickEditCancel}
        />
      )}
      {/* 거절 팝업 */}
      {deleteInfo.isOpen && (
        <MessageBox
          boxType={MessageBoxType.YesNo}
          message={deleteInfo.message}
          onClick1={clickDeleteNo}
          onClick2={clickDeleteYes}
        />
      )}
    </>
  );
};

export default DashBoardDepartManager;
