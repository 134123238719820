import React, { forwardRef, useEffect, useRef, useState } from "react";
import { RowEmployeeList } from ".";
import ChipInput from 'material-ui-chip-input';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { toast } from "react-hot-toast";
import { useInviteEmployeeMutation } from "../../../store/slices/dashboard/dashboardApiSlice";

export interface EventPopupRole {}

export interface PropsPopupRole {
  onSave?: Function;
  onCancel?: any;
}

const CreatePopup = forwardRef<EventPopupRole, PropsPopupRole>((props, ref) => {

    const loginUser:any = useSelector((state:RootState)=> state.loginUser.userInfo);
    const [inviteEmail, setInviteEmail] = useState<any>([]); // 배열
    const [inviteEmails, setInviteEmails] = useState<any>(null); // 배열 -> 문자열\
    const companyId = loginUser.companyId;  // 유저 회사 아이디
    const userId = loginUser.userId;    // 유저 아이디
    const [inviteData, {data}] = useInviteEmployeeMutation();

    const handleAdd = (chip:any) => {
        if(inviteEmail && inviteEmail.length > 9){
            toast.error("최대 10명까지 초대가 가능합니다.")
            return;
        } else {
            setInviteEmail(inviteEmail.concat(chip));
        }
    }

    const handleDelete = (chip : any, index : any) => {
        const list = inviteEmail.filter((data: any) => data !== chip);
        setInviteEmail(list)
    }

    const handleInviteEmployee = async() => {
        try {
            const result = await inviteData({inviteEmails, companyId, userId})
            if(result){
                props.onCancel();
            }
        } catch(err) {
            console.error(err);
        }
    }

    const clickCancel = () => {
        props.onCancel();
    };

    useEffect(()=>{
        setInviteEmails(inviteEmail.join())
    }, [handleAdd, handleDelete])

  return (
    <>
      <div className="staff_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body">
                <div className="content">
                      <p style={{display:'flex'}}>
                        <strong style={{whiteSpace:'nowrap',margin:'0 10px',display:'flex',alignItems:'center'}}>이메일</strong>
                        <ChipInput
                                className="chip-input"
                                value={inviteEmail}
                                disableUnderline={false}
                                onDelete={(chip,index)=> handleDelete(chip,index)}
                                onAdd={(chip)=> handleAdd(chip)}                                                  
                                style={{width: '100%'}}
                                newChipKeyCodes={[13,188]}
                                //ref = {mainProductsRef}
                            />
                      </p>
                </div>
                <div className="buttonBox">
                  <button onClick={clickCancel}>취소</button>
                  <button onClick={handleInviteEmployee}>저장</button>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CreatePopup;
