import React from 'react';
import ProfileCircle from './profile-circle';
import type { FC } from 'react';
import ProfileGroupList from './profile-group-list';
// import AssigneePopup from '../../dashboard/popup/assigneeMenu/assigneePopup';

interface GroupProfileProps {
  managers: any;
}

const GroupProfile: FC<GroupProfileProps> = (props) => {

  const { managers } = props;

  return (
    <>
      {/* 담당자 지정 영역 */}
      <div className="rece-col person">
        <div className="manager_list">
          <div className="content-row">
            {/* ※ 프로필 정렬 주의 사항
              - 맨 왼쪽으로 나와야할 프로필이 가장 마지막 줄에 생성되어야 합니다.
              ※ class="circle" 가 현재 접속 유무. css 처리 안하고 태그 생성or삭제 */}
            <div className="todo-list-field">
              <div className="todo-col assignee">
                <ProfileGroupList managers={managers} />
                {/* <AssigneePopup/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GroupProfile;