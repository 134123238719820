import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PartsInspectionRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_inspectionRegist";
import { useDelPartsInspectionMutation, useGetPartsInspectionMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props{partsName : string; partsId:string}
const PartsInspection:React.FC<Props> = ({partsName , partsId}) => {
    const {modelingId} = useParams();
    const [activeIndex, setActiveIndex] = useState<any>({})
    const [getPartsInspectionApi] = useGetPartsInspectionMutation()
    const [delPartsInspectionApi] = useDelPartsInspectionMutation()
    const [inspectList, setInspectList] = useState<any>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [inspectType, setInspectType] = useState<any>([])
    
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    
    const getPartsInspection = async() => {
        const param = {modelingId : modelingId, id : partsId}
        const result:any = await getPartsInspectionApi(param);
        if(result.data.result === true){
            setInspectList(result.data.list);
            setInspectType(result.data.code_info)
        }
    }

    const handleDataListClick = (idx:number) => {
        setActiveIndex((prev:any) =>({...prev, [idx]: !prev[idx]}))
    }

    const handleFileDown = (file : string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }

    const handleDelInsp = async(idx : string) => {
        const param = {insp_idx : idx, image_idx : 0}
        const confirm = window.confirm("해당 성정서를 삭제하시겠습니까?")
        if(confirm){
            const result:any = await delPartsInspectionApi(param)
            if(result.data.result === true){getPartsInspection()}
        }
    }

    const handleDelImage = async(insp_idx:string, image_idx:string) => {
        const param = {insp_idx : insp_idx, image_idx : image_idx}
        const confirm = window.confirm("해당 이미지를 삭제하시겠습니까?");
        if(confirm){
            const result:any = await delPartsInspectionApi(param)
            if(result.data.result === true){getPartsInspection()}
        }
    }
    
    useEffect(()=>{
        getPartsInspection()
        setIsOpen(false)
    }, [partsId])

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_3, PageStyles.active].join(" ")}>

            {inspectList.map((data:any, index : number)=>(

                <div className={PageStyles.dataBox} id="partsInspectionList" key={data.insp_idx}>
                    {/* className={`${PageStyles.dataBox} ${activeIndex === index ? PageStyles.active : ""}`} */}
                    <div className={[PageStyles.dataList, PageStyles.active].join(" ")} id="dataList1">
                        {/* ball : Red or Blue */}
                        <div className={PageStyles.dataList_title} id="dimension-inspection-btn">
                            <p className={data.parts_insp_result==='Y'?PageStyles.blue:PageStyles.red}><span></span>{data.parts_insp_type_name}</p>
                            <div className={PageStyles.commentReadBox}>
                                <span></span>
                                <div>
                                    {data.parts_insp_comments}
                                </div>
                            </div>
                        </div><div className={PageStyles.uploadPdf1}>
                        <div className={PageStyles.catalogDown}>
                            <div style={{display : 'flex', alignItems: 'center'}}>
                                <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                                <a target="_black" onClick={()=>handleFileDown(data.parts_insp_pdsFilename)}><input id="dimension-file" name="dimension-file" value={data.parts_insp_pdsFile} readOnly/></a>
                            </div>

                            <div style={{display : 'flex'}}>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button onClick={()=>handleDelInsp(data.insp_idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                    </div>

                    <ul className={PageStyles.uploadImgArea}>
                        {data.image_file.map((img:any) => (
                        <li key={img.idx}>
                            <div>
                                <button onClick={()=>handleDelImage(data.insp_idx,img.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="close button"/></button>
                                <div>
                                    <img src={img.parts_insp_image_pdsFilename} alt=""/>
                                </div>
                            </div>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
            ))}

            {isOpen && <PartsInspectionRegist getData={getPartsInspection} inspectType={inspectType} partsName={partsName} partsId={partsId} onClose={handleOpen}/>}
            <button className={[PageStyles.blueAddBtn, PageStyles.dataAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
        </div>
        </>
    )
}

export default PartsInspection;