import React, { FC, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './calendar.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from 'styled-components';
import BigCalendar from '../../../../components/dashboard-requsetManagement/bigCalendar/bigCalendar';
import { ModelingOrderType, ModelingOrderDetailType } from '../../../../types/modelingOrderType';

interface ISecondSubPopupProps {
  selectedPopupInfo: ModelingOrderType | any;
  detailInfo: ModelingOrderDetailType | any;
}

const SecondSubPopup: FC<ISecondSubPopupProps> = (props) => {

  const dateInfo = {
    manufacturedDate: props.detailInfo.manufacturedDate ? new Date(props.detailInfo.manufacturedDate) : null,
    freeExpiredDate: props.detailInfo.freeExpiredDate ? new Date(props.detailInfo.freeExpiredDate) : null,
    expiredDate : props.detailInfo.expiredDate ? new Date(props.detailInfo.expiredDate) : null,
  };

  const handleChange = (e: any, type: string) => {
  }

  if (!props.detailInfo) {
    return <></>;
  }

  return (
    <>
      <div className="modal_popUp_3">
        <BigCalendar dateInfo={dateInfo} />
        <div className="calendar_area" style={{ marginTop: '40px' }}>
          <ul style={{ display: 'flex', justifyContent: 'space-between' }}>
            <li className="off">
              <StyledTitle>제작 완료일자</StyledTitle>
              <StyledBox><DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.manufacturedDate} onChange={(e) => handleChange(e, 'registeredDate')} /><StyledIcon></StyledIcon></StyledBox>
            </li>
            <li className="off">
              <StyledTitle>무료사용 만기일자</StyledTitle>
              <StyledBox><DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.freeExpiredDate} onChange={(e) => handleChange(e, 'paidDate')} /><StyledIcon></StyledIcon></StyledBox>
            </li>
            <li className="on">
              <StyledTitle>만기일자</StyledTitle>
              <StyledBox>
                <DatePicker dateFormat="yyyy-MM-dd" selected={dateInfo.expiredDate} onChange={(e) => handleChange(e, 'expectedDate')} />
                <StyledIcon></StyledIcon>
              </StyledBox>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const StyledIcon = styled.span`
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAABHNCSVQICAgIfAhkiAAAAJlJREFUKFNjZICC8+fPK/z9+/e8iYmJIEwMRJ85c+YDMzOzgaGh4QMQnxFEnD592oGRkVEfyJzAxMTkgKzh379/B4D8AqD4BSMjo4OMQMUHgIrtkRXhYR9gBFr5n0jFYGXkafj//7+jqakpyK04AdSf+8E2jGrAEU7IoXQBqIYfiMFpBQ9QAMp9ZAQmOoE/f/4UEBPbLCwsEwDpCXxegc3HCQAAAABJRU5ErkJggg==) center / contain no-repeat;
  position: absolute;
  right: 20px;
  top: 13px;
  display: block;
  width: 12px;
  height: 13.5px;
`

const StyledBox = styled.p`
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  font-size: 14px;
  color: #999999;
  position: relative;
`

const StyledTitle = styled.h3`
    font-size:12px;
    font-weight:600;
    line-height: 25px;
    color: #444444;
`


export default SecondSubPopup;