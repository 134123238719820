import React, { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//김건우
import { useTranslation } from "react-i18next";


interface DashBoardModelingRequestProps {

}


const DashBoardModelingRequest: FC<DashBoardModelingRequestProps> = (props) => {
    
    //김건우
    const { t } = useTranslation();
    
    const user = useSelector((state: any) => state.loginUser.userInfo);
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState<boolean>(false)
    const handleClickClose = () => {
        setIsShow(false)
    }
    console.log(user)
    const handleClick = (pathName : string) => {
        if(user.companyId === "0"){
            setIsShow(true)
        } else {
            navigate(pathName)
        }
    }
    const handleClickMyCompany = () => {
        navigate('/dashboard/company');
        setIsShow(false);
    }
    return (
        <>
            <div className="modeling_request_container">
                <div className="wrap">
                    <div className="content">
                        <div className="fixed">
                            <h3>{t("modelingRequest-main")}</h3>
                        </div>
                        <div>
                             <h4>{t("modelingRequest-choice")}</h4>
                            <p>{t("modelingRequest-Explan")}</p>
                            <ul>
                                <li>
                                    <div></div>
                                    {/* <!-- <img src="../images/pictureFrame.png" alt="제품샘플 or 사진"> --> */}
                                        <p>{t("modelingRequest-photo")}<span>*.png, *.jpeg, *.jpg</span></p>
                                        <button onClick={()=>handleClick('/dashboard/modelingPhoto')}>{t("modelingRequest-commission")}</button>
                                        {/* <Link to="/dashboard/modelingPhoto">
                                            <button>의뢰하기</button>
                                        </Link> */}
                                </li>
                                <li>
                                    {/* <!-- <img src="../images/pencil.png" alt="AutoCAD 2D 도면"> --> */}
                                        <div></div>
                                        <p>{t("modelingRequest-2d")}<span>*.dwg (AutoCAD)</span></p>
                                        <button onClick={()=>handleClick('/dashboard/modeling2d')}>{t("modelingRequest-commission")}</button>
                                        {/* <Link to="/dashboard/modeling2d">
                                        </Link> */}
                                </li>
                                <li>
                                    {/* <!-- <img src="../images/object.png" alt="자체 3D 모델링"> --> */}
                                        <div></div>
                                        <p>{t("modelingRequest-3d")}<span>*.igs, *.stl, *.obj, *.stp, *.3ds</span></p>
                                        <button onClick={()=>handleClick('/dashboard/modeling3d')}>{t("modelingRequest-commission")}</button>
                                        {/* <Link to="/dashboard/modeling3d">
                                        </Link> */}
                                </li>
                            </ul>
                            <p>{t("modelingRequest-guide")}</p>
                        </div>
                    </div>

                </div>
            </div>

            {isShow && (
        <div className="modeling_request_popUp active">
          <div className="wrap">
            <div>
              <div className="con on">
                <div className="body">
                  <div className="content">
                    <div>
                      <p><span>{t("modelingRequest-popUp")}</span>
                        <strong>{t("modelingRequest-askRegist")}</strong></p>
                    </div>
                    <div className="buttonBox">
                      <button onClick={handleClickClose}>{t("modelingRequest-cancel")}</button>
                      <button onClick={handleClickMyCompany}>{t("modelingRequest-regist")}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg"></div>
            </div>
          </div>
        </div>
      )}
        </>
    )
}

export default DashBoardModelingRequest;