import React, { useEffect, useRef, useState } from "react";
import PageStyles from '../../css/page.module.css';
import ProductAdminLog from "./subPage/product_adminLog";
import ProductCatalog from "./subPage/product_catalogue";
import ProductMemo from "./subPage/product_memo";
import ProductPerformance from "./subPage/product_performance";
import ProductSpec from "./subPage/product_spec";
import ProductTrouble from "./subPage/product_trouble";
import ProductVideo from "./subPage/product_video";

export interface Props {
    onClose : Function
}
const ProductPopUp:React.FC<Props> = ({onClose}) => {

    const menuList = [
        {idx : 1, title : "제품사양"},{idx : 2, title : "홍보영상"},{idx : 3, title : "카탈로그"},{idx : 4, title : "제품실적"},{idx : 5, title : "트러블슈팅"},{idx : 6, title : "제품메모"},{idx : 7, title : "관리자 로그"}
    ];

    const menuListRef = useRef<any>([]);

    const [subPageIdx, setSubPageIdx] = useState<number>(1);

    const handleOpen = (list:any) => {
        // 모든 속성에 remmove
        Object.values(menuListRef.current).forEach((ref : any) => {
            ref?.classList.remove(PageStyles.active);
        })
        // 선택된 버튼만 속성 add
        menuListRef.current?.[list.title].classList.add(PageStyles.active);
        setSubPageIdx(list.idx)
    }

    useEffect(()=>{
        // 초기 버튼 찾기
        const initBtn = menuList.find((btn:any) => btn.idx === 1);
        if(initBtn){
            menuListRef.current?.[initBtn.title].classList.add(PageStyles.active);
        }
    }, [])

    return(
        <>
        <div className={[PageStyles.popUp_1, PageStyles.active].join(" ")}>
            <ul className={PageStyles.iconBox}>
                {menuList.map((list:any)=> {
                    return(
                        <>
                        <li 
                        ref={(e)=>menuListRef.current[list.title] = e} 
                        className={PageStyles[`iconList_${list.idx}`]}
                        onClick={()=>handleOpen(list)}
                        >
                            <div><span className={PageStyles.icon}></span></div>
                            <span className={PageStyles.txt}>{list.title}</span>
                        </li>
                        </>
                    )
                })}
            </ul>
            <button className={PageStyles.popUpClose_btn} onClick={() => onClose()}> 
                <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn"/>
            </button>

            <div className={PageStyles.contentBox}>
                {subPageIdx === 1 ? <ProductSpec/> : ''}
                {subPageIdx === 2 ? <ProductVideo/> : ''}
                {subPageIdx === 3 ? <ProductCatalog/> : ''}
                {subPageIdx === 4 ? <ProductPerformance/> : ''}
                {subPageIdx === 5 ? <ProductTrouble/> : ''}
                {subPageIdx === 6 ? <ProductMemo/> : ''}
                {subPageIdx === 7 ? <ProductAdminLog/> : ''}
            </div>
        </div>
        </>
    )
}

export default ProductPopUp;