export default function validate(value:any) {

    const errors:any = {};

    const { name, businessNumber,businessStatus, sectors, phoneNumber, address, detailAddress, companyDescription,introduction } = value;

    console.log("company",companyDescription);
    if(name === '') {
        errors.name = '대표자명을 입력해 주세요';
    }

    if(businessNumber === '') {
        errors.businessNumber = '설립연도를 입력해 주세요';
    }

    if(businessStatus === '') {
        errors.businessStatus = '업태를 입력해 주세요';
    }

    if(sectors === '') {
        errors.sectors = '업종을 입력해 주세요';
    }

    // if(companyDescription === ''){
    //     errors.companyDescription = '기업소개를 입력해 주세요';
    // }

    if(address === '' ) {
        errors.address = '주소를 입력해주세요'
    }

    if(detailAddress === '') {
        errors.detailAddress = '상세 주소를 입력해주세요'
    }


    return errors;
}