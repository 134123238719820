import React from 'react';
import * as Icon from 'react-feather';
import { useLocation } from 'react-router-dom';

export interface Props {
    topbarRef : any
}

const DashboardTopbar:React.FC<Props> = ({topbarRef}) => {

    const location = useLocation();

    const getIcon = () => {
        switch (location.pathname) {
            case "/dashboard/modelingOrders":
                return (
                    <span className="ic">
                        <i className="fa-regular fa-folder-open"></i>
                    </span>
                )
            case "/dashboard/modelingSupple":
                return (
                    <span className="ic">
                        <i className="fa-regular fa-folder-open"></i>
                    </span>
                )
            case "/dashboard/modelingRequest": 
                return (
                    <></>
                )
            default : 
                return <></>
        }
    }

    const getHeaderTitle = () => {
        let title = '';

        switch (location.pathname) {
            case "/dashboard/modelingOrders":
                title = '3D 모델링 의뢰 및 결제 관리'
                break;
            case "/dashboard/modelingSupple":
                title = '보유 모델링 관리'
                break;
            case "/dashboard/modelingRequest":
                title = ''
                break;
            default:
                title = '';
        }
        return title;
    }

    return (
        <>
            <div id="layers-wrap">
                <div className="layers-con">
                    <ul>
                        <li>
                            <div className="layers-logo" ref={topbarRef}>
                                {/* <span className="ic">
                                    <i className="fa-regular fa-folder-open"></i>
                                </span> */}
                                {getIcon()}
                                <span className="layers-bold">{getHeaderTitle()}</span>
                            </div>
                        </li>
                    </ul>
                    {/* <DashboardModelingOrdersListFilter totalList={totalList} isCheckAll={isCheckAll} onSelectAll={onSelectAll}  onClick={onClick} filter={filter} /> */}

                </div>
            </div>
        </>
    )
}

export default DashboardTopbar;