import React, { useRef, useState, useEffect } from "react";
import type { FC } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useSetModeling2DMutation, useSetModeling3DMutation, useSetModelingPhotoMutation } from "../../../store/slices/dashboard/dashboardApiSlice";
import ModelingCheckbox from "../../../components/checkbox/modelingCheckbox";
import { useLocation, useNavigate } from 'react-router-dom';
import AgreeBox from "../../../components/agree-box";
import RequestBox from "../../../components/request-box";
import NoticeBox from "../../../components/notice-box";

//김건우
import { useTranslation } from "react-i18next";


interface DashBoardModelingPhotoProps {}

const DashBoardModelingPhoto: FC<DashBoardModelingPhotoProps> = (props) => {
  
  //김건우
  const { t } = useTranslation();  
  
  const navigate = useNavigate();
  const [setModelingPhoto] = useSetModelingPhotoMutation();
  const [setModeling2D] = useSetModeling2DMutation();
  const [setModeling3D] = useSetModeling3DMutation();

  const user:any = useSelector((state:RootState)=>state.loginUser.userInfo);
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const subjectRef = useRef<any>();
  const modelingNameRef = useRef<any>();
  const location = useLocation();
  const [type , setType] = useState<string>('');
  const [ allCheck, setAllCheck ] = useState(false);
  const [ filter , setFilter] = useState<any>({
     file: '',
     subject:'',
     content:'',
     modelingName:'',
     companyId:user.companyId,
     idx:'',    
     userID:user.userId,
     ckFilm:false,
     ckManage:false,
     ckMarketing:false,
     ckPublicMode:false,
  }) 

  const { file } = filter;

  const handleFileChange = (file:any) => {
    setFilter({
        ...filter,
        file:file[0]
    })
  }

  const allCheckChange = (e:any) => {
    setAllCheck(e.target.checked);
  }

  const handleCheckChange = (e:any) => {
    const {name, checked} = e.target;
    setFilter({
        ...filter,
        [name]:checked
    })
  }

  const handleSubmit = async (e:any) => {

    e.preventDefault();
      if (!subjectRef.current.value) {
        toast.error(t("modelingPhoto-requestTitle"));
        return;
      }
      if (!titleRef.current.value) {
        toast.error(t("modelingPhoto-requestName"));
        return;
      }
      if (!contentRef.current.value) {
        toast.error(t("modelingPhoto-requestDetail"));
        return;
      }
      if (!filter.file) {
        toast.error(t("modelingPhoto-requestFile"));
        return;
      }
      
    const confirm = window.confirm("해당 모델링 의뢰를 진행하시겠습니까?")
    if(confirm){
      const formData = new FormData();
      formData.append('file',filter.file);
      formData.append('ckFilm',filter.ckFilm ? "Y" : "N");
      formData.append('ckManage',filter.ckManage ? "Y" : "N");
      formData.append('ckMarketing',filter.ckMarketing ? "Y" : "N");
      formData.append('ckPublicMode',filter.ckPublicMode ? "Y" : "N");
      formData.append('companyId',filter.companyId);
      formData.append('userId',filter.userID);
      formData.append('subject',subjectRef.current.value);
      formData.append('content',contentRef.current.value);
      formData.append('modelingName',titleRef.current.value);
      formData.append('modelingType', type)
  
      const body = formData;
  
      let result:any ;
  
      switch(location.pathname) {
          case "/dashboard/modelingPhoto" :
              result = await setModelingPhoto(body);
              break;
          case "/dashboard/modeling2d":
              result = await setModeling2D(body);
              break;
          case "/dashboard/modeling3d":
              result = await setModeling3D(body);
              break;
          default :
              result = ''
              break;   
      }
  
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if(result.data.result) {
          await toast.success(t("modelingRequest-complete"))
          navigate('/dashboard/modelingOrders');
      }
      else{
          toast.error(t("modelingRequest-fail"))
      }
    }
  }

  const getTitle = () => {
    let title = ''
    switch(location.pathname) {
        case "/dashboard/modelingPhoto" :
            title = t("modelingRequest-photoTitle")
            break;
        case "/dashboard/modeling2d":
            title = t("modelingRequest-2dTitle")
            break;
        case "/dashboard/modeling3d":
            title = t("modelingRequest-3dTitle")
            break;
        default :
            title = ''
            break;   
    }

    return title;
  }

  useEffect(()=>{
    if(location.pathname === '/dashboard/modelingPhoto'){
      setType('photo')
    } else if(location.pathname === '/dashboard/modeling2d'){
      setType('2D')
    } else {
      setType('3D')
    }
  }, [])
  console.log(type)
  useEffect(()=> {
    if(allCheck) {
        setFilter({
            ...filter,
            ckFilm:true,
            ckManage:true,
            ckMarketing:true,
            ckPublicMode:true
        })
    }else {
        setFilter({
            ...filter,
            ckFilm:false,
            ckManage:false,
            ckMarketing:false,
            ckPublicMode:false
        })
    }
  },[allCheck])

  return (
    <>
      <Toaster />
      <div className="modeling_request_container_step2">
                <div className="wrap">
                    <div className="content">
                        <div className="fixed">
                            <h3>{t("modelingRequest-main" )}</h3>
                        </div>
                        <div>
                            <h4>{getTitle()}</h4>
                            <p>{t("modelingRequest-requestUpload" )}</p>
                            <div>
                                <NoticeBox />
                                <RequestBox 
                                    file={file}
                                    handleFileChange={handleFileChange} 
                                    titleRef={titleRef} 
                                    contentRef={contentRef}
                                    subjectRef={subjectRef}
                                    // modelingNameRef={modelingNameRef}
                                    placeholder={t("modelingPhoto-explan")}
                                />
                                <AgreeBox allCheck={allCheck} allCheckChange={allCheckChange} filter={filter} handleCheckChange={handleCheckChange}/>
                                <div className="buttonBox">
                                    <button onClick={()=>navigate('/dashboard/modelingRequest')}>{t("modelingPhoto-before" )}</button>
                                    <button onClick={handleSubmit}>{t("modelingPhoto-request" )}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
    </>
  );
};

export default DashBoardModelingPhoto;
