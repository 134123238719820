import React, { ChangeEvent, useRef, useState } from "react";

//김건우
import { useTranslation } from "react-i18next";

export interface Props {
    password : string
    onChange : Function
}
const EditPassWord:React.FC<Props> = ({password, onChange}) => {

    //김건우
    const { t } = useTranslation();
    const requestPw = t("memberModify-requestPw");

    const newPw1 = useRef<any>(null)
    const newPw2 = useRef<any>(null)

    const [passwordInfo, setPasswordInfo] =useState<any>({
        newPw1 : '', newPw2 : ''
    })

    // 신규 비밀번호 유효성 검사
    const checkPw1 = (e : any) => {
        const check =/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(!check.test(e.target.value) && passwordInfo.newPw1 !== ''){
            alert(t("memberModify-alertPw"))
            setPasswordInfo({
                ...passwordInfo,
                newPw1 : ''
            })
            newPw1.current.focus( )
        }
    }
    // 신규 비밀번호 유효성 검사
    const checkPw2 = (e : any) => {
        const check =/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
        if(!check.test(e.target.value) && passwordInfo.newPw2 !==''){
            alert(t("memberModify-alertPw"))
            setPasswordInfo({
                ...passwordInfo,
                newPw2 : ''
            })
            newPw2.current.focus( )
        }
    }

    // 텍스트 입력 이벤트
    const handleText = (e:ChangeEvent<HTMLInputElement>) => {
        setPasswordInfo({
            ...passwordInfo,
            [e.target.name] : e.target.value
        })
    }

    const handleChangePassword = () => {
       const newPassword = passwordInfo.newPw1;
       const newPassword2 = passwordInfo.newPw2;

       if(newPassword === newPassword2){
            onChange(newPassword)
            if(onChange){
                setPasswordInfo({
                    newPw1 : '' , newPw2 : ''
                })
            }
       } else {
        alert(t("memberModify-discordPw"))
       }
    }

    return(
        <>
        <div className="section_1">
            <h3>{t("memberModify-pwChange")}</h3>
            <ul>
                <li>
                    <h4>{t("memberModify-beforePw")}</h4>
                    <input type="password" name="" id="" className="input_text" value={password} placeholder={requestPw} disabled/>
                </li>
                <li>
                    <h4>{t("memberModify-afterPw")}</h4>
                    <input ref={newPw1} type="password" value={passwordInfo.newPw1} onChange={handleText} name="newPw1" onBlur={checkPw1} id="" className="input_text" placeholder={requestPw} />
                </li>
                <li>
                    <h4>{t("memberModify-checkPw")}</h4>
                    <input ref={newPw2} type="password" value={passwordInfo.newPw2} onChange={handleText} name="newPw2" onBlur={checkPw2} id="" className="input_text" placeholder={requestPw} />
                </li>
            </ul>
            <div>
                <button onClick={handleChangePassword}>{t("memberModify-changePw")}</button>
            </div>
        </div>
        </>
    )
}

export default EditPassWord;