import React from "react";
import moduleStyles from './project.module.css'
import { useLocation, useParams } from "react-router-dom";

export const ProjectHeader = () => {
    const {projectType} = useParams()
    
    return(
        <>
        <div className={moduleStyles.title_header}>
            <div className={moduleStyles.titleBox}>
                {projectType === 'major' ? 
                <img src="/assets/images/sideBar_star.png" alt="modeling_request_header"/>:
                <img src="/assets/images/folder.png" alt="modeling_request_header"/>
                }
                <span>
                    {projectType === 'major' ? '주요 프로젝트' : ''}
                    {projectType === 'internal' ? `내부프로젝트` : ''}
                    {projectType === 'new-internal' ? '내부 프로젝트 / New 프로젝트' : ''}
                    {projectType === 'external' ? '외부 프로젝트' : ''}
                </span>
            </div>
        </div>
        </>
    )
}