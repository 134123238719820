import React, { useRef, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LicensePopup from '../../../components/dashboard/popup/licensePopup/license-popup';
import {
  useCreateDashboardCompanyInfoMutation,
  useGetCompanySearchInfoMutation,
  useUpdateDashboardCompanyInfoMutation,
} from "../../../store/slices/dashboard/dashboardApiSlice";
import { RootState } from '../../../store/store';
import DashboardCompanyList from './dashboard-company-list';
import DashboardCompanyLogo from './dashboard-company-logo';
import { useGetUserInfoMutation } from '../../../store/slices/member/memberApiSlice';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { loginUserInfo, setCompanyId, setCompanyName, setLoginId, setUserId, setUserInfo, setUserName, setUserPic, setUserPic2 } from '../../../store/slices/member/loginUserSlice';

//김건우
import { useTranslation } from "react-i18next";

const DashBoardCompany = () => {
  //김건우
  const { t } = useTranslation();  
  const requestName = t("company-requestName");
  const searchAlert = t("company-searchAlert");
  const searchNum = t("company-searchNum");

  // 컨트롤 사용 여부
  // 검색버튼 disable : 소속회사가 있음
  // 사업자등록번호 disable : 소속회사가 있고 검색을 안 했으면
  // 로고 등록, 제거 disable : 소속회사도 있고 관리자도 아님
  // 회사명 disable : 소속회사도 있고 관리자도 아님
  // 저장 disable : 소속회사도 있고 관리자도 아님

  const dispatch = useAppDispatch();
  const userId = useAppSelector(state=> state.loginUser.userId)
  const companyNameRef = useRef<HTMLInputElement>(null);
  const companyLicenseRef = useRef<HTMLInputElement>(null);
  const user = useSelector((state: RootState) => state.loginUser.userInfo);
  const hasCompanyId = user.companyId == 0 ? false : true;
  const isAdmin = user.isAdmin === "Y" ? true : false;
  console.log("회사아이디 : "+user.companyId + "hasCompany : " + hasCompanyId)

  const navigate = useNavigate();
  const [getCompanyInfo] = useGetCompanySearchInfoMutation();
  const [createCompanyInfo] = useCreateDashboardCompanyInfoMutation();
  const [updateCompanyInfo] = useUpdateDashboardCompanyInfoMutation();
  const [ getSessionInfo ] = useGetUserInfoMutation();

  const [isShow, setShow] = useState(false);
  const [isSearched, setSearched] = useState(true);      // 회사명 검색여부 확인
  const [uploadFile, setUploadFile] = useState<any>("");
  const [previewUrl, setPreviewUrl] = useState<any>("");
  console.log(previewUrl)
  const [isDelLogo, setDelLogo] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(searchNum);
  const [companyInfo, setCompanyInfo] = useState({
    companyId: user.companyId,       // 회사ID
    companyLicense: "",              // 사업자 등록번호
    companyName: "",                 // 회사명
    companyLogoUrl: "",              // 로고 URL?
  });

  var disableCompanyNo = hasCompanyId ? true : false;
  if (hasCompanyId == false && isSearched == false)
    disableCompanyNo = true;
  // console.log( `hasCompanyId[${hasCompanyId}] isSearched[${isSearched}] disableCompanyNo[${disableCompanyNo}]`);

  // 소속 회사 검색
  const handleCloseModal = (licenseNo: string) => {

    // 직접입력시 2줄 추가 - 인수로 받아올 것
    setSearched(true);
    setPlaceHolder(searchAlert);
    if (licenseNo.length > 0 && companyLicenseRef != undefined && companyLicenseRef.current != undefined) {
      companyLicenseRef.current.value = licenseNo;
    }

    setShow(false);
  };

  // 로고 등록시, uploadFile 에 file 정보 등록,  fileReader 를 통해 이미지 preview 기능 적용.
  const handleLogoChange = (fileBlob: any) => {
    setDelLogo(true);
    setUploadFile(fileBlob);
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);

    return new Promise((resolve: any) => {
      reader.onload = () => {
        setPreviewUrl(reader.result);
        resolve();
      };
    });
  };

  // 로고제거 함수
  const handleRemoveLogo = () => {
    setDelLogo(true);
    setUploadFile("");
    setPreviewUrl("");
  };

  // 저장 버튼 클릭시, data를 서버로 전송시키는 함수
  const handleSaveInfo = async () => {

    if (companyNameRef.current?.value.length === 0) {
      // toast.error("회사명을 입력하세요");
      alert(t("company-nameAlert"));
      companyNameRef.current.focus();
      return;
    }

    if (companyLicenseRef.current?.value.length === 0) {
      // toast.error("사업자등록번호를 입력하세요");
      alert(t("company-numAlert"));
      companyLicenseRef.current.focus();
      return;
    }

    try {
      const formData = new FormData();
      formData.append("companyName", companyNameRef.current?.value ?? ""); // 회사명
      formData.append("companyLicense", companyLicenseRef.current?.value ?? ""); // 사업자등록번호
      formData.append("userId", user.userId.toString()); // 사용자아이디
      formData.append("file", uploadFile); // 로고파일

      let response: any;
      if (hasCompanyId) { // 소속회사 번호가 있으면 수정
        if (isDelLogo) {
          formData.append("is_del", "true"); // 로고파일
        }
        formData.append("companyId", user.companyId.toString()); // 회사ID
        response = await updateCompanyInfo(formData);
      }
      else {
        response = await createCompanyInfo(formData);
      }

      if (response.data.result === true) {
        if(!hasCompanyId){
          dispatch(setUserInfo({...user, isAdmin : 'Y', companyId : response.data.companyInfo.companyId}))
        }
        alert("수정되었습니다.")
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  const clickInputCompany = () => {
    if (hasCompanyId == false && companyLicenseRef.current?.readOnly == true) {
      setShow(true);
    }
  }

  const handleSearch = () => {
    setShow(true);
  }

  const refreshCompanyInfo = async () => {
    try {
      const response: any = await getCompanyInfo(user.companyId);
      if (response.data.result === true) {
        setCompanyInfo(response.data.companyInfo);
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  useEffect(() => {
    if (user.companyId != 0) {
      refreshCompanyInfo();
    }
  }, []);

  useEffect(() => {
    if (user.companyId != 0) {
      refreshCompanyInfo();
    }
  }, [user.companyId]);

  useEffect(() => {
    if (companyNameRef.current) {
      companyNameRef.current.value = companyInfo.companyName;
    }

    if (companyLicenseRef.current) {
      companyLicenseRef.current.value = companyInfo.companyLicense;
    }

    if (companyInfo.companyLogoUrl.length > 0) {
      setPreviewUrl(companyInfo.companyLogoUrl);
    }
  }, [companyInfo]);

  
  console.log(user)

  return (
    <>
      <div className="company_information_container">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3>{t("company-main")}</h3>
            </div>
            <div>
              <div className="company_registrationNumber">
                <h4>{t("company-num")} <span className="red">*</span></h4>
                <div>
                  <input ref={companyLicenseRef} type="text" className="input_text" placeholder={placeHolder} readOnly={disableCompanyNo} onClick={clickInputCompany} />
                  {/* <button onClick={handleSearch} disabled={false}>{t("company-search")}</button> */}
                  <button onClick={handleSearch} disabled={hasCompanyId}>{t("company-search")}</button>
                </div>
                {/* <!-- popUp --> */}

              </div>
              <DashboardCompanyLogo preview={previewUrl} logoChange={handleLogoChange} deleteLogo={handleRemoveLogo} disabled={hasCompanyId && !isAdmin} />
              <div className="company_name">
                <h4>{t("company-name")} <span className="red">*</span></h4>
                <input ref={companyNameRef} type="text" name="" id="" className="input_text" placeholder={requestName} disabled={hasCompanyId && !isAdmin} />
              </div>
              <div className="company_button">
                {/* <button onClick={testCilck}>취소</button> */}
                <button onClick={handleSaveInfo} disabled={hasCompanyId && !isAdmin}>{t("company-save")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isShow && <DashboardCompanyList close={handleCloseModal} setShow={setShow} />
      }
    </>
  )
}

export default DashBoardCompany;