import React from "react";
import moduleStyles from './project.module.css'
import { ItemFliter } from "./item/item-fliter";
import { ItemGroup } from "./item/item-group";
import { ItemProjectBox } from "./item/item-projectBox";
export const ProjectList = () => {

    return(
        <>
        <div className={`${moduleStyles.listWrap} ${moduleStyles.blue}`}>
            {/* <!-- sticky 고정영역 --> */}
            <div className={moduleStyles.list_table_head}>
                <div className={moduleStyles.headName}>
                    <span className={moduleStyles.name}>진행중</span>
                    <span className={moduleStyles.line}></span>
                    <img src="/assets/images/headName_arrow.png" alt="headName arrow"/>
                </div>
            </div>
            {/* <!-- sticky 고정영역 Fin --> */}

            <div className={moduleStyles.scrollTable}>
                <table className={moduleStyles.modeling_approach}>
                    <ItemFliter/>
                    <tbody>
                        <tr>
                            <ItemProjectBox/>
                            <td className={`${moduleStyles.gray_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.blue_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.yellow_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.mint_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.red_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.purple_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <td className={`${moduleStyles.black_cubeBtn} ${moduleStyles.hoverBtn}`}>
                                <button><span></span></button>
                            </td>
                            <ItemGroup/>
                            <ItemGroup/>
                        </tr>
                        
                        <tr className={moduleStyles.white_space}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}