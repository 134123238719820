import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuillEditorModeling from "../../../../../../../components/reactQuillEditor/quillEditor_modeling";
import { useSetUserPartsSpecMutation, useUptUserPartsSpecMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../../../../store/storeHooks";
import PageStyles from '../../../css/page.module.css';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { SelectBox } from "../../../../../../../components/modelingCtrl/bottomPopup/parts/selectBox";
export interface Props {partsInfo : any; partsName : string; partsInfoYn : boolean;supplyInfo:any}
const PartsSpec:React.FC<Props> = ({partsInfo, partsName, partsInfoYn,supplyInfo}) => {
    const {modelingId} = useParams()
    const userData = useAppSelector(state=>state.loginUser.userInfo)
    const [setUserPartsSpecApi] = useSetUserPartsSpecMutation()
    const [uptUserPartsSpecApi] = useUptUserPartsSpecMutation()
    const registInfo = {
        userId : userData.userId, modelingId : modelingId, partsId : '', partName : '', supplier : '', supplyPrice : 0, 
        salesPrice : 0, manufacturedDate : '', releaseData : '', inspectionDate : '2023-05-01', inspectionTerm : 0, nextDate : '', 
        expireDate : '', specContentsKr : '' , specContentsEn : '', supplierName : ''
    }

    const [specInfo, setSpecInfo] = useState<any>(registInfo)

    const handleChange = (e:any) => {
        const {name, type, value} = e.target;
        if(type==='textarea'){setSpecInfo({...specInfo,[name]:value})}
        else {setSpecInfo({...specInfo, [name] : value})}
    }
    const handleChangeKr = (e:any) => {setSpecInfo((prev:any)=>({...prev, specContentsKr :e}))}
    const handleChangeEn = (e:any) => {setSpecInfo((prev:any)=>({...prev, specContentsEn :e}))}
    const handleCountNextDate = (e: any) => {
        // 입력한 숫자 값을 정수로 변환
        const inspectionTerm = parseInt(e.target.value);
        // 만약 inspectionTerm이 유효한 숫자라면 계산 수행
        if (!isNaN(inspectionTerm)) {
            const inspectionDate = new Date(specInfo.inspectionDate);
            inspectionDate.setDate(inspectionDate.getDate() + inspectionTerm);
            const formattedDate = `${inspectionDate.getFullYear()}-${String(
            inspectionDate.getMonth() + 1
            ).padStart(2, '0')}-${String(inspectionDate.getDate()).padStart(2, '0')}`;
            setSpecInfo((prev: any) => ({ ...prev, nextDate: formattedDate, inspectionTerm }));
        }
    };
    
    const handleSave = async() => {
        const formData : any = new FormData();
        formData.append('user_id',specInfo.userId); formData.append('AssetModelingId',specInfo.modelingId); formData.append('part_unique_value', partsName);
        formData.append('part_name',specInfo.partName); formData.append('part_supplier_id',specInfo.supplier===''?0:specInfo.supplier); formData.append('part_supplier_price',specInfo.supplyPrice);
        formData.append('part_sales_price',specInfo.salesPrice); formData.append('part_manufactured_date',specInfo.manufacturedDate); formData.append('part_release_date',specInfo.releaseData);
        formData.append('part_inspection_terms',specInfo.inspectionTerm); formData.append('part_last_inspection_date',specInfo.inspectionDate); formData.append('part_expire_date',specInfo.expireDate);
        formData.append('part_spec_kr',specInfo.specContentsKr); formData.append('part_spec_en',specInfo.specContentsEn); formData.append('part_next_inspection_date',specInfo.nextDate);
        // if(partsInfoYn===false){ const result : any = await setUserPartsSpecApi(formData); if(result.data.result===true){alert("등록이 완료되었습니다.")}}
        // else {const result : any = await uptUserPartsSpecApi(formData); if(result.data.result===true){alert("수정이 완료되었습니다.")}}
        const result : any = await setUserPartsSpecApi(formData); if(result.data.result===true){alert("등록이 완료되었습니다.")}
    }

    useEffect(()=>{
        if(partsInfo){
        setSpecInfo({...specInfo, 
            partName : partsInfo?.part_name, supplyPrice: partsInfo.part_supply_price, salesPrice: partsInfo.part_sales_price, manufacturedDate: partsInfo.part_manufactured_date,
            releaseData : partsInfo.part_release_date, inspectionDate: partsInfo.part_last_inspection_date, inspectionTerm : partsInfo.part_inspection_terms===''?0:partsInfo.part_inspection_terms, expireDate: partsInfo.part_expire_date,
            specContentsKr : partsInfo.part_spec_kr, specContentsEn : partsInfo.part_spec_en, nextDate: partsInfo.part_next_inspection_date, supplier : partsInfo.part_supplier_id, supplierName : partsInfo.part_supplier_name})
        }
    }, [partsInfo])
    // { fontSize: '15px', top:'5px',height:'35px'}
        console.log(specInfo)
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_1, PageStyles.active].join(" ")}  style={{lineHeight : '5px'}}>
            <div className={PageStyles.titleBox}>
                <span></span>
                <p>{partsName}<strong id="selected_parts_name"></strong></p>
            </div>
            <ul className={[PageStyles.dateCheckBox, PageStyles.dateCheckBox_1].join(" ")}>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">관리품명</label>
                    <input type="text" name="partName" id="part_name" value={specInfo.partName} placeholder="관리품명을 입력해 주세요." onChange={handleChange}/>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">공급업체</label>
                    <SelectBox selectStyle={selStyle} selValue={specInfo.mp_company_id} selName={specInfo.mp_company_name_kr} name={'supplier'} list={supplyInfo} onChange={handleChange}/>
                    <Box sx={{ minWidth: 120, color: "white" }}>
                        <FormControl fullWidth>
                            <Select
                                style={{ fontSize: '15px', top:'5px',height:'35px'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='supplier'
                                value={specInfo.supplier || 0}
                                onChange={handleChange}
                            >
                                {supplyInfo && supplyInfo?.map((supplier: any) => (
                                    <MenuItem key={supplier.mp_company_id} value={supplier.mp_company_id || ''} style={{ fontSize: '15px' }}>
                                        {supplier.mp_company_name_kr}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">구매단가</label>
                    <div>
                        <input type="text" value={specInfo.supplyPrice} name="supplyPrice" id="part_supply_price" onChange={handleChange}/>
                        <span>￦</span>
                    </div>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">판매단가</label>
                    <div>
                        <input type="text" value={specInfo.salesPrice} name="salesPrice" id="part_sales_price" onChange={handleChange}/>
                        <span>￦</span>
                    </div>
                </li>
                
            </ul>
            <ul className={[PageStyles.dateCheckBox, PageStyles.dateCheckBox_2].join(" ")}>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">제작년월</label>
                    <input type="date" value={specInfo.manufacturedDate} name="manufacturedDate" id="part_manufactured_date" max="9999-12-31" onChange={handleChange}/>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">출시년월</label>
                    <input type="date" value={specInfo.releaseData} name="releaseData" id="part_release_date" max="9999-12-31" onChange={handleChange}/>
                </li>
                
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">최종점검일</label>
                    <input type="date" value={specInfo.inspectionDate} name="inspectionDate" id="part_last_inspection_date" max="9999-12-31" onChange={(e)=>handleChange(e)}/>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">점검주기</label>
                    <div>
                        <input type="text" value={specInfo.inspectionTerm} name="inspectionTerm" id="part_inspection_terms" onChange={handleChange} onKeyUp={handleCountNextDate}/>
                        <span>일</span>
                    </div>
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">다음점검일</label>
                    <input type="date" value={specInfo.nextDate} name="nextDate" id="part_next_inspection_date" readOnly/>                                            
                </li>
                <li className={PageStyles.dateBox}>
                    <label htmlFor="">폐기일자</label>
                    <input type="date" value={specInfo.expireDate} name="expireDate" id="part_expire_date" max="9999-12-31" onChange={handleChange}/>
                </li>
            </ul>
        
            <div className={[PageStyles.partSpec_textarea, PageStyles.kr].join(" ")}>
                <span className={PageStyles.lang}>KR</span>
                <QuillEditorModeling value={specInfo.specContentsKr} onChange={handleChangeKr}/>
            </div>

            <div className={[PageStyles.partSpec_textarea, PageStyles.en].join(" ")}>
                <span className={PageStyles.lang}>EN</span>
                <QuillEditorModeling value={specInfo.specContentsEn} onChange={handleChangeEn}/>
                
            </div>

            
            <div className={PageStyles.saveBox}>
                <button type="button" onClick={handleSave} className="dots-btn dots-btn-5" id="goPartSpecSubmit">저장</button>
            </div>
        </div>
        </>
    )
}

export default PartsSpec;

export const selStyle = {
    fontSize: '15px', top:'5px',height:'35px'
}