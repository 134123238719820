import { forwardRef, useEffect, useRef, useState } from "react";
import { PageInfo } from "./gridPageInfo";

// 외부에서 사용하기 위한 인터페이스
export interface EventSizeSelectBox {}

// props
export interface PropsSizeSelectBox {
  pageInfo: PageInfo;
  onClick?: Function;
  items?: Array<number>;
}

const GridSizeSelectBox = forwardRef<EventSizeSelectBox, PropsSizeSelectBox>(
  (props, ref) => {
    const options: number[] = props.items ?? [10, 30, 50];

    const handleSelectChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
      if(props.onClick) {
        props.onClick(e.target.value);
      }
    }

    return (
      <>
        <div className="select_box">
          <select onChange={handleSelectChange} name="" id="">
            {
                options.map((item: number) => (
                  <option key={item}>{item}</option>
                ))
              }
          </select>
        </div>
      </>
    );
  }
);

export default GridSizeSelectBox;
