import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MailCertification = () => {
    const navi = useNavigate()
    const location = useLocation()
    const userId = location.state.id;
    const userName = location.state.name;

    return(
        <>
        <div id="wrap" className="login-wrap">
			<div className="container">
				<div className="con-w1400">
					<div className="login-logo" onClick={()=>navi('/')} style={{cursor : 'pointer'}}>
                        <img src="/assets/images/logo-w180.png" alt=""/>
                    </div>
					<div className="signup-box">
                        <div className="signup-t1">{userName} 님,</div>
                        <div className="signup-t2">
                            입력하신 이메일({userId})로 인증 메일이 발송되었습니다. <br/>
                            인증 메일을 확인하여 회원가입을 완료해 주세요.
                        </div>
                        <div className="signup-t3">
                            가입인증 메일을 받지 못하셨나요? <a className="link" onClick={()=>navi('/main/support_question')} style={{cursor : 'pointer'}}>문의하기</a>
                        </div>
                        <div className="submit-row">
                            <div className="col">
                                <button type="button" className="blue" onClick={()=>navi('/')}>메인화면으로 돌아가기</button>
                            </div>
                        </div>
					</div>
				</div>
			</div>
			<footer className="login-footer">
				<div className="con-w1400">
					<div className="f-t">
						<div className="flex-list">
							<ul>
								<li><a href="">이용약관</a></li>
								<li><a href="" className="c-f79e00">개인정보처리방침</a></li>
								<li><a href="">3D 모델링 보안 방침</a></li>
								<li className="lang">
									<div className="fdropbox">
										<div className="fdropbox-name">한국어</div>
										<div className="fdropbox-value">
											<div className="value"><a href="">한국어</a></div>
											<div className="value"><a href="">English</a></div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="f-b">
						<div className="copyright">
							COPYRIGHT<b>ⓒALINK3D</b> ALL RIGHTS RESERVED.
						</div>
					</div>
				</div>
			</footer>
		</div>
        </>
    )
}

export default MailCertification;