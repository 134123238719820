import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const todoListApi = createApi({
  reducerPath: "todoListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://210.114.19.235:8080/api"
  }),

  endpoints: (builder: any) => ({

    // Task 생성
    createTask: builder.mutation({
      query: (body: { userId: string, assigneeId: string, groupcode: string, subject: string, startDate: string, dueDate: string, priority: string, progress: any }) => {
        return {
          url: "/todo/create?userId=" + body.userId + "&assigneeId=" + body.assigneeId + "&groupcode=" + body.groupcode + "&subject=" + body.subject + "&startDate=" + body.startDate + "&dueDate="
            + body.dueDate + "&priority=" + body.priority + "&progress=" + body.progress,
          method: "get",
        };
      },
    }),

    // Task 조회
    searchList: builder.mutation({
      query: (params: any) => {
        const { listInfo } = params;
        return {
          url: `/todo/list?sortOrder=${listInfo.sortOrder}`,
          method: "get",
        };
      },
    }),
    // 소속 기업 직원 목록 조회
    searchMemberList: builder.mutation({
      query: (body: { companyId: any, keyword: any, page: number, size: number }) => {
        return {
          url: "/todo/memberlist?companyId=" + body.companyId + "&keyword=" + body.keyword + "&page=" + body.page + "&size=" + body.size,
          method: "get",
        };
      },
    }),

    // Task GroupCode 수정
    modifyGroupCode: builder.mutation({
      query: (body: { taskId: any, groupCode: string }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&groupcode=" + body.groupCode,
          method: "get",
        }
      }
    }),
    // Task 'subject' 수정
    modifySubjectTask: builder.mutation({
      query: (body: { taskId: any, subject: string }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&subject=" + body.subject,
          method: "get",
        };
      },
    }),
    // Task 'progress' 수정
    modifyProgressTask: builder.mutation({
      query: (body: { taskId: any, progress: string }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&progress=" + body.progress,
          method: "get",
        };
      },
    }),
    // Task 'StartDate' 수정
    modifyStartDateTask: builder.mutation({
      query: (body: { taskId: any, startDate: any }) => {
        return {

          url: "/todo/update?taskId=" + body.taskId + "&startDate=" + body.startDate,
          method: "get",
        };
      },
    }),
    // Task 'DueDate' 수정
    modifyDueDateTask: builder.mutation({
      query: (body: { taskId: any, dueDate: any }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&dueDate=" + body.dueDate,
          method: "get",
        }
      },
    }),
    // Task 'Priority' 수정
    modifyPriorityTask: builder.mutation({
      query: (body: { taskId: any, priority: any }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&priority=" + body.priority,
          method: "get",
        }
      }
    }),
    // Task 'Assignee' 수정
    modifyAssigneeTask: builder.mutation({
      query: (body: { taskId: any, assigneeId: any }) => {
        return {
          url: "/todo/update?taskId=" + body.taskId + "&assigneeId=" + body.assigneeId,
          method: "get",
        }
      }
    }),


    // Task 삭제
    deleteTask: builder.mutation({
      query: (body: { taskId: any }) => {
        return {
          url: "/todo/delete?taskId=" + body.taskId,
          method: "get",
        };
      },
    }),

  }),
});

export const {
  useCreateTaskMutation, useSearchListMutation, useModifySubjectTaskMutation, useDeleteTaskMutation, useModifyProgressTaskMutation, useModifyStartDateTaskMutation, useSearchMemberListMutation,
  useModifyDueDateTaskMutation, useModifyPriorityTaskMutation, useModifyAssigneeTaskMutation, useModifyGroupCodeMutation
} = todoListApi;
