import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "http://210.114.19.235/api";

export const dashBoardModelingOrderApiSlice = createApi({
  reducerPath: "dashboardModelingOrderApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder: any) => ({

    // 모델링 제작 관리
    // 3D 모델링 접수 현황 조회
    getModelingOrderList: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/getOrderList.php`,
          method: "get",
          params
        };
      },
    }),

    // 3D 모델링 접수 현황 수정
    editOrderModel: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/uptOrderModel.php?modelingId=${params.modelingId}&inspectionStatus=${params.status}`,
          method: 'get'
        }
      }
    }),

    // 3D 모델링 접수 현황 상세 조회
    getOrderModelDetail: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/getOrderDetail.php?order_modeling_id=${params}`
        }
      }
    }),


    // 보유모델링 목록 조회
    getAssetModelList: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/getAssetList.php`,
          method: 'get',
          params
        }
      }
    }),
    // 보유모델링 상세조회
    getAssetModelDetail: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/getAssetDetail.php?modelingId=${params}`,
          method: 'get',
        }
      }
    }),
    // 보유모델링 정보 수정
    editAssetModel: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/uptAssetModel.php`,
          method: 'get',
          params
        }
      }
    }),

    // 결제내역 조회
    getPaymentList: builder.mutation({
      query: (body: { modelingId: any }) => {
        return {
          url: 'https://alink3d.com/api/controller/user/getPaymentList.php?modelingId=' + body.modelingId,
          method: 'get'
        }
      }
    }),

    // 보유모델링 메세지 수신
    getAssetMessage: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/receiveMsgAsset.php`,
          method: 'get',
          params
        }
      },
    }),

    // 보유모델링 메세지 발신
    sendAssetMessage: builder.mutation({
      query: (params: { modelingId: number, msg: string, msgType: string, userId: number }) => {
        const { modelingId, msg, msgType, userId } = params;
        return {
          url: `https://alink3d.com/api/controller/user/sendMsgAsset.php?modelingId=${modelingId}&msg=${msg}&msgType=${msgType}&userId=${userId}`,
          method: 'get'
        }
      }
    }),
    // 모델링제작관리 : 파일목록
    getOrderModelFileList: builder.mutation({
      query: (params: any) => {
        return {
          url: `https://alink3d.com/api/controller/user/getOrderFiles.php?modelingId=${params.modelingId}&keyword=${params.keyword}`,
          method: 'get'
        }
      },
    }),
    // getModelingNoteList: builder.mutation({
    //   query: (params: any) => {
    //     return {
    //       url: `/todo/notes-list?todolist_id=0`
    //     }
    //   }
    // }),

    recvMessage: builder.mutation({
      query: (params: { modelingId: number, size: number, page: number }) => {
        const { modelingId, size, page } = params;
        return {
          url: `https://alink3d.com/api/controller/user/receiveMsg.php?modelingId=${modelingId}&page=${page}&size=${size}`,
          method: 'get'
        }
      }
    }),

    sendMessage: builder.mutation({
      query: (params: { modelingId: number, msg: string, msgType: string, userId: number }) => {
        const { modelingId, msg, msgType, userId } = params;
        return {
          url: `https://alink3d.com/api/controller/user/sendMsg.php?modelingId=${modelingId}&msg=${msg}&msgType=${msgType}&userId=${userId}`,
          method: 'get'
        }
      }
    }),

    // 첨부파일 생성
    setOrderFiels : builder.mutation({
      query : (body : any) => {
        return{
          url : 'https://alink3d.com/api/controller/user/setUserOrderFiles.php', method : 'post', body
        }
      }
    }),

    // 첨부파일 삭제
    removeFile: builder.mutation({
      query: (params: any) => {
        return {
          url: `/order-model/files-delete?fileId=${params}`
        }
      }
    })

  })
})

export const {
  useGetModelingOrderListMutation, useGetOrderModelDetailMutation, useGetOrderModelFileListMutation,
  useRecvMessageMutation, useSendMessageMutation, useGetAssetModelListMutation, useGetAssetModelDetailMutation, useEditAssetModelMutation,
  useGetPaymentListMutation, useGetAssetMessageMutation, useSendAssetMessageMutation, useRemoveFileMutation, useEditOrderModelMutation,
  useSetOrderFielsMutation
} = dashBoardModelingOrderApiSlice

