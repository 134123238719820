import React, { forwardRef, useEffect, useRef, useState } from "react";
import { RowEmployeeList } from ".";

export interface EventPopupRole {}

export interface PropsPopupRole {
  roleInfo: RowEmployeeList;
  onSave: Function;
  onCancel: Function;
  popupType?: string;
}

const PopupRole = forwardRef<EventPopupRole, PropsPopupRole>((props, ref) => {
  const [permission, setPermission] = useState(props.roleInfo.permission);

  const clickCancel = () => {
    props.onCancel();
  };

  const clickSave = () => {
    props.onSave({
      idx: props.roleInfo.idx,
      permission: permission,
    });
  };

  

  return (
    <>
      <div className="staff_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body">
                <div className="content">
                  <ul>
                    <li>
                      <p>
                        <strong>이름</strong>{props.roleInfo.name}
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>이메일</strong>{props.roleInfo.email}
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>부서</strong>{props.roleInfo.departmentName}
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>직급</strong>{props.roleInfo.positionName}
                      </p>
                    </li>
                  </ul>
                  <div>
                    <div>
                      <input
                        type="checkbox"
                        name="staff_popUp"
                        id="staff_popUp_1"
                        onClick={()=>setPermission('A')} checked={permission==="A"} onChange={() =>{}}
                      />
                      <label htmlFor="staff_popUp_1">관리자</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="staff_popUp"
                        id="staff_popUp_2"
                        onClick={()=>setPermission('S')} checked={permission==="S"} onChange={() =>{}}
                      />
                      <label htmlFor="staff_popUp_2">스텝</label>
                    </div>
                  </div>
                </div>
                <div className="buttonBox">
                  <button onClick={clickCancel}>취소</button>
                  <button onClick={clickSave}>저장</button>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PopupRole;
