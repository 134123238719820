import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductPerfoemanceRegist from "../../../../../../../components/modelingCtrl/bottomPopup/product/product_perfoemanceRegist";
import SlickSlider from "../../../../../../../components/react-slick/slick_slider";
import { useDelAssemblePerformanceMutation, useGetAssemblePerformanceMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductPerformance = () => {
    const {modelingId} = useParams()
    const [getAssemblePerformanceApi] = useGetAssemblePerformanceMutation();
    const [delAssemblePerformanceApi] = useDelAssemblePerformanceMutation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [performanceList, setPerformanceList] = useState<any>([])
    const [slick, setSlick] = useState<any>({
        isOpen : false , slickImage : []
    })
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    const handleSlickOpen = (data:any) => {
        setSlick({...slick, isOpen : true, slickImage : data?.image_file})
    }
    const handleSlickClose = (e:any) => {
        setSlick({...slick, isOpen : false})
    }
    const getAssemblePerformance = async() => {
        const param = {modelingId : modelingId}
        const result : any = await getAssemblePerformanceApi(param);
        if(result.data.result === true) {
            setPerformanceList(result.data.list)
        }
    }
    const delAssemblePerformance = async(id:number) => {
        const confirm = window.confirm("삭제하시겠습니까?");
        if(confirm){
            const param = {id : id}
            const result : any = await delAssemblePerformanceApi(param)
            if(result.data.result ===true ){getAssemblePerformance();}
        }
    }
    useEffect(()=>{ getAssemblePerformance(); },[])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_4, PageStyles.active].join(" ")}>
            <ul className={PageStyles.img_viewBox} id="performance_image_view">
                {performanceList?.map((data : any) => (
                    <li>
                        <div className={PageStyles.thumbBox}>
                            <div className={PageStyles.top} onClick={()=>handleSlickOpen(data)}>
                                <img src={data?.image_file[0]?.performance_image_pdsFilename} alt=""/>
                            </div>
                            <div className={PageStyles.bottom}>
                                <p>{data?.title_kr}</p>
                                <div>
                                    <button className={PageStyles.thumb_closeBtn} onClick={()=>delAssemblePerformance(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="close"/></button>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            
            <button className={[PageStyles.imgAddBtn, PageStyles.blueAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            {isOpen && <ProductPerfoemanceRegist getPerformance={getAssemblePerformance} onClose={handleOpen}/>}

            {slick.isOpen === true ? 
            <>
            <div className={PageStyles.product_performance_sliderBG} onClick={handleSlickClose}>
                {/* <div className={PageStyles.zoomBox} id="performance_detail_views"></div> */}
            </div> 
            <div className={PageStyles.product_performance_sliderWrap}>
                <button className={PageStyles.product_performance_closeBtn} onClick={handleSlickClose}></button>
                <SlickSlider image={slick.slickImage}/>
            </div>
            </>
            : ''
            }
        </div>
        </>
    )
}

export default ProductPerformance;