import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserPartsMutation } from "../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../css/page.module.css';
import PartsAdminLog from "./subPage/parts_adminLog";
import PartsDrawing from "./subPage/parts_drawing";
import PartsInspection from "./subPage/parts_inspection";
import PartsMemo from "./subPage/parts_memo";
import PartsMenual from "./subPage/parts_menual";
import PartsSpec from "./subPage/parts_spec";
import PartsTrouble from "./subPage/parts_trouble";
import PartsVideo from "./subPage/parts_video";

export interface Props{
    partsName : string, onClose : Function
}

const PartsPopUp:React.FC<Props> = ({partsName, onClose}) => {
    const {modelingId} = useParams()
    const [partsInfo, setPartsInfo] = useState<any>({
        partsSpec : null, supplier_info : [] ,partsInfoYn : false, partsId : ''
    })
    const [getUserPartsApi] = useGetUserPartsMutation()
    const [subPageIdx, setSubPageIdx] = useState<number>(1);
    const menuList = [ {idx : 1, title : '부품사양'}, {idx : 2, title : '도면관리'}, {idx : 3, title : '품질검사'}, {idx : 4, title : '메뉴얼'}, {idx : 5, title : '관련영상'}, {idx : 6, title : '트러블 슈팅'}, {idx : 7, title : '부품메모'}, {idx : 8, title : '관리자 로그'}]
    const menuListRef = useRef<any>([]);

    const getUserParts = async() =>{
        const param ={modelingId : modelingId, partsId : partsName}
        const result : any = await getUserPartsApi(param)
        if(result.data.result === true){
            setPartsInfo({...partsInfo ,partsId : result.data.part_info.idx, partsSpec : result.data.part_info, supplier_info : result.data.supply_info, partsInfoYn : result.data.result})
            
        } else {
            setPartsInfo({...partsInfo , partsId : result.data.part_info.idx, partsSpec : result.data.part_info, supplier_info : result.data.supply_info, partsInfoYn : result.data.result})
        }
    }
    // 부품 팝업 내 헤더 클릭
    const handleOpen = (list : {idx:number,title:string}) => {
        if(list.idx !== 1 && partsInfo.partsInfoYn === false){
            alert("부품 사양을 먼저 등록 후 진행 가능합니다."); return;
        }
        if(list.idx === 1){getUserParts()}
        Object.values(menuListRef.current).forEach((ref : any) => {
            ref?.classList.remove(PageStyles.active);
        })
        menuListRef.current?.[list.title].classList.add(PageStyles.active);
        setSubPageIdx(list.idx)
    }

    useEffect(()=> {
        // 초기 버튼 찾기
        const initBtn = menuList.find((btn:any) => btn.idx === 1);
        if(initBtn){
            // 해당 버튼 속성 추가
            menuListRef.current?.[initBtn.title].classList.add(PageStyles.active);
        }
    }, [])

    useEffect(()=>{
        getUserParts()
    }, [partsName])
    
    return(
        <>
        <div className={[PageStyles.popUp_2, PageStyles.active].join(" ")}>
            <ul className={PageStyles.iconBox}>
                {menuList.map((list: {idx:number, title:string}) => {
                    return(
                        <>
                        <li 
                        ref={(e)=>menuListRef.current[list.title] = e} 
                        className={PageStyles[`iconList_${list.idx}`]}
                        onClick={()=>handleOpen(list)}
                        >
                            <div><span className={PageStyles.icon}></span></div>
                            <span className={PageStyles.txt}>{list.title}</span>
                        </li>
                        </>
                    )
                })}
            </ul>
            <button className={PageStyles.popUpClose_btn} onClick={()=>onClose()}> 
                <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn"/>
            </button>
            <div className={PageStyles.contentBox}>
                {subPageIdx === 1 ? <PartsSpec partsName={partsName} partsInfo={partsInfo.partsSpec} partsInfoYn={partsInfo.partsInfoYn} supplyInfo={partsInfo.supplier_info}/> : ''}
                {subPageIdx === 2 ? <PartsDrawing partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 3 ? <PartsInspection partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 4 ? <PartsMenual partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 5 ? <PartsVideo partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 6 ? <PartsTrouble partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 7 ? <PartsMemo partsName={partsName} partsId={partsInfo.partsId}/> : ''}
                {subPageIdx === 8 ? <PartsAdminLog/> : ''}
            </div>
        </div>
        </>
    )
}

export default PartsPopUp;