import React from 'react';

const MessageDate = ({date}:any) => {
    return (
        <>
            <div className="chat_date" style={{ margin: '5px 0', height: '80px' }}>
                <span>{date}</span>
            </div>
        </>
    )
}

export default MessageDate;