import React from "react";
import { useNavigate } from "react-router-dom";
import './calendar.css'

const Toolbar = (props : any) => {

    const {date} = props;
    const navigate = (action : any) => {
        props.onNavigate(action)
    }

    const currentMonth = date.getMonth();

    const monthNames = [
    "JANUARY" , "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];

    return(
        <>
        <div className="rbc-toolbar">
            <button type="button" onClick={navigate.bind(null, 'PREV')}>
                <i className="fa-solid fa-angle-left"></i>
            </button>
            <span className="rbc-btn-group">
                <span className="rbc-toolbar-label">{monthNames[currentMonth]}, {`${date.getFullYear()}`}</span>
            </span>
            <button type="button" onClick={navigate.bind(null, 'NEXT')}>
                <i className="fa-solid fa-angle-right"></i>
            </button>
        </div>
        </>
    )
}

export default Toolbar;