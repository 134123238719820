import React, { FC } from 'react';
import FileItem from './fileItem';
import { File } from '../../../../types/fileType';

interface IFileGroupProps {
  fileList: File[];
  getFileList: () => void;
}

const FileGroup: FC<IFileGroupProps> = (props) => {

  const { fileList, getFileList } = props;

  return (
    <>
      <ul className="zipFileBox">
        {
          fileList.filter(item => (item.contentType == "CTYPE_100" || item.contentType == "CTYPE_500"))
            .map(item => (
              <FileItem getFileList={getFileList} data={item} />
            ))
        }
      </ul>
    </>
  )
}

export default FileGroup;