import React from "react";
import moduleStyles from '../project.module.css'
import { useParams } from "react-router-dom";
export const ItemFliter = () => {
    const {projectType} = useParams()
    return(
        <>
        <thead>
            <tr>
                <th className={moduleStyles.first_child}><span className={moduleStyles.sortWrap}>프로젝트 명<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>프로젝트 관리<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>3D 모델링<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>업무관리<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>품질검사<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>노후도관리<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>유지보수관리<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>기술지원<span className={moduleStyles.sort}></span></span></th>
                <th><span className={moduleStyles.sortWrap}>담당자</span></th>
                {projectType !== 'external' ? <th><span className={moduleStyles.sortWrap}>거래처</span></th> : ''}
            </tr>
        </thead>
        </>
    )
}