import React from "react";

const EditSubScribe = () => {
    return(
        <>
        
        </>
    )
}

export default EditSubScribe;