import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetAssembleMemoMutation } from "../../../../store/slices/modeling/modelingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
export interface Props {getMemo : Function; onClose : Function}
const ProductMemoRegist:React.FC<Props> = ({getMemo, onClose}) => {
    const {modelingId}:any = useParams();
    const userData :any = useAppSelector(state=>state.loginUser.userInfo)
    const [setAssembleMemoApi] = useSetAssembleMemoMutation()
    const [memoInfo, setMemoInfo] = useState<any>({modelingId : modelingId, memoContents : '', openYn : 'Y'})

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, type, value} = e.target;
        if(type==='radio'){
            if(name==='checkY'){setMemoInfo({...memoInfo,openYn:'Y'})}
            else{setMemoInfo({...memoInfo,openYn:'N'})}
        } else {
            setMemoInfo({...memoInfo,[name]:value})
        }
    }
    const handleSave = async() => {
        const formData = new FormData();
        formData.append('AssetModelingId', modelingId)
        formData.append('UserId', userData.userId)
        formData.append('memo_contents', memoInfo.memoContents)
        formData.append('open_yn', memoInfo.openYn)
        const result : any = await setAssembleMemoApi(formData)
        if(result.data.result === true) {alert("등록이 완료되었습니다."); getMemo(); onClose();}
    }
    return(
        <>
         <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>제품 메모</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <span className={PageStyles.title}>메모</span>
                    <div>
                        <textarea name="memoContents" id="product_memo_contents" onChange={(e:any)=>handleChange(e)}></textarea>
                    </div>
                    <div className={PageStyles.radioBox}>
                        <div>
                            <input type="radio" name="checkY" id="radio_open1" checked={memoInfo.openYn==='Y'?true:false} onChange={handleChange}/>
                            <label htmlFor="radio_open1">공개</label>
                        </div>
                        <div>
                            <input type="radio" name="checkN" id="radio_close1" checked={memoInfo.openYn==='N'?true:false} onChange={handleChange}/>
                            <label htmlFor="radio_close1">비공개</label>
                        </div>
                    </div>
                </div>


                <div className={PageStyles.dots_memo_btnBox}>
                    <div className={[PageStyles.dots_memo_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                    <button type="button" className={PageStyles.dots_product_memo_submit} onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default ProductMemoRegist;