import React from 'react';
import dayjs from 'dayjs';

// 일반채팅
const Chat01 = ({ data }: any) => {
  const { msgJson: { type, message }, registeredDate, memberInfo: { lastName, firstName, profile1Url } } = data;

  return (
    <>
      <li style={{ marginBottom: '30px', listStyle: "none" }}>
        <div className="user_chat" style={{ marginTop: '20px', padding: '0 20px', display: 'flex', marginBottom: '10px' }}>
          <div className="todo-content" style={{ display: 'flex', alignItems: 'center' }}>
            {profile1Url === '' || profile1Url === undefined ?
            <div className='' style={{width: '30px', height: '30px', borderRadius : '100%',  background: 'rgb(52, 152, 219)', textAlign: 'center', lineHeight: '30px', color: '#fff'}}>{lastName}</div>:
            <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={profile1Url} />
            }
          </div>
          <span className="chat_userName" style={{ fontWeight: 700, color: '#555555', marginLeft: '10px', fontSize: '14px', lineHeight: '28px' }}>{lastName}{firstName}</span>
          <span className='time' style={{ color: '#999999', fontSize: '12px', lineHeight: '28px', marginLeft: '10px' }}>
            {dayjs(registeredDate).format('HH:mm')}
          </span>
        </div>
        <div className='textarea none' style={{ padding: '0 20px', marginBottom: '20px' }}>
          <div className="chat_content" style={{ borderRadius: '5px', width: '100%', border: '1px solid #eeeeee', lineHeight: '22px', padding: '15px', boxSizing: 'border-box', background: '#fff' }}>
            {message}
          </div>
        </div>
      </li>
    </>
  )
}

// 견적완료
const Chat02 = ( props : any ) => {

  const getDescription = ( state : string ) => {
    switch(state.toUpperCase()) {
      case "S01" : return "접수대기";
      case "S02" : return "결제대기";
      case "S03" : return "결제완료";
      case "S04" : return "제작중";
      case "S05" : return "제작완료";
      case "S06" : return "제작불가";
      default : return "알수없음";
    }
  } 

  return (
    <>
      <div className="chat_state status">
        <div>
            <p><strong>Automatically</strong> changed status</p>
        </div>
        <div>
            <span>{getDescription(props.data.msgJson.prevState)}</span>
            <span>{getDescription(props.data.msgJson.currState)}</span>
        </div>
      </div>
    </>
  )
}

// 상태변경
const Chat04 = ( props : any ) => {
  return (
    <>
      <div className='chat_state state_change' style={{ marginBottom: '10px'}}>
        <p style={{ fontSize: '12px', color: '#999999', position: 'relative', paddingLeft:'15px', lineHeight:'25px'}}>{dayjs(props.data.registeredDate).format('HH:mm')}<strong>{props.data.msgJson.amount}원 결제 완료</strong></p>
      </div>
    </>
  )
}

const MessageChat = ( props : any) => {

  switch (props.data.msgType) {
    case "MSG01":
      return (
        <>
          <Chat01 data={props.data} />
        </>
      )
    case "MSG02":
      return (
        <>
          <Chat02 data={props.data} />
        </>
      )
    case "MSG03":
      return (
        <>
        </>
      )
    case "MSG04":
      return (
        <>
          <Chat04 data={props.data} />
        </>
      )
    default:
      return <></>
  }
}

export default MessageChat;