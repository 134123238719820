import React, { ChangeEvent, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useAppSelector } from '../../../store/storeHooks';
import QuillEditor from '../../reactQuillEditor/quillEditor';

//김건우
import { useTranslation } from "react-i18next";

export interface Props {
    commonData: any
    addInfo: any
    roleTypeArr : string[]
    setRoleTypeArr : any
    onSave : Function
}

const ITEM_HEIGHT = 48;
const ITME_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITME_PADDING_TOP,
            width: 250
        }
    }
}

const EditAddInfo: React.FC<Props> = ({ commonData, addInfo, roleTypeArr, setRoleTypeArr, onSave }) => {

    //김건우
    const { t } = useTranslation();
    const explanIntro = t("memberModify-explanIntro");
    const explanCategory = t("memberModify-explanCategory");
    const requestRegist = t("memberModify-requestRegist");

    const user: any = useAppSelector(state => state.loginUser.userInfo);

    console.log(user)

    const accInfoDefault = {
        biography: '',
        userType: '',
        departMent: '',
        postition: '',
        phone: '',
        fax: '',
        country: '',
        emailSignatur: ''
    }

    const [accInfo, setAccInfo] = useState(accInfoDefault)
    const [selRoleType, setSelRoleType] = useState<boolean>(false)
   
    const handleText = (e: any) => {
        setAccInfo({
            ...accInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleEditorText = (e:any) => {
    
        setAccInfo({
            ...accInfo,
            emailSignatur : e
        })
    }

    const handleSelectChange = (e:React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setSelRoleType(check => !check)
    }

    const handleUserRoletype = (e:React.ChangeEvent<HTMLInputElement>, data : any) => {
        let dataList: string[] = [];

        console.log(data.mp_user_roletype_name_kr)
        console.log(roleTypeArr)
        if(!roleTypeArr.includes(data.mp_user_roletype_name_kr)){
            setRoleTypeArr(roleTypeArr.concat(data.mp_user_roletype_name_kr))
        } else {
            roleTypeArr.map((value)=>{
                if(value !== data.mp_user_roletype_name_kr){
                    dataList.push(value)
                }
            })
            setRoleTypeArr(dataList)
        }   
    }

    const handleChangeAccInfo = () => {
        onSave(accInfo)
    }
    
    useEffect(()=>{
        let selArea = '';
        roleTypeArr.map((data)=>{
            selArea += data + ',';
        })
        setAccInfo({
            ...accInfo, userType : selArea 
        })
    } , [roleTypeArr])

    useEffect(() => {
        
        setAccInfo({
            biography: addInfo.biography,
            userType: addInfo.userType,
            departMent: addInfo.depart,
            postition: addInfo.position,
            phone: addInfo.phone === "null          " ? '' : addInfo.phone,
            fax: addInfo.fax,
            country: addInfo.country,
            emailSignatur: addInfo.emailSignature,
        })
    }, [addInfo])

    if(!accInfo)
        return <></>;

    return (
        <>
            <div className="section_2">
                <h3>{t("memberModify-section2")}
                    <span style={{float : 'right', color : '#256dcb', fontSize : '18px', cursor : 'pointer'}} onClick={handleChangeAccInfo}>저장</span>
                </h3>
                <div>
                    <h4>{t("memberModify-intro")}</h4>
                    <div className="textarea_box">
                        <textarea name="biography" id="" value={accInfo.biography} onChange={handleText} placeholder={explanIntro}></textarea>
                        <span>{accInfo.biography?.replace(/<br>\s*\/?>/gm, "\n").length}/100</span>
                    </div>
                </div>
                {/* 회원 유형 */}
                <div>
                    <h4>{t("memberModify-category")}</h4>
                    <div className="memberModifyBox">
                        <input className="input_text" style={{width:'100%',textAlign:'left', cursor:'pointer'}} value={accInfo.userType?.slice(0,-1)} type="text" onClick={handleSelectChange} placeholder={explanCategory} readOnly/>
                        <img src={selRoleType===true ? "/assets/images/arrow_up.png" : "/assets/images/arrow_down.png"} alt="arrow"/>
                    </div>
                    {selRoleType &&<>
                        <div className="subBusinessArea" style={{width:'100%', height:'auto', border:'1px solid #ddd', overflow:'hidden',borderRadius:'2px', boxShadow:'2px 3px 3px rgb(0 0 0 / 11%)', marginTop:'4px'}}>
                            {commonData.userRolyTypeData.map((data : any)=> (
                                <div key={data.mp_user_roletype_id} className="hoverGray" style={ data > data.length-2 ? {width:'50%',padding:'0',margin:0,float:'left',borderRight:'1px solid #ddd'} : data % 2 ===0 ?{width:'50%',padding:'0',margin:0,float:'left',borderBottom:'1px solid #ddd',borderRight:'1px solid #ddd'}:{width:'50%',padding:'0',margin:0,float:'left',borderBottom:'1px solid #ddd'}}>
                                    <div onClick={(e)=>e.stopPropagation()} style={{display:'flex',padding:'10px',alignItems:'center'}}>
                                        <input checked={roleTypeArr.includes(data.mp_user_roletype_name_kr)} onChange={(e)=>handleUserRoletype(e,data)} style={{width:'15px',height:'15px',marginRight:'10px'}} type="checkbox" />
                                        <label style={{margin:"auto 0"}}>{data.mp_user_roletype_name_kr}</label>
                                    </div>
                                </div>
                            ))}
                        </div></>
                    }
                </div>

                <ul>
                    <li>
                        <h4>{t("memberModify-department")}</h4>
                        {user.companyId == 0 ? <input className='modifyInput' value={requestRegist} disabled /> :
                            <Box sx={{ minWidth: 120, color: "white" }}>
                                <FormControl fullWidth>
                                    <Select
                                        style={{ fontSize: '15px' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name='departMent'
                                        value={accInfo.departMent || ''}
                                        onChange={handleText}
                                        MenuProps={MenuProps}
                                    >
                                        {commonData.departMentData && commonData.departMentData.map((list: any) => (
                                            <MenuItem key={list.idx} value={list.idx || ''} style={{ fontSize: '15px' }}>
                                                {list.ud_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </li>
                    <li>
                        <h4>{t("memberModify-spot")}</h4>
                        {user.companyId == 0 ? <input className='modifyInput' value={requestRegist} disabled /> :

                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <Select
                                        style={{ fontSize: '15px' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name='postition'
                                        value={accInfo.postition || ''}
                                        onChange={handleText}
                                        MenuProps={MenuProps}
                                    >
                                        {commonData.positionData && commonData.positionData.map((list: any) => (
                                            <MenuItem key={list.idx} value={list.idx || ''} style={{ fontSize: '15px' }}>
                                                {list.up_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </li>
                    <li>
                        <h4>{t("memberModify-phoneNum")}</h4>
                        <input type="text" name="phone" id="" value={accInfo.phone} onChange={handleText} className="input_text" placeholder="000-0000-0000" />
                    </li>
                    <li>
                        <h4>{t("memberModify-fax")}</h4>
                        <input type="text" name="fax" id="" value={accInfo.fax} onChange={handleText} className="input_text" placeholder="000-0000-0000" />
                    </li>
                </ul>
                <div>
                    <h4>{t("memberModify-country")}</h4>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <Select
                                style={{ fontSize: '15px' }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='country'
                                value={accInfo.country || ''}
                                onChange={handleText}
                                MenuProps={MenuProps}
                            >
                                {commonData.countryData && commonData.countryData.map((data: any) => (
                                    <MenuItem key={data.mp_countryid} value={data.mp_countryid || ''} style={{ fontSize: '15px' }}>
                                        {data.mp_countryname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </div>

                {/* <div>
                    <h4>이메일 서명</h4>
                    <div>
                        <QuillEditor
                            value={accInfo.emailSignatur}
                            onChange={handleEditorText}
                        />
                    </div>
                </div> */}

                <div>
                    <button>{t("memberModify-cancel")}</button>
                    <button onClick={handleChangeAccInfo}>{t("memberModify-save")}</button>
                </div>


            </div>
        </>
    )
}

export default EditAddInfo;