import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import GroupListBox from '../../../components/grouplist/groupListBox';
import DashboardModelingOrdersListFilter from './dashboard-modeling-orders-listfilter';
import { useGetModelingOrderListMutation } from '../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { ModelingOrderType } from '../../../types/modelingOrderType';

//김건우
import { useTranslation } from "react-i18next";
 

interface DashBoardModelingOrdersProps {

  
}

export type OrderPageType = {
  companyId: number;
  keyword: string;
  sortColumn: string;
  sortOrder: boolean;
}





const DashBoardModelingOrders: FC<DashBoardModelingOrdersProps> = (props) => {

  //김건우
  const { t } = useTranslation();

  //useTranslation() 사용을 위해 밖에 있던 변수 선언을 안으로 가져옴.
  const initFilter: string[] = [t("modelingOrder-reciveWaiting"), t("modelingOrder-paymentWaiting"), t("modelingOrder-paymentComplete"), t("modelingOrder-making"), t("modelingOrder-makingComplete")];

  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);

  const [selectedFilter, setSelectedFilter] = useState<string[]>(initFilter);

  const [getModelingOrderList] = useGetModelingOrderListMutation();

  const [pageInfo, setPageInfo] = useState<OrderPageType>({
    companyId: user.companyId,
    keyword: '',
    sortColumn: 'requestDate',
    sortOrder: false
  })

  const [totalList, setTotalList] = useState<Array<ModelingOrderType>>([]);
  const [listS01, setListS01] = useState<Array<ModelingOrderType>>([]);
  const [listS02, setListS02] = useState<Array<ModelingOrderType>>([]);
  const [listS03, setListS03] = useState<Array<ModelingOrderType>>([]);
  const [listS04, setListS04] = useState<Array<ModelingOrderType>>([]);
  const [listS05, setListS05] = useState<Array<ModelingOrderType>>([]);
  const [listS06, setListS06] = useState<Array<ModelingOrderType>>([]);

  const getGrid = async () => {
    try {
      const response: any = await getModelingOrderList(pageInfo);

      if (response.data.result === true) {
        const s01: Array<ModelingOrderType> = [];
        const s02: Array<ModelingOrderType> = [];
        const s03: Array<ModelingOrderType> = [];
        const s04: Array<ModelingOrderType> = [];
        const s05: Array<ModelingOrderType> = [];
        const s06: Array<ModelingOrderType> = [];

        for (let item of response.data.list) {
          switch (item.status) {
            case "S01": s01.push(item); break;
            case "S02": s02.push(item); break;
            case "S03": s03.push(item); break;
            case "S04": s04.push(item); break;
            case "S05": s05.push(item); break;
            case "S06": s06.push(item); break;
          }
        }

        setTotalList(response.data.list);
        setListS01(s01);
        setListS02(s02);
        setListS03(s03);
        setListS04(s04);
        setListS05(s05);
        setListS06(s06);
      }
    } catch (err) {
      console.error("api fetch error");
    }
  }

  const handleSelectAll = () => {
    setSelectedFilter(initFilter);
  }

  const handleSelectfilter = (filter: string) => {
    if (filter === t("modelingOrder-makingUnable")) {
      setSelectedFilter([t("modelingOrder-makingUnable")])
      return;
    }

    if (selectedFilter.length === 5) {
      setSelectedFilter([filter])
      return;
    }

    if (selectedFilter.includes(filter)) {
      if( selectedFilter.length === 1 ) {
        // 하나 남았으면 무시
        return;
      }
      setSelectedFilter(selectedFilter.filter(x => x != filter));
    }
    else {
      setSelectedFilter([...selectedFilter.filter(x => x != t("modelingOrder-makingUnable")), filter]);
    }
  }

  const handleQueryChange = (input: string) => {
    setPageInfo({
      ...pageInfo,
      keyword: input
    })
  }

  const headerClick = (data: any) => {

    if (data.key === '')
      return;

    setPageInfo({
      ...pageInfo,
      sortColumn: data.key,
      sortOrder: !pageInfo.sortOrder
    })
  }

  useEffect(() => {
    getGrid();
  }, [pageInfo]);

  useEffect(()=>{
    if(user.companyId){
      setPageInfo({...pageInfo, companyId : user.companyId})
    }
  }, [user.companyId])
  return (
    <>
      <div className="list-wrap">
        <DashboardModelingOrdersListFilter onChangeKeyword={handleQueryChange} totalList={totalList} onSelectAll={handleSelectAll} onClick={handleSelectfilter} filter={selectedFilter} />
        <div className="list-contents">
          <div className="list-box">
            <div className="box">
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-reciveWaiting")} groupColor="#666666" list={listS01} />
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-paymentWaiting")} groupColor="#256DCB" list={listS02} />
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-paymentComplete")} groupColor="#FFCC00" list={listS03} />
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-making")} groupColor="#F37373" list={listS04} />
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-makingComplete")} groupColor="#93D955" list={listS05} />
              <GroupListBox headerClick={headerClick} pageInfo={pageInfo} type="defaultHeaderList" show={selectedFilter} groupName={t("modelingOrder-makingUnable")} groupColor="#CCC" list={listS06} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoardModelingOrders;