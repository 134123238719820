import { useSendMessageMutation } from '../store/slices/dashboard/dashboardModelingOrderApiSlice';
import MessageList from './chat/message-list';
import MessageSendBox from './chat/message-sendBox';
import MessageSendBoxAssetModeling from './chat/message-sendBox-asset-modeling';

export enum eChatType {
  eOrderModeling,
  eAssetModeling,
}

export interface IChartWrapper {
  eChatType: eChatType,
  modelingId: number,
  innerRef: any,
  getItems: any,
  message: any,
  inputRef: any,
  page: any,
  size: any,
}

const ChatWrapper = (props: IChartWrapper) => {

  const messageBox = () => {
    switch (props.eChatType) {
      case eChatType.eOrderModeling:
        return (
          <>
            <MessageSendBox modelingId={props.modelingId} />
          </>
        );
      case eChatType.eAssetModeling:
        return (
          <>
            <MessageSendBoxAssetModeling modelingId={props.modelingId} />
          </>
        );
    }
  }
  return (
    <>
      <MessageList modelingId={props.modelingId} message={props.message} getItems={props.getItems} innerRef={props.innerRef} />
      {messageBox()}
    </>
  )
}

export default ChatWrapper;