import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
    check: boolean;
}

const initialState : StateType = {
    check:false
}

export const menuSlice = createSlice({
    name : 'menu',
    initialState,
    reducers : {
        menuOpen(state,actions) {
            state.check = actions.payload
        }

    },
});

export const { menuOpen } = menuSlice.actions;
export default menuSlice;
