import React, { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetModelingIotMutation } from "../../../../../../store/slices/modeling/modelingIotApiSlice";
import PageStyles from '../../css/page.module.css';

export interface Props {
    onClose : Function
}

const IotPopUp:React.FC<Props> = ({onClose}) => {
    const {modelingId} = useParams()
    const [getIotInfoApi, {data, isSuccess, isError}] = useGetModelingIotMutation();
    useEffect(()=>{
        getIotInfoApi({modelingId:modelingId})
        const intervalId = setInterval(() => {
            getIotInfoApi({ modelingId: modelingId });

        }, 10000); // 10 seconds in milliseconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    },[modelingId])
    return(
        <>
        <div className={[PageStyles.popUp_4, PageStyles.active].join(" ")}>
            <div className={PageStyles.titleBox}>
                <span className={PageStyles.active}>실시간 IoT 정보</span>
            </div>
            <button className={PageStyles.popUpClose_btn} onClick={()=>onClose()}> 
                <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn"/>
            </button>
            <div className={PageStyles.contentBox}>
                <div className={PageStyles.ballastingBox}>
                    <span>BALLASTING</span>
                    <div className={PageStyles.listBox}>
                        <div>
                            <div>
                                <span className={data?.list?.iot1 >= 150 && data?.list?.iot1 <=200 ? PageStyles.red : PageStyles.gray}>
                                    {data?.list?.iot1} 
                                </span>
                                <p>
                                    <strong>D10</strong>
                                    <span>(메인유량, h/m3)</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className={data?.list?.iot2 >= 80 && data?.list?.iot2 <=100 ? PageStyles.red : PageStyles.gray}>{data?.list?.iot2}</span>
                                <p>
                                    <strong>D610</strong>
                                    <span>(메인밸브, %)</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className={data?.list?.iot3 >= 80 && data?.list?.iot3 <=100 ? PageStyles.red : PageStyles.gray}>{data?.list?.iot3}</span>
                                <p>
                                    <strong>D510</strong>
                                    <span>(수소가스, %)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={PageStyles.deballastingBox}>
                    <span>DEBALLASTING</span>
                    <div className={PageStyles.listBox}>
                        <div>
                            <div>
                                <span className={data?.list?.iot4 >= 150 && data?.list?.iot4 <=200 ? PageStyles.red : PageStyles.gray}>{data?.list?.iot4}</span>
                                <p>
                                    <strong>D10</strong>
                                    <span>(메인유량, h/m3)</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className={data?.list?.iot5 >= 80 && data?.list?.iot5 <=100 ? PageStyles.red : PageStyles.gray}>{data?.list?.iot5}</span>
                                <p>
                                    <strong>D610</strong>
                                    <span>(메인밸브, %)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default IotPopUp;