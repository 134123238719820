import { current } from "@reduxjs/toolkit";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export interface EventSearchBox {
}

export interface PropsSearchBox {
  onChangeKeyword?: Function,     // inputbox가 바뀌면 호출되는 함수
}

const GridSearchBox = forwardRef<EventSearchBox, PropsSearchBox>((props,  ref) => {
  
  const searchInputRef = useRef<any>();

  const pressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if( e.code === "Enter") {
        handleQueryChange();
    }
  }

  const handleQueryChange = () => {
    if(searchInputRef.current){
      const keyword = searchInputRef.current.value;
      if( props.onChangeKeyword )
        props.onChangeKeyword( keyword.trim() );
    }
  }

  return (
    <>
      <div className="search_box" >
        <StyledInput ref={searchInputRef} type="search" name="" id="" placeholder="search ..." onKeyPress={pressSearch}/>
        <StyledButton onClick={handleQueryChange}>
          <img src="/assets/images/search_thin.png" />
        </StyledButton>
      </div>
    </>
  )
});

export default GridSearchBox;

const StyledInput = styled.input`
  outline: none;
  border: none;
  height: 38px !important;
  padding: 0 15px;
  background: none;
  &:focus {
   outline: none;
   border: none;
  }
`

// border: 1px solid #256DCB !important;
// box-shadow: 0 0 3px #256dcb;

const StyledButton = styled.button`
  backgroundColor: transparent;
  outline: none;
  border: none;
  height: 35px;
  width: 40px;
  cursor: pointer;
`