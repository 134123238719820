import React from "react";
import moduleStyles from '../project.module.css'
export const ItemGroup = () => {

    return(
        <>
        <td>
            {/* <!-- ※ 프로필 정렬 주의 사항
            - 맨 왼쪽으로 나와야할 프로필이 가장 마지막 줄에 생성되어야 합니다. -->
            <!-- 프로필 3개 초과시 아래 group 클래스 생성 --> */}
            <div className={moduleStyles.manager_profileBox}>
                <div className={`${moduleStyles.profileList} ${moduleStyles.group}`}>
                    <div>
                        <span style={{backgroundColor : 'indianred'}}>+ 2</span>
                    </div>
                </div>
                <div className={`${moduleStyles.profileList} ${moduleStyles.pic}`}>
                    <div>
                        <span style={{backgroundImage : `url('/assets/images/test2.png')`}}>고</span>
                    </div>
                </div>
                <div className={`${moduleStyles.profileList}`}>
                    <div>
                        <span style={{backgroundColor : 'plum'}}>고</span>
                    </div>
                </div>
                <div className={`${moduleStyles.profileList}`}>
                    <div>
                        <span style={{backgroundColor : 'pink'}}>고</span>
                    </div>
                </div>
            </div>
        </td>
        </>
    )
}