import React from 'react';
import type { FC } from 'react';
// import '../../../src/css/common.css';

interface DashBoardFooterProps {

}

// const DashBoardFooter: FC<DashBoardFooterProps> = () => {
//     return (
//         <>
//             <footer className="dsbd">
//                 <div className="con-w1400">
//                     <div className="copyright">
//                         COPYRIGHT<b>ⓒALINK3D</b> ALL RIGHTS RESERVED.
//                     </div>
//                 </div>
//             </footer>
//         </>
//     )
// }

const DashBoardFooter = () => {
    return(
        <>
        <div className='dashboard_footer'>
            <p>COPYRIGHTⓒ<strong>ALINK3D</strong> ALL RIGHTS RESERVED.</p>
        </div>
        {/* <footer>
            <div className="alink-dots-footer">
                <div className="con">
                    <div className="copyright">
                        COPYRIGHT<b>ⓒALINK3D</b> ALL RIGHTS RESERVED.
                    </div>
                </div>
            </div>
        </footer> */}
        </>
    )
}

export default DashBoardFooter;