import { forwardRef } from "react";
import styled from "./avatarCircle.module.css";
import Tooltip from '@mui/material/Tooltip';

// interface
export interface EventAvatarCircle { }

// props
export interface PropsAvatarCircle {
  // userInfo? : any;
  lastName?: string;
  firstName?: string;
  profile1Url?: string;
  profile2Url?: string;
  backgroundColor? : string;
}

const AvatarCircle = forwardRef<EventAvatarCircle, PropsAvatarCircle>(
  (props, ref) => {

    const lastName = props.lastName ?? "?";
    const firstName = props.firstName?? "?";
    const hasProfile = Boolean(props.profile2Url);
    const backgroundColor = props.backgroundColor ?? `rgb(52, 152, 219)`;   // 기본색

    return (
      <>
        <Tooltip title={lastName+firstName} arrow color="black">
          {
            hasProfile ?
              <span className='profile_image' style={{ background: `url("${props.profile2Url}") center / cover no-repeat`, borderRadius : '10px'}}></span>
              :
              <span className='profile_none' style={{ backgroundColor: backgroundColor, borderRadius : '10px' }}>{lastName}</span>
          }
          {/* 툴팁이 없음 */}
        </Tooltip>
      </>
    );
  }
);

export default AvatarCircle;
