import React, { useEffect, useRef } from "react";

export interface Props{
    mobile : string
    onSave : Function
    onClose : Function
}

const EditMobile:React.FC<Props> = ({mobile, onSave, onClose}) => {

    const mobileRef = useRef<any>();

    const handleClose = () => {
        onClose()
    }

    const handleSaveMobile = () => {
        const mobile = mobileRef.current.value;
        onSave(mobile)
    }

    useEffect(()=>{
        mobileRef.current.value = mobile;
    }, [])

    return(
        <>
        <div className="profile_phoneNumb_popUp active">
            <div className="wrap">
                <div>
                    <div className="con on">
                        <div className="body">
                            <div className="content">
                                <div>
                                    <h4>휴대전화</h4>
                                    <input ref={mobileRef} type="text" name="" id="" className="input_text" placeholder="'-'를 제외한 번호를 입력해 주세요'."/>
                                </div>
                                <div className="buttonBox">
                                    <button onClick={handleSaveMobile}>저장</button>
                                    <button onClick={handleClose}>취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg"></div>
                </div>
            </div>
        
        </div>
        </>
    )
}

export default EditMobile;