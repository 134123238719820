import axios from 'axios';
import React, { FC, useState, useRef, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IMAGE_SRC } from '../../../../constant';
import { useRemoveFileMutation } from '../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { RootState } from '../../../../store/store';
import { File } from '../../../../types/fileType';
import { toLocaleToDate } from '../../../../utils/toLocaleDate';
import AvatarCircle from '../../../avatar/avatar-circle/avatarCircle';

interface IFileItemProps {
  data: File;
  getFileList?: any;
}

const FileItem: FC<IFileItemProps> = (props) => {

  const { data, getFileList } = props;

  console.log("FileItem", data);
  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);
  const userProfile = data.creatorInfo.profile2Url != "" ? data.creatorInfo.profile2Url : '../../../assets/images/profile_none.png';

  const fileRef = useRef<any>(null);
  const fileType = data.filePath.split("/").slice(-1).toString().split(".")[1].toLowerCase();

  const [removeFile] = useRemoveFileMutation();

  const [isShow, setShow] = useState(false);

  const downloadFile = async () => {

    // 리펙토링 필요
    let path = data.filePath.replace('http://210.114.19.235', '').trim();

    try {
      await axios.get(`${path}`, { responseType: 'blob' }).then((res: any) => {
        console.log(res);
        return res.data;
      })
        .then((blob: any) => {

          const url = window.URL.createObjectURL(blob);
          // const link = document.createElement('a');
          // link.target = "_blank"
          // link.rel = 'noopener noreferrer'
          // link.href = url;
          // link.download = data.fileName;
          window.open(url);
          // document.body.appendChild(link);
          // link.click(); 
          setTimeout((_: any) => {
            window.URL.revokeObjectURL(url) // 해당 url을 더 사용 못하게 날려버린다
          }, 60000)
          // link.remove()
          setShow(false);
        })
    } catch (err) {
      console.error(err);
    }
  }

  const handefileRemove = async () => {
    try {
      const result: any = await removeFile(data.fileId);
      if (result.data.result) {
        toast.success('삭제가 완료되었습니다.')
        getFileList();
      }
    } catch (err) {
      console.error(err);
    }
  }

  // 이것을 공통으로 만들 수 없는지?
  useEffect(() => {
    const handleOutSide = (e: any) => {
      if (fileRef.current && !fileRef.current.contains(e.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOutSide);
    }
  }, [fileRef])


  const handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(true)
  }

  // util로 이동
  const getSizeString = () => {

    // GB
    if (data.fileSize / 1073741824 > 1) {
      return `${Math.floor(data.fileSize / 1073741824)} GB`;
    }

    // MB
    if (data.fileSize / 1048576 > 1) {
      return `${Math.floor(data.fileSize / 1048576)} MB`;
    }

    // KB
    if (data.fileSize / 1024 > 1) {
      return `${Math.floor(data.fileSize / 1024)} KB`;
    }

    if (data.fileSize == 1)
      return "1 byte";

    return `${data.fileSize} bytes`;
  }

  return (
    <>
      <StyledBox ref={fileRef}>
        <StyledImageBox>
          <img style={{ display: 'block', margin: '0 auto' }} src={IMAGE_SRC[fileType]} alt="file" />
          <StyledButton onClick={handleShow}></StyledButton>
        </StyledImageBox>
        <div style={{ padding: '10px 15px' }}>
          <FileName>{data.fileName}</FileName>
          <span style={{ fontSize: '10px', color: '#256dcb' }}>{getSizeString()}</span>
        </div>
        <div style={{ padding: '10px 15px', display: 'flex', justifyContent: 'space-between' }}>

          <AvatarCircle
            lastName={data.creatorInfo.lastName}
            firstName={data.creatorInfo.firstName}
            profile2Url={data.creatorInfo.profile2Url}
            backgroundColor={'orange'}
          />

          {/* 프로필 이미지 */}
          {/* <span className="profile_image" style={{ background: `url(${userProfile}) center / cover no-repeat` }}></span> */}
          <span style={{ fontSize: '10px', color: '#999999' }}>{toLocaleToDate(data.created)}</span>
        </div>

        {
          isShow &&
          <>
            <div className='moreBox' style={{ display: 'block', position: 'absolute', borderRadius: '5px', padding: '5px', boxSizing: 'border-box', right: '20px', top: 0, width: '120px', background: '#fff', boxShadow: '1px 1px 8px rgba(0,0,0,0.3)' }}>
              <StyledModalButton background={"#256dcb"} onClick={downloadFile} className="btn_download">Download</StyledModalButton>
              {/* <StyledModalButton background={"#F37373"} onClick={handefileRemove} className="btn_remove">Remove</StyledModalButton> */}
            </div>
          </>
        }

      </StyledBox>
    </>
  )
}

export default FileItem;

const StyledBox = styled.li`
    position: relative;
    margin-bottom: 25px;
    border: 1px solid #eeeeee;
    width: 185px;
    border-radius: 5px;
    margin-left: 10px;
    list-style: none;
`

const StyledImageBox = styled.div`
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0;
    box-sizing: border-box;
`

const StyledButton = styled.button`
    top: 10px;
    border : none;
    background: url('/assets/images/more-btn.png') center / contain no-repeat;
    width: 3px;
    height: 17px;
    position:absolute;
    right: 5px;
`

const FileName = styled.p`
    font-size : 13px;
`

const StyledModalButton = styled.button<{ background: any }>`
    margin-bottom: 5px;
    text-align: left;
    padding-left: 25px;
    border: none;
    position: relative;
    border-radius: 5px;
    background: #fff;
    line-height: 25px;
    width: 100%;

    &:before {
        content:'';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        left: 7px;
        top: 9px;
        background-color: ${(props) => props.background};
    }
`