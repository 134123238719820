import React, { FC } from 'react';

interface IModelingPopupRoute {
  onClick: any;
  popupNumber: string;
  type: string;
  visibleInspect: Boolean;
}

const ModelingPopupRoute: FC<IModelingPopupRoute> = (props) => {

  const { onClick, popupNumber, type } = props;

  return (
    <>
      <div>
        {
          type === "defaultHeaderList" ?
            <ul>
              <li onClick={() => onClick('0')} className={popupNumber === '0' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              <li onClick={() => onClick('1')} className={popupNumber === '1' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              <li onClick={() => onClick('2')} className={popupNumber === '2' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              <li onClick={() => onClick('3')} className={popupNumber === '3' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              {
                props.visibleInspect &&

                <li onClick={() => onClick('4')} className={popupNumber === '4' ? "active" : ""}>
                  <a href="#">icon</a>
                </li>
              }
            </ul>
            :
            <ul>
              <li onClick={() => onClick('0')} className={popupNumber === '0' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              <li onClick={() => onClick('1')} className={popupNumber === '1' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
              <li onClick={() => onClick('2')} className={popupNumber === '2' ? "active" : ""}>
                <a href="#">icon</a>
              </li>
            </ul>
        }

      </div>
    </>
  )
}

export default ModelingPopupRoute;