import React, { FC } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

//김건우
import { useTranslation } from "react-i18next";

const NoticeBox = () => {
  
    //김건우
    const { t } = useTranslation(); 

    const location = useLocation();

    const getContent = () => {
        let content = ''
        switch(location.pathname) {
            case "/dashboard/modelingPhoto" :
                content = t("modelingRequest-photoNotice")
                break;
            case "/dashboard/modeling2d":
                content = t("modelingRequest-2dNotice")
                break;
            case "/dashboard/modeling3d":
                content = t("modelingRequest-3dNotice")
                break;
            default :
                content = ''
                break;   
        }
        return content;
    }

    return (
        <>
            <ul className="notice">
                <li>{t("modelingRequest-notice1")}</li>
                <li>{getContent()}</li>
                <li><span>{t("modelingRequest-notice2")}</span>
                {t("modelingRequest-notice3")}</li>
                <li>{t("modelingRequest-notice4")}</li>
                <li>{t("modelingRequest-notice5")}</li>
            </ul>
        </>
    )
}

export default NoticeBox;