import { forwardRef, ReactElement } from "react";

// 외부에서 사용하기 위한 인터페이스
export interface EventMessageBox {}

export enum MessageBoxType {
  YesNo,
  SaveCancel,
}
// props
export interface PropsMessageBox {
  id?: string;
  boxType: MessageBoxType;
  message: ReactElement;
  onClick1: Function; // 첫번째 버튼
  onClick2: Function; //
}

const MessageBox = forwardRef<EventMessageBox, PropsMessageBox>(
  (props, ref) => {
    let btnCaption1 = "취소";
    let btnColor1 = "btn-b";
    let btnCaption2 = "확인";
    let btnColor2 = "btn-a";

    switch (props.boxType) {
      default:
      case MessageBoxType.YesNo: {
        btnCaption1 = "취소";
        btnColor1 = "btn-b";
        btnCaption2 = "확인";
        btnColor2 = "btn-a";
        break;
      }

      case MessageBoxType.SaveCancel: {
        btnCaption1 = "저장";
        btnColor1 = "btn-a";
        btnCaption2 = "취소";
        btnColor2 = "btn-b";
        break;
      }
    }

    const onClick1 = () => {
      console.log("1번 버튼 클릭");
      props.onClick1();
    };

    const onClick2 = () => {
      console.log("2번 버튼 클릭");
      props.onClick2();
    };
    // const checkOrderStatus = () => {
    //   if( !props.onClick )
    //     return "";

    //   if( props.pageInfo.sortColumn !== props.id )
    //     return "sort";

    //   if( props.pageInfo.sortOrder === false)
    //     return "sort desc";
    //   else
    //     return "sort asc";
    // }

    // const onClick = () => {
    //   if( props.onClick ) {
    //     props.onClick(props.id);
    //   }
    // }

    // const orderStatus = checkOrderStatus();

    return (
      <>
        {/* <div className="mfp-bg active" />
      <div className="mfp-wrap active">
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div className="mfp-content">
            <div className="modal-st-1">
              <div className="popup-contents">
                <div className="txt-box">
                  {props.message}
                </div>
                <div className="two-btn">
                  <div className="col"><button onClick={()=>{onClick1()} } type="button" className={btnColor1}>{btnCaption1}</button></div>
                  <div className="col"><button onClick={()=>{onClick2()}} type="button" className={btnColor2}>{btnCaption2}</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="company_registration_popUp active">
          <div className="wrap">
            <div>
              <div className="con on">
                <div className="body">
                  <div className="content">
                    <p>
                      {props.message}
                    </p>
                  </div>
                  <div className="buttonBox">
                    <button onClick={()=>{onClick1()} }>취소</button>
                    <button onClick={()=>{onClick2()}}>확인</button>
                  </div>
                </div>
              </div>
              <div className="bg"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default MessageBox;
