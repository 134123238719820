import React, { forwardRef } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
// import './calendar.css';
import Toolbar from "./toolbar";
import { format, parse, startOfWeek, getDay, isWeekend } from "date-fns";

const locales = {
  "en-US": require("date-fns")
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const BigCalendar = (props: any) => {

  const { dateInfo } = props;
  const dateFormatter = (date: string) => {
    return new Date(date).toISOString().replace('T', ' ').replace(/\..*/, '')
  }

  const eventData = () => {
    var data = [];

    if (dateInfo.paidDate != null) {
      data.push({
        id: '0',
        title: '결제완료일자',
        start: dateFormatter(dateInfo.paidDate),
        end: dateFormatter(dateInfo.paidDate)
      });
    }

    if (dateInfo.expectedDate != null) {
      data.push({
        id: '1',
        title: '제작완료예상일',
        start: dateFormatter(dateInfo.expectedDate),
        end: dateFormatter(dateInfo.expectedDate),
      });
    }

    if (dateInfo.registeredDate != null) {
      data.push({
        id: '2',
        title: '의뢰접수일자',
        start: dateFormatter(dateInfo.registeredDate),
        end: dateFormatter(dateInfo.registeredDate),
      });
    }

    if (dateInfo.manufacturedDate != null) {
      data.push({
        id: '3',
        title: '제작완료일',
        start: dateFormatter(dateInfo.manufacturedDate),
        end: dateFormatter(dateInfo.manufacturedDate),
      });
    }

    if (dateInfo.freeExpiredDate != null) {
      data.push({
        id: '4',
        title: '제작완료일자',
        start: dateFormatter(dateInfo.freeExpiredDate),
        end: dateFormatter(dateInfo.freeExpiredDate),
      });
    }

    if (dateInfo.expiredDate != null) {
      data.push({
        id: '5',
        title: '무료사용만기일자',
        start: dateFormatter(dateInfo.expiredDate),
        end: dateFormatter(dateInfo.expiredDate),
      });
    }

    
    if (dateInfo.completedDate != null) {
      data.push({
        id: '6',
        title: '만기일자',
        start: dateFormatter(dateInfo.completedDate),
        end: dateFormatter(dateInfo.completedDate),
      });
    }

    return data;
  }

  // 이벤트 요소마다 색상 변경
  const eventPropGetter = (e: any) => {
    let backgroundColor = '';

    switch (e.title) {
      case '의뢰접수일자': backgroundColor = '#256DCB'; break;
      case '결제완료일자': backgroundColor = '#93D955'; break;
      case '제작완료예상일': backgroundColor = '#F37373'; break;
      case '제작완료일': backgroundColor = '#FFCC00 '; break;
      case '제작완료일자': backgroundColor = '#256DCB'; break;
      case '무료사용만기일자': backgroundColor = '#93D955'; break;
      case '만기일자': backgroundColor = '#F37373'; break;
    }
    return {
      style: {
        backgroundColor
      }
    }
  }

  return (
    <>
      <Calendar
        views={["month"]}
        localizer={localizer}
        style={{ height: 450 }}
        events={eventData()}
        components={{
          toolbar: Toolbar
        }}
        eventPropGetter={eventPropGetter}
      />
    </>
  )
}

export default BigCalendar;