import React, { FC, useState } from 'react';

interface IDocumentProps {
  keyType: string;
  fileInfo: any;
  name : string
}

const Document: FC<IDocumentProps> = (props) => {

  const { keyType, name } = props;

  const dateFormatter = (date: string) => {
    return new Date(date).toISOString().substring(0, 10).replace('T', ' ').replace(/\..*/, '')
  }

  const getSizeString = () => {

    // GB
    if (props.fileInfo.fileSize / 1073741824 > 1) {
      return `${Math.floor(props.fileInfo.fileSize / 1073741824)} GB`;
    }

    // MB
    if (props.fileInfo.fileSize / 1048576 > 1) {
      return `${Math.floor(props.fileInfo.fileSize / 1048576)} MB`;
    }

    // KB
    if (props.fileInfo.fileSize / 1024 > 1) {
      return `${Math.floor(props.fileInfo.fileSize / 1024)} KB`;
    }

    if (props.fileInfo.fileSize == 1)
      return "1 byte";

    return `${props.fileInfo.fileSize} bytes`;
  }

  return (
    <>
      {
        props.fileInfo !== undefined ?
          <li className="document_list">
            <div>
              <div>
                <p>
                  <a href={props.fileInfo.filePath} target={'_blank'} download={props.fileInfo.fileName}>
                    {props.fileInfo.fileName}
                  </a>
                </p>
              </div>
              <div>
                <span>{getSizeString()}</span>
                <span>{dateFormatter(props.fileInfo.created)}</span>
              </div>
              {/* <div>
                <button><img src="/assets/images/pen-to-square-light.png" alt="pen-to-square-light" /></button>
                <button><img src="/assets/images/trash-can-light.png" alt="trash-can-light" /></button>
              </div> */}
            </div>
          </li>
          :
          <li className={keyType}>
            <div style={{ height: '70px' }}>
              <input type="file" name="tax_file" id={keyType} style={{ display: 'none' }} disabled={true} />
              <label htmlFor={keyType}><span className="estimate_text" style={{ fontWeight: '400' }}>{name}</span></label>
              {/* <span><img src="/assets/images/upload-light.png" alt="upload-light" style={{ height: '14px' }}/></span> */}
            </div>
          </li>
      }
    </>
  )
}

export default Document;