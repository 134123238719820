import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useGetPaymentListMutation } from '../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';

interface IThirdSPopup {
  detailInfo: any;
  selectedPopupInfo: any;
}

const ThirdSupPopup: FC<IThirdSPopup> = (props) => {

  const [paymentList, setPaymentList] = useState({
    list: []
  })
  const [getPaymentList] = useGetPaymentListMutation();

  const getPayments = async () => {
    try {
      const result: any = await getPaymentList({
        modelingId: props.detailInfo.modelingId
      })
      if (result.data.result === true) {
        setPaymentList({
          list: result.data.list
        })
      }
    } catch {
    }
  }

  useEffect(() => {
    getPayments()
  }, [])

  const handlePaymentUpload = async (e: any, file: any) => {
  }

  // 파일 다운로드
  const handleDownLoad = (e: any, file: any) => {
    const downLoad = document.createElement("a")
    if (e.target.className === 'file1') {
      downLoad.download = file.paymentName1
      downLoad.href = file.paymentUrl1
    } else {
      downLoad.download = file.paymentName2
      downLoad.href = file.paymentUrl2
    }
    downLoad.target = "_blank"
    document.body.appendChild(downLoad)
    downLoad.click()
    document.body.removeChild(downLoad)
  }

  const handleDelete = async (e: any, list: any) => {
  }

  return (
    <>
      <div className="modal_popUp_8">
        <div className="payment_details">
          <h3>결제내역</h3>
          <ul>
            {paymentList.list.map((list: any) => {
              const paymentDate = moment(list.paymentDate).format('YYYY.MM.DD HH:mm')
              return (
                <>
                  <li key={list.paymentId}>
                    <ul>
                      <li>
                        <span>일시</span>
                        <span>{paymentDate}</span>
                      </li>
                      <li>
                        <span>결제방식</span>
                        <span>무통장입금</span>
                      </li>
                      <li>
                        <span>결제금액</span>
                        <span>{list.paymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                      </li>
                    </ul>
                    <div className={list.paymentUrl1 === '' ? 'transaction none' : 'transaction'}>
                      {list.paymentUrl1 === '' ?
                        <>
                          <label htmlFor="file1">거래명세서 미발행</label>
                          {/* <input type='file' id='file1' name='file1' onChange={(e) => handlePaymentUpload(e, list)} hidden /> */}
                        </> :
                        <>
                          <p className="file1" onClick={(e) => handleDownLoad(e, list)}>{list.paymentUrl1.split('/').pop()}</p>
                          <span className="file1" onClick={(e) => handleDelete(e, list)}></span>
                        </>
                      }
                    </div>
                    <div className={list.paymentUrl2 === '' ? 'attachment none' : 'attachment'}>
                      {list.paymentUrl2 === '' ?
                        <>
                          <label htmlFor="file2">세금계산서 미발행</label>
                          {/* <input type='file' id='file2' name='file2' onChange={(e) => handlePaymentUpload(e, list)} hidden /> */}
                        </> :
                        <>
                          <p className="file2" onClick={(e) => handleDownLoad(e, list)}>{list.paymentUrl2.split('/').pop()}</p>
                          <span className="file2" onClick={(e) => handleDelete(e, list)}></span>
                        </>
                      }
                    </div>
                  </li>
                </>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default ThirdSupPopup;
