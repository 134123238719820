import React, { useEffect, useRef, useState } from "react";
import PageStyles from './css/page.module.css';
import ContactUs from "./popUp/contact_popUp";
import IotPopUp from "./popUp/Iot_popUp";
import PartsPopUp from "./popUp/parts_popUp";
import ProductPopUp from "./popUp/product_popUp";
export interface Props {partsName : string}
const BottomPopUpBtn:React.FC<Props> = ({partsName}) => {
    console.log(partsName)
    const btnList = 
    [ {idx : 1 , title : '제품정보'}, {idx : 2 , title : '부품정보'}, {idx : 4 , title : 'IoT정보'}, {idx : 3 , title : '문의하기'} ]
    // 버튼 클릭 시, 보여줄 페이지 고유 값
    const [popUpPageIdx, setPopUpPageIdx] = useState<number>(0);
    const [isSlideIn, setIsSlideIn] = useState<boolean>(false);
    console.log(popUpPageIdx)
    const popUpbtnRef = useRef<any>([]);
    // 팝업 창을 열기 위한 이벤트 처리
    const handleOpen = (list: any) => {
        if(list.idx === 2 && (partsName === undefined || partsName === 'ALINK3D')){
            setPopUpPageIdx(0);
            alert("부품을 선택해주세요."); return;
        }
        // 속성이 들어있는지 여부 판단
        else if (popUpbtnRef.current[list.idx].classList.contains(PageStyles.active)) {
            // 속성이 들어있을 경우 remove
            popUpbtnRef.current[list.idx].classList.remove(PageStyles.active);
            setIsSlideIn(false);
            setPopUpPageIdx(0);
            return; // 반환 필수
        }
        Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
            buttonRef?.classList.remove(PageStyles.active);
        });
        
        popUpbtnRef.current?.[list.idx].classList.add(PageStyles.active);
        setIsSlideIn(true);
        setPopUpPageIdx(list.idx)
    };

    const handleClose = () => {
        Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
            buttonRef?.classList.remove(PageStyles.active);
        });
        setPopUpPageIdx(0);
    }
    useEffect(()=>{
        if(popUpPageIdx === 2 && partsName === undefined){
            setPopUpPageIdx(0)
        }
    }, [partsName])
    useEffect(()=>{
        if(popUpPageIdx === 0){
            Object.values(popUpbtnRef.current).forEach((buttonRef: any) => {
                buttonRef?.classList.remove(PageStyles.active);
            });
        }
    }, [popUpPageIdx])

    return(
        <>
        {/* 하단 팝업 버튼 */}
        <div className={PageStyles.popUpOpen_btnBox}>
            
            {btnList.map((list : any) => {
                return(
                    <>
                    <button 
                        className={PageStyles[`popUp_btn${list.idx}`]}
                        ref={(e)=>popUpbtnRef.current[list.idx]= e}
                        onClick={()=>handleOpen(list)}
                    >
                    <span></span>{list.title}
                    </button>
                    </>
                )
            })}
			<button className={[PageStyles.popUp_langBtn, PageStyles.popUp_lang_ko].join(" ")}><span></span></button>
		</div>

        {/* 팝업 페이지 */}
        <div className={PageStyles.popUpBox}>
            {popUpPageIdx === 1 ? <ProductPopUp onClose={handleClose}/> : ''}
            {popUpPageIdx === 2 ? <PartsPopUp partsName={partsName} onClose={handleClose}/> : ''}
            {popUpPageIdx === 4 ? <IotPopUp onClose={handleClose}/> : ''}
            {popUpPageIdx === 3 ? <ContactUs onClose={handleClose}/> : ''}
        </div>
        </>
    )
}

export default BottomPopUpBtn;