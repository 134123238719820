import React, { FC } from 'react';
import ModelingCheckbox from './checkbox/modelingCheckbox';

interface IAgreeBoxProps {
    allCheck : boolean;
    allCheckChange : any;
    filter: any;
    handleCheckChange: any;
}

const AgreeBox:FC<IAgreeBoxProps> = (props) => {

    const { allCheckChange , allCheck, filter, handleCheckChange } = props;

    return (
        <>
            <ul className='agreeBox'>
                <li>
                    <input
                        onChange={allCheckChange}
                        type="checkbox"
                        className="agreement_box"
                        id="all_agree"
                        checked={allCheck}
                    />{" "}
                    <label htmlFor="all_agree"> 전체 동의</label>
                </li>
                <ModelingCheckbox name="ckMarketing" checked={filter.ckMarketing} onCheckChange={handleCheckChange} content="웹사이트 연동 & 디지털 마케팅 및 영업 활용 목적" labelProps="agree_1" />
                <ModelingCheckbox name="ckFilm" checked={filter.ckFilm} onCheckChange={handleCheckChange} content="홍보 영상 제작 의뢰 목적" labelProps="agree_2"/>
                <ModelingCheckbox name="ckPublicMode" checked={filter.ckPublicMode} onCheckChange={handleCheckChange} content="ALINK3D 웹상에 모델링 정보 공개 여부" labelProps="agree_3"/>
                <ModelingCheckbox name="ckManage" checked={filter.ckManage} onCheckChange={handleCheckChange} content="ALINK3D 관리 프로그램 사용 여부" labelProps="agree_4"/>
            </ul>
        </>
    )
}

export default AgreeBox;