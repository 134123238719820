
// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { restApi_baseUrl } from '../sliceEnv';

//Redux RTK

// TO DO
// 1. CORS 이슈 해결
// 2. refetch 표준 방법
// 3. 첫번째 arg가 뭐지?

export const counterApiSlice = createApi({
    reducerPath : 'api',
    baseQuery : fetchBaseQuery({ baseUrl : 'http://210.114.19.235:8080/testapi/',
        // mode: "no-cors"
    }),
    endpoints : builder => ({
        getClassList: builder.query({
            query: () => '/login'
        }),
        getCatFact: builder.query({
            query: () => '/fact'
        })
    })
    
});

export const { useGetClassListQuery, useGetCatFactQuery } = counterApiSlice

