import React, { FC } from "react";
interface IPopupDeleteProps {
    onClick1: Function;
    onClick2: Function;
    message:any;
}

const PopupDelete: FC<IPopupDeleteProps> = (props) => {
  const { onClick1 , onClick2 } = props;  

  const clickCancel = () => {
    onClick1();
  } 

  const clickSave = () => {
    onClick2();
  }

  return (
    <>
      <div className="rank_remove_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div className="body">
                <div className="content">
                  <div>
                    {/* <p>'대표'직급을 삭제하시겠습니까?</p> */}
                    {props.message}
                  </div>
                  <div className="buttonBox">
                    <button onClick={clickCancel}>취소</button>
                    <button onClick={clickSave}>삭제</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupDelete;
