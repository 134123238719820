import React from 'react';
import type { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';

interface ProfileCircleProps {
  item: any;
}

const ProfileCircle: FC<ProfileCircleProps> = (props) => {

  // const profileUrl = props.item.profile2Url != "" ? props.item.profile2Url : "/assets/images/profile_none.png";
  const profileUrl = props.item.profile2Url;
  const profileName = props.item.lastName+props.item.firstName;

  return (
    <>
    <Tooltip title={profileName} arrow color="black">
      <div className='pic-align'>
        {/* <div className='pic' style={{background: `url(${item.url}) center center / cover'`}}>
                </div> */}
          {profileUrl === '' ?
          <div className='pic' style={{ background: 'rgb(52, 152, 219)' }}>{profileName.substring(0,1)}</div> :
          <div className='pic' style={{ background: `url(${profileUrl}) center center / cover` }}></div>
          }
      </div>
    </Tooltip>
    </>
  )
}

export default ProfileCircle;