import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductMemoRegist from "../../../../../../../components/modelingCtrl/bottomPopup/product/product_memoRegist";
import { useDelAssembleMemoMutation, useGetAssembleMemoMutation, useUptAssembleMemoMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductMemo = () => {
    const {modelingId}:any = useParams()
    const [getAssembleMemoApi] = useGetAssembleMemoMutation()
    const [delAssembleMemoApi] = useDelAssembleMemoMutation()
    const [uptAssembleMemoApi] = useUptAssembleMemoMutation();
    const [memoList, setMemoList] = useState<any>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const getAssembleMemo = async() => {
        const param = {modelingId : modelingId}
        const result : any = await getAssembleMemoApi(param)
        if(result.data.result===true){
            setMemoList(result.data.list)
        }
    }
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    const handleEditOpenYn = async(id: any, open_yn:string, type : string) => {
        const formData = new FormData();
        formData.append('idx' , id)
        if(type === 'Y'){
            if(open_yn === 'N'){
                formData.append('open_yn', 'Y')
            }
        } else if(type === 'N') {
            if(open_yn === 'Y'){
                formData.append('open_yn', 'N')
            }
        }
        const result : any = await uptAssembleMemoApi(formData);
        if(result.data.result ===true ) {getAssembleMemo()}
    }
    const handleDelMemo = async(id:number) => {
        const confirm = window.confirm("삭제하시겠습니까?")
        if(confirm){
            const param = {id : id}
            const result : any = await delAssembleMemoApi(param)
            if(result.data.result === true) {getAssembleMemo();}
        }
    }
    useEffect(()=>{
        getAssembleMemo()
    }, [])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_6, PageStyles.active].join(" ")}>
            
            <div className={PageStyles.memoBox}>
                <ul>
                    {memoList?.map((data : any) => (
                        <li key={data.idx}>
                            <div className={PageStyles.top}>
                                <div className={PageStyles.textArea}>
                                    {data.memo_contents}
                                </div>
                            </div>
                            <div className={PageStyles.bottom}>
                                <div>
                                    <span className={PageStyles.name}>{data.last_kr}{data.first_kr}</span>
                                    {/* <span className={PageStyles.date}>23.03.22  10:24</span> */}
                                    <span className={PageStyles.date}>{data.memo_mdata}</span>
                                </div>
                                <div>
                                    <div className={PageStyles.radioBox}>
                                        <div>
                                            <input type="radio" name={`sample${data.idx}`} id={`sample${data.idx}`} checked={data.open_yn==="Y"?true:false} onClick={()=>handleEditOpenYn(data.idx, data.open_yn, 'Y')}/>
                                            <label htmlFor={`sample${data.idx}`}>공개</label>
                                        </div>
                                        <div>
                                            <input type="radio" name={`sam${data.idx}`} id={`sam${data.idx}`} checked={data.open_yn==="N"?true:false} onClick={()=>handleEditOpenYn(data.idx, data.open_yn, 'N')}/>
                                            <label htmlFor={`sam${data.idx}`}>비공개</label>
                                        </div>
                                    </div>
                                    <div className={PageStyles.btnBox}>
                                        <button className={PageStyles.thumb_closeBtn} onClick={()=>handleDelMemo(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="close"/></button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <button className={[PageStyles.memoAddBtn, PageStyles.blueAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            {isOpen && <ProductMemoRegist getMemo={getAssembleMemo} onClose={handleOpen}/>}
        </div>
        </>
    )
}

export default ProductMemo;