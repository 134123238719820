import React, { FC } from 'react';
import styled from 'styled-components';
import FileItem from '../fourthPopup/fileItem';

//김건우
import { useTranslation } from "react-i18next";

interface IFileGroupProps {
  fileList: any[];
  getFileList: () => void;
}

const StyledGridBox = styled.div`
    display: grid;
    width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`

export const FileGroup: FC<IFileGroupProps> = (props) => {

    //김건우
    const { t } = useTranslation();

  const { fileList, getFileList } = props;

  return (
    <>
      <div>
        <h4>{t("popup-zipFile")} <span>{fileList?.length}</span></h4>

        {
          !fileList || fileList.length === 0 ?
            <></>
            :
            <StyledGridBox>
              {
                fileList.filter(item => (item.contentType == "CTYPE_100"))
                  .map((item: any, index) => (
                    <FileItem getFileList={getFileList} data={item} key={index} />
                  ))
              }
            </StyledGridBox>
        }
      </div>
    </>
  )
}
