import { FC, useRef, useState } from 'react';

interface IRequestBoxProps {
  handleFileChange: any;
  titleRef: any;
  contentRef: any;
  placeholder: string;
  subjectRef: any;
  file: any;
}

const RequestBox: FC<IRequestBoxProps> = (props) => {

  const { handleFileChange, titleRef, contentRef, placeholder, subjectRef, file } = props;
  const inputRef = useRef<any>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  const onChangeFile = (e: any) => {
    handleFileChange(e.target.files);
  }
  
  return (
    <>
      <div className="request_contents">
        <h5><input ref={subjectRef} style={{ border: 'none', fontSize: '20px', outline: "none" }} placeholder='의뢰 제목' /></h5>
        <div className="modeling_name">
          <input ref={titleRef} type="text" className="input_text" placeholder="모델링 명을 입력해 주세요." />
          {/* <button className="input_closeBtn" onClick={handleDelText}></button> */}
        </div>
        <textarea ref={contentRef} name="" id="" placeholder="의뢰 내용 기재"></textarea>
        <div>
          <div className="upload-file-st-1">
            <input value={file && file.name} type="text" placeholder={placeholder} className="file-name-box" readOnly />
            <input ref={inputRef} type="file" className="input-hidden" accept=".zip" onChange={onChangeFile} />
            <button onClick={handleClick} type="button" className="file-btn-box">파일 선택</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestBox;