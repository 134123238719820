import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PartsTroubleRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_troubleRegist";
import { useDelPartsTroubleMutation, useGetPartsTroubleMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props{partsName : string; partsId : string}
const PartsTrouble:React.FC<Props> = ({partsName, partsId}) => {
    const {modelingId} = useParams()
    const [getPartsTroubleApi] = useGetPartsTroubleMutation();
    const [delPartsTroubleApi] = useDelPartsTroubleMutation()
    const [troubleList, setTroubleList] = useState<any>({kr: [], en: []})
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleOpen = () => {
        setIsOpen(prev=>!prev)
    }
    const getPartsTrouble = async() => {
        const param = {modelingId : modelingId, idx : partsId}
        const result : any = await getPartsTroubleApi(param)
        if(result.data.result){
            setTroubleList({
                kr : result?.data?.list?.filter((item:any)=>item.parts_trouble_lang==="KR"),
                en : result?.data?.list?.filter((item:any)=>item.parts_trouble_lang==="EN")
            })
        }
    }
    const handleFileDown = (file : string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    const handleDelTrouble = async (idx:string) => {
        const param = {idx : idx}
        const confirm = window.confirm("해당 트러블슈팅을 삭제하시겠습니까?");
        if(confirm){
            const result : any = await delPartsTroubleApi(param)
            if(result.data.result === true){getPartsTrouble()}
        }
    }

    useEffect(()=>{
        getPartsTrouble()
    }, [partsId])
    

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_6, PageStyles.active].join(" ")}>

            <div className={PageStyles.troubleBox}>
                <div className={PageStyles.parts_trouble_kr_box}>
                    <span className={PageStyles.lang}>KR</span>
                    <div className={PageStyles.trouble_fileBox}>
                        {troubleList?.kr?.map((data:any) => (
                        <div className={PageStyles.catalogDown} key={data.idx}>
                            <div>
                                <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                                <a target="_black" onClick={()=>handleFileDown(data.parts_trouble_pdsFilename)}><input id="troubleKr_after" name="trouble_title" value={data.parts_trouble_pdsFile} placeholder="등록할 트러블슈팅 첨부 파일을 업로드하시기 바랍니다." readOnly/></a>
                            </div>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button type="button" onClick={()=>handleDelTrouble(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

                <div className={PageStyles.parts_trouble_en_box}>
                    <span className={PageStyles.lang}>EN</span>
                    <div className={PageStyles.trouble_fileBox}>
                        {troubleList?.en?.map((data:any) => (
                        <div className={PageStyles.catalogDown} key={data.idx}>
                            <div>
                                <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                                <a target="_black" onClick={()=>handleFileDown(data.parts_trouble_pdsFilename)}><input id="troubleKr_after" name="trouble_title" value={data.parts_trouble_pdsFile} placeholder="등록할 트러블슈팅 첨부 파일을 업로드하시기 바랍니다." readOnly/></a>
                            </div>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button type="button" onClick={()=>handleDelTrouble(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>

            {isOpen&&<PartsTroubleRegist getTrouble={getPartsTrouble} partsName={partsName} partsId={partsId} onClose={handleOpen}/>}

            <button className={[PageStyles.blueAddBtn, PageStyles.frmPartsTroubleAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
        </div>
        </>
    )
}

export default PartsTrouble;