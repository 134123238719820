import React from "react";
import type { FC } from "react";

interface ModelingCheckboxProps {
    content:string;
    onCheckChange:any;
    name:string;
    checked:boolean;
    labelProps?: string; 
}

const ModelingCheckbox: FC<ModelingCheckboxProps> = (props) => {
  
  const { content, name, onCheckChange,checked, labelProps } = props;  
    
  return (
    <>
      <li>
        <input name={name} onChange={onCheckChange} checked={checked} type="checkbox" id={labelProps} />
        <label htmlFor={labelProps}>{content}<span>(선택)</span></label>
      </li>
    </>
  );
};

export default ModelingCheckbox;
