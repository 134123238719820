import global from '../main/css/global.module.css';
const Footer = () => {
    return (
        <>
        <footer className={global.main_footer}>
            <div>
                <div>
                    <ul>
                        <li>
                            <a href="#">회사소개</a>
                        </li>
                        <li>
                            <a href="#">서비스 문의</a>
                        </li>
                        <li>
                            <a href="#">파트너 신청</a>
                        </li>
                        <li>
                            <a href="#">사용자 매뉴얼</a>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <a href="#">이용약관</a>
                        </li>
                        <li>
                            <a href="#">3D 모델링 보안 방침</a>
                        </li>
                        <li>
                            <a href="#">개인정보 처리방침</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <div className={global.left}>
                        <div>
                            <p><strong>(주) 비엠씨</strong></p>
                            <p><strong>대표이사</strong>황선일</p>
                            <p><strong>사업자등록번호</strong>883-88-01284</p>
                        </div>
                        <div>
                            <address>[21990] 인천 연수구 송도미래로 30, 송도스마트밸리 E동 1906호</address>
                        </div>
                        <div>
                            <p><strong>Email</strong>admin@bmcnet.kr</p>
                            <p><strong>Fax</strong>032.837.5388</p>
                        </div>
                    </div>
                    <div className={global.right}>
                        <p>고객센터 →</p>
                        <a href="tel:+0328375387">032-837-5387</a>
                        <p>평일 (공휴일 제외) : 09:00 ~ 18:00</p>
                        <ul>
                            <li>
                                <a></a>
                            </li>
                            <li>
                                <a></a>
                            </li>
                            <li>
                                <a></a>
                            </li>
                            <li>
                                <a></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <a><img src="/assets/images/main/alink3d_footer_logo.png" alt="alink3d logo"/></a>
                    <p>Copyright © ALINK3D. All rights reserved.</p>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer;