import React, { useCallback, useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import PageStyles from '../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';

export interface Props {onFileUpload : Function, type : string}
const Dropzone:React.FC<Props> = ({onFileUpload, type}) => {

    const acceptFile : any = 'application/pdf';

    const onDrop = useCallback((acceptedFiles:any)=> {
        onFileUpload(acceptedFiles)
      }, [])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
        maxFiles : 99,
        accept:acceptFile
    });

    return(
        <>
        <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <div className={[PageStyles.kr, PageStyles.catalog_upload, PageStyles.active].join(" ")} style={{backgroundColor : isDragActive ? '#f89b00' : ''}}>
                <div className={[PageStyles.dropzone, PageStyles.custom].join(" ")} id="dropzone5">
                    <div className={PageStyles.dz_message}>
                        {
                            isDragActive ?
                            <button className={PageStyles.dz_button} type="button">
                                Drop the Files Here
                                <span>Files supported : PDF</span>
                            </button>
                            :                       
                            <button className={PageStyles.dz_button} type="button">
                                Click here to upload the troubleshooting file
                                <span>Files supported : PDF</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        {/* <input {...getInputProps()} />
            {
                isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            } */}
        </>
    )
}

export default Dropzone;