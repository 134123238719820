import React, { FC, useEffect, useRef, useState } from 'react';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../../types/modelingOrderType';
import { useEditOrderModelMutation, useGetOrderModelFileListMutation } from '../../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import IFrameControlCompnent, { EventIFrameControl } from '../../../../../components/xvl/IFrameControl';

//김건우
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
export const completed = { backgroundColor : '#F2F2F2', padding : '25px' }
export const ptag : any = { fontWeight : '600', color : '#777777', fontSize : '14px', lineHight : '20px', textAlign : 'center' }
export const pStrong : any = { cursor : 'pointer', color : '#555555', textDecoration : 'underline', fontWeight : '700' }
interface IFifthPopupProps {
  selectedPopupInfo: ModelingOrderType;
  detailInfo: ModelingOrderDetailType;
  getDetailInfo: Function
}
const FifthPopup: FC<IFifthPopupProps> = (props) => {
  const navi = useNavigate()
  const [inspect, setInspect] = useState<string>(props.detailInfo.inspectionStatus)
    //김건우
  const { t } = useTranslation();
  const { getDetailInfo } = props;
  console.log('검수화면');
  console.log(props.detailInfo);
  console.log(props.selectedPopupInfo);
  const [editStatus] = useEditOrderModelMutation();
  const completeOrderModeling = async (inspection:string) =>{
    const params = {modelingId : props.detailInfo.modelingId, status : inspection}
    console.log(props.detailInfo.inspectionStatus)
    try{
      const result:any = await editStatus(params);
      getDetailInfo({modelingId : props.detailInfo.modelingId}); // 얘가 작동하지 않는거 같은데
      if(inspection === 'INSPECT03'){
        setInspect('INSPECT03')
      } else if(inspection === 'INSPECT04'){
        setInspect('INSPECT04')
      }
    }catch{

    }
  }

  console.log(inspect)

  const refIframeCtrl = useRef<EventIFrameControl>(null);
  useEffect(()=>{
    
  })

  const displayButtons = () => {
    switch (inspect) {
      case "INSPECT02":
        return <>
          <button onClick={()=>completeOrderModeling('INSPECT03')}>수정 요청</button>
          <button onClick={()=>completeOrderModeling('INSPECT04')}>검수 완료</button>
        </>
      case "INSPECT03":
        return <>
          {/* <button>{t("modelingPopup-waitChange")}</button> */}
          <button>수정대기</button> 
        </>
    }
  }
  return (
    <>
      <div className="modal_popUp_5">
        <div className="result">
          <h3>{t("modelingPopup-resultInspection")}</h3>
          <div>
            {/* <IFrameControlCompnent ref={refIframeCtrl} modelingName={"오토바이"} path={"orderModeling/24/TM-Engine.json"} /> */}
            <iframe title='xvl frame' width="100%" height="100%" style={{ position: 'relative' }}
              frameBorder="0" scrolling="no" src={props.detailInfo.iframePath}></iframe>
          </div>
        </div>
        <div className="document">
          <div>
            {displayButtons()}
          </div>
        </div>
        {
          inspect == "INSPECT04" ?
            <>
              {/* <div className="completed">
                <p>{t("modelingPopup-explan1")}</p>
                <p><strong>{t("modelingPopup-explan2")}</strong>{t("modelingPopup-explan3")}</p>
              </div> */}
              <div className="completed" style={completed}>
                  <p style={ptag}>검수 완료된 모델링입니다.</p>
                  <p style={ptag}><strong style={pStrong} onClick={()=>navi('/dashboard/modelingSupple')}>보유 모델링 관리 페이지</strong>에서 확인해주세요.</p>
              </div>
            </>
            :
            <></>
        }
      </div>
    </>
  )
}

export default FifthPopup;

// .modal_popUp .wrap > div .con .body > div:nth-of-type(2).modal_popUp_6_1 .completed{background-color: #F2F2F2; padding: 25px;}
// .modal_popUp .wrap > div .con .body > div:nth-of-type(2).modal_popUp_6_1 .completed p{font-weight: 600; color: #777777; font-size: 14px; line-height: 20px; text-align: center;} 
// .modal_popUp .wrap > div .con .body > div:nth-of-type(2).modal_popUp_6_1 .completed p strong{cursor: pointer; color: #555555; text-decoration: underline; font-weight: 700;}