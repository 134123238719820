import React, { FC, useEffect, useRef, useState } from "react";
import GridSizeSelectBox from "../../../components/grid/gridSizeSelectBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { PageInfo } from "../../../components/grid/gridPageInfo";
import {
  useEditEmployeeMutation,
  useEditRoleMutation,
  useGetEmployeeListMutation,
  useInviteEmployeeMutation,
} from "../../../store/slices/dashboard/dashboardApiSlice";
import { toast } from "react-hot-toast";
import GridSearchBox from "../../../components/grid/gridSearchBox";
import GridColumnHeader from "../../../components/grid/gridColumnHeader";
import Pagination from "../../../components/dashboard/pagination";
import PopupRole from "./popup-role";
import CreatePopup from "./create-popup";
import { elapsedTime } from "../../../utils/toLocaleString";

//김건우
import { useTranslation } from "react-i18next";

export interface RowEmployeeList {
  permission: string;
  name: string;
  email: string;
  departmentName: string;
  positionName: string;
  status: string;
  lastLogin: string;
  idx: number;
}

interface IDashBoardEmployListProps {}

const DashBoardEmployList: FC<IDashBoardEmployListProps> = (props) => {

  //김건우
  const { t } = useTranslation();

  const loginUser: any = useSelector(
    (state: RootState) => state.loginUser.userInfo
  );
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 0,
    size: 10,
    sortColumn: "loginId",
    search: "",
    companyId: loginUser.companyId,
    sortOrder: true,
    lang: "KR",
  });

  const [gridData, setGridData] = useState({
    list: [],
    totalCount: 0,
  });

  const [openRolePopup, setOpenRolePopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [popupType, setPopupType] = useState('create');

  const [roleInfo, setRoleInfo] = useState<RowEmployeeList>({
    permission: "S",
    name: "",
    email: "",
    departmentName: "",
    positionName: "",
    status: "N",
    lastLogin: "",
    idx: 0,
  });

  const [getEmployeeList] = useGetEmployeeListMutation();
  const [sendEnableYn] = useEditEmployeeMutation();
  const [sendRoleYn] = useEditRoleMutation();

  const getGrid = async () => {
    try {
      const response: any = await getEmployeeList(pageInfo);
      if (response.data.result === true) {
        setGridData({
          list: response.data.list,
          totalCount: response.data.totalCount,
        });
      }
    } catch (err) {
      console.log("api fetch error");
    }
  };

  useEffect(() => {
    // 처음 1회 조회
    getGrid();
  }, []);

  useEffect(() => {
    // pageInfo가 바뀔때마다 조회
    getGrid();
  }, [pageInfo]);

  const handlePageChange = (e: any) => {
    setPageInfo({
      ...pageInfo,
      page: e.selected,
    });
  };

  const clickColumnHeader = (columnId: string) => {
    setPageInfo({
      ...pageInfo,
      sortColumn: columnId,
      sortOrder: !pageInfo.sortOrder,
    });
  };

  const clickSizeSelectBox = (size: number) => {
    setPageInfo({
      ...pageInfo,
      size: size,
    });
  };

  const searchKeyword = (keyword: string) => {
    setPageInfo({
      ...pageInfo,
      search: keyword,
    });
  };

  const changeEmployeeYn = async (row: RowEmployeeList) => {
    try {
      await sendEnableYn({
        idx: row.idx,
        status: row.status === "A" ? "N" : "A",
      });
      getGrid();
    } catch (err) {}
  };

  const clickRolePopup = (row: RowEmployeeList) => {
    setRoleInfo(row);
    setPopupType('edit');
    setOpenRolePopup(true);
  };

  const onClickRoleSave = async (params: any) => {
    try {
      await sendRoleYn(params);
      setOpenRolePopup(false);
      setRoleInfo({
        permission: "S",
        name: "",
        email: "",
        departmentName: "",
        positionName: "",
        status: "N",
        lastLogin: "",
        idx: 0,
      });
      getGrid();
    } catch (err) {}
  };

  const onClickRoleCancel = () => {
    setOpenRolePopup(false);
    setRoleInfo({
      permission: "S",
      name: "",
      email: "",
      departmentName: "",
      positionName: "",
      status: "N",
      lastLogin: "",
      idx: 0,
    });
  };

  // 직원 초대 --------------------------------------------------------------------------------------------------------------------------
  const [inviteEmail, setInviteEmail] = useState<any>([]); // 배열
  const [inviteEmails, setInviteEmails] = useState<any>(null); // 배열 -> 문자열
  const companyId = loginUser.companyId; // 유저 회사 아이디
  const userId = loginUser.userId; // 유저 아이디
  const handleAdd = (chip: any) => {
    if (inviteEmail && inviteEmail.length > 9) {
      toast.error(t("employee-list-error"));
      return;
    } else {
      setInviteEmail(inviteEmail.concat(chip));
    }
  };

  const handleDelete = (chip: any, index: any) => {
    const list = inviteEmail.filter((data: any) => data !== chip);
    setInviteEmail(list);
  };

  const [inviteData, { data }] = useInviteEmployeeMutation();

  //직원초대
  const handleInviteEmployee = async () => {
    try {
      const result = await inviteData({ inviteEmails, companyId, userId });
      if (result) {
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setInviteEmails(inviteEmail.join());

  }, [handleAdd, handleDelete]);

  const onClickCreateSave = (params:any) => {

  }

  const onClickCancelSave = () => {
    setOpenCreatePopup(false);
  }

  const handlePrev = () => {
    if (pageInfo.page === 0) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page - 1,
    });
  };
  const handleNext = () => {
    if (pageInfo.page + 1 === Math.ceil(gridData.totalCount / pageInfo.size)) {
      return;
    }
    setPageInfo({
      ...pageInfo,
      page: pageInfo.page + 1,
    });
  };

  useEffect(()=>{
    if(loginUser.companyId){
      setPageInfo({...pageInfo, companyId : loginUser.companyId})
    }
  }, [loginUser.companyId])

  return (
    <>
      <div className="staff_list_container">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3 style={{cursor:'pointer'}}>
                {t("employee-list-main")}<span onClick={()=> setOpenCreatePopup(true)}>{t("employee-list-newEmployee")}</span>
                {/* ()=>setOpenRolePopup(true) */}
                {/* setPopupType('create') */}
              </h3>
            </div>
            <div className="table_area">
              <div>
                <GridSizeSelectBox
                  pageInfo={pageInfo}
                  onClick={clickSizeSelectBox}
                />
                <GridSearchBox onChangeKeyword={searchKeyword} />
              </div>
              <div>
                <table>
                  <caption>{t("employee-list-employeeList")}</caption>
                  <thead>
                    <tr>
                      <GridColumnHeader
                        id="permission"
                        caption={t("employee-list-permission")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="name"
                        caption={t("employee-list-name")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="loginId"
                        caption={t("employee-list-loginId")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="department"
                        caption={t("employee-list-department")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="position"
                        caption={t("employee-list-position")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="lastLogin"
                        caption={t("employee-list-lastLogin")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                      <GridColumnHeader
                        id="status"
                        caption={t("employee-list-status")}
                        pageInfo={pageInfo}
                        onClick={clickColumnHeader}
                      />
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.list.map((row: RowEmployeeList) => {
                      return (
                        <tr>
                          <td>
                            <button onClick={(e)=>clickRolePopup(row)} className={row.permission==="A" ? 'yellow' : 'gray'}>{row.permission}</button>
                          </td>
                          <td>{row.name}</td>
                          <td>{row.email}</td>
                          <td>{row.departmentName}</td>
                          <td>{row.positionName}</td>
                          <td>{elapsedTime(row.lastLogin)}</td>
                          <td>
                            <input
                              onClick={(e) => changeEmployeeYn(row)}
                              type="checkbox"
                              className="toggleIpt"
                              id={`toggle-${row.idx}`}
                              checked={row.status === "A"}
                              hidden
                              onChange={() => {}}
                            />
                            <label
                              htmlFor={`toggle-${row.idx}`}
                              className="toggleSwitch"
                            >
                              <span className="toggleButton"></span>
                            </label>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination prevClick={handlePrev} nextClick={handleNext} totalCount={gridData.totalCount} page={pageInfo.page} size={pageInfo.size} />
              {
                openRolePopup &&
                    <PopupRole
                        roleInfo = {roleInfo}
                        onSave = {onClickRoleSave}
                        onCancel = {onClickRoleCancel}
                        popupType = {popupType}
                    />
              }
              {
                openCreatePopup && 
                  <CreatePopup
                      // onSave={onClickCreateSave}
                      onCancel={onClickCancelSave}
                  /> 
              }

              {/* <div className="staff_popUp">
                <div className="wrap">
                  <div>
                    <div className="con on">
                      <div className="body">
                        <div className="content">
                          <ul>
                            <li>
                              <p>
                                <strong>이름</strong>이창근
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>이메일</strong>adrian@bmcnet.kr
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>부서</strong>사업총괄부
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>직급</strong>이사
                              </p>
                            </li>
                          </ul>
                          <div>
                            <div>
                              <input
                                type="checkbox"
                                name="staff_popUp"
                                id="staff_popUp_1"
                              />
                              <label htmlFor="staff_popUp_1">관리자</label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                name="staff_popUp"
                                id="staff_popUp_2"
                              />
                              <label htmlFor="staff_popUp_2">스텝</label>
                            </div>
                          </div>
                        </div>
                        <div className="buttonBox">
                          <button>취소</button>
                          <button>저장</button>
                        </div>
                      </div>
                    </div>
                    <div className="bg"></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardEmployList;
