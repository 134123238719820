import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://alink3d.com/api/controller/user";
// const baseUrl = "http://akuser.alink3d.com";

export const modelingApiSlice = createApi({
  reducerPath: "modelingApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 리스트에서 모델링 클릭 시, 아이디 남겨줌.
    setUserAssemble : builder.mutation({
      query : (param : any) => {
        const {modelingId, userId, modelingName} = param;
        return{
          url : `/setUserAssemble.php?AssetModelingId=${modelingId}&ModelingName=${modelingName}&UserId=${userId}`,
        }
      }
    }),
    // 제품 스펙정보 가져오기
    getAssembleSpec : builder.mutation({
      query : (param : any) =>{
        const {modelingId} = param;
        return{
          url : `/getAssembleSpec.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 스펙정보 업데이트
    editAssembleSpec : builder.mutation({
      query : (body : any)=>{
        return{
          url : `/uptAssembleSpec.php`, method : 'post' , body
        }
      }
    }),
    // 제품 홍보영상(비디오) 가져오기
    getAssembleVideo : builder.mutation({
      query : (param : any) => {
        const {modelingId} = param;
        return{
          url : `/getAssembleVideo.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 홍보영상(비디오) 저장
    setAssembleVideo : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setAssembleVideo.php`, method : 'post', body
        }
      }
    }),
    // 제품 홍보영상(비디오) 삭제
    delAssembleVideo : builder.mutation({
      query : (param : any) => {
        const {id} = param;
        return {
          url : `/delAssembleVideo.php?idx=${id}`
        }
      }
    }),
    // 제품 카탈로그 가져오기
    getAssembleCatalogue : builder.mutation({
      query : (param : any) => {
        const {modelingId} = param;
        return{
          url : `/getAssembleBrouchure.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 카탈로그 저장
    setAssembleCatalogue : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setAssembleBrouchure.php`,
          method : 'post' , body
        }
      }
    }),
    // 제품 카탈로그 삭제
    delAssembleCatalogue : builder.mutation({
      query : (param : any) => {
        const {id} = param;
        return{
          url : `/delAssembleBrouchure.php?idx=${id}`
        }
      }
    }),

    // 제품 실적 가져오기
    getAssemblePerformance : builder.mutation({
      query : (param : any) => {
        const {modelingId} = param;
        return{
          url : `/getAssemblePerformance.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 실적 저장
    setAssemblePerformance : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setAssemblePerformance.php`,
          method : 'post', body
        }
      }
    }),
    // 제품 실적 삭제
    delAssemblePerformance : builder.mutation({
      query : (param : any) => {
        const { id } = param;
        return{
          url : `/delAssemblePerformance.php?idx=${id}`
        }
      }
    }),
    // 제품 트러블 슈팅 가져오기
    getAssembleTrouble : builder.mutation({
      query : (param : any) => {
        const {modelingId} = param;
        return{
          url : `/getAssembleTrouble.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 트러블 슈팅 저장
    setAssembleTrouble : builder.mutation({
      query : (body : any) => {
        return{
          url : `/setAssembleTrouble.php`,
          method : 'post', body
        }
      }
    }),
    // 제품 트러블 슈팅 삭제
    delAssembleTrouble : builder.mutation({
      query : (param : any) => {
        const {id} = param;
        return{
          url : `/delAssembleTrouble.php?idx=${id}`
        }
      }
    }),
    // 제품 메모 가져오기
    getAssembleMemo : builder.mutation({
      query : (param : any) => {
        const {modelingId} = param
        return{
          url : `/getAssembleMemo.php?AssetModelingId=${modelingId}`
        }
      }
    }),
    // 제품 메모 저장
    setAssembleMemo : builder.mutation({
      query : (body : any) =>{
        return{
          url : `/setAssembleMemo.php`, method: 'post', body
        }
      }
    }),
    // 제품 메모 삭제
    delAssembleMemo : builder.mutation({
      query : (param : any) => {
        const {id} = param;
        return{
          url : `/delAssembleMemo.php?idx=${id}`
        }
      }
    }),
    // 제품 메모 수정
    uptAssembleMemo : builder.mutation({
      query : (body : any) => {
        return{
          url : `/uptAssembleMemo.php`, method: 'post', body
        }
      }
    })
  }),
});

export const {
  useSetUserAssembleMutation, useGetAssembleSpecMutation, useEditAssembleSpecMutation, useGetAssembleVideoMutation, useSetAssembleVideoMutation,
  useDelAssembleVideoMutation, useGetAssembleCatalogueMutation, useSetAssembleCatalogueMutation, useGetAssemblePerformanceMutation, useDelAssembleCatalogueMutation,
  useSetAssemblePerformanceMutation, useDelAssemblePerformanceMutation, useSetAssembleTroubleMutation, useGetAssembleTroubleMutation, useDelAssembleTroubleMutation,
  useSetAssembleMemoMutation, useGetAssembleMemoMutation, useDelAssembleMemoMutation, useUptAssembleMemoMutation
} = modelingApiSlice;