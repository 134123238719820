import React, { FC, useRef } from 'react';

//김건우
import { useTranslation } from "react-i18next";

interface IDashboardCompanyLogoProps {
    logoChange : any;
    preview : any;
    disabled : boolean;
    deleteLogo : ()=> void;
}

const DashboardCompanyLogo:FC<IDashboardCompanyLogoProps> = (props) => {

    //김건우
    const { t } = useTranslation();

    const { logoChange, preview, deleteLogo } = props;
    console.log(props.preview)
    const fileRef = useRef<any>(null);

    const handleAddLogo = () => {
        fileRef.current.click();
    }

    const handleChangeLogo = (e:any) => {
        logoChange(e.target.files ? e.target.files[0] : "");
        e.target.value = "";
    }

    return (
        <>
            <div className="company_logo">
                <h4>{t("company-logo-main")} <span>*</span></h4>
                <div>
                    <div>
                        <img src={preview ? preview : "/assets/images/dsbd/no-logo.png" }/>
                    </div>
                    <div>
                        <p>{t("company-logo-explan")}
                            <span className="gray">* Files supported : PNG, JPEG</span>
                        </p>
                        <div className="buttonBox">
                            <button onClick={handleAddLogo} disabled={props.disabled}>{t("company-logo-regist")}
                            </button>
                            <input onChange={handleChangeLogo} ref={fileRef} type="file" style={{display:"none"}}/>
                            <button onClick={deleteLogo} disabled={props.disabled}>{t("company-logo-remove")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardCompanyLogo;