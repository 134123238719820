import React from 'react';
import type { FC } from 'react';

interface SelectFilterProps {
  checked: Boolean;
  text: string;
  onClick: Function;

  title?: string;
  filter?: any;
  value?: number;
}

export const SelectFilter: FC<SelectFilterProps> = (props) => {
  return (
    <>
      <li style={props.checked ? { whiteSpace: 'normal' , color: '#256DCB' } : { whiteSpace: 'nowrap' }} onClick={() => props.onClick()}>
        <span>{props.text}</span>
      </li>
    </>
  )
}