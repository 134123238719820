import { FC } from 'react';
import { groupHeaderList } from '../../constant';
import { ModelingOrderType } from '../../types/modelingOrderType';
import GroupColumnHeader from './groupColumnHeader';

interface IGroupListHeader {
  type: string;
  pageInfo: any;
  headerClick: (data: { key: string, title: string }) => void;
  list: ModelingOrderType[];
}

const GroupListHeader: FC<IGroupListHeader> = (props) => {

  const { type, pageInfo, headerClick, list } = props;
  const header: { key: string, title: string } = groupHeaderList[type];

  const getContent = (header: any) => {

    let left: Array<{ key: string, title: string }> = [];
    let right: Array<{ key: string, title: string }> = [];
    header.map((x: { key: string, title: string }, index: number) => {
      if (index < 2)
        left.push(x);
      else
        right.push(x);
    })

    return (
      <>
        <div className="receipt-name">
          {
            left.map((data, index: number) => (
              <div key={data.key} style={data.key === "subject" ? { marginLeft: 0, minWidth: '150px' } : { marginLeft: 0 }} className={index === 0 || index === 2 ? 'status' : 'title'}>
                <GroupColumnHeader type="left" data={data} headerClick={headerClick} pageInfo={pageInfo} />
              </div>
            ))
          }
        </div>
        <div className="receipt-field">
          {
            right.map((data) => (
              <div key={data.key}>
                <GroupColumnHeader data={data} headerClick={headerClick} pageInfo={pageInfo} />
              </div>
            ))
          }
        </div>
      </>
    )
  }

  if (!list || list.length === 0) {
    return <></>
  }

  return (
    <>
      <div className="receipt-header">
        {
          getContent(header)
        }
      </div>
    </>
  )
}

export default GroupListHeader;