import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsVideoMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";

export interface Props {getVideo:any,partsName : string, partsId : string; onClose:any}
const PartsVideoRegist:React.FC<Props> = ({getVideo, partsName, partsId, onClose}) => {
    const {modelingId} = useParams()
    const userData:any = useAppSelector(state=>state.loginUser.userInfo)
    const [setPartsVideoApi] = useSetPartsVideoMutation()
    const [videoInfo, setVideoInfo] = useState<any>({modelingId : modelingId , partsName : partsName, partsId : partsId, lang : 'KR', videoUrl : '', videoSubject: '',videoDescription:''})
    const handleChange = (e:any) => {
        const {name, type, value} = e.target;
        if(type==='textarea'){setVideoInfo({...videoInfo, [name] : value})}
        else {setVideoInfo({...videoInfo, [name] : value})}
    }
    const handleLangChange = (lang:string) => {
        setVideoInfo((prev:any) => ({...prev, lang : lang}))
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('idx', partsId)
        formData.append('AssetModelingId', modelingId)
        formData.append('UserId', userData.userId)
        formData.append('part_unique_id', partsName)
        formData.append('parts_video_url', videoInfo.videoUrl)
        formData.append('parts_video_lang', videoInfo.lang)
        formData.append('parts_video_title', videoInfo.videoSubject)
        formData.append('parts_video_description', videoInfo.videoDescription)
        const result : any = await setPartsVideoApi(formData)
        if(result.data.result === true) {getVideo(); onClose()}
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>관련영상 업로드</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.langBoxWrap}>
                        <div className={PageStyles.langBox}>
                            <span className={[PageStyles.lang, PageStyles.lang_KR, videoInfo.lang==='KR'?PageStyles.active:''].join(" ")} onClick={()=>handleLangChange('KR')}>KR</span>
                            <span className={[PageStyles.lang, PageStyles.lang_EN, videoInfo.lang==='EN'?PageStyles.active:''].join(" ")} onClick={()=>handleLangChange('EN')}>EN</span>
                        </div>
                        <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 영상 업로드가 가능합니다.</p>
                    </div>

                    <table className={[PageStyles.kr, PageStyles.active].join(" ")}>
                        <tr>
                            <td className={PageStyles.admin_input_underline}>
                                <input type="text" name="videoUrl" id="parts_video_url" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 링크를 기입해 주세요.":"홍보영상(영문) 링크를 기입해 주세요."} onChange={handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={PageStyles.admin_input_border}>
                                <input type="text" name="videoSubject" id="parts_video_title_kr" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 제목을 입력해 주세요.":"홍보영상(영문) 제목을 입력해 주세요."} onChange={handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={PageStyles.admin_input_border}>
                                <textarea name="videoDescription" id="parts_video_description_kr" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 설명을 기입해 주세요.":"홍보영상(영문) 설명을 기입해 주세요."} onChange={handleChange}></textarea>
                            </td>
                        </tr>
                    </table>

                </div>


                <div className={PageStyles.frmPartVideo_btnBox}>
                    <div className={[PageStyles.frmPartVideo_cancleBtn, PageStyles.cancle].join(" ")} onClick={onClose}>취소</div>
                    <button type="button" id="gofrmPartVideoSubmit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsVideoRegist;