import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import MessageDate from './message-date';
import MessageChat from './message-chat';

// 리펙토링 : MessageList 컴포넌트가 메시지를 받도록 할 것
const MessageList = ( props : any ) => {

  // 날짜단위로 메시지를 구분하며 배열을 만듬
  const makeSection = (items: any) => {
    const dateObj: any = {};
    items.forEach((data: any) => {
      const monthData = dayjs(data.registeredDate).format('YYYY-MM-DD');
      if (Array.isArray(dateObj[monthData])) {
        dateObj[monthData].push(data);
      } else {
        dateObj[monthData] = [data];
      }
    })

    return dateObj;
  }

  const dateArray = makeSection(props.message ?? []);

  return (
    <>
      <div style={{ height: 600, display: 'flex', flexDirection: 'column-reverse', overflowY: 'auto' }} ref={props.innerRef} id="scrollableDiv">
        <InfiniteScroll
          dataLength={props.message.length}
          // pullDownToRefreshThreshold={50}
          next={props.getItems}
          style={{ display: 'flex', flexDirection: 'column-reverse', overflowY: 'hidden' }}
          inverse={true}
          hasMore={true}
          loader={<h4>...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {
            Object.entries(dateArray).map(([date, chat]: any) => {
              return (
                <>
                  {
                    chat.map((x: any) => (
                      <MessageChat data={x} />
                    ))
                  }
                  <MessageDate date={date} />
                </>
              )
            })
          }
        </InfiniteScroll>
      </div>
    </>
  )
}

export default MessageList;