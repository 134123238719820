import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://alink3d.com/api/controller/user";
// const baseUrl = "http://akuser.alink3d.com";

export const modelingIotApiSlice = createApi({
  reducerPath: "modelingIotApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 리스트에서 모델링 클릭 시, 아이디 남겨줌.
    getModelingIot : builder.mutation({
      query : (param : {modelingId : any}) => {
        return{
          url : `/getIotInfo.php?AssetModelingId=${param.modelingId}`,
        }
      }
    }),
  }),
});

export const {
  useGetModelingIotMutation
} = modelingIotApiSlice;