import React from "react";
import moduleStyles from '../project.module.css'
export const ItemProjectBox = () => {

    return(
        <>
        <td className={`${moduleStyles.first_child} ${moduleStyles.titleBox}`}><span className={moduleStyles.triangle}></span>
            <div className={moduleStyles.titleBox_flexBox}>
                <div className={moduleStyles.txtBox}>
                    <div className={`${moduleStyles.starBox} ${moduleStyles.blue}`}>
                        {/* <!-- <i className="fa-light fa-star"></i> --> */}
                        <i className="fa-solid fa-star"></i>
                        {/* <!-- 클릭했을 때 별 --> */}
                        <span>PROJECT-AXD84382-DFS</span>
                    </div>
                </div>
            </div>
        </td>
        </>
    )
}