import React, { FC } from 'react';
import { groupHeaderList } from '../../constant';
import GroupColumnHeader from './groupColumnHeader';

interface IGroupListHeader {
  type: string;
  headerClick: any;
  pageInfo: any;
}

const SuppleGroupListHeader: FC<IGroupListHeader> = (props) => {

  const { type, headerClick, pageInfo } = props;
  const header: string[] = groupHeaderList[type];

  const getContent = (header: any) => {

    let left: any = [];
    let right: any = [];
    header.map((x: any, index: number) => {
      if (index < 2)
        left.push(x);
      else
        right.push(x);
    })

    return (
      <>
        <div className="receipt-name">
          {
            left.map((data: any, index: number) => (
              <div style={{ marginLeft: 0 }} className={index === 0 ? 'status' : 'title'}>
                <GroupColumnHeader type="left" data={data} headerClick={headerClick} pageInfo={pageInfo} />
              </div>
            ))
          }
        </div>
        <div className="receipt-field">
          {
            right.map((data: any) => (
              <GroupColumnHeader data={data} headerClick={headerClick} pageInfo={pageInfo} />
            ))
          }
        </div>
      </>
    )
  }


  return (
    <>
      <div className="receipt-header">
        {
          getContent(header)
        }
      </div>
    </>
  )
}

export default SuppleGroupListHeader;