export const toLocaleString = (value:string) => {
    return Number(value).toLocaleString('en');
}

export function elapsedTime(date : string) {
  const start = new Date(date);
  const end = new Date();

  const diff =  (end.getTime() - start.getTime()) / 1000;
  
  const times = [
    { name: '년', milliSeconds: 60 * 60 * 24 * 365 },
    { name: '개월', milliSeconds: 60 * 60 * 24 * 30 },
    { name: '주', milliSeconds: 60 * 60 * 24 * 7 },
    { name: '일', milliSeconds: 60 * 60 * 24 },
    { name: '시간', milliSeconds: 60 * 60 },
    { name: '분', milliSeconds: 60 },
  ];

  for (const value of times) {
    const betweenTime = Math.floor(diff / value.milliSeconds);

    if (betweenTime > 0) {
      return `${betweenTime}${value.name} 전`;
    }
  }
  return '방금 전';
}