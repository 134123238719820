import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PartsDrawingRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_drawingRegist";
import { useGetPartsDrawingMutation, useUptPartsDrawingMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props {partsName : string; partsId : string}
const PartsDrawing:React.FC<Props> = ({partsName, partsId}) => {
    const {modelingId} = useParams()
    const [getPartsDrawingApi] = useGetPartsDrawingMutation();
    const [uptPartsDrawingApi] = useUptPartsDrawingMutation()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [drawingList, setDrawingList] = useState<any>([]);
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }

    const getPartsDrawing = async() => {
        const param = {modelingId : modelingId, id : partsId}
        const result : any = await getPartsDrawingApi(param);
        if(result.data.result === true){
            setDrawingList(result.data.drawing_info)
        }
    }
    const handleFileDown = (file : string) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    const handleEditOpenYn = async(idx : any, yn:string) => {
        const param = {openYn : yn==='Y'?'N':'Y' , idx : idx}
        const confirm = window.confirm(yn === 'Y'?"비공개로 변경하시겠습니끼?":"공개로 변경하시겠습니끼?");
        if(confirm){
            const result : any = await uptPartsDrawingApi(param);
            if(result.data.result === true){getPartsDrawing()}
        }
        
    }
    useEffect(()=>{
        getPartsDrawing()
    },[partsId])
    // 모델링이 변경될 때마다 팝업창이 닫혀야 한다.
    useEffect(()=>{
        setIsOpen(false)
    }, [partsName])

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_2, PageStyles.active].join(" ")}>
								
            <div className={PageStyles.file_historyBox} id="#file_historyBox">
                {drawingList?.map((data:any)=> {
                    return(
                    <>
                    <div className={PageStyles.file_historyList} key={data.idx}>
                    <div>
                        <div className={PageStyles.file_titleBox}>
                            <p className={[data.part_drawing_publish==='Y'?PageStyles.blue:PageStyles.red, PageStyles.unlock].join(" ")}>
                                <span className={PageStyles.ball}></span>
                                <a target="_black" onClick={()=>handleFileDown(data.part_drawing_pdsFilename)}>{data.part_drawing_pdsFile}</a>
                                <span className={data.part_drawing_publish==='Y'?PageStyles.unlock:PageStyles.lock} onClick={()=>handleEditOpenYn(data.idx,data.part_drawing_publish)}>{data.part_drawing_publish==='Y'?'공개':'비공개'}</span>
                            </p>
                                <span className={PageStyles.file_history_date}>{data.part_drawing_mdate}</span></div></div><div className={PageStyles.commentBox}>
                                <span className={PageStyles.comment_icon}><img src="/assets/images/modelingCtrl/popUp_comment.png" alt="comment icon"/></span>
                            <p>
                            <span>{data.part_drawing_comments}</span>
                            </p>
                        </div>
                    </div>
                    </>
                    )
                })}
                
            </div>
            {isOpen &&
            <PartsDrawingRegist getDrawing={getPartsDrawing} partsName={partsName} partsId={partsId} onClose={handleOpen}/>
            }
            <button className={[PageStyles.blueAddBtn, PageStyles.frmPartDrawingAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
        </div>
        </>
    )
}

export default PartsDrawing;