import { useState, useEffect } from 'react';
import { phoneNumberAutoFormat } from '../utils/phoneAutoFormat';

const useInput = ({ initialValue, onSubmit, validate}:any) => {
    const [values, setValues] = useState(initialValue);
    const [errors, setErros] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name , value } = e.target;
        let formatValue = value;
        if(name === "phoneNumber") {
            formatValue = phoneNumberAutoFormat(value);
        } 

        setValues({
            ...values,
            [name]: formatValue
        })
    }

    const handleSubmit = async (e:any) => {
        setSubmitting(true);
        setErros(validate(values));
    }

    useEffect(()=> {
        if(submitting) {
            if(Object.keys(errors).length === 0) {
                onSubmit(values);
            }
            setSubmitting(false);
        }
    },[errors])

    return {
        values,
        errors,
        submitting,
        handleChange,
        handleSubmit
    }
}

export default useInput;