import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Profiler } from "inspector";
import { stringify } from "querystring";

export const memberApi: any = createApi({
  reducerPath: 'memberApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'http://210.114.19.235/api',
  }),

  endpoints: (builder: any) => ({
    // mutation :: 데이터 조작 정의 ( post, put, delete, patch)

    // 로그인
    loginUser: builder.mutation({
      query: (body: { mp_login_id: string; mp_dumie_password: string; }) => {
        return {
          url: "https://alink3d.com/api/controller/member/loginP.php",
          method: "post",
          body
        };
      },
    }),

    // loginUser: builder.mutation({
    //   query: (body: { mp_login_id: string; mp_dumie_password: string; }) => {
    //     return {
    //       url: "/user/login?mp_login_id=" + body.mp_login_id + "&mp_dumie_password=" + body.mp_dumie_password,
    //       method: "get",
    //     };
    //   },
    // }),

    // 유저 정보 가져오기
    getterUserInfo : builder.mutation({
      query : (param : {userId : number}) => {
        const {userId} = param;
        return{
          url : `https://alink3d.com/api/controller/member/getUserInfo.php?userId=${userId}`,
          method : 'get'
        }
      }
    }),

    // 유저 정보 조회 ( 세션 ) - 당분간은 사용하지 않을 것
    getUserInfo: builder.mutation({
      query: (userId: number) => {
        let requestUrl = "/user/getUserinfo";
        if( userId != 0 ) {
          requestUrl += `?userId=${userId}`;
        }
        console.log(requestUrl);

        return {
          url: requestUrl,
          method: "get",
        };
      },
    }),

    // 회원가입
    joinUser: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_dumie_password: string;
        mp_firstname_kr: string; mp_lastname_kr: string;
        mp_user_mobile: string; mp_user_consent_to_use_of_personal_info: boolean;
      }) => {
        return {
          url: "https://alink3d.com/api/controller/member/joinP.php",
          method: "post",
          body,
        };

      },
    }),

    // 비밀번호 찾기
    findPw: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_user_mobile: string; mp_firstname_kr: string; mp_lastname_kr: string;
      }) => {
        return {
          url: "https://alink3d.com/api/controller/user/findPwP.php?mp_login_id=" + body.mp_login_id + "&mp_user_mobile=" + body.mp_user_mobile + "&mp_firstname_kr=" + body.mp_firstname_kr + "&mp_lastname_kr=" + body.mp_lastname_kr,
          method: "get"
        };
      }
    }),

    // 아이디 찾기
    findId: builder.mutation({
      query: (body: {
        mp_user_mobile: string; mp_firstname_kr: string; mp_lastname_kr: string;
      }) => {
        return {
          url: "https://alink3d.com/api/controller/member/findIdP.php",
          method: "post",
          body
        };
      }
    }),

    // 회원정보 수정 페이지 입장 시, 회원 정보 및 기본 데이터 출력
    userInfo: builder.mutation({
      query: (param: {
        userId: string;
      }) => {
        const {userId} = param;
        return {
          url: `https://alink3d.com/api/controller/member/selectUserP.php?userId=${userId}`,
          method: "get",
        };
      }
    }),

    // 추가 회원 정보 수정
    userModify: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_user_biography_kr: string; 
        mp_user_phone: string;
        mp_user_fax: string; mp_user_country: string; mp_email_signature: string;
        mp_user_position_kr: string; mp_user_department_kr: string; mp_user_roletype_id: string;
        mp_user_instagram: string; mp_user_youtube: string; mp_user_facebook: string; mp_user_twitter: string;
        mp_user_linkedin: string;
      }) => {
        return {
          url: `https://alink3d.com/api/controller/member/changeAccinfoP.php?mp_login_id=${body.mp_login_id}&mp_user_biography_kr=${body.mp_user_biography_kr}&mp_user_phone=${body.mp_user_phone}
          &mp_user_fax=${body.mp_user_fax}&mp_user_country=${body.mp_user_country}&mp_email_signature=${body.mp_email_signature}&mp_user_position_kr=${body.mp_user_position_kr}
          &mp_user_department_kr=${body.mp_user_department_kr}&mp_user_roletype_id=${body.mp_user_roletype_id}&mp_user_instagram=${body.mp_user_instagram}
          &mp_user_youtube=${body.mp_user_youtube}&mp_user_facebook=${body.mp_user_facebook}&mp_user_twitter=${body.mp_user_twitter}&mp_user_linkedin=${body.mp_user_linkedin}`,
          method: "get",
        };
        // return{
        //   url : `http://akadmin.alink3d.com/api/controller/member/changeAccinfoP.php`,
        //   method : 'post',
        //   body
        // }
      }
    }),

    // 비밀번호 변경
    userChngePassword: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_dumie_password: string;
      }) => {
        return {
          //url : "/user/profile/changePW?mp_login_id=" + body.mp_login_id + "&mp_dumie_password=" + body.mp_dumie_password ,
          url: "https://alink3d.com/api/controller/member/changePwP.php",
          method: "post",
          body,
        };
      }
    }),

    // 구독하기
    userNewsLetterSubscribe: builder.mutation({
      query: (body: {
        mp_login_id: string;
      }) => {
        return {
          url: "/user/profile/subscribe",
          method: "post",
          body,
        };
      }
    }),

    // 구독취소
    userNewsLetterSubscribeCancle: builder.mutation({
      query: (body: {
        mp_login_id: string;
      }) => {
        return {
          url: "/user/profile/unsubscribe",
          method: "post",
          body,
        };
      }
    }),

    // 회원 이름 (성.이름) 변경
    userNameModify: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_firstname_kr: string; mp_lastname_kr: string;
      }) => {
        return {
          url: "https://alink3d.com/api/controller/member/changeNameP.php",
          method: "post",
          body,
        }
      }
    }),
    // 휴대 전화 변경
    userMobileModify: builder.mutation({
      query: (body: {
        mp_login_id: string; mp_user_mobile: string;
      }) => {
        return {
          url: "https://alink3d.com/api/controller/member/changeMobileP.php",
          method: "post",
          body,

        }
      }
    }),
    // 프로필 사진 변경 (업로드) /user/image/upload
    userProfileImageModify: builder.mutation({
      query: (body: any) => {
        return {
          url : "https://alink3d.com/api/controller/member/profileUploadP.php",
          // url: "/user/image/upload",
          method: "post",
          body,
        }
      }
    }),

    // 기업 정보 조회 company/selected
    selectCompany: builder.mutation({
      query: (body: {
        mp_company_id: string;
      }) => {
        return {
          url: '/company/selected?mp_company_id=' + body.mp_company_id,
          method: 'get'
        }
      }
    }),

    // 초대 회원 정보 표시
    getInviteInfo: builder.query({
      query: (params: any) => {
        const { inviteInfo } = params;
        return {
          url: `https://alink3d.com/api/controller/employee/getInviteInfo.php?inviteUser=${inviteInfo.inviteUser}&companyId=${inviteInfo.companyId}`,
          method: "get",
        }
      }
    }),

    // 초대 회원 가입
    inviteJoin: builder.mutation({
      query: (body: {
        senderId: string; mp_dumie_password: string;
        mp_firstname_kr: string; mp_lastname_kr: string;
        mp_user_mobile: string; mp_user_consent_to_use_of_personal_info: boolean;
        companyId: any
      }) => {
        return {
          url: "https://alink3d.com/api/controller/employee/InviteJoin.php?loginId=" + body.senderId + "&companyId=" + body.companyId + "&firstName=" + body.mp_firstname_kr + "&lastName=" + body.mp_lastname_kr
            + "&mobile=" + body.mp_user_mobile + "&password=" + body.mp_dumie_password + "&agreement=" + body.mp_user_consent_to_use_of_personal_info,
          method: "get",
        }
      }
    })
  })
})
// psdfileFile :: 임의의 난수 처리
// psdfileFileName :: 실제 파일명

export const { useLoginUserMutation, useJoinUserMutation, useFindPwMutation, useGetterUserInfoMutation,
  useFindIdMutation, useUserInfoMutation, useUserModifyMutation,
  useUserChngePasswordMutation, useUserNewsLetterSubscribeMutation,
  useUserNewsLetterSubscribeCancleMutation, useUserNameModifyMutation,
  useUserMobileModifyMutation, useUserProfileImageModifyMutation, useSelectCompanyMutation,
  useGetUserInfoMutation, useGetInviteInfoQuery, useInviteJoinMutation } = memberApi;