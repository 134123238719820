import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsMemoMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";

export interface Props {getMemo : any;partsName : string; partsId : string;onClose:any}
const PartsMemoRegist:React.FC<Props> = ({getMemo,partsName, partsId, onClose}) => {
    const {modelingId}:any = useParams()
    const userData : any = useAppSelector(state=>state.loginUser.userInfo)
    const [setPartsMemoApi] = useSetPartsMemoMutation()
    const [memoInfo, setMemoInfo] = useState<any>({memoComment : '', memoOpenYn : 'Y'})
    const handleChange = (e:any) =>{
        const {value, name} = e.target;
        setMemoInfo({...memoInfo, [name]:value})
    }
    const handleOpenYn = (yn:string) => {
        setMemoInfo((prev:any) =>({...prev, memoOpenYn : yn}))
    }
    const handleSave = async() => {
        const formData = new FormData();
        formData.append('UserId', userData.userId)
        formData.append('AssetModelingId', modelingId)
        formData.append('part_unique_id', partsName)
        formData.append('idx', partsId)
        formData.append('parts_memo_contents', memoInfo.memoComment)
        formData.append('parts_memo_publish', memoInfo.memoOpenYn)
        const result : any = await setPartsMemoApi(formData)
        if(result.data.result === true){
            alert("등록이 완료되었습니다."); getMemo(); onClose()
        }
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>부품 메모</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <span className={PageStyles.title}>메모</span>
                    <div>
                        <textarea name="memoComment" id="parts_memo_contents" onChange={handleChange}></textarea>
                    </div>
                    <div className={PageStyles.radioBox}>
                        <div>
                            <input type="radio" name="parts_memo_publish" id="parts_memo_publish_open" onChange={()=>handleOpenYn('Y')} checked={memoInfo.memoOpenYn==='Y'?true:false}/>
                            <label htmlFor="parts_memo_publish_open">공개</label>
                        </div>
                        <div>
                            <input type="radio" name="parts_memo_publish" id="parts_memo_publish_close" onChange={()=>handleOpenYn('N')} checked={memoInfo.memoOpenYn==='N'?true:false}/>
                            <label htmlFor="parts_memo_publish_close">비공개</label>
                        </div>
                    </div>
                </div>


                <div className={PageStyles.memo_btnBox}>
                    <div className={[PageStyles.memo_cancleBtn, PageStyles.cancle].join(" ")} onClick={onClose}>취소</div>
                    <button type="button" className={PageStyles.dots_parts_memo_submit} id="dots_parts_memo_submit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsMemoRegist;