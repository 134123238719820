import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState:any = {
    name: ''
}

export const xvlSlice = createSlice({
    name:'xvSlice',
    initialState,
    reducers: {
        checkData(state,action) {
            console.log(action.payload);
            state.name = action.payload.id;
        }
    }
});

export const { checkData } = xvlSlice.actions;
export default xvlSlice.reducer;