import React, { FC } from 'react';
import IFrameControlCompnent from '../../../../components/xvl/IFrameControl';

// 클립보드 샘플
// export const copyClipboard = async (
//   text: string,
//   successAction?: () => void,
//   failAction?: () => void,
// ) => {
//   try {
//     await navigator.clipboard.writeText(text);
//     successAction && successAction();
//   } catch (error) {
//     failAction && failAction();
//   }
// };

interface IFirstSubPopup {
  detailInfo: any;
  selectedPopupInfo: any;
}

const FirstSupPopup: FC<IFirstSubPopup> = (props) => {

  // util folder로 옮길 것
  const copyClipboard = async() => {
    await navigator.clipboard.writeText(props.detailInfo.modelingIframePath);
  }

  return (
    <>
      <div className="modal_popUp_6">
        <div className="modelling">
          <div className="scrap_area">
            <button onClick={copyClipboard} className="active"></button>
            {/* 
            <div>
              <input type="text" name="" id="" placeholder="iframe을 입력해 주세요." />
              <button></button>
            </div> 
            */}
          </div>
          <h3>{props.detailInfo.modelingName}</h3>
          <div className="modelling_image">
          <iframe title='xvl frame' width="100%" height="100%" style={{ position: 'relative' }}
              frameBorder="0" scrolling="no" src={props.detailInfo.iframePath}>

              </iframe>
            {/* <IFrameControlCompnent modelingId={props.detailInfo.modelingId} modelingName={props.detailInfo.modelingName} /> */}
          </div>
          <div className="management_menu">
              <ul>
                  
              </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default FirstSupPopup;

function asnyc() {
  throw new Error('Function not implemented.');
}
