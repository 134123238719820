import { Link } from "react-router-dom";

const MemberJoinComplete = () => {
  return (
    <>
      <header><title>ALINK3D : 회원가입</title></header>
      <div id="wrap" className="login-wrap">
        <div className="container">
          <div className="con-w1400">
            <div className="login-logo"><img src="/assets/images/logo-w180.png" alt="" /></div>
            <div className="signup-box">
              <div className="signup-t1">회원가입을 축하드립니다.</div>
              <div className="signup-t2">
                회원가입이 완료되었습니다. <br />
                로그인 페이지로 돌아가 로그인을 해주세요.
              </div>
              <div className="signup-t3">
                <a href="" className="link-qa">궁금하신 사항이 있으신가요?</a>
              </div>
              <div className="submit-row">
                <div className="col">
                  <Link to='/memberLogin'>
                    <button type="button" className="blue">로그인 하러가기</button>-
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="login-footer">
          <div className="con-w1400">
            <div className="f-t">
              <div className="flex-list">
                <ul>
                  <li><a href="">이용약관</a></li>
                  <li><a href="" className="c-f79e00">개인정보처리방침</a></li>
                  <li><a href="">3D 모델링 보안 방침</a></li>
                  <li className="lang">
                    <div className="fdropbox">
                      <div className="fdropbox-name">한국어</div>
                      <div className="fdropbox-value">
                        <div className="value"><a href="">한국어</a></div>
                        <div className="value"><a href="">English</a></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="f-b">
              <div className="copyright">
                COPYRIGHT<b>ⓒALINK3D</b> ALL RIGHTS RESERVED.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default MemberJoinComplete;