import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "http://akadmin.alink3d.com/api/controller";
// const baseUrl = "http://akuser.alink3d.com";

export const marineConnect = createApi({
  reducerPath: "marineConnect",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 공개 여부 Y 시, 마린플라자에 데이터 전달
    setAssetToMp2024 : builder.mutation({
      query : (param : {modelingId:string,modelingName:string,companyId:number,modelingPath:string}) => {
        return{
          url : `https://alink3d.com/api/controller/mp2024/setAssetToMP2024.php?AssetModelingId=${param.modelingId}&ModelingName=${param.modelingName}&CompanyId=${param.companyId}&ModelingPath=${param.modelingPath}`,
        }
      }
    }),
  }),
});

export const { useSetAssetToMp2024Mutation
} = marineConnect;