import React, { FC } from "react";
import ReactQuill, { Quill } from 'react-quill';
import './quillcss_modeling.css';
import ImageResize from 'quill-image-resize-module-react';

interface EditorProps {
    value: string;
    onChange: any
}

const QuillEditorModeling: FC<EditorProps> = (props) => {
    Quill.register('modules/ImageResize', ImageResize);
    const { value, onChange } = props;

    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'link'],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] }],
                ['image', 'video'],
                ['clean']
            ],
        },
        ImageResize: {
            parchment: Quill.import('parchment'),
            modules: ["Resize"]
        },
        
    };

    return (
        <>
            <ReactQuill
                value={value}
                onChange={onChange}
                modules={modules}
                theme="snow"
            />
        </>
    )
}

export default QuillEditorModeling;