import { useCallback, useState, FC, useEffect, useRef } from 'react';
import { useGetOrderModelFileListMutation } from '../../../../store/slices/dashboard/dashboardModelingOrderApiSlice';
import { ModelingOrderDetailType, ModelingOrderType } from '../../../../types/modelingOrderType';
import FirstSupPopup from '../dashboard-supple-popup/firstPopup';
import SecondSubPopup from '../dashboard-supple-popup/secondSubPopup';
import ThirdSupPopup from '../dashboard-supple-popup/thirdPopup';
import FifthPopup from './fifthPopup';
import FirstPopup from './firstPopup';
import FourthPopup from './fourthPopup';
import ModelingPopupHeader from './modeling-popup-header';
import ModelingPopupRoute from './modeling-popup-route';
import ModelingPopupSideContent from './modeling-popup-sideContent';
import SecondPopup from './secondPopup';
import ThirdPopup from './thirdPopup';

interface IDashboardModelingMainPopupProps {
  closePopup: any;
  selectedPopupInfo: ModelingOrderType | any;
  detailInfo: ModelingOrderDetailType | any;
  type: string;
  getDetailInfo: Function
}

const DashboardModelingMainPopup: FC<IDashboardModelingMainPopupProps> = (props) => {

  const { closePopup, selectedPopupInfo, detailInfo, type , getDetailInfo } = props;
  const popupRef = useRef<HTMLDivElement>(null);

  const [popupNumber, setPopupNumber] = useState('0');
  const handleChangePopup = (popupNumber: string) => {
    setPopupNumber(popupNumber);
  }

  const getConents = useCallback(() => {
    switch (popupNumber) {
      case "0":
        return <FirstPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      case '1':
        return <SecondPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      case "2":
        return <ThirdPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      case "3":
        return <FourthPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      case "4":
        return <FifthPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} getDetailInfo={getDetailInfo} />
      default:
        return <></>
    }
  }, [popupNumber])

  const getSupContent = useCallback(() => {
    switch (popupNumber) {
      case "0":
        return <FirstSupPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />;
      case '1':
        return <SecondSubPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      case "2":
        return <ThirdSupPopup detailInfo={detailInfo} selectedPopupInfo={selectedPopupInfo} />
      default:
        return <></>
    }
  }, [popupNumber])

  useEffect(() => {
    document.body.style.cssText = `overflow: hidden`;
    return () => {
      document.body.style.cssText = `overflow: auto`;
    }
  }, [])

  useEffect(() => {
    const handleOutSide = (e: any) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        props.closePopup();
      }
    }
    document.addEventListener('mousedown', handleOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOutSide);
    }
  }, [popupRef])

  return (
    <>
      <div id="modal" className="modal_popUp active">
        <div className="wrap">
          <div>
            <div className="con on">
              <div ref={popupRef}>
                <ModelingPopupHeader selectedPopupInfo={selectedPopupInfo} closePopup={closePopup} />
                <div className="body">
                  <ModelingPopupRoute type={type} popupNumber={popupNumber} onClick={handleChangePopup} visibleInspect={detailInfo.inspectionStatus != "INSPECT01"}/>
                  {
                    <>
                      {
                        type === "defaultHeaderList" ?
                          getConents()
                          :
                          getSupContent()
                      }
                    </>
                  }
                  <ModelingPopupSideContent type={type} selectedPopupInfo={selectedPopupInfo} />
                </div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>

      </div>
    </>
  )
}

export default DashboardModelingMainPopup;