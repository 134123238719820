import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProductVideoRegist from "../../../../../../../components/modelingCtrl/bottomPopup/product/product_videoRegist";
import VideoPlayer from "../../../../../../../components/react_video_player/video_player";
import { useDelAssembleVideoMutation, useGetAssembleVideoMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductVideo = () => {
    const {modelingId} = useParams()
    const [getAssembleVideoApi] = useGetAssembleVideoMutation();
    const [delAssembleVideoApi] = useDelAssembleVideoMutation()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [videoList, setVideoList] = useState<any>({
        kr : [], en : []
    })
    const getAssembleVideo = async()=>{
        const param = {modelingId : modelingId}
        const result : any = await getAssembleVideoApi(param)
        if(result.data.result===true){
            setVideoList({...videoList, 
                kr : result.data.list.filter((item:any)=>item.video_lang === 'KR'),
                en : result.data.list.filter((item:any)=>item.video_lang === 'EN')
            })
        }
    }
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    const handleVideoDeltet = async(id : any) => {
        const param = {id : id}
        const confirm = window.confirm("해당 비디오를 삭제하시겠습니까?");
        if(confirm){
            const result : any = await delAssembleVideoApi(param);
            if(result.data.result === true){ getAssembleVideo() } 
        }
    }
    useEffect(()=>{getAssembleVideo()},[])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_2, PageStyles.active].join(" ")}>
            <div className={PageStyles.videoBox}>
                <div>
                    <div>
                        <span className={PageStyles.lang}>KR</span>
                        {videoList.kr.length === 0 ? 
                            <div className={PageStyles.none_content_admin}>
                                <p>
                                    <span>아직 등록된 콘텐츠가 없습니다.</span>
                                    하단의 '+' 버튼을 눌러 콘텐츠를 등록해 주세요.
                                </p>
                            </div> :
                            <ul className={PageStyles.videoWrap} id="assemble_video_kr">
                                {/* kr Loop Area */}
                                {videoList?.kr?.map((data : any) => (
                                    <li key={data.idx}>
                                        <div className={PageStyles.videoArea}>
                                            <VideoPlayer url={data.video_url}/>
                                        </div>
                                        <div className={PageStyles.videoTitleBox}>
                                            <div>
                                                <p>{data.video_title}</p>
                                            </div>
                                            <div>
                                                <button onClick={()=>handleVideoDeltet(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="삭제 버튼"/></button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                    <div>
                        <span className={PageStyles.lang}>EN</span>
                        
                        {videoList.en.length === 0 ? 
                            <div className={PageStyles.none_content_admin}>
                                <p>
                                    <span>아직 등록된 콘텐츠가 없습니다.</span>
                                    하단의 '+' 버튼을 눌러 콘텐츠를 등록해 주세요.
                                </p>
                            </div> :
                            <ul className={PageStyles.videoWrap} id="assemble_video_kr">
                                {/* kr Loop Area */}
                                {videoList?.en?.map((data : any) => (
                                    <li key={data.idx}>
                                        <div className={PageStyles.videoArea}>
                                            <VideoPlayer url={data.video_url}/>
                                        </div>
                                        <div className={PageStyles.videoTitleBox}>
                                            <div>
                                                <p>{data.video_title}</p>
                                            </div>
                                            <div>
                                                <button onClick={()=>handleVideoDeltet(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="삭제 버튼"/></button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            </div>
            <button className={[PageStyles.blueAddBtn, PageStyles.frmAssembleVideoAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            {isOpen && <ProductVideoRegist getVideo={getAssembleVideo} onClose={handleOpen}/>}
        </div>
        </>
    )
}

export default ProductVideo;