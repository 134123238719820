import React, { useEffect, useRef, useState } from 'react';
import EditMobile from '../../components/member/Popup/editMobile';
import EditName from '../../components/member/Popup/editName';
import EditProfileImage from '../../components/member/Popup/editProfileImage';
import EditAddInfo from '../../components/member/subPage/editAddInfo';
import EditPassWord from '../../components/member/subPage/editPassword';
import EditSubScribe from '../../components/member/subPage/editSubscribe';
import { setUserInfo, setUserName, setUserPic2 } from '../../store/slices/member/loginUserSlice';
import { useUserChngePasswordMutation, useUserInfoMutation, useUserMobileModifyMutation, useUserModifyMutation, useUserNameModifyMutation, useUserProfileImageModifyMutation } from '../../store/slices/member/memberApiSlice';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';

//김건우
import { useTranslation } from "react-i18next";

const MemberModify = () => {

  //김건우
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.loginUser.userId)
  const mp_login_id = useAppSelector(state => state.loginUser.account);
  const userImage2 = useAppSelector(state => state.loginUser.userImage2)
  const userData = useAppSelector(state => state.loginUser.userInfo)
  const [userInfo] = useUserInfoMutation();
  const [editName] = useUserNameModifyMutation();
  const [editMobile] = useUserMobileModifyMutation();
  const [editPassword] = useUserChngePasswordMutation();
  const [editProfileImage] = useUserProfileImageModifyMutation()
  const [modifyUserInfo] = useUserModifyMutation();
  console.log(userData)

  const [profile, setProfile] = useState({
    firstName: '',   // 이름
    lastName: '',   // 성
    email: '',     // 아이디 (이메일)
    mobile: '',    // 휴대전화
    password: '',    // 더미 비밀번호
    profileUrl: '',  // 프로필 사진

    phone: '', // 휴대폰 연락처
    biography: '',     // 회원 소개
    userType: '', // 회원 유형
    depart: '',  // 부서
    position: '', // 직위
    fax: '', // fax
    country: '', // 국가
    emailSignature: '',  // 이메일 서명
  })

  const [commonData, setCommonData] = useState({
    countryData: [], departMentData: [], positionData: [], userRolyTypeData: []
  })

  const [roleTypeArr, setRoleTypeArr] = useState<string[]>([])

  const getUserInfo = async (userId : any) => {
    try {
      const param = {userId : userData.userId}
      const { data: user } = await userInfo(param);
      const { profileList, countryCodeList, userDepartmentList, userPositionList, userTypeList, splitRoletype } = user;
      if (user.result) {
        setProfile({
          ...profile,
          firstName: profileList.mp_firstname_kr,
          lastName: profileList.mp_lastname_kr,
          email: profileList.mp_login_id,
          mobile: profileList.mp_user_mobile,
          profileUrl: profileList.mp_user_profile_image_pdsFile,
          password: profileList.mp_dumie_password,

          biography: profileList.mp_user_biography_kr,
          phone: profileList.mp_user_phone,
          fax: profileList.mp_user_fax,
          depart: profileList.mp_user_department_kr,
          position: profileList.mp_user_position_kr,
          country: profileList.mp_user_country,
          emailSignature: profileList.mp_email_signature,
          userType: profileList.mp_user_roletype_id
        })

        setRoleTypeArr(splitRoletype)

        setCommonData({
          countryData: countryCodeList,
          departMentData: userDepartmentList,
          positionData: userPositionList,
          userRolyTypeData: userTypeList
        })
      }
    } catch (err) {
    }
  }

  const [editInfo, setEditInfo] = useState<any>({
    isShowImage: false, isShowName: false, isShowMobile: false
  })

  // 사진 편집 창 열고 닫기
  const handleOpenEditImage = () => {
    setEditInfo((prev: any) => ({
      ...editInfo,
      isShowImage: !prev.isShowImage
    }))
  }
  // 이름 편집 창 열고 닫기 
  const handleOpenEditName = () => {
    setEditInfo((prev: any) => ({
      ...editInfo,
      isShowName: !prev.isShowName
    }))
  }
  // 전화번호 편집 창 열고 닫기
  const handleOpenEditMobile = () => {
    setEditInfo((prev: any) => ({
      ...editInfo,
      isShowMobile: !prev.isShowMobile
    }))
  }
  // 프로필 사진 편집 저장
  const handleChangePic = async (profileUrl: string, isDel: boolean) => {
    const formData: any = new FormData();

    formData.append("mp_user_profile_image_pdsFilename", profileUrl)
    formData.append("mp_login_id", userData.account)
    formData.append("is_del", isDel)
    for (let key of formData.keys()) {
      console.log(key)
    }
    for (let value of formData.values()) {
      console.log(value)
    }
    const body = formData;
    // const body = {"mp_user_profile_image_pdsFilename" : profileUrl, "mp_login_id" : mp_login_id, "is_del" : isDel}
    const result: any = await editProfileImage(body);
    if (result) {
      dispatch(setUserInfo({
        ...userInfo, 
        profile1Url : result.data.profile1Url, profile2Url : result.data.profile2Url
      }))
      if (isDel) {
        alert(t("memberModify-delete"))
      } else {
        alert(t("memberModify-change"))
      }
      getUserInfo(userId)
      handleOpenEditImage()
      dispatch(setUserPic2({ userImage2: result.data.profile2Url })) // store 사진 변경 사항 저장
      dispatch(setUserInfo({...userData, profile1Url : result.data.profile1Url, profile2Url : result.data.profile2Url}))

    }
  }
  // 이름 편집 저장
  const handleSaveName = async (lastName: string, firstName: string) => {
    const result: any = await editName({
      mp_login_id: userData.account,
      mp_lastname_kr: lastName,
      mp_firstname_kr: firstName
    })
    if (result) {
      alert(t("memberModify-modify"))
      getUserInfo(userId)
      handleOpenEditName()
      dispatch(setUserInfo({...userData, lastName : lastName, firstName : firstName}))
    }
  }
  // 전화번호 편집 저장
  const handleSaveMobile = async (mobile: string) => {
    const result: any = await editMobile({
      mp_login_id: userData.account,
      mp_user_mobile: mobile
    })
    if (result) {
      alert(t("memberModify-modify"))
      getUserInfo(userId)
      handleOpenEditMobile()
    }
  }
  // 비밀번호 변경
  const handleChangePw = async (password: string) => {
    const result: any = await editPassword({
      mp_login_id: userData.account,
      mp_dumie_password: password
    })
    if (result) {
      alert(t("memberModify-change"))
    }
  }

  // 추가 회원 정보 수정
  const handleChangeAccInfo = async (accInfo: any) => {
    const result: any = await modifyUserInfo({
      mp_login_id: userData.account,
      mp_user_biography_kr: accInfo.biography,
      mp_user_phone: accInfo.phone,
      mp_user_fax: accInfo.fax,
      mp_user_country: accInfo.country,
      mp_email_signature: accInfo.emailSignatur,
      mp_user_department_kr: accInfo.departMent,
      mp_user_position_kr: accInfo.postition,
      mp_user_roletype_id: accInfo.userType
    })
    if (result) {
      alert(t("memberModify-modify"))
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if(userData.userId){
      getUserInfo(userData.userId);
    }
  }, [userData.userId])


  return (
    <>
      <div className="profile_custom">
        <div className="wrap">
          <div className="content">
            <div className="left_area">
              <div className="section_1">
                <h3>{t("memberModify-info")}</h3>
                <ul>
                  <li className="profile_photoBox">
                    <h4>{t("memberModify-profile")}</h4>
                    <div>
                      {profile.profileUrl !== "" ?
                        <div style={{ background: `url(${profile.profileUrl}) center center / cover`, borderRadius: '10%' }}></div> :
                        <div style={{ background: `url(/assets/images/dsbd/no-profile.png) center center / cover`, borderRadius: '10%' }}></div>
                      }
                      <div>
                        <p>{t("memberModify-explanProfile1")}<br />{t("memberModify-explanProfile2")}</p>
                        <button onClick={handleOpenEditImage}>{t("memberModify-photoRegist")}</button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <h4>{t("memberModify-name")}</h4>
                    {/* <p>백 지희</p> */}
                    <p>{profile.lastName} {profile.firstName}</p>
                    <button onClick={handleOpenEditName}>{t("memberModify-edit")}</button>
                  </li>
                  <li>
                    <h4>{t("memberModify-id")}</h4>
                    <p>{profile.email}</p>
                  </li>
                  <li>
                    <h4>{t("memberModify-phone")}</h4>
                    <p>{profile.mobile}</p>
                    <button onClick={handleOpenEditMobile}>{t("memberModify-edit")}</button>
                  </li>
                </ul>
              </div>
              {/* 추가 회원 정보 */}
              <EditAddInfo
                onSave={handleChangeAccInfo}
                commonData={commonData}   // 국자 데이터 & 부서 데이터 & 직위 데이터
                addInfo={profile}
                roleTypeArr={roleTypeArr}
                setRoleTypeArr={setRoleTypeArr}
              />
            </div>
            <div className="right_area">
              {/* 비밀번호 변경 */}
              <EditPassWord
                password={profile.password}
                onChange={handleChangePw}
              />
              {/* 구독 변경 */}
              <EditSubScribe/>
            </div>
          </div>
        </div>
      </div>
      {/* 프로필 사진 편집 */}
      {editInfo.isShowImage &&
        <EditProfileImage
          profileUrl={profile.profileUrl}
          onChange={handleChangePic}
          onClose={handleOpenEditImage}
        />
      }
      {/* 이름 편집 */}
      {editInfo.isShowName &&
        <EditName
          lastName={profile.lastName}
          firstName={profile.firstName}
          onSave={handleSaveName}
          onClose={handleOpenEditName}
        />
      }
      {/* 전화번호 편집 */}
      {editInfo.isShowMobile &&
        <EditMobile
          mobile={profile.mobile}
          onSave={handleSaveMobile}
          onClose={handleOpenEditMobile}
        />
      }
    </>
  )
}

export default MemberModify;