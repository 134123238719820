import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsDrawingMutation, useUptPartsDrawingMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";
export interface Props{getDrawing:any; partsName : string; partsId : string; onClose : any}
const PartsDrawingRegist:React.FC<Props> = ({getDrawing,partsName, partsId,onClose}) => {
    const {modelingId} = useParams()
    const userData : any = useAppSelector(state=>state.loginUser.userInfo)
    const [setPartsDrawingApi] = useSetPartsDrawingMutation()
    const [drawingInfo, setDrawingInfo] = useState<any>({modelingId : modelingId, partsName: partsName, partsId : partsId, openYn : 'Y', drawingComment: '', drawingFiles : []})
    const handleChange = (e:any) => {setDrawingInfo({...drawingInfo, drawingComment : e.target.value})}
    const handleOpenYn = (yn : string) => {
        setDrawingInfo({...drawingInfo, openYn : yn})
    }
    const handleUpload = (files : any) => {
        setDrawingInfo((prev : any) => ({
            ...prev,
            drawingFiles: files,
        }));
    }
    const handleDelFiles = (id : number) => {

    }
    const handleSave = async() => {
        const formData = new FormData();
        formData.append('AssetModelingId', drawingInfo.modelingId)
        formData.append('part_unique_value', drawingInfo.partsName)
        formData.append('idx', partsId)
        formData.append('user_id' , userData.userId)
        formData.append('part_drawing_comments', drawingInfo.drawingComment)
        formData.append('part_drawing_publish' , drawingInfo.openYn)
        formData.append('drawing_files', drawingInfo.drawingFiles[0])
        const result : any = await setPartsDrawingApi(formData);
        if(result.data.result === true) {alert("등록이 완료되었습니다."); getDrawing(); onClose()}
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>신규도면 등록</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.frmPartDrawingWrap}>
                        
                        <Dropzone type={'PDF'} onFileUpload={handleUpload}/>
                        <ul className={PageStyles.dropZone_attachmentBox}>
                            {drawingInfo?.drawingFiles?.map((file:any, index : number) => (
                            // <p key={index}>{file.name}</p>
                                <li key={index}>
                                    <div className={PageStyles.dropZone_attachmentList}>
                                        <p key={index}>{file.name}</p>
                                        <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/redCloseBtn.png" alt="deleteBtn"/></button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={PageStyles.radioBox}>
                            <div>
                                <input type="radio" name="part_drawing_publish" id="part_drawing_publish_open" onChange={()=>handleOpenYn('Y')} checked={drawingInfo.openYn==='Y'?true:false}/>
                                <label htmlFor="part_drawing_publish_open">공개</label>
                            </div>
                            <div>
                                <input type="radio" name="part_drawing_publish" id="part_drawing_publish_close" onChange={()=>handleOpenYn('N')} checked={drawingInfo.openYn==='N'?true:false}/>
                                <label htmlFor="part_drawing_publish_close">비공개</label>
                            </div>
                        </div>

                        <div className={PageStyles.commentBox}>
                            <span>Comment</span>
                            <textarea onChange={handleChange} name="part_drawing_comments" id="part_drawing_comments"></textarea>
                        </div>
                    </div>
                </div>
                <div className={PageStyles.frmPartDrawing_btnBox}>
                    <div className={[PageStyles.frmPartDrawingAddBtn_cancleBtn, PageStyles.cancle].join(" ")} onClick={onClose}>취소</div>
                    <button type="button" id="goPartDrawingSubmit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsDrawingRegist;