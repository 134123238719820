import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PartsVideoRegist from "../../../../../../../components/modelingCtrl/bottomPopup/parts/parts_videoRegist";
import VideoPlayer from "../../../../../../../components/react_video_player/video_player";
import { useDelPartsVideoMutation, useGetPartsVideoMutation } from "../../../../../../../store/slices/modeling/modelingPartsApiSlice";
import PageStyles from '../../../css/page.module.css';
export interface Props {partsName:string; partsId:string}
const PartsVideo:React.FC<Props> = ({partsName, partsId}) => {
    const {modelingId} : any = useParams()
    const [getPartsVideoApi] = useGetPartsVideoMutation();
    const [delPartsVideoApi] = useDelPartsVideoMutation()
    const [videoList, setVideoList] = useState<any>({
        kr: [], en : []
    })
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setIsOpen(prev =>!prev)
    }
    const getPartsVideo = async() => {
        const param = {modelingId : modelingId, idx : partsId}
        const result : any = await getPartsVideoApi(param)
        if(result.data.result === true){
            setVideoList({
                kr : result?.data?.list?.filter((item:any)=>item.parts_video_lang==="KR"),
                en : result?.data?.list?.filter((item:any)=>item.parts_video_lang==="EN")
            })
        }
    }
    const handleDelVideo = async(id:string) => {
        const param = {video_idx : id}
        const confirm = window.confirm("해당 비디오를 삭제하시겠습니까?")
        if(confirm){
            const result : any = await delPartsVideoApi(param);
            if(result.data.result === true){getPartsVideo()}
        }
    }
    useEffect(()=>{
        getPartsVideo()
    }, [partsId])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_5, PageStyles.active].join(" ")}>
            <div className={PageStyles.videoBox}>
                <div>
                    <div>
                        <span className={PageStyles.lang}>KR</span>
                        <ul className={PageStyles.videoWrap} id="parts_video_kr">
                            {videoList?.kr?.map((data:any) => (
                            <li key={data.idx}>
                                <div className={PageStyles.videoArea}>
                                    <VideoPlayer url={data.parts_video_url}/>
                                </div>
                                <div className={PageStyles.videoTitleBox}>
                                    <div>
                                        <p>{data.parts_video_title}</p>
                                    </div>
                                    <div>
                                        <button onClick={()=>handleDelVideo(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="삭제 버튼"/></button>
                                    </div>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <span className={PageStyles.lang}>EN</span>
                        
                        <ul className={PageStyles.videoWrap} id="parts_video_en">
                            {videoList?.en?.map((data: any)=>(
                            <li key={data.idx}>
                                <div className={PageStyles.videoArea}>
                                    <VideoPlayer url={data.parts_video_url}/>
                                </div>
                                <div className={PageStyles.videoTitleBox}>
                                    <div>
                                        <p>{data.parts_video_title}</p>
                                    </div>
                                    <div>
                                        <button onClick={()=>handleDelVideo(data.idx)}><img src="/assets/images/modelingCtrl/popUp_thumb_close.png" alt="삭제 버튼"/></button>
                                    </div>
                                </div>
                            </li>
                            ))}
                        </ul>

                    </div>
                </div>
            </div>

            {isOpen && <PartsVideoRegist getVideo={getPartsVideo} partsName={partsName} partsId={partsId} onClose={handleOpen}/>}
            
            <button className={[PageStyles.blueAddBtn, PageStyles.frmPartVideoAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
        </div>
        </>
    )
}

export default PartsVideo;