import React from "react";
import PageStyles from '../../../css/page.module.css';

const PartsAdminLog = () => {

    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_8, PageStyles.active].join(" ")}>
        
            <div className={PageStyles.admin_log}>
                <div className={PageStyles.top}>
                    <select name="" id="" className={PageStyles.select}>
                        <option value="" disabled selected>전체</option>
                    </select>
                    <div className={PageStyles.search_box}>
                        <input type="text" placeholder="Search..."/>
                        <button type="button"><img src="/assets/images/modelingCtrl/search_thin.png" alt="검색아이콘"/></button>
                    </div>
                </div>

                <ul className={PageStyles.historyList}>
                    <li>
                        <span className={PageStyles.date}>2023.01.10</span>
                        <ol className={PageStyles.timeline}>
                            <li>
                                <div>
                                    <span className={PageStyles.timeline_name}>이름 직책</span>
                                    <span className={PageStyles.timeline_content}>부품 홍보영상</span>
                                    <span className={PageStyles.timeline_status}>등록</span>
                                </div>
                                <span className={PageStyles.timeline_time}>09:14</span>
                            </li>
                            <li>
                                <div>
                                    <span className={PageStyles.timeline_name}>이름 직책</span>
                                    <span className={PageStyles.timeline_content}>부품 매뉴얼</span>
                                    <span className={PageStyles.timeline_status}>등록</span>
                                </div>
                                <span className={PageStyles.timeline_time}>10:31</span>
                            </li>
                            <li>
                                <div>
                                    <span className={PageStyles.timeline_name}>관리자</span>
                                    <span className={PageStyles.timeline_content}>카탈로그(dh_pdf_kr.pdf)</span>
                                    <span className={PageStyles.timeline_status}>삭제</span>
                                </div>
                                <span className={PageStyles.timeline_time}>16:22</span>
                            </li>
                        </ol>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default PartsAdminLog;