import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FileDownLoad } from "../../../../../../../components/modelingCtrl/bottomPopup/product/fileDownload";
import ProductCatalogueRegist from "../../../../../../../components/modelingCtrl/bottomPopup/product/product_catalogueRegist";
import { useDelAssembleCatalogueMutation, useGetAssembleCatalogueMutation } from "../../../../../../../store/slices/modeling/modelingApiSlice";
import PageStyles from '../../../css/page.module.css';

const ProductCatalog = () => {
    const {modelingId} = useParams()
    const [getAssembleBrouchureApi] = useGetAssembleCatalogueMutation();
    const [delAssembleBrouchureApi] = useDelAssembleCatalogueMutation()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [catalogueList, setCatalogueList] = useState<any>({
        kr : [], en : []
    });
    const getAssembleCatalogue = async() => {
        const param = {modelingId : modelingId}
        const result : any = await getAssembleBrouchureApi(param);
        if(result.data.result === true){
            setCatalogueList({...catalogueList,
            kr : result?.data?.list?.filter((item : any) => item.brouchure_lang === "KR"),
            en : result?.data?.list?.filter((item : any) => item.brouchure_lang === "EN"),
        })
        }
    }
    
    const delCatalogue = async(id:number) => {
        const confirm = window.confirm("삭제하시겠습니까?");
        if(confirm){
            const param = {id:id}
            const result : any = await delAssembleBrouchureApi(param)
            if(result.data.result === true) { getAssembleCatalogue() }
        }
    }
    const handleOpen = (e:React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(prev => !prev)
    }
    useEffect(()=>{getAssembleCatalogue()},[])
    return(
        <>
        <div className={[PageStyles.content, PageStyles.content_3, PageStyles.active].join(" ")}>
            <div className={PageStyles.file_uploadBox}>
                <div className={PageStyles.catalog_uploadBox}  id="catalog_kr_box">
                    <span className={PageStyles.lang}>KR</span>
                    <div className={PageStyles.catalog_kr}>
                        {catalogueList?.kr?.length === 0 ? 
                        <div className={PageStyles.catalogDown}>
                            <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                            <input id="brouchure_title" name="brouchure_title" placeholder="등록할 브로셔 첨부 파일을 업로드하시기 바랍니다." readOnly/>
                            <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                            <button><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                        </div> : 
                        <> 
                            {catalogueList?.kr?.map((data : any) => (
                                <div className={PageStyles.catalogDown} key={data.idx}>
                                    <FileDownLoad fileUrl={data.brouchure_pdsFilename} fileName={data.brouchure_pdsFile}/>
                                    <div>
                                        <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                        <button type="button" id="del_brouchure_shooting" onClick={()=>delCatalogue(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                                    </div>
                                </div>
                            ))}
                        </>
                        } 

                    </div>
                </div>

                <div className={PageStyles.catalog_uploadBox}  id="catalog_en_box">
                    <span className={PageStyles.lang}>EN</span>
                    <div className={PageStyles.catalog_en}>

                        {catalogueList?.en?.length === 0 ? 
                        <div className={PageStyles.catalogDown}>
                            <span><img src="/assets/images/modelingCtrl/popUp1_download1.png" alt="download"/></span>
                            
                            <a target="_blank"><input id="brouchure_title" name="brouchure_title" placeholder="등록할 브로셔 첨부 파일을 업로드하시기 바랍니다." readOnly/></a>
                            <div>
                                <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                <button><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                            </div>
                        </div> : 
                        <> 
                            {catalogueList?.en?.map((data : any) => (
                                <div className={PageStyles.catalogDown} key={data.idx}>
                                    <FileDownLoad fileUrl={data.brouchure_pdsFilename} fileName={data.brouchure_pdsFile}/>
                                    <div>
                                        <a target="_blank"><img src="/assets/images/modelingCtrl/popUp1_download2.png" alt="download"/></a>
                                        <button type="button" id="del_brouchure_shooting" onClick={()=>delCatalogue(data.idx)}><img src="/assets/images/modelingCtrl/trash-can-light.png" alt="close"/></button>
                                    </div>
                                </div>
                            ))}
                        </>
                        } 

                    </div>
                </div>
            </div>

            <button className={[PageStyles.blueAddBtn, PageStyles.frmAssembleBrouchureAddBtn].join(" ")} onClick={handleOpen}><img src="/assets/images/modelingCtrl/popUp_imgAddBtn.png" alt="img add button"/></button>
            {isOpen && <ProductCatalogueRegist getCatalogue={getAssembleCatalogue} onClose={handleOpen}/>}
        </div>
        </>
    )
}

export default ProductCatalog;