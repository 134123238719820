import React, { FC } from "react";
import ArrowLeft from "./left-arrow";
import ArrowRight from "./right-arrow";

interface IPaginationProps {
  totalCount: number;
  page: number;
  size: number;
  prevClick: any;
  nextClick: any;
}

const Pagination: FC<IPaginationProps> = (props) => {
  const { totalCount, page, size, prevClick, nextClick } = props;

  return (
    <>
      <div>
        <div className="paging_box">
          <span>{page + 1}</span>
          <span>/</span>
          <span>{Math.ceil(totalCount / size)}</span>
        </div>
        <div className="arrow_box" style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
          <span onClick={prevClick} style={{top:'6px',display:'block', cursor:'pointer'}}><ArrowLeft color={page===0 ? "#e2e2e2" : "#808080"}/></span>
          <span onClick={nextClick} style={{top:'6px',display:'block', cursor:'pointer'}}><ArrowRight color={page+1 === Math.ceil(totalCount /size) ? "#e2e2e2" : "#808080"} /></span>
        </div>
      </div>
    </>
  );
};

export default Pagination;
