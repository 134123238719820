import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SampleState {
    value : number,
    gameStyle : string
}

const initialState : SampleState = {
    value: 0,
    gameStyle: 'MMO'
}

export const sampleReducer = createSlice({
    name : 'counter',
    initialState,
    reducers : {
        increment : state => {
            state.value += 1;
        },
        incrementGameStyle : state => {
            state.gameStyle = 'AAA';
        },
        decrement : state => {
            state.value -= 1;
        },
        incrementByAmount : ( state, action: PayloadAction<number> ) => {
            state.value += action.payload;
        }
    },
});

export const { increment, incrementGameStyle, decrement, incrementByAmount } = sampleReducer.actions;
export default sampleReducer.reducer;
