import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetAssembleCatalogueMutation } from "../../../../store/slices/modeling/modelingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";
export interface Props {getCatalogue : Function; onClose : Function}
const ProductCatalogueRegist:React.FC<Props> = ({getCatalogue, onClose}) => {
    const {modelingId} = useParams()
    const userData : any = useAppSelector(state=>state.loginUser.userInfo)
    const [setAssembleCatalogueApi] = useSetAssembleCatalogueMutation();
    const [catalogueInfo, setCatalogueInfo] = useState<any>({modelingId : modelingId, lang : 'KR', catalogueFiles : []})
    const handleLangChange = (type : string) => {
        if(type==='KR'){setCatalogueInfo({...catalogueInfo, lang : "KR"})}
        else {setCatalogueInfo({...catalogueInfo, lang : 'EN'})}
    }
    const handleFilesUpload = (files : File) => {
        console.log(files)
        setCatalogueInfo((prev : any) => ({
            ...prev,
            catalogueFiles: files,
        }));
    }
    const handleDelFiles = (index:number) => {
        const updatedFils = [...catalogueInfo.catalogueFiles];
        updatedFils.splice(index,1)
        setCatalogueInfo({...catalogueInfo,catalogueFiles : updatedFils})
    }
    const handleSave =async()=>{
        if(catalogueInfo?.catalogueFiles?.length < 1){ alert("최소 1개의 파일은 업로드 하셔야 합니다."); return; }
        console.log(catalogueInfo)
        const formData = new FormData();
        formData.append('AssetModelingId', catalogueInfo.modelingId)
        formData.append('UserId', userData.userId)
        formData.append('catalogue_lang', catalogueInfo.lang)
        catalogueInfo.catalogueFiles.forEach((file : File, index : number) => {
            formData.append(`catalogue_files_${index+1}`, file);
        });
        formData.append('files_length', catalogueInfo?.catalogueFiles?.length)
        console.log(catalogueInfo.catalogueFiles)
        const result : any = await setAssembleCatalogueApi(formData);
        if(result.data.result === true){
            alert("등록이 완료되었습니다.");
            getCatalogue();
            onClose()
        }
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>브로셔 업로드</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.langBoxWrap}>
                        <div className={PageStyles.langBox}>
                            <span className={[PageStyles.lang, PageStyles.lang_KR, catalogueInfo.lang==='KR'?PageStyles.active :''].join(" ")} onClick={()=>handleLangChange('KR')}>KR</span>
                            <span className={[PageStyles.lang, PageStyles.lang_EN, catalogueInfo.lang==='EN'?PageStyles.active :''].join(" ")} onClick={()=>handleLangChange('EN')}>EN</span>
                        </div>
                        <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 업로드가 가능합니다.</p>
                    </div>

                    <Dropzone type={'PDF'} onFileUpload={handleFilesUpload}/>
                    <ul className={PageStyles.dropZone_attachmentBox}>
                    {catalogueInfo?.catalogueFiles?.map((file:any, index : number) => (
                        // <p key={index}>{file.name}</p>
                            <li key={index}>
                                <div className={PageStyles.dropZone_attachmentList}>
                                    <p key={index}>{file.name}</p>
                                    <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/redCloseBtn.png" alt="deleteBtn"/></button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={PageStyles.frmAssembleBrouchure_btnBox}>
                    <div className={[PageStyles.frmAssembleBrouchureAddBtn_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                    <button type="button" id="dots_catalog_submit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default ProductCatalogueRegist;