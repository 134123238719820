import DashBoardSideBar from './dashboard-sidebar'
import DashBoardFooter from './dashboard-footer';
import DashBoardCompany from './dashboard-company';
import DashBoardDetail from './dashboard-detail';
import DashBoardPosition from './dashboard-position';
import DashBoardDepartManager from './dashboard-depart';
import { useLocation, useParams } from 'react-router-dom';
import DashboardApprove from "./dashboard-approve";
import DashBoardEmployList from './dashboard-employee-list';
import MemberModify from '../members/memberModify';
import DashBoardModelingRequest from './dashboard-modeling-request';
import DashBoardModelingPhoto from './dashboard-modeling-photo';
import DashBoardModelingManagement from './dashboard-modeling-management';
import DashBoardModelingPurchase from './dashboard-modeling-purchase';
import Test from './dashboard-todoList/test';
import DashBoardModelingOrders from './dashboard-modeling-orders';
import DashboardTopbar from './dashboard-topbar';
import './DotsAdmin.css';
import DashboardModelingSupple from './dashboard-modeling-supple';
import TestA from './test/test';
import TestPage from './test/test';
import { useRef, useState } from 'react';
import DashboardModelingCtrl from './dashboard-modeling-ctrl';
import { DashboardModelingPayment } from './dashboard-modeling-payment';
import { DashboardProject } from './dashboard-project';


const DashBoard = () => {
	
	const paddingRef = useRef<any>();
	const topbarRef = useRef<any>();
	const location = useLocation();
	const {modelingId,projectType} = useParams()
	// 사이드바가 열려있는지 닫혀있는지 판단하기 위한 값
	const [sideBarOpen, setSideBarOpen] = useState<boolean>(false)
  	console.log(paddingRef.current);

	return (
		<>
			<div id="dots-wrap">
				{/* 사이드 바 */}
				<DashBoardSideBar paddingRef={paddingRef} topbarRef={topbarRef} isOpen={setSideBarOpen}/>
				<div className={location.pathname === `/dashboard/modelingCtrl/${modelingId}` ? 'dots-contents modelingBox' : `dots-contents`} ref={paddingRef}>
					<DashboardTopbar topbarRef={topbarRef}/>
					{/* 3D 모델링 의뢰 관리 */}
					{location.pathname === "/dashboard/requestManagement" && <DashBoardModelingOrders />}
					{location.pathname === "/dashboard/memberModify" && <MemberModify />}
					{location.pathname === "/dashboard/company" && <DashBoardCompany />}
					{location.pathname === "/dashboard/companyDetail" && <DashBoardDetail /> }
					{location.pathname === "/dashboard/department" && <DashBoardDepartManager /> }
					{location.pathname === "/dashboard/position" && <DashBoardPosition /> }
					{location.pathname === "/dashboard/approve" && <DashboardApprove /> }
					{location.pathname === "/dashboard/employee" && <DashBoardEmployList /> }
					{location.pathname === "/dashboard/modelingRequest" && <DashBoardModelingRequest />}
					{location.pathname === "/dashboard/modelingPhoto" && <DashBoardModelingPhoto />}
					{location.pathname === "/dashboard/modelingManagement" && <DashBoardModelingManagement />}
					{location.pathname === "/dashboard/modeling2d" && <DashBoardModelingPhoto />}
					{location.pathname === "/dashboard/modeling3d" && <DashBoardModelingPhoto />}
					{location.pathname === "/dashboard/modelingPurchase" && <DashBoardModelingPurchase />}
					{location.pathname === "/dashboard/modelingOrders" && <DashBoardModelingOrders />}
					{location.pathname === "/dashboard/modelingPayment" && <DashboardModelingPayment/>}
					{location.pathname === "/dashboard/modelingSupple" && <DashboardModelingSupple />}
					{location.pathname === `/dashboard/modelingCtrl/${modelingId}`&& <DashboardModelingCtrl/>}
					{location.pathname === "/dashboard/test" && <TestPage />}
					{location.pathname === `/dashboard/modelingCtrl/${modelingId}` ? '' : <DashBoardFooter />}
					{location.pathname === `/dashboard/project/${projectType}` ? <DashboardProject sideBar={sideBarOpen}/> : ''}
					
				</div>
			</div>
		</>
	)
}

export default DashBoard;