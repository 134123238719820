import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';

import DashBoard from './pages/dashboard';
import MemberJoinComplete from './pages/members/memberJoin/memberJoinCompleted';
import MemberInviteJoin from './pages/members/memberJoin/memberInviteJoin';
import EmbeddedIFrame from './pages/embedded/EmbeddedIFrame';
import MailCertification from './pages/members/memberJoin/memberEmailCerti';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { useGetterUserInfoMutation, useGetUserInfoMutation } from './store/slices/member/memberApiSlice';
import { useAppDispatch, useAppSelector } from './store/storeHooks';
import { setCompanyId, setCompanyName, setLoginId, setUserId, setUserInfo, setUserName, setUserPic, setUserPic2 } from './store/slices/member/loginUserSlice';
import WebDigitalTwin from './layout/main/subPage/webAndDigitalTwin';
import NewsBlog from './layout/main/subPage/blog/news_blog';
import Blog from './layout/main/subPage/blog/blog';
import Example from './layout/main/subPage/webAndDigitalTwin/example';
import './locales/i18n';
import TestPage from './pages/dashboard/test/test';
import TestList from './pages/dashboard/test/testList';
import { setUser } from './store/slices/member/userLocalSlice';

// import MemberLogin from './pages/members/memberLogin';
// import MemberLogin from './pages/members/memberLogin';

const Main = React.lazy(() => import('../src/layout/main/index'));
const MemberLogin = React.lazy(() => import('./pages/members/memberLogin'));
const MemberJoin = React.lazy(() => import('./pages/members/memberJoin/memberJoin'));
const FindPw = React.lazy(() => import('./pages/members/memberFindPw'));
const Header = React.lazy(() => import('./layout/header/header'));
const Terms_of_Use = React.lazy(() => import('./pages/members/terms/terms_of_use'));
const Terms_privacy_Agreement = React.lazy(() => import('./pages/members/terms/terms_privacy_agreement'));
const Terms_personal_Info = React.lazy(() => import('./pages/members/terms/terms_personal_info'));
const FindPwCompleted = React.lazy(() => import('./pages/members/userInfoFindCompleted/findPwCompleted'));
const FindIdCompleted = React.lazy(() => import('./pages/members/userInfoFindCompleted/findIdCompleted'));
const FindId = React.lazy(() => import('./pages/members/memberFindId'));
const MemberModify = React.lazy(() => import('./pages/members/memberModify'));


function App() {

  const user = useSelector((state: RootState) => state.loginUser.userInfo);
  const [getUserInfo] = useGetterUserInfoMutation();
  const [expired, setExpired] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const getUser = async(userId : any) => {
    try{
      const result : any = await getUserInfo({userId})
      if(result.data.result === true){
        dispatch(setUserInfo(result.data.userInfo[0]))
      } 
    } catch{}
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('key');
    if (storedUser) {
      const userId = storedUser;
      getUser(userId)
    }
  }, [dispatch]);

  useEffect(() => {
    const updateLastActivityTime = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem('lastActivityTime', currentTime.toString());
    };
    document.addEventListener('mousemove', updateLastActivityTime);
    document.addEventListener('keydown', updateLastActivityTime);
    return () => {
      document.removeEventListener('mousemove', updateLastActivityTime);
      document.removeEventListener('keydown', updateLastActivityTime);
    };
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('key');
    if(storedUser){
      const timeoutInMilliseconds = 24 * 60 * 60 * 1000;
      const timer = setTimeout(() => {
        localStorage.removeItem('key');
        setShowAlert(true);
        setExpired(true);
      }, timeoutInMilliseconds);
  
      if (expired) {
        navigate('/');
      }
      return () => clearTimeout(timer);
    }
  }, [expired, navigate]);

  useEffect(() => {
    if (showAlert) {
      alert("로그인이 만료되었습니다.")
      navigate('/');
      setExpired(false);
      setShowAlert(false);
      window.location.reload();
    }
  }, [showAlert, navigate]);
  return (
    <>
      <Suspense fallback={""}>
        {/* <Header /> */}
        <Routes>
          <Route path='/example' element={<Example />}></Route>
          {/* 메인페이지 */}
          <Route path="/" element={<Main />}></Route>
          {/* 서브페이지 */}
          <Route path='/main/serviceInfo' element={<Main />}></Route>
          <Route path='/main/serviceInfo_chemical' element={<Main/>}></Route>
          <Route path='/main/serviceInfo_shipping' element={<Main/>}></Route>
          <Route path='/main/serviceInfo_building' element={<Main/>}></Route>
          <Route path='/main/webDitigalTwin' element={<Main />}></Route>
          <Route path='/main/blog' element={<Main />}></Route>
          <Route path='/main/news_blog' element={<Main />}></Route>
          <Route path='/main/tips_blog' element={<Main />}></Route>
          <Route path='/main/support_faq' element={<Main />}></Route>
          <Route path='/main/support_question' element={<Main />}></Route>
          <Route path='/main/support_reference' element={<Main />}></Route>
          {/* 로그인 */}
          <Route path="/memberLogin" element={<MemberLogin />}></Route>
          {/* 회원가입 */}
          <Route path="/memberJoin" element={<MemberJoin />}></Route>
          <Route path='/memberJoinCompleted' element={<MemberJoinComplete />}></Route>
          <Route path='/memberMailCerti' element={<MailCertification />}></Route>
          <Route path="/memberInviteJoin" element={<MemberInviteJoin />}></Route>
          {/* 아이디 (이메일) 찾기 */}
          <Route path="/findId" element={<FindId />}></Route>
          <Route path="/findIdCompleted" element={<FindIdCompleted />}></Route>
          {/* 비밀번호 찾기 */}
          <Route path="/findPw" element={<FindPw />}></Route>
          <Route path="/findPwCompleted" element={<FindPwCompleted />}></Route>

          {/* 이용 약관 */}
          <Route path="/terms1" element={<Terms_of_Use />}></Route>
          <Route path="/terms2" element={<Terms_privacy_Agreement />}></Route>
          <Route path="/terms3" element={<Terms_personal_Info />}></Route>

          {/* 회원정보수정 */}
          <Route path="/dashboard/memberModify" element={<DashBoard />}></Route>
          {/* 자사 관리  */}
          <Route path="/dashboard/company" element={<DashBoard />}></Route>
          <Route path="/dashboard/companyDetail" element={<DashBoard />}></Route>
          <Route path="/dashboard/department" element={<DashBoard />}></Route>
          <Route path="/dashboard/position" element={<DashBoard />}></Route>
          <Route path="/dashboard/employee" element={<DashBoard />}></Route>
          <Route path="/dashboard/approve" element={<DashBoard />}></Route>
          <Route path="/dashboard/modeling" element={<DashBoard />}></Route>

          {/*모델링*/}
          <Route path="/dashboard/modelingManagement" element={<DashBoard />}></Route>
          <Route path="/dashboard/modelingRequest" element={<DashBoard />}></Route>
          <Route path="/dashboard/modelingPhoto" element={<DashBoard />}></Route>
          <Route path="/dashboard/modeling2d" element={<DashBoard />}></Route>
          <Route path="/dashboard/modeling3d" element={<DashBoard />}></Route>
          <Route path="/dashboard/modelingPurchase" element={<DashBoard />}></Route>
          <Route path="/dashboard/modelingOrders" element={<DashBoard/>}></Route>
          <Route path="/dashboard/modelingPayment" element={<DashBoard/>}></Route>
          <Route path="/dashboard/modelingSupple" element={<DashBoard />}></Route>
          <Route path="/dashboard/modelingRetention" element={<DashBoard />}></Route>
          <Route path='/dashboard/project/:projectType' element={<DashBoard/>}></Route>

          {/* 모델링 IFrame */}
          <Route path="/embedded" element={<EmbeddedIFrame />}></Route>
          {/* 모델링 CTRL */}
          <Route path='/dashboard/modelingCtrl/:modelingId' element={<DashBoard/>}></Route>

          {/* test page */}
          <Route path='/dashboard/test' element={<DashBoard />}></Route>
          <Route path='/testList' element={<TestList/>}></Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;