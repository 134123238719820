import React, { useState, useRef, useEffect, FC } from 'react';
import useInput from '../../../hook/useInput';
import { useCreateDashboardDetailInfoMutation, useGetCompanyDetailsMutation } from '../../../store/slices/dashboard/dashboardApiSlice';
import InputLabels from './inputLabel';
import ChipInput from "material-ui-chip-input";
import { toast, Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import validate from './validate';
import CheckList from './checklist';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import PostCodeModal from '../../../components/dashboard/post-code-modal';
import QuillEditor from '../../../components/reactQuillEditor/quillEditor';
//김건우
import { useTranslation } from "react-i18next";

interface IDashBoardDetailBoxProps {
  data: {
    categoryEmployees: Array<string>;
    certificationList: Array<{ codeId: string, codeName: string }>;
    divisionList: Array<{ codeId: string, codeName: string }>;
    companyDetail: any;
    result: boolean;
  },
  refetch: any;
}

const DashBoardDetailBox: FC<IDashBoardDetailBoxProps> = (props) => {

  //김건우
  const { t } = useTranslation();
  const name = t("detail-name");
  const year = t("detail-year");
  const business = t("detail-business");
  const sector = t("detail-sector");
  const sales = t("detail-sales");
  const phone = t("detail-phone");
  const fax = t("detail-fax");
  const post = t("detail-post");
  const requestProduct = t("detail-requestProduct");
  const requestEmail  = t("detail-requestEmail");
  
  const { refetch } = props;
  const { categoryEmployees, certificationList, divisionList, companyDetail } = props.data;

  const inputRef = useRef<any[]>([]);
  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);
  const [chipEmail, setChipEmial] = useState<any>(companyDetail.email);
  
  const [chipProduct, setChipProduct] = useState<string[]>(companyDetail.mainProducts);
  const [division, setDivision] = useState(false);
  const [certification, setCertification] = useState(false);
  const [createDashboardDetailInfo] = useCreateDashboardDetailInfoMutation();
  const [divisionCheckedList, setDivisionCheckedList] = useState<string[]>([]);
  const [certificationCheckList, setCertificationCheckedList] = useState<string[]>([]);
  const [showDivision, setShowDivision] = useState('');
  const [showCertification, setShowCertification] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);               // 주소검색 버튼 클릭시, modal 띄우기 위한 state    
  const quillRef = useRef<any>(null);
  const [introduction, setIntroduction] = useState(companyDetail.introduction)

  console.log("com1", introduction)

  const { values, errors, submitting, handleChange, handleSubmit }: any = useInput({
    initialValue: {
      name: companyDetail.representativeName,
      businessNumber: companyDetail.businessCommencementYear,
      businessStatus: companyDetail.businessType,
      sectors: companyDetail.businessItem,
      sales: companyDetail.annualRevenue,
      employeeNumber: companyDetail.employeeNumber,
      phoneNumber: companyDetail.telNo,
      faxNumber: companyDetail.faxNo,
      zonecode: companyDetail.address1.substring(0, 5),
      address: companyDetail.address1.substring(6),
      detailAddress: companyDetail.address2,
      instagram: companyDetail.snsInstagram,
      youtube: companyDetail.snsYoutube,
      facebook: companyDetail.snsFacebook,
      skype: companyDetail.snsSkype,
      linkedin: companyDetail.snsLinkedIn,
      companyDescription: introduction,
    },
    onSubmit: (values: any) => {
      submitChange(values)
    },
    validate,

  })



  const submitChange = async (value: any) => {
    const { name, businessNumber, businessStatus, sectors, sales, employeeNumber, phoneNumber,
      faxNumber, zonecode, address, detailAddress, instagram, youtube, facebook, skype, linkedin, companyDescription
    } = value;

    if (introduction.replace(" ", "").replace("<p>", "").replace("</p>", "").replace("<br>", "").trim(" ", "").length === 0) {
      toast.error(t("detail-introError"));
      return;
    }

    // if (!chipProduct.length) {
    //   toast.error(t("detail-productError"));
    //   return;
    // }

    const body = {
      address1: `${zonecode}!${address}`,
      address2: detailAddress,
      annualRevenue: sales,
      businessCommencementYear: businessNumber,
      businessDivision: divisionCheckedList.toString(),
      businessItem: sectors,
      businessType: businessStatus,
      certifications: certificationCheckList.toString(),
      companyId: user.companyId,
      email: chipEmail.toString(),
      employeeNumber: employeeNumber,
      faxNo: faxNumber,
      introduction: introduction,
      mainProducts: chipProduct.toString(),
      representativeName: name,
      snsFacebook: facebook,
      snsInstagram: instagram,
      snsLinkedIn: linkedin,
      snsSkype: skype,
      snsYoutube: youtube,
      telNo: phoneNumber,
      userId: user.userId
    }
    try {
      const result = await createDashboardDetailInfo(body);
      toast.success(t("detail-saveSuccess"))
      refetch();
    } catch (err) {
      console.error(err);
    }
  }

  const checkEmail = (emailValue: string) => {
    let email = emailValue;
    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    if (exptext.test(email) === false) {
      alert(t("detail-idAlert"));
      return false;
    }
    return true;
  };

  const handleAddChip = (chip: string) => {
    if (chipEmail && chipEmail.length > 2) {
      toast.error(t("detail-overId"), {
        duration: 1500,
      });
      return;
    }
    if (!checkEmail(chip)) {
      toast.error(t("detail-idAlert2"));
    } else {
      setChipEmial(chipEmail.concat(chip));
    }
  };

  const handleAddProductChip = (chip: string) => {
    setChipProduct(chipProduct.concat(chip));
  }
  const handleDeleteChip = (chip: string, index: number) => {
    let list = chipEmail.filter((data: any) => data !== chip);
    setChipEmial(list);
  };
  const handleDeleteProductChip = (chip: string, index: number) => {
    let list = chipProduct.filter((data: any) => data !== chip);
    setChipProduct(list);
  }

  const handleDivisionChange = (codeId: string) => {
    if (!divisionCheckedList.includes(codeId)) {
      setDivisionCheckedList(prev => [...prev, codeId]);
    } else {
      setDivisionCheckedList(prev => [...prev.filter(x => x !== codeId)])
    }
  }
  const handleCertificationChange = (codeId: string) => {
    if (!certificationCheckList.includes(codeId)) {
      setCertificationCheckedList(prev => [...prev, codeId]);
    } else {
      setCertificationCheckedList(prev => [...prev.filter(x => x !== codeId)])
    }
  }

  const onChange = (data: any) => {
    setIntroduction(data);
  }

  useEffect(() => {
    if (companyDetail.businessDivision.length > 0) setDivisionCheckedList(companyDetail.businessDivision)
  }, [companyDetail.businessDivision])

  useEffect(() => {
    if (companyDetail.certifications.length > 0) setCertificationCheckedList(companyDetail.certifications)
  }, [companyDetail.certifications])

  useEffect(() => {
    let list: string[] = [];
    certificationCheckList.map((x: any) => {
      let result = certificationList.find((data: any) => data.codeId === x)
      if (result)
        list.push(result.codeName)
    })
    setShowCertification(list.toString());
  }, [certificationCheckList])

  useEffect(() => {
    let list: string[] = [];
    divisionCheckedList.map((x: any) => {
      let result = divisionList.find((data: any) => data.codeId === x)
      if (result)
        list.push(result.codeName)
    })
    setShowDivision(list.toString());
  }, [divisionCheckedList])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      for (let data in inputRef.current) {
        if(inputRef.current[data].name === Object.keys(errors)[0])
          inputRef.current[data].focus();
      }
      toast.error(Object.values(errors)[0] as string)
    }
  }, [errors])

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current = companyDetail.introduction
    }
  }, [quillRef])

  // 주소 검색 모달 닫는 함수
  const handleClose = () => {
    setIsOpen(false);
  }

  // 주소 검색 통해서 자동입력 함수
  const handleComplete = (data: any) => {

    values.zonecode = data.zonecode;
    values.address = data.address;

    handleClose();
  }

  return (
    <>
      <Toaster />
      <div className="company_details">
        <div className="wrap">
          <div className="content">
            <div className="fixed">
              <h3><span>{companyDetail.companyName}</span> {t("detail-main")}</h3>
            </div>
            <ul className="section_1">
              <InputLabels ref={element => (inputRef.current[0] = element)} value={values.name} onChange={handleChange} name="name" starYn={true} liClassname="" title={name} placeholder={t("detail-requestName")} />
              <InputLabels ref={element => (inputRef.current[1] = element)} value={values.businessNumber} onChange={handleChange} type={"number"} name="businessNumber" starYn={true} liClassname="registratinoNumber_area" title={year} placeholder={t("detail-requestYear")} />
              <InputLabels ref={element => (inputRef.current[2] = element)} value={values.businessStatus} onChange={handleChange} name="businessStatus" starYn={true} liClassname="" title={business} placeholder={t("detail-requestBusiness")} />
              <InputLabels ref={element => (inputRef.current[3] = element)} value={values.sectors} onChange={handleChange} name="sectors" starYn={true} liClassname="" title={sector} placeholder={t("detail-requestSector")} />
              <InputLabels value={values.sales} onChange={handleChange} name="sales" starYn={false} liClassname="" title={sales} placeholder={t("detail-requestSales")} />
              <li>
                <h4>{t("detail-employee")}</h4>
                <select
                  style={{ border: '1px solid #eeeeee', width: '100%', padding: '15px 20px', borderRadius: '10px', fontSize: '16px', fontWeight: 500 }}
                  name="employeeNumber"
                  onChange={handleChange}
                  value={values.employeeNumber}
                >
                  {categoryEmployees.map((v: any) => (
                    <option value={v}>{v}</option>
                  ))}
                </select>
              </li>
              <InputLabels type="tel" maxLength={13} ref={element => (inputRef.current[4] = element)} value={values.phoneNumber} onChange={handleChange} name="phoneNumber" starYn={true} liClassname="" title={phone} placeholder='000-0000-0000' />
              <InputLabels value={values.faxNumber} onChange={handleChange} name="faxNumber" starYn={false} liClassname="" title={fax} placeholder='000-0000-0000' />
              <li className="address">
                <h4>{t("detail-address")}<span className="red"> *</span></h4>
                <input onChange={handleChange} type="text" name="zonecode" value={values.zonecode} className="input_text zipCode" placeholder={post} />
                <button onClick={() => setIsOpen(true)} type="button">{t("detail-searchAddress")}</button>
                <input ref={element => (inputRef.current[5] = element)} value={values.address} onChange={handleChange} type="text" name="address" className="input_text" />
                <input ref={element => (inputRef.current[6] = element)} value={values.detailAddress} onChange={handleChange} type="text" name="detailAddress" className="input_text" />
              </li>

              <li className="email_area">
                <h4>{t("detail-id")}<span className="red"> *</span></h4>
                <span>{t("detail-explanId")}</span>
                {/* <input value={'asd'} type="text" name="" id="" className="input_text" placeholder="admin@bmcnet.kr, adrian@bmcnet.kr" /> */}
                <ChipInput
                  className="chip-input"
                  value={chipEmail}
                  disableUnderline={false}
                  onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  onAdd={(chip) => handleAddChip(chip)}
                  style={{ width: "100%", padding: '5px 20px', border: 0, outline: 0, fontSize: '16px' }}
                  newChipKeyCodes={[13, 188]}
                  placeholder={requestEmail}
                />
              </li>
            </ul>
            <div className="section_2">
              <h4>{t("detail-businessArea")}<span className="red"> *</span></h4>
              <CheckList title={t("detail-shipbuilding")} open={division} onOpen={() => setDivision(prev => !prev)} list={divisionList} checkList={divisionCheckedList} onChange={handleDivisionChange} showData={showDivision} />

            </div>
            <div className="section_3">
              <h4>{t("detail-holdingCertificate")}</h4>
              <CheckList title={t("detail-certificate")} open={certification} onOpen={() => setCertification(prev => !prev)} list={certificationList} checkList={certificationCheckList} onChange={handleCertificationChange} showData={showCertification} />
            </div>
            <div className="section_4">
              <h4>{t("detail-product")}<span className="red"> *</span></h4>
              <span>{t("detail-explanProduct")}</span>
              <ChipInput
                className="chip-input"
                value={chipProduct}
                disableUnderline={false}
                onDelete={(chip, index) => handleDeleteProductChip(chip, index)}
                onAdd={(chip) => handleAddProductChip(chip)}
                style={{ width: "100%", padding: '5px 20px', border: 0, outline: 0, fontSize: '16px' }}
                newChipKeyCodes={[188]}
                placeholder={requestProduct}
              />
            </div>
            <div className="section_5">
              <h4>{t("detail-sns")}</h4>
              <ul>
                <InputLabels inputType='sns' value={values.instagram} onChange={handleChange} name="instagram" placeholder={t("detail-instagram")} />
                <InputLabels inputType='sns' value={values.youtube} onChange={handleChange} name="youtube" placeholder={t("detail-youtube")} />
                <InputLabels inputType='sns' value={values.facebook} onChange={handleChange} name="facebook" placeholder={t("detail-facebook")} />
                <InputLabels inputType='sns' value={values.skype} onChange={handleChange} name="skype" placeholder={t("detail-skype")} />
                <InputLabels inputType='sns' value={values.linkedin} onChange={handleChange} name="linkedin" placeholder={t("detail-linkedin")} />
              </ul>
            </div>
            <div className="section_6">
              <h4>{t("detail-intro")}<span className="red"> *</span></h4>
              <QuillEditor
                value={introduction}
                onChange={onChange}
              // onChange={(content, delta, source, editor) => onChange(editor.getHTML())}
              />

              {/* <textarea ref={element => (inputRef.current[7] = element)} value={values.companyDescription} onChange={handleChange} name="companyDescription" id="" placeholder="기업 소개글을 200자 내로 입력해 주세요."></textarea>
                            <span className="text_count">0/200</span> */}
            </div>

            <div className="section_8">
              <button>{t("detail-cancel")}</button>
              <button onClick={handleSubmit}>{t("detail-save")}</button>
            </div>
          </div>
        </div>
      </div>
      {
        <PostCodeModal show={isOpen} handleClose={handleClose} handleComplete={handleComplete} />
      }
    </>
  )
}


const DashBoardDetail = () => {
  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);
  
  const [getDetail , { data, refetch }]: any = useGetCompanyDetailsMutation();
  const getDetailList = async() => {
    const param = {companyId : user.companyId};
    await getDetail(param);
  }

  useEffect(()=>{
    if(user.companyId){
      getDetailList()
    }
  }, [user.companyId])

  if (!data) {
    return <></>;
  }
  return (
    <>
    {user.companyId ? 
    <DashBoardDetailBox refetch={refetch} data={data} />
    : ''
    }
    </>
  )
}

export default DashBoardDetail;

