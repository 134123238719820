import QueryString from "qs";
import { useSearchParams } from "react-router-dom";
import IFrameControlCompnent from "../../components/xvl/IFrameControl";
import styled from "./EmbeddedIFrame.module.css"

const EmbeddedIFrame = () => {

  const [searchParams] = useSearchParams();
  const modelingId = searchParams.get("modelingId") ?? "0";
  const modelingName = searchParams.get("modelingName") ?? "Sample";
  const path = searchParams.get("path") ?? "Sample";

  return (
    <>
      <div className={styled.fullscreen}>
        <IFrameControlCompnent
          path={path}
          iframePath={path}
        />
      </div>
    </>
  );
}

export default EmbeddedIFrame;