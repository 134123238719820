import React, { FC } from 'react';
import { SelectFilter } from '../../../../components/dashboard/dashboard-modeling-orders/selectFilter/selectFilter';
import GridSearchBox from '../../../../components/grid/gridSearchBox';

//김건우
import { useTranslation } from "react-i18next";

interface DashboardModelingOrdersListFilterProps {
  onClick: any;
  filter: any;
  onSelectAll: any;
  totalList: any;
  onChangeKeyword: (value: string) => void;
}

const DashboardModelingOrdersListFilter: FC<DashboardModelingOrdersListFilterProps> = (props) => {

  // All > 제작불가를 제외하고 모두 표기
  // 제작불가(S06) > S06 Only
  // 기타 토글 & 합집합

    //김건우
    const { t } = useTranslation();

  const { onClick, filter, onSelectAll, totalList, onChangeKeyword } = props;

  let l1length = totalList.filter((item: any) => item.status === 'S01').length;
  let l2length = totalList.filter((item: any) => item.status === 'S02').length;
  let l3length = totalList.filter((item: any) => item.status === 'S03').length;
  let l4length = totalList.filter((item: any) => item.status === 'S04').length;
  let l5length = totalList.filter((item: any) => item.status === 'S05').length;
  let l6length = totalList.filter((item: any) => item.status === 'S06').length;

  return (
    <>
      <div className="list-menu" style={{ position: 'sticky', top: '51px', zIndex: 10, display: 'flex', width: '100%', background: '#fff', justifyContent: 'space-between', paddingRight: '20px', alignItems: 'center' }}>
        <div style={{ background: '#fff', display: 'flex', width: '100%' }}>
          <div style={{ width: '65px' }}>
            <span></span>
          </div>
          <div style={{ position: 'relative' }}>
            <ul>
              <SelectFilter checked={props.filter.length === 5} onClick={() =>{props.onSelectAll()}} text={"ALL"} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-reciveWaiting"))} onClick={() =>{props.onClick(t("modelingOrder-reciveWaiting"))}} text={`${t("modelingOrder-reciveWaiting")}(${l1length})`} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-paymentWaiting"))} onClick={() =>{props.onClick(t("modelingOrder-paymentWaiting"))}} text={`${t("modelingOrder-paymentWaiting")}(${l2length})`} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-paymentComplete"))} onClick={() =>{props.onClick(t("modelingOrder-paymentComplete"))}} text={`${t("modelingOrder-paymentComplete")}(${l3length})`} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-making"))} onClick={() =>{props.onClick(t("modelingOrder-making"))}} text={`${t("modelingOrder-making")}(${l4length})`} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-makingComplete"))} onClick={() =>{props.onClick(t("modelingOrder-makingComplete"))}} text={`${t("modelingOrder-makingComplete")}(${l5length})`} />
              <SelectFilter checked={props.filter.includes(t("modelingOrder-makingUnable"))} onClick={() =>{props.onClick(t("modelingOrder-makingUnable"))}} text={`${t("modelingOrder-makingUnable")}(${l6length})`} />
            </ul>
          </div>
        </div>

        <GridSearchBox onChangeKeyword={onChangeKeyword} />

      </div>
    </>
  )
}

export default DashboardModelingOrdersListFilter;