import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetAssemblePerformanceMutation } from "../../../../store/slices/modeling/modelingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";

export interface Props {getPerformance : Function; onClose : Function}
const ProductPerfoemanceRegist:React.FC<Props> = ({getPerformance, onClose}) => {
    const {modelingId} = useParams()
    const userData : any = useAppSelector(state => state.loginUser.userInfo);
    const [setAssemblePerformanceApi] = useSetAssemblePerformanceMutation()
    const [performanceInfo, setPerformanceInfo] = useState<any>({modelingId : modelingId, performanceSubjectKr : '', performanceSubjectEn : '', performanceFiles : []})
    const handleFilesUpload = (files : any) => {
        setPerformanceInfo({...performanceInfo, performanceFiles : files})
    }
    const handleDelFiles = (index : number) => {
        const updatedFiles = [...performanceInfo.performanceFiles];
        updatedFiles.splice(index, 1)
        setPerformanceInfo({...performanceInfo, performanceFiles : updatedFiles})
    }
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPerformanceInfo({...performanceInfo, [name]:value})
    }
    const handleSave = async() => {
        const formData = new FormData();
        formData.append('AssetModelingId' , performanceInfo.modelingId)
        formData.append('UserId' , userData.userId)
        formData.append('performance_subject_kr' , performanceInfo.performanceSubjectKr)
        formData.append('performance_subject_en' , performanceInfo.performanceSubjectEn)
        performanceInfo?.performanceFiles?.forEach((file:File, index:number) => {
            formData.append(`performance_files_${index+1}`, file)
        });
        formData.append('files_length', performanceInfo?.performanceFiles?.length);
        const result : any = await setAssemblePerformanceApi(formData);
        if(result.data.result === true) { alert("등록이 완료되었습니다."); getPerformance(); onClose() }
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>제품실적 등록</span>
            </div>
            
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="information"/></span> 등록된 이미지 선택하면 대표 이미지가 변경됩니다.</p>

                    <Dropzone type={'JPG'} onFileUpload={handleFilesUpload}/>

                    <ul className={PageStyles.dropZone_imgBox}>
                    {performanceInfo?.performanceFiles?.map((files:any, index:number) => (
                        // <img src={URL.createObjectURL(files)} alt="pic"/>
                        <li key={index}>
                            <div>
                                <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/whiteCloseBtn.png" alt="" /></button>
                                <img src={URL.createObjectURL(files)} alt="pic"/>
                            </div>
                        </li>
                    ))}
                    </ul>
                </div>
                <table>
                    <tr>
                        <td className={PageStyles.admin_input_underline}>
                            <span className={PageStyles.lang}>KR</span>
                            <input type="text" name="performanceSubjectKr" id="performance_title_kr" placeholder="실적 국문 제목" onChange={handleChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={PageStyles.admin_input_underline}>
                            <span className={PageStyles.lang}>EN</span>
                            <input type="text" name="performanceSubjectEn" id="performance_title_en" placeholder="실적 영문 제목" onChange={handleChange}/>
                        </td>
                    </tr>
                </table>
            </div>

            <div className={PageStyles.imgUpload_btnBox}>
                <div className={[PageStyles.imgUpload_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                <button type="button" className={PageStyles.dots_performance_submit} id="dots_performance_submit" onClick={handleSave}>등록</button>
            </div>
            
        </div>
        </>
    )
}

export default ProductPerfoemanceRegist;