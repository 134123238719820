import { forwardRef } from "react";
import { PageInfo } from "./gridPageInfo";

// 외부에서 사용하기 위한 인터페이스
export interface EventColumnHeader {
}

// props
export interface PropsColumnHeader {
  id: string,
  caption: string,
  pageInfo: PageInfo,
  width?: number;
  onClick?: Function,
  widthStyle?: string,
}

const GridColumnHeader = forwardRef<EventColumnHeader, PropsColumnHeader>((props,  ref) => {

  const { width } = props;

  const checkOrderStatus = () => {
    if( !props.onClick )
      return "";
      
    if( props.pageInfo.sortColumn !== props.id )
      return "sort";

    if( props.pageInfo.sortOrder === false)
      return "sort desc";
    else
      return "sort asc";
  }

  const onClick = () => {
    if( props.onClick ) {
      props.onClick(props.id);
    }
  }

  const orderStatus = checkOrderStatus();
  
  return (
    <>
      <th style={{width:`${width}%`,whiteSpace:'nowrap'}} className={props.widthStyle}>
        <span className={"sortWrap"} onClick={onClick}>
          {props.caption}<span className={orderStatus} style={{position:'static'}}></span>
        </span>
      </th>
    </>
  );

});

export default GridColumnHeader;