import React, { useRef } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PageStyles from '../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
export interface Props {image : any}
const SlickSlider:React.FC<Props> = ({image}) => {
    const sliderRef = useRef<any>()
    const setting = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // 한 번에 보여지는 갯수
        slidesToScroll: 1, // 한 번에 스크롤되는 슬라이드 수
        vertical: false,
        arrows : true
    }
    const data =[
        {idx : 1, img : 'https://miro.medium.com/v2/resize:fit:1200/1*RfTwVSzlXZcwpqpuvJ37Zg.png'},
        {idx : 2, img : 'https://i.ytimg.com/vi/CF1Ht5ahhB0/maxresdefault.jpg'},
        {idx : 3, img : 'https://i.stack.imgur.com/9SDer.jpg'},
        {idx : 4, img : 'https://i.stack.imgur.com/WNMhK.jpg'},
    ]
    console.log(image)
    return(
        <>
        
        <div className={PageStyles.page_carousel}>
            <Slider {...setting} ref={sliderRef}>
                {image?.map((data : any, index : number) => (
                    // <li className="active"
                    <div className={PageStyles.height_box}>
                        <img src={data.performance_image_pdsFilename} alt="thumbnail"/>
                    </div>
                ))}
            </Slider>
        </div>
        <div className={PageStyles.product_performance_pageCount}>
            <span className={PageStyles.current}>00</span>
            <span>/</span>
            <span className={PageStyles.total}>00</span>
        </div>
        </>
    )
}

export default SlickSlider;