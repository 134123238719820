import { forwardRef } from "react";

// 외부에서 사용하기 위한 인터페이스
export interface EventColumnHeader {
}

// props
export interface PropsColumnHeader {
  id?: string,
  caption?: string,
  onClick?: Function,
  widthStyle?: string,
  pageInfo: any;
  headerClick: any;
  data?:any;
  index?: number;
  type?: any;
}

const GroupColumnHeader = forwardRef<EventColumnHeader, PropsColumnHeader>((props,  ref) => {

  const { pageInfo, headerClick, data } = props;

  const checkOrderStatus = () => {

    if(data.key === '') {
        return '';
    }

    if( pageInfo.sortColumn !== data.key )
      return "sort";

    if( pageInfo.sortOrder === false)
      return "sort desc";
    else
      return "sort asc";
  }

  const orderStatus = checkOrderStatus();
  
  return (
    <>
        <div className={'rece-col'} style={{whiteSpace:'nowrap'}}>
            <span onClick={()=> headerClick(data)} className={orderStatus}>{data.title}</span>
        </div>
    </>
  );

});

export default GroupColumnHeader;