import React, { forwardRef, useEffect, useRef, useState } from "react";
import DashboardModelingMainPopup from "../../pages/dashboard/dashboard-modeling-orders/dashboard-modeling-popup";
import { useGetAssetModelDetailMutation, useGetOrderModelDetailMutation } from "../../store/slices/dashboard/dashboardModelingOrderApiSlice";
import { ModelingOrderDetailType, ModelingOrderType } from "../../types/modelingOrderType";
import GroupAvaliableItem from "./groupAvailableItem";
import GroupListHeader from "./groupListHeader";
import GroupListItem from "./groupListItem";
import SuppleGroupListHeader from "./suppleGroupListHeader";

export interface IOrderData {
  amount: number,
}

export interface EventGroupListBox {
}

export interface PropsGroupListBox {
  groupName: string,
  groupColor: string,
  list: Array<any>,
  show: any;
  type: string;
  getGrid?: () => void;
  headerClick: any;
  pageInfo: any;
  
}

const SuppleGroupListBox = forwardRef<EventGroupListBox, PropsGroupListBox>((props, ref) => {

  const [detailInfo, setDetailInfo] = useState<ModelingOrderDetailType | any>();
  const { list, show, groupColor, groupName, type, getGrid, headerClick, pageInfo } = props;

  // const [getOrderModelDetail] = useGetOrderModelDetailMutation();
  const [getAssetModelDetail] = useGetAssetModelDetailMutation();

  const [requestPopupInfo, setRequestPopupInfo] = useState({
    isOpen: false,
    selectedPopupInfo: {} as ModelingOrderType | any,
    detailInfo: {} as ModelingOrderDetailType | any
  })

  const { isOpen, selectedPopupInfo } = requestPopupInfo;

  const getDetailInfo = async (data: any) => {
    let result: any;
    try {
      if (type !== 'defaultHeaderList') {
        result = await getAssetModelDetail(data.modelingId);
      } else {
        console.log('getOrderModelDetail?????????????????');
        // result = await getOrderModelDetail(data.modelingId)
      }

      if (result.data.result) {
        setDetailInfo(result.data.detail);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const openPopup = async (data: any) => {

    await getDetailInfo(data);

    setRequestPopupInfo({
      ...requestPopupInfo,
      isOpen: true,
      selectedPopupInfo: data
    })
  }

  const closePopup = () => {
    setRequestPopupInfo({
      ...requestPopupInfo,
      isOpen: false
    })
  }

  if (!list) {
    return <></>;
  }

  const handleClickModelingCtrl = (iframePath : string) => {
    alert("1")
  }

  return (
    <>
      <div style={show.includes(groupName) ? { marginBottom: '50px' } : { display: 'none', marginBottom: '50px' }} id="receipt-container" className="receipt-container">
        <div className="receipt-head">
          <div className="head-name">
            {/* 그룹별 색상 */}
            {/* 접수대기 : #666666 , 결제대기 : #256DCB , 결제완료 : #FFCC00 , 제작중 : F37373 , 제작완료 : #93D955 , 제작불가 : #CCC */}
            <div className="status-blue" style={{ background: `${groupColor}` }}>
              <span>{groupName}</span>
            </div>
            <div className="line"></div>
            <div className="arrow">
              <img src="/assets/images/da-arrow-down.png" alt="" />
            </div>
          </div>
          <SuppleGroupListHeader headerClick={headerClick} pageInfo={pageInfo} type={type} />
        </div>
        {
          <div className="receipt">
            <ul>
              <li>
                <div className="rece-content">
                  {
                    list.map((item: any, index: number) => (
                      <GroupAvaliableItem getGrid={getGrid} type={type} key={index} openPopup={openPopup} data={item} groupColor={groupColor}/>
                    ))
                  }
                </div>
              </li>
            </ul>
          </div>
        }
        {isOpen &&
          <DashboardModelingMainPopup type={type} detailInfo={detailInfo} getDetailInfo={getDetailInfo} selectedPopupInfo={selectedPopupInfo} closePopup={closePopup} />
        }
      </div>
    </>
  )
});

export default SuppleGroupListBox;