import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const taskPopupApi = createApi({
    reducerPath : "taskPopupApi",
    baseQuery : fetchBaseQuery({
        baseUrl : "http://210.114.19.235:8080/api"
    }),

    endpoints : (builder : any) => ({

        // subTask 리스트 불러오기
        getSubTaskList : builder.mutation({
            query : (body : {taskId : any}) => {
                return {
                    url : "/order-model/subtask-list?todolist_id="+body.taskId,
                    method : "get",
                };
            },
        }),

        // 노트 리스트 불러오기
        getNoteList : builder.mutation({
            query: (body : {taskId : any}) => {
                return {
                    url : "/order-model/notes-list?todolist_id="+body.taskId,
                    method : "get",
                };
            },
        }),
        // 노트 생성
        createNote: builder.mutation({
            query: (body : {userId : any, taskId : any, subject : string, contents : string, star : string}) => {
                return {
                    url : "/order-model/notes-create?creator_id="+body.userId+"&todolist_id="+body.taskId+"&subject="+body.subject+"&contents="+body.contents+"&star="+body.star,
                    method : "get",
                };
            },
        }),


    })
})

export const {useGetSubTaskListMutation ,useGetNoteListMutation ,useCreateNoteMutation} = taskPopupApi;