import React from "react";
import PageStyles from '../../css/page.module.css';
export interface Props {
    onClose : Function
}
const ContactUs:React.FC<Props> = ({onClose}) => {

    return(
        <>
        <div className={[PageStyles.popUp_3, PageStyles.active].join(" ")}>
            <div className={PageStyles.titleBox}>
                <span className={PageStyles.active}>문의리스트</span>
            </div>
            <button className={PageStyles.popUpClose_btn}> 
                <img src="/assets/images/modelingCtrl/popUpClose_btn.png" alt="close btn"/>
            </button>
            <fieldset>
                <div>
                    <ul className={PageStyles.contactUsWrap}>
                        <li className={PageStyles.active}>
                            <div className={PageStyles.contactUsTitle}>
                                <p><span>23.04.26</span>설비 구매 관련 문의의 건</p>
                                <span className={PageStyles.before}>답변전</span>
                            </div>

                            <div className={PageStyles.contactUsContent}>
                                <ul>
                                    <li className={PageStyles.half}>
                                        <label htmlFor="">회사명</label>
                                        <input type="text" name="" id="" readOnly/>
                                    </li>
                                    <li className={PageStyles.half}>
                                        <label htmlFor="">이름</label>
                                        <input type="text" name="" id="" readOnly/>
                                    </li>
                                    <li className={PageStyles.half}>
                                        <label htmlFor="">이메일</label>
                                        <input type="text" name="" id="" readOnly/>
                                    </li>
                                    <li className={PageStyles.half}>
                                        <label htmlFor="">연락처</label>
                                        <input type="text" name="" id="" readOnly/>
                                    </li>
                                    <li className={PageStyles.full}>
                                        <label htmlFor="">문의내용</label>
                                        <div>
                                            <textarea name="" id="" readOnly></textarea>
                                        </div>
                                    </li>
                                    <li className={PageStyles.full}>
                                        <label htmlFor="">문의답변</label>
                                        <div>
                                            <textarea name="" id="contactUs_answer" className="ipt-base ipt-border"></textarea>
                                        </div>
                                    </li>
                                </ul>
                                <div className={PageStyles.btnBox}>
                                    <button type="button">취소하기</button>
                                    <button type="submit">답변달기</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    
                </div>
            </fieldset>
        </div>
        </>
    )
}

export default ContactUs;