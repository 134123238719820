import React, { ChangeEvent, useState } from "react";

export interface Props {
    profileUrl : string
    onChange : Function
    onClose : Function
}

const EditProfileImage:React.FC<Props> = ({profileUrl, onChange, onClose}) => {

    const handleClose = () => {
        onClose()
    }

    const [profileImageInfo, setProfileImageInfo] = useState<any>({
        profileUrl : null,
        previewPic : null,
        isDel : false
    })

    // 파일 업로드
    const handleUpload = (e:any) => {
        // setProfileImageInfo({
        //     ...profileImageInfo,
        //     profileUrl : e.target.files[0]
        // })
        const reader = new FileReader();
        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
        } reader.onloadend = () => {
            const previewPicUrl = reader.result;

            if(previewPicUrl){
                setProfileImageInfo({
                    ...profileImageInfo,
                    profileUrl : e.target.files[0],
                    previewPic : previewPicUrl
                })
            }
        }
    }
    console.log(profileImageInfo)
    // 파일 삭제
    const handleRemoveImg = () => {
        setProfileImageInfo({
            ...profileImageInfo,
            profileUrl : '', previewPic : "/assets/images/dsbd/no-profile.png", isDel : true
        })
    }
    // 파일 저장
    const handleChangePic = () => {
        const profileUrl = profileImageInfo.profileUrl
        const isDel = profileImageInfo.isDel
        onChange(profileUrl, isDel)
    }

    return(
        <>
        <div className="profile_photo_popUp active">
            <div className="wrap">
                <div>
                    <div className="con on">
                        <div className="body">
                            <div className="content">
                                <h4>프로필 사진</h4>
                                <div className="photoBox">
                                    {profileUrl === undefined || profileUrl === '' ?
                                        <div className="profile_photo" style={profileImageInfo.previewPic ? {background : `url(${profileImageInfo.previewPic}) center center / cover`} : {background : `url(/assets/images/dsbd/no-profile.png) center center / cover`}}></div> :
                                        <div className="profile_photo" style={profileImageInfo.previewPic ? {background : `url(${profileImageInfo.previewPic}) center center / cover`} : {background : `url(${profileUrl}) center center / cover`}}></div>
                                    }
                                    <div className="uploadBox">
                                        <div>
                                            <input type="file" name="file" id="profile_photoUpload" onChange={handleUpload}/>
                                            <label htmlFor="profile_photoUpload"><span className="photoUpload_btn"></span></label>
                                        </div>
                                        <button onClick={handleRemoveImg}></button>
                                    </div>
                                </div>
                                <div className="buttonBox">
                                    <button onClick={handleChangePic}>저장</button>
                                    <button onClick={handleClose}>취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg"></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default EditProfileImage;