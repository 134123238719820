import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SuppleGroupListBox from "../../../components/grouplist/suppleGroupListBox";
import { useGetAssetModelListMutation } from "../../../store/slices/dashboard/dashboardModelingOrderApiSlice";
import { RootState } from "../../../store/store";
import { RequestUserType } from "../../../types/modelingOrderType";
import DashboardModelingSuppleListFilter from "./DashboardModelingSuppleListFilter";

//김건우
import { useTranslation } from "react-i18next";

interface DashBoardModelingSuppleProps {
}

export type SuppleType = {
  companyId: number;
  keyword: string;
  sortColumn: string;
  sortOrder: boolean;
}

export enum eAssetModelingGroupName {
  Enable = "사용 가능",
  Expired = "기간 만료",
}


const DashboardModelingSupple: FC<DashBoardModelingSuppleProps> = (props) => {

  //김건우
  const { t } = useTranslation();

  const filterList: string[] = [t("modelingSupple-useable"), t("modelingSupple-expiration")]

  const user: any = useSelector((state: RootState) => state.loginUser.userInfo);

  const [getAssetModelList] = useGetAssetModelListMutation();

  const [selectedFilter, setSelectedFilter] = useState<string[]>(filterList);
  const [totalList, setTotalList] = useState<Array<any>>([]);
  const [listS01, setListS01] = useState<Array<any>>([]);
  const [listS02, setListS02] = useState<Array<any>>([]);

  const [pageInfo, setPageInfo] = useState<SuppleType>({
    companyId: user.companyId,
    keyword: '',
    sortColumn: '',
    sortOrder: false
  })

  const handleSelectAll = () => {
    setSelectedFilter(filterList);
  }

  const handleSelectfilter = (filter: string) => {
    if (!selectedFilter.includes(filter)) {
      setSelectedFilter(prev => [...prev, filter])
    } else {
      if( selectedFilter.length === 1 ) {
        return;
      }
      setSelectedFilter(prev => [...prev.filter(data => data !== filter)])
    }
  }

  const getGrid = async () => {

    try {

      // console.log(`정렬 ${pageInfo.sortColumn} ${pageInfo.sortOrder} -------------------------------- `);
      const response: any = await getAssetModelList(pageInfo);
      const { result, list } = response.data;

      if (result && list.length > 0) {

        let enable: any = [];
        let expired: any = [];

        list.map((x: any) => {

          if (new Date(x.expiredDate) > new Date()) {
            enable.push(x);
          } else {
            expired.push(x);
          }
        });

        setTotalList(response.data.list);
        setListS01(enable);
        setListS02(expired);
      }
    } catch (err) {
      console.error("api fetch error");
    }
  }

  const handleQueryChange = (input: string) => {
    setPageInfo({
      ...pageInfo,
      keyword: input
    })
  }

  const headerClick = (data: any) => {

    if (data.key === '')
      return;

    setPageInfo({
      ...pageInfo,
      sortColumn: data.key,
      sortOrder: !pageInfo.sortOrder
    })
  }

  useEffect(() => {
    getGrid();
  }, [pageInfo])

  useEffect(()=>{
    if(user.companyId){setPageInfo({...pageInfo, companyId : user.companyId})}
  },[user.companyId])

  return (
    <>
      <DashboardModelingSuppleListFilter onChangeKeyword={handleQueryChange} totalList={totalList} onSelectAll={handleSelectAll} onClick={handleSelectfilter} filter={selectedFilter} />
      <div className="list-wrap">
        <div className="list-contents">
          <div className="list-box">
            <div className="box">
              <SuppleGroupListBox headerClick={headerClick} pageInfo={pageInfo} getGrid={getGrid} type="useHeaderList" show={selectedFilter} groupName={eAssetModelingGroupName.Enable} groupColor="#256DCB" list={listS01} />
              <SuppleGroupListBox headerClick={headerClick} pageInfo={pageInfo} getGrid={getGrid} type="expireHeaderList" show={selectedFilter} groupName={eAssetModelingGroupName.Expired} groupColor="#CCCCCC" list={listS02} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardModelingSupple;
