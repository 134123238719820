import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetPartsManuelMutation } from "../../../../store/slices/modeling/modelingPartsApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
import Dropzone from "../../../dropzone/dropzone";

export interface Props{getManuel: any;partsName:string; partsId:string; onClose :any}
const PartsMenualRegist:React.FC<Props> = ({getManuel, partsName, partsId, onClose}) => {
    const {modelingId}:any = useParams()
    const userData : any = useAppSelector(state=>state.loginUser.userInfo)
    const [setPartsManualApi] = useSetPartsManuelMutation()
    const [menualInfo, setMenualInfo] = useState<any>({modelingId : modelingId, partsId : partsId, lang : 'KR',menualFiles : []})
    const handleUpload= (files:any) => {
        setMenualInfo((prev:any) => ({
            ...prev , menualFiles : files
        }))
    }
    const handleChangeLang = (lang : string) => {
        setMenualInfo((prev:any) => ({
            ...prev, lang : lang
        }))
    }
    const handleDelFiles = (index : number) => {
        const updatedFiles = [...menualInfo.menualFiles];
        updatedFiles.splice(index, 1)
        setMenualInfo({...menualInfo, menualFiles : updatedFiles})
    }
    const handleSave = async() => {
        const formData = new FormData()
        formData.append('idx', partsId)
        formData.append('AssetModelingId', modelingId)
        formData.append('UserId', userData.userId)
        formData.append('part_unique_id', partsName)
        formData.append('parts_manuel_lang', menualInfo.lang)
        menualInfo.menualFiles.forEach((file : any , index : number) => {
            formData.append(`manuel_files_${index+1}`, file)
        })
        formData.append('files_length', menualInfo.menualFiles.length)
        const result : any = await setPartsManualApi(formData)
        if(result.data.result === true) {alert("등록이 완료되었습니다."); getManuel(); onClose();}
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>매뉴얼 업로드</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.langBoxWrap}>
                        <div className={PageStyles.langBox}>
                            <span className={[PageStyles.lang, PageStyles.lang_KR, menualInfo.lang==='KR'?PageStyles.active:''].join(" ")} onClick={()=>handleChangeLang('KR')}>KR</span>
                            <span className={[PageStyles.lang, PageStyles.lang_EN, menualInfo.lang==='EN'?PageStyles.active:''].join(" ")} onClick={()=>handleChangeLang('EN')}>EN</span>
                        </div>
                        <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 업로드가 가능합니다.</p>
                    </div>


                    <div className={[PageStyles.kr, PageStyles.frmPartManuel_upload, PageStyles.active].join(" ")}>
                        <Dropzone type={'PDF'} onFileUpload={handleUpload}/>
                        {menualInfo?.menualFiles?.map((files:any,index:number)=>(
                            // <p>{files.name}</p>
                            <ul className={PageStyles.dropZone_attachmentBox} key={index}>
                                <li>
                                    <div className={PageStyles.dropZone_attachmentList}>
                                        <p key={index}>{files.name}</p>
                                        <button onClick={()=>handleDelFiles(index)}><img src="/assets/images/modelingCtrl/redCloseBtn.png" alt="deleteBtn"/></button>
                                    </div>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div className={PageStyles.frmPartManuel_btnBox}>
                    <div className={[PageStyles.frmPartManuel_cancleBtn, PageStyles.cancle].join(" ")} onClick={onClose}>취소</div>
                    <button type="button" id="dots_parts_manuel_submit" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartsMenualRegist;