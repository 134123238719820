import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageStyles from '../../../../pages/dashboard/dashboard-modeling-ctrl/bottomPopup/css/page.module.css';
import { useSetAssembleVideoMutation } from "../../../../store/slices/modeling/modelingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";

export interface Props {getVideo : Function, onClose : Function}
const ProductVideoRegist:React.FC<Props> = ({getVideo, onClose}) => {
    const {modelingId} = useParams()
    const userData : any = useAppSelector(state => state.loginUser.userInfo)
    const [setAssembleVideoApi] = useSetAssembleVideoMutation();
    // Video Regist Info
    const [videoInfo , setVideoInfo] = useState<any>({
        modelingId : modelingId, lang : 'KR', videoUrl : '', videoSubject : '' , videoDescription : '' 
    })
    // Lang Type Change
    const handleLang = (e:React.MouseEvent<HTMLSpanElement>, type : string) => {
        setVideoInfo({...videoInfo, lang : type})
    } 
    // text change
    const handleChange = (e : any) => {
        const {name, type, value} = e.target;
        {setVideoInfo({...videoInfo, [name] : value})}
    }
    const handleSave = async() => {
        const formData = new FormData();
        formData.append('AssetModelingId', videoInfo.modelingId)
        formData.append('UserId', userData.userId)
        formData.append('video_lang', videoInfo.lang)
        formData.append('video_title', videoInfo.videoSubject)
        formData.append('video_url', videoInfo.videoUrl)
        formData.append('video_description', videoInfo.videoDescription)
        const result : any = await setAssembleVideoApi(formData);
        if(result.data.result === true){
            alert("등록이 완료되었습니다.");
            getVideo(); onClose()
        }
    }
    return(
        <>
        <div className={[PageStyles.wrap, PageStyles.active].join(" ")}>
            <div className={PageStyles.miniPopUp_titleBox}>
                <span className={PageStyles.active}>홍보영상 업로드</span>
            </div>
            <div className={PageStyles.scrollBox}>
                <div className={PageStyles.paddingBox}>
                    <div className={PageStyles.langBoxWrap}>
                        <div className={PageStyles.langBox}>
                            <span className={[PageStyles.lang, PageStyles.lang_KR, videoInfo.lang === 'KR' ? PageStyles.active : ''].join(" ")} id="kr" onClick={(e)=>handleLang(e, 'KR')}>KR</span>
                            <span className={[PageStyles.lang,PageStyles.lang_EN , videoInfo.lang === 'EN' ? PageStyles.active : ''].join(" ")} id="en" onClick={(e)=>handleLang(e, 'EN')}>EN</span>
                        </div>
                        <p><span><img src="/assets/images/modelingCtrl/circle_info.png" alt="circle information"/></span> 언어 선택 후 영상 업로드가 가능합니다.</p>
                    </div>
                    <table className={[PageStyles.kr, PageStyles.active].join(" ")}>
                        <tr>
                            <td className={PageStyles.admin_input_underline}>
                                <input type="text" name="videoUrl" id="modeling_video_kr_url" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 링크를 기입해 주세요.":"홍보영상(영문) 링크를 기입해 주세요."} onChange={handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={PageStyles.admin_input_border}>
                                <span><img src="/assets/images/modelingCtrl/popUp_videoTitle.png" alt="popUp_videoTitle"/></span>
                                <input type="text" name="videoSubject" id="modeling_video_title_kr" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 제목을 기입해 주세요.":"홍보영상(영문) 제목을 기입해 주세요."} onChange={handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={PageStyles.admin_input_border}>
                                <span><img src="/assets/images/modelingCtrl/popUp_videoText.png" alt="popUp_videoText"/></span>
                                <textarea name="videoDescription" id="modeling_video_description_kr" placeholder={videoInfo.lang==='KR'?"홍보영상(국문) 설명을 기입해 주세요.":"홍보영상(영문) 설명을 기입해 주세요."} onChange={handleChange}></textarea>
                            </td>
                        </tr>
                    </table>
                </div>


                <div className={PageStyles.frmAssembleVideo_btnBox}>
                    <div className={[PageStyles.frmAssembleVideo_cancleBtn, PageStyles.cancle].join(" ")} onClick={()=>onClose()}>취소</div>
                    <button type="button" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default ProductVideoRegist;