import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface taskListState {
    taskList : object,
}

const initialState : taskListState = {
    taskList : {},
}

export const taskListReducer = createSlice ({
    name : 'taskInfo',
    initialState,
    reducers : {
        setTaskList : (state, action) => {
            state.taskList = action.payload;
        }
    },
});

export const {setTaskList} = taskListReducer.actions;
export default taskListReducer.reducer;