import React from "react";
import Slider from 'infinite-react-carousel';


const Example = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <div>
                <Slider dots>
                    <div>

                    </div>
                    <div>
                        <div className="img_area img_area_2" style={{ backgroundImage: "url(/assets/images/main/web_3d_information_technology_section4_2.png)" }}></div>
                    </div>
                    <div>
                        <div className="img_area img_area_2" style={{ backgroundImage: "url(/assets/images/main/web_3d_information_technology_section4_2.png)" }}></div>
                    </div>
                    <div>

                    </div>
                    <div>

                    </div>
                </Slider>
            </div>
        </>
    )
}

export default Example;