import React, { FC } from 'react';
import { SelectFilter } from '../../../components/dashboard/dashboard-modeling-orders/selectFilter/selectFilter';
import GridSearchBox from '../../../components/grid/gridSearchBox';

//김건우
import { useTranslation } from "react-i18next";

interface DashboardModelingSuppleListFilterProps {
  onClick: any;
  filter: any;
  // isCheckAll:any;
  onSelectAll: any;
  // listS01: any;
  // listS02: any;
  totalList: any;
  onChangeKeyword: (value: string) => void;
}

const DashboardModelingSuppleListFilter: FC<DashboardModelingSuppleListFilterProps> = (props) => {

  //김건우
  const { t } = useTranslation();


  const colName1 = t("modelingSupple-useable");
  const colName2 = t("modelingSupple-expiration");

  let l1length = props.totalList.filter((item: any) => new Date(item.expiredDate) > new Date()).length;
  let l2length = props.totalList.filter((item: any) => new Date(item.expiredDate) <= new Date()).length;

  return (
    <>
      <div className="list-menu" style={{ position: 'sticky', top: '51px', zIndex: 10, display: 'flex', width: '100%', background: '#fff', justifyContent: 'space-between', paddingRight: '20px', alignItems: 'center' }}>
        <div style={{ background: '#fff', display: 'flex', width: '100%' }}>
          <div style={{ width: '65px' }}>
            <span></span>
          </div>
          <div style={{ position: 'relative' }}>
            <ul>
              <SelectFilter checked={props.filter.length === 2} onClick={() => { props.onSelectAll() }} text={"ALL"} />
              <SelectFilter checked={props.filter.includes(colName1)} onClick={() => { props.onClick(colName1) }} text={`${colName1}(${l1length})`} />
              <SelectFilter checked={props.filter.includes(colName2)} onClick={() => { props.onClick(colName2) }} text={`${colName2}(${l2length})`} />
            </ul>
          </div>
        </div>
        <GridSearchBox onChangeKeyword={props.onChangeKeyword} />
      </div>
    </>
  )
}

export default DashboardModelingSuppleListFilter;